import { StatusBar } from "expo-status-bar";
import React, { ReactNode } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { StyleProp, ViewStyle } from "react-native";
import StyleSheet from "react-native-media-query";

export default function Layout(props: LayoutProps) {

    return <SafeAreaView style={[ styles.container, props.style ]} dataSet={{ media: ids.container }}>
        <StatusBar style="auto" />
        {props.children}
    </SafeAreaView>
}

const { ids, styles } = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 24,
        '@media (min-width: 800px)': {
            alignSelf: "center",
            maxWidth: 960,
            margin: "auto",
        }
    },
    
});

interface LayoutProps {
    children: ReactNode,
    style?: StyleProp<ViewStyle>
}