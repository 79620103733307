import * as React from "react"
import Svg, {SvgProps, Path} from "react-native-svg"
import {memo} from "react"


const DistanceIcon = (props: SvgProps) => (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
        <Path
            d="M9.189 7.19266H12.7707V5.87156H10.1138L8.64588 3.42752C8.4257 3.06055 8.02937 2.81101 7.57432 2.81101C7.44221 2.81101 7.32478 2.83303 7.20735 2.86972L3.22937 4.11009V7.92661H4.55047V5.23303L6.09909 4.74862L3.22937 16H4.55047L6.65689 10.0477L8.36698 12.3303V16H9.68809V11.2954L7.86056 7.9633L8.39634 5.85688M9.10093 2.6422C9.83487 2.6422 10.422 2.05505 10.422 1.3211C10.422 0.587156 9.83487 0 9.10093 0C8.36698 0 7.77983 0.587156 7.77983 1.3211C7.77983 2.05505 8.36698 2.6422 9.10093 2.6422Z"
            fill="#3C2F03"/>
    </Svg>
)

const Memo = memo(DistanceIcon)
export default Memo
