import { Asset } from "expo-asset";
import { ExpoLeaflet, MapLayerType } from "expo-leaflet";
import { View, Platform, ViewStyle, StyleProp } from "react-native";
import * as FileSystem from 'expo-file-system';
import React, { useCallback, useEffect, useState } from "react";
import { mapData } from "../../data/mapData";
import { LeafletWebViewEvent, MapLayer, MapMarker, MapShape, OfflineTileImage } from "expo-leaflet/web/src/model";
import { ActivityIndicator, Modal, Portal, useTheme } from "react-native-paper";
import MapShapes from "../../data/mapShapes.json";
import TrailTile from "../trailTile/trailTile";
import { educationPointsData } from "../../data/educationPoints";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { LatLngBoundsLiteral, MapOptions } from "leaflet";
import { CombinedTheme } from "../../styles/theme";
import { useAllBoardVisited } from "../../hooks/visitHooks";
import TrailTileModal from "../trailTile/trailTileModal";

const pointVisited = `<svg width="21" height="30" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C6.33696 9.5 5.70107 9.23661 5.23223 8.76777C4.76339 8.29893 4.5 7.66304 4.5 7C4.5 6.33696 4.76339 5.70107 5.23223 5.23223C5.70107 4.76339 6.33696 4.5 7 4.5C7.66304 4.5 8.29893 4.76339 8.76777 5.23223C9.23661 5.70107 9.5 6.33696 9.5 7C9.5 7.66304 9.23661 8.29893 8.76777 8.76777C8.29893 9.23661 7.66304 9.5 7 9.5Z"
    fill="${CombinedTheme.colors.primary}"/>
</svg>`;


const point = `<svg width="21" height="30" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C6.33696 9.5 5.70107 9.23661 5.23223 8.76777C4.76339 8.29893 4.5 7.66304 4.5 7C4.5 6.33696 4.76339 5.70107 5.23223 5.23223C5.70107 4.76339 6.33696 4.5 7 4.5C7.66304 4.5 8.29893 4.76339 8.76777 5.23223C9.23661 5.70107 9.5 6.33696 9.5 7C9.5 7.66304 9.23661 8.29893 8.76777 8.76777C8.29893 9.23661 7.66304 9.5 7 9.5Z"
    fill="${CombinedTheme.colors.tertiary}"/>
</svg>`;

function useMapMarkers(): MapMarker[] {
    const visitedBoards = useAllBoardVisited();

    const [markers] = useState(() => educationPointsData.map((m, i) => ({
        id: (i + 1).toString(),
        position: { lat: Number(m.coordinates.latitude),  lng: Number(m.coordinates.longitude) },
        icon: `<span onmousedown="this.style.opacity=0.25" ontouchstart="this.style.opacity=0.25" style="transition: opacity 0.2s ease-in-out;filter: drop-shadow(3px 2px 2px rgb(0 0 0 / 0.4));color:${CombinedTheme.colors.tertiary}">${visitedBoards.includes(i) ? pointVisited : point}<span style="position:absolute;font-size: 16px; left: 12px; top:16px">${i + 1}</span></span>`
    })));

    return markers;
}

function useMapShapes(): MapShape[] {

    const theme = useTheme();

    const [shapes] = useState(() => [{
        shapeType: "polyline",
        positions: MapShapes.gpx.trk.trkseg.trkpt.map(p => ({
            lat: Number(p._lat),
            lng: Number(p._lon)
        })),
        id: "trail",
        color: theme.colors.primary,
        weight: 5,
        smoothFactor: 1
    } as MapShape]);

    return shapes;
}

const mapOptions: MapOptions = {
    maxBounds: [[51.5, 22.6], [52.5, 23.6]] as LatLngBoundsLiteral,
    minZoom: 12,
    preferCanvas: true,
    attributionControl: false
};

let offlineTileLayer: MapLayer | undefined; 

function useMapLayers() {
    const [layers, setLayers] = useState<MapLayer[]>([
         {
            url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
            attribution: `&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>`,
            layerType: "TileLayer" as MapLayerType,
            bounds: [[51, 22], [53, 24]],
            zIndex: 1
        },
        ...(offlineTileLayer ? [offlineTileLayer] : [])
    ]);

    useEffect(() => {

        if (offlineTileLayer) {
            console.log("offline map already loaded, skipping");
            return;
        }

        Asset.loadAsync(mapData.map(d => d.image)).then(async assets => {
            const promises = assets.map((a, i) => new Promise<OfflineTileImage>(resolve => {

                if (Platform.OS === "web") {
                    resolve({ imageUrl: a.localUri!, tile: mapData[i].tile });
                    return;
                }

                FileSystem.readAsStringAsync(a.localUri!, { encoding: 'base64' }).then(v => {
                    resolve({ imageUrl: "data:image/png;base64, " + v, tile: mapData[i].tile });
                });
            }));

            const result = await Promise.all(promises);

            offlineTileLayer = {
                layerType: "OfflineTileLayer" as MapLayerType,
                images: result
            };

            setLayers([offlineTileLayer, ...layers]);
        });
    }, []);

    return layers;
}

export default function MapView(props: MapViewProps) {
    const navigation = useNavigation();
    let mapMarkers = useMapMarkers();
    const mapShapes = useMapShapes();
    const isFocused = useIsFocused();
    const [mapCenterPosition, setMapCenterPosition] = useState({
        lat: props.latitude || 52.025,
        lng: props.longitude || 23.10802
    });

    const [modalBoardId, setModalBoardId] = useState<number>();
    const layers = useMapLayers();

    useEffect(() => {
        setMapCenterPosition({
            lat: props.latitude || 52.025,
            lng: props.longitude || 23.10802
        });
    }, [props.latitude, props.longitude]);

    const loadingIndicator = useCallback(() => <ActivityIndicator />, []);

    const onMessage = useCallback((e: LeafletWebViewEvent) => {

        if (e.tag === "onMapMarkerClicked") {
            console.log(e);
            setModalBoardId((+e.mapMarkerId) - 1);
        }
    }, []);

    if (!isFocused) {
        return null;
    }

    if (props.latitude && props.longitude) {
        const marker = mapMarkers.find(m => m.id == "here-you-are");

        if (marker) {
            mapMarkers = mapMarkers.filter(m => m.id !== "here-you-are")
        }
        
        mapMarkers.push({
            position: { lat: props.latitude, lng: props.longitude },
            id: "here-you-are",
            icon: `<div style="color:white;font-size:18px;text-wrap:nowrap;margin-top:32px;text-shadow: 1px 1px 3px black">Tu jesteś</div>`
        });
    }

    return <View
        style={[{ width: '100%', height: '100%' }, props.style]}>
        {layers.length > 0 ? <ExpoLeaflet
            loadingIndicator={loadingIndicator}
            mapLayers={layers}
            mapCenterPosition={mapCenterPosition}
            zoom={props.zoom || 12}
            maxZoom={18}
            onMessage={onMessage}
            mapOptions={mapOptions}
            mapMarkers={mapMarkers}
            mapShapes={mapShapes}
        /> : <ActivityIndicator />}
        <Portal>
            {Platform.OS === "web" ? <>
                {modalBoardId !== undefined && <View style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}>
                    <Modal visible={modalBoardId !== undefined} onDismiss={() => setModalBoardId(undefined)} style={{ alignItems: "center" }} contentContainerStyle={{ margin: 24, maxWidth: 700, borderRadius: 12 }}>
                        {modalBoardId !== undefined && <TrailTile elevation={0} onPress={() => setModalBoardId(undefined)} boardNumber={modalBoardId + 1} boardData={educationPointsData[modalBoardId]} navigation={navigation} />}
                    </Modal>
                </View>
                }</>
            : <Modal visible={modalBoardId !== undefined} onDismiss={() => setModalBoardId(undefined)} style={{ alignItems: "center" }} contentContainerStyle={{ margin: 24, maxWidth: 700 }}>
                {modalBoardId !== undefined && <TrailTileModal onPress={() => setModalBoardId(undefined)} boardNumber={modalBoardId + 1} boardData={educationPointsData[modalBoardId]} navigation={navigation} />}
            </Modal>}
        </Portal>
    </View>
 }

 interface MapViewProps {
    zoom?: number,
    latitude?: number,
    longitude?: number,
    style?: StyleProp<ViewStyle>
 }
