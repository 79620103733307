import * as React from 'react';
import {StyleSheet} from 'react-native';
import { Text } from 'react-native-paper';
import AudioInvoker from "../../components/audioPlayer/audioInvoker";
import { AudioPlaylistEntry } from "../../contexts/audioContext";
import { CombinedTheme } from "../../styles/theme";
import DidYouKnowTile from "../../components/didYouKnow/didYouKnowTile";
import EducationBoardPageBase from "../../components/educationBoardPageBase/educationBoardPageBase";
import BirdAudioInvoker from '../../components/audioPlayer/birdAudioInvoker';

export default function EducationBoard5() {
    return <EducationBoardPageBase boardNumber={5}>
            <EducationBoardContent />
    </EducationBoardPageBase>
}

const playlist: AudioPlaylistEntry[] = [{
    title: "Tablica edukacyjna nr 5",
    subtitle: "Ptaki parków i ogrodów",
    source: require("../../assets/boards/6/1.mp3")
}, {
    title: "Tablica edukacyjna nr 5",
    subtitle: "Kos",
    source: require("../../assets/boards/6/1.1.mp3")
}, {
    title: "Tablica edukacyjna nr 5",
    subtitle: "Modraszka",
    source: require("../../assets/boards/6/1.2.mp3")
}, {
    title: "Tablica edukacyjna nr 5",
    subtitle: "Bogatka",
    source: require("../../assets/boards/6/1.3.mp3")
}, {
    title: "Tablica edukacyjna nr 5",
    subtitle: "Zięba",
    source: require("../../assets/boards/6/1.4.mp3")
}, {
    title: "Tablica edukacyjna nr 5",
    subtitle: "Wróbel",
    source: require("../../assets/boards/6/1.5.mp3")
}, {
    title: "Tablica edukacyjna nr 5",
    subtitle: "Rudzik",
    source: require("../../assets/boards/6/1.6.mp3")
}, {
    title: "Tablica edukacyjna nr 5",
    subtitle: "Mazurek",
    source: require("../../assets/boards/6/1.7.mp3")
}, {
    title: "Tablica edukacyjna nr 5",
    subtitle: "Szpak",
    source: require("../../assets/boards/6/1.8.mp3")
}, {
    title: "Tablica edukacyjna nr 5",
    subtitle: "Czy wiesz, że? I",
    source: require("../../assets/boards/6/2.mp3")
}, {
    title: "Tablica edukacyjna nr 5",
    subtitle: "Czy wiesz, że? II",
    source: require("../../assets/boards/6/3.mp3")
}, ];

function EducationBoardContent() {
    return <>
        <AudioInvoker index={0} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Wiele ptaków dobrze zaadaptowało się do życia w mieście. Obok przedstawiamy kilka gatunków często obserwowanych w parkach i ogrodach. Miłego wypatrywania!
        </Text>
        <BirdAudioInvoker index={1} playlist={playlist} image={require("../../assets/boards/6/kos.png")} />
        <BirdAudioInvoker index={2} playlist={playlist} image={require("../../assets/boards/6/modraszka.png")} />
        <BirdAudioInvoker index={3} playlist={playlist} image={require("../../assets/boards/6/bogatka.png")} />
        <BirdAudioInvoker index={4} playlist={playlist} image={require("../../assets/boards/6/zieba.png")} />
        <BirdAudioInvoker index={5} playlist={playlist} image={require("../../assets/boards/6/wrobel.png")} />
        <BirdAudioInvoker index={6} playlist={playlist} image={require("../../assets/boards/6/rudzik.png")} />
        <BirdAudioInvoker index={7} playlist={playlist} image={require("../../assets/boards/6/mazurek.png")} />
        <BirdAudioInvoker index={8} playlist={playlist} image={require("../../assets/boards/6/szpak.png")} />
        <Text variant="labelSmall" style={styles.photoLabel}>
        rys. Malwina Łazęcka-Malińska
        </Text>
        <AudioInvoker index={9} playlist={playlist} />
        <DidYouKnowTile title={"Czy wiesz, że?"}
            alternativeColors={true}
            description={"Proces przystosowania się zwierząt lub roślin w środowisku silnie przekształconym przez człowieka nazywamy synantropizacją (z greckiego: syn — razem, anthropos — człowiek)."}
        />
        <AudioInvoker index={10} playlist={playlist} />
        <DidYouKnowTile title={"Czy wiesz, że?"}
            description={"Wróble to ptaki, które od dawna zasiedlają nasze miasta. Pojawiły się w ślad za końmi, które dawniej stanowiły główny środek transportu. Owies, którym karmiono konie, szczególnie zasmakował wróblom. Nie gardziły też niestrawionymi resztkami czy larwami znalezionymi w końskich odchodach."}
        />
</>
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 12
    },
    text: {
        marginBottom: 16
    },
    photoLabel: {
        color: CombinedTheme.colors.neutral60,
        marginBottom: 24,
        marginTop: 16
    }
});