import { AVPlaybackSource, AVPlaybackStatus } from "expo-av";
import { Sound } from "expo-av/build/Audio";
import React from "react";
import { createContext, ReactNode, useReducer } from "react";

export interface AudioState {
    playlist?: AudioPlaylistEntry[],
    current?: { sound?: Sound, index?: number },
    playbackStatus?: AVPlaybackStatus,
    playerHidden?: boolean
}

export interface AudioPlaylistEntry {
    source: AVPlaybackSource,
    title: string,
    subtitle: string
}

interface AudioActionStatus {
    type: AudioActionType.SetStatus,
    status: AVPlaybackStatus
}

interface AudioActionSound {
    type: AudioActionType.SetSound,
    sound: Sound,
    index: number
}

interface AudioActionPlaylist {
    type: AudioActionType.SetPlaylist,
    playlist: AudioPlaylistEntry[]
}

interface AudioActionClear {
    type: AudioActionType.ClearCurrent
}

interface AudioTogglePlayer {
    type: AudioActionType.TogglePlayer,
    value: boolean
}

export enum AudioActionType {
    SetSound,
    SetStatus,
    SetPlaylist,
    ClearCurrent,
    TogglePlayer
}

type AudioAction = AudioActionSound | AudioActionStatus | AudioActionPlaylist | AudioActionClear | AudioTogglePlayer;

export const AudioContext = createContext<{state: AudioState, dispatch: React.Dispatch<AudioAction>}>({ state: {}, dispatch: () => {} });

function reducer(state: AudioState, action:AudioAction ): AudioState {
    switch (action.type) {
        case AudioActionType.SetPlaylist:
            return { ...state, playlist: action.playlist };
        case AudioActionType.SetSound:
            return { ...state, current: { sound: action.sound, index: action.index }};
        case AudioActionType.SetStatus:
            return { ...state, playbackStatus: action.status};
        case AudioActionType.ClearCurrent:
            return { ...state, current: undefined };
        case AudioActionType.TogglePlayer:
            return { ...state, playerHidden: action.value };
        default:
            throw new Error();
    }
}

export function AudioContextProvider(props: { children: ReactNode }) {
    const [state, dispatch] = useReducer(reducer, {});

    return <AudioContext.Provider value={{ state, dispatch }}>
        {props.children}
    </AudioContext.Provider>

}