import {NavigationContainer} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {Provider as PaperProvider} from 'react-native-paper';
import HomePage from './pages/homePage';
import PanoramaPage from './pages/panoramaPage';
import ContactFormPage from './pages/contactForm';
import 'react-native-url-polyfill/auto';
import {CombinedTheme} from './styles/theme';
import {useFonts} from 'expo-font';
import {Lora_500Medium} from '@expo-google-fonts/lora';
import * as SplashScreen from 'expo-splash-screen';
import React, {useCallback} from 'react';
import {Inter_400Regular, Inter_500Medium, Inter_600SemiBold} from '@expo-google-fonts/inter';
import WelcomePage from './pages/welcomePage';
import {AudioContextProvider} from './contexts/audioContext';
import AudioPlayer from './components/audioPlayer/audioPlayer';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import AboutTrailPage from "./pages/aboutTrailPage";
import PlayerPage from "./pages/playerPage";
import MenuModal from './pages/menuModal';
import QrCodeScanner from "./components/qrCode/qrCodeScanner";
import EducationBoard1 from "./pages/educationBoards/educationBoard1";
import EducationBoard2 from "./pages/educationBoards/educationBoard2";
import EducationBoard3 from "./pages/educationBoards/educationBoard3";
import EducationBoard4 from "./pages/educationBoards/educationBoard4";
import EducationBoard5 from "./pages/educationBoards/educationBoard5";
import EducationBoard6 from "./pages/educationBoards/educationBoard6";
import EducationBoard7 from "./pages/educationBoards/educationBoard7";
import EducationBoard8 from "./pages/educationBoards/educationBoard8";
import EducationBoard9 from "./pages/educationBoards/educationBoard9";
import EducationBoard10 from "./pages/educationBoards/educationBoard10";
import EducationBoard11 from "./pages/educationBoards/educationBoard11";
import EducationBoard12 from "./pages/educationBoards/educationBoard12";
import EducationBoard13 from "./pages/educationBoards/educationBoard13";
import EducationBoard14 from "./pages/educationBoards/educationBoard14";
import EducationBoard15 from "./pages/educationBoards/educationBoard15";
import EducationBoard16 from "./pages/educationBoards/educationBoard16";
import EducationBoard17 from "./pages/educationBoards/educationBoard17";
import * as BackgroundFetch from 'expo-background-fetch';
import * as TaskManager from 'expo-task-manager';

import CheckGpsPage from "./pages/ar/checkGpsPage";
import CheckLocationPage from "./pages/ar/checkLocationPage";
import CheckWeatherPage from "./pages/ar/checkWeatherPage";

import { enableExperimentalWebImplementation } from 'react-native-gesture-handler';

import ARPage from './pages/arPage';
import WhatsNewPage from "./pages/whatsNewPage";
import ContactUsInitPage from "./pages/contactUsInitPage";
import QuizSummaryPage from './pages/quizSummaryPage';
import NewsTileDetails from "./components/newsTile/newsTileDetails";
import EStyleSheet from "react-native-extended-stylesheet";
import BranchProvider from './components/branchProvider/branchProvider';
import * as Notifications from 'expo-notifications';
import { getApi } from './hooks/useApi';
import { EventApi } from './api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useEvents, { scheduleNotifications } from './hooks/useEvents';
import { Platform } from 'react-native';

SplashScreen.preventAutoHideAsync();
EStyleSheet.build();

const Stack = createNativeStackNavigator();

enableExperimentalWebImplementation(true);

const linking = {
    prefixes: ["https://app.dolinakrzny.digimuth.com", "https://dolinakrzny.app.link", "dolinakrzny://"],
    config: {
        screens: {
            Welcome: "/",
            AboutTrail: "about-trail",
            Home: "home",
            ContactForm: "contact/form",
            ContactUsInitial: "contact",
            NewsPage: "news",
            NewsTileDetails: "news/details",
            PlayerPage: "/player",
            EducationBoard1: "board/1",
            EducationBoard2: "board/2",
            EducationBoard3: "board/3",
            EducationBoard4: "board/4",
            EducationBoard5: "board/5",
            EducationBoard6: "board/6",
            EducationBoard7: "board/7",
            EducationBoard8: "board/8",
            EducationBoard9: "board/9",
            EducationBoard10: "board/10",
            EducationBoard11: "board/11",
            EducationBoard12: "board/12",
            EducationBoard13: "board/13",
            EducationBoard14: "board/14",
            EducationBoard15: "board/15",
            EducationBoard16: "board/16",
            EducationBoard17: "board/17",
        }
    }
};

export default function App() {

    const [fontsLoaded] = useFonts({
        Lora_500Medium,
        Inter_400Regular,
        Inter_500Medium,
        Inter_600SemiBold
    });

    const onLayoutRootView = useCallback(async () => {
        if (fontsLoaded) {
            await SplashScreen.hideAsync();
        }
    }, [fontsLoaded]);

    useEvents();

    if (!fontsLoaded) {
        return null;
    }

    return (<AudioContextProvider>
        <SafeAreaProvider style={{flex: 1}}>
            <NavigationContainer linking={linking} onReady={onLayoutRootView} theme={CombinedTheme}>
                <PaperProvider theme={CombinedTheme}>
                    <BranchProvider>
                        <Stack.Navigator animationEnabled={true} screenOptions={{ headerShown: false }}>
                            <Stack.Screen name="Welcome" options={{ title: "Szlak Doliny Krzny w Białej Podlaskiej" }} component={WelcomePage} />
                            <Stack.Screen name="ArPage" component={ARPage} />
                            <Stack.Screen name="AboutTrail" component={AboutTrailPage} options={{ title: "O Szlaku - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="Home" component={HomePage} options={{ title: "Szlak Doliny Krzny w Białej Podlaskiej" }} />
                            <Stack.Screen name="Ar-CheckGps" component={CheckGpsPage} />
                            <Stack.Screen name="Ar-CheckLocation" component={CheckLocationPage} />
                            <Stack.Screen name="Ar-CheckWeather" component={CheckWeatherPage} />
                            <Stack.Screen name="PlayerPage" component={PlayerPage} />
                            <Stack.Screen name="ContactForm" component={ContactFormPage} options={{ title: "Kontakt - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name={"QrCodeScanner"} component={QrCodeScanner} />
                            <Stack.Screen name="Panorama" component={PanoramaPage} options={{ title: "Wirtualna wycieczka - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="NewsPage" component={WhatsNewPage} options={{ title: "Wydarzenia - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="NewsTileDetails" component={NewsTileDetails} options={{ title: "Wydarzenia - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="ContactUsInitial" component={ContactUsInitPage} options={{ title: "Kontakt - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="QuizSummary" component={QuizSummaryPage}/>

                            <Stack.Screen name="EducationBoard1" component={EducationBoard1} options={{ title: "Tablica edukacyjna nr 1 - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="EducationBoard2" component={EducationBoard2} options={{ title: "Tablica edukacyjna nr 2 - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="EducationBoard3" component={EducationBoard3} options={{ title: "Tablica edukacyjna nr 3 - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="EducationBoard4" component={EducationBoard4} options={{ title: "Tablica edukacyjna nr 4 - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="EducationBoard5" component={EducationBoard5} options={{ title: "Tablica edukacyjna nr 5 - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="EducationBoard6" component={EducationBoard6} options={{ title: "Tablica edukacyjna nr 6 - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="EducationBoard7" component={EducationBoard7} options={{ title: "Tablica edukacyjna nr 7 - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="EducationBoard8" component={EducationBoard8} options={{ title: "Tablica edukacyjna nr 8 - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="EducationBoard9" component={EducationBoard9} options={{ title: "Tablica edukacyjna nr 9 - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="EducationBoard10" component={EducationBoard10} options={{ title: "Tablica edukacyjna nr 10 - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="EducationBoard11" component={EducationBoard11} options={{ title: "Tablica edukacyjna nr 11 - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="EducationBoard12" component={EducationBoard12} options={{ title: "Tablica edukacyjna nr 12 - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="EducationBoard13" component={EducationBoard13} options={{ title: "Tablica edukacyjna nr 13 - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="EducationBoard14" component={EducationBoard14} options={{ title: "Tablica edukacyjna nr 14 - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="EducationBoard15" component={EducationBoard15} options={{ title: "Tablica edukacyjna nr 15 - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="EducationBoard16" component={EducationBoard16} options={{ title: "Tablica edukacyjna nr 16 - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            <Stack.Screen name="EducationBoard17" component={EducationBoard17} options={{ title: "Tablica edukacyjna nr 17 - Szlak Doliny Krzny w Białej Podlaskiej" }}/>
                            {/* put modals here */}
                            <Stack.Group screenOptions={{presentation: "fullScreenModal", headerShown: false}}>
                                <Stack.Screen name="MenuModal" component={MenuModal}/>
                            </Stack.Group>
                        </Stack.Navigator>
                    </BranchProvider>
                </PaperProvider>
            </NavigationContainer>
            <AudioPlayer/>
        </SafeAreaProvider>
    </AudioContextProvider>);
}


if (Platform.OS !== "web") {
    const BACKGROUND_FETCH_TASK = 'background-fetch';
    const eventsStorageKey = "whats_new_events";

    Notifications.setNotificationHandler({
        handleNotification: async () => ({
            shouldShowAlert: true,
            shouldPlaySound: true,
            shouldSetBadge: true,
        }),
    });
    
    TaskManager.defineTask(BACKGROUND_FETCH_TASK, async () => {
        console.log("background fetch task started");
        const api = getApi(EventApi);
        
        try {
            const response = await api.apiEventGet();
            
            scheduleNotifications(response.data);
            await AsyncStorage.setItem(eventsStorageKey, JSON.stringify(response.data));
            // Be sure to return the successful result type!
            return BackgroundFetch.BackgroundFetchResult.NewData;
        } catch (e) {
            console.log(e);
    
            return BackgroundFetch.BackgroundFetchResult.Failed;
        }
    });
    
    BackgroundFetch.registerTaskAsync(BACKGROUND_FETCH_TASK, {
        minimumInterval: 60 * 60 * 12, // 12 hours
        stopOnTerminate: false, // android only,
        startOnBoot: true, // android only
    });
}
