import Layout from "../components/layout/layout";
import {View} from "react-native";
import ContactUsCategoryTile from "../components/contactUsCategoryTile/contactUsCategoryTile";
import {ContactFormCategory} from "../api";
import {StyleSheet} from "react-native";
import {useNavigation} from "@react-navigation/native";


export const contactCategoryMapping: { [key: string]: string } = {
    [ContactFormCategory.FeedbackApplication]: "Pomoc z aplikacją",
    [ContactFormCategory.Partnership]: "Współpraca",
    [ContactFormCategory.MediaAndAdvertisement]: "Media i reklama",
    [ContactFormCategory.Other]: "Inne",
};

export default function ContactUsInitPage() {

    return <Layout title={"kontakt"} subtitle={"Skontaktuj się z nami"}>
        <View style={styles.tilesWrapper}>
            <ContactUsCategoryTile category={ContactFormCategory.FeedbackApplication} categoryName={"Pomoc z aplikacją"}/>
            <ContactUsCategoryTile category={ContactFormCategory.Partnership} categoryName={"Współpraca"}/>
            <ContactUsCategoryTile category={ContactFormCategory.MediaAndAdvertisement} categoryName={"Media i reklama"}/>
            <ContactUsCategoryTile category={ContactFormCategory.Other} categoryName={"Inne"}/>
        </View>
    </Layout>
}

const styles = StyleSheet.create({
    tilesWrapper: {
        marginTop: 24,
        display: "flex",
        flexDirection: "column",
        borderRadius: 16,
        flex: 1
    }
})
