import {StyleSheet, View} from "react-native";
import {Button, Text} from "react-native-paper";
import React, {useEffect, useState} from "react";
import {LinearGradient} from "expo-linear-gradient";
import {useNumberOfBoardsVisited} from "../../hooks/visitHooks";
import QuizModal from "../../pages/quizModal";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function QuizTile(props: QuizTileProps) {

    const visitedCount = useNumberOfBoardsVisited(); //todo change back to visited count<17
    const [topScore, setTopScore] = useState<number | undefined>(undefined);

    const getTopScore = async () => {
        try {
            const value = await AsyncStorage.getItem('@topScore')
            if (value !== null) {
                setTopScore(+value);
            }
        } catch (e) {
            // error reading value
        }
    }

    useEffect(() => {
        getTopScore();
    }, [props.modalVisible])

    return <LinearGradient colors={["#FAF3DD", "#FCEEC2"]} style={styles.tileWrapper}>
        <LinearGradient colors={["rgba(251, 250, 248, 0.2)", "rgba(251, 250, 248, 0)"]}>
            <QuizModal modalVisible={props.modalVisible} setModalVisible={props.setModalVisible}/>
            <View style={styles.textWrapper}>
                <Text variant={"headlineSmall"}>Quiz</Text>
                <Text variant={"bodyMedium"} style={{marginVertical: 16}}>Sprawdź swoją wiedzę zdobytą podczas wycieczki
                    na trasie. Aby odblokować quiz należy zameldować się we wszystkich punktach dostępnych na szlaku.
                </Text>
                <Text variant={"titleMedium"}
                      style={{marginBottom: 8}}>{topScore ? "Najlepszy wynik" : "Odwiedzone miejsca"}</Text>
                <Text variant={"headlineSmall"}
                      style={{marginBottom: 24}}>{topScore ? topScore + "/10" : visitedCount + "/17"}</Text>
                <Button disabled={visitedCount < 17} mode="contained" onPress={() => props.setModalVisible(true)}>
                    Przejdź do quizu
                </Button>
            </View>
        </LinearGradient>
    </LinearGradient>
}

interface QuizTileProps {
    modalVisible: boolean,
    setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const styles = StyleSheet.create({
    tileWrapper: {
        borderRadius: 28,
    },
    textWrapper: {
        padding: 24,
    }
})
