import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
import { memo } from "react"
import { CombinedTheme } from "../../styles/theme"

const ArrowsOut = (props: SvgProps) => (
  <Svg
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.732 15.268a.753.753 0 0 0-1.064 0l-6.163 6.163v-4.165a.752.752 0 1 0-1.505 0v5.982A.752.752 0 0 0 .752 24h5.982a.752.752 0 0 0 0-1.505H2.569l6.163-6.163a.752.752 0 0 0 0-1.064Zm6.536-6.536a.752.752 0 0 0 1.064 0l6.163-6.163v4.165a.752.752 0 0 0 1.505 0V.752A.752.752 0 0 0 23.248 0h-5.982a.752.752 0 1 0 0 1.505h4.166l-6.164 6.163a.753.753 0 0 0 0 1.064Z"
      fill={CombinedTheme.colors.tertiary}
    />
  </Svg>
)

const Memo = memo(ArrowsOut)
export default Memo
