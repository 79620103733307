import * as React from "react"
import Svg, { SvgProps, G, Path, Defs, ClipPath } from "react-native-svg"
import { memo } from "react"

const ArIcon = (props: SvgProps) => (
  <Svg
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <G clipPath="url(#a)">
      <Path
        d="M18.546 0v2.182h3.272v3.273H24V0h-5.454Zm3.272 21.818h-3.273V24H24v-5.454h-2.182v3.272ZM2.182 2.182h3.273V0H0v5.455h2.182V2.182Zm0 16.364H0V24h5.455v-2.182H2.182v-3.273ZM19.636 7.604 12 3.207 4.364 7.604v8.792L12 20.793l7.636-4.397V7.604ZM10.91 17.64l-4.364-2.51v-5.05l4.364 2.542v5.018ZM12 10.735l-4.32-2.52L12 5.727l4.32 2.488-4.32 2.52Zm5.454 4.396-4.363 2.509v-5.018l4.364-2.542v5.05Z"
        fill="#fff"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)

const Memo = memo(ArIcon)
export default Memo
