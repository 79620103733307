import { ImageBackground, StyleSheet } from "react-native";
import {LinearGradient} from "expo-linear-gradient";
import {Button, Text} from "react-native-paper"
import LoopArrowIcon from "../icons/loopArrowIcon";
import {useNavigation} from "@react-navigation/native";
import { useState } from "react";

export default function VirtualView(props: { boardId: number }) {

    const navigation = useNavigation();
    const [viewDimensions, setViewDimensions] = useState<{width: number}>({width: 0});

    const height = viewDimensions.width / 1.797;

    return <ImageBackground style={[{ height }, styles.image]}
                            source={require("../../assets/educationBoard/imageAr1.png")}
                            onLayout={(event) => {
                                if (event.nativeEvent.layout.width) {
                                    setViewDimensions({ width: event.nativeEvent.layout.width });
                                }
                            }}>
        <LinearGradient style={[styles.imageContent, { height }]} colors={["rgba(15, 15, 15, 0.05)", "rgba(15, 15, 15, 0.85)"]}>
            <Text style={{color: "#FFFFFF"}} variant={"labelLarge"}> Zobacz trasę w 360° </Text>
            <LoopArrowIcon style={styles.arrowIcon}/>
            <Button onPress={() => navigation.navigate("Panorama" as never, { id: props.boardId } as never)} mode={"contained"}>Rozpocznij wirtualne
                zwiedzanie</Button>
        </LinearGradient>
    </ImageBackground>
}

const styles = StyleSheet.create({
    image: {
        display: "flex",
        justifyContent: "center"
    },
    imageContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    arrowIcon: {
        marginTop: 13,
        marginBottom: 20
    }
})

