import * as React from 'react';
import {StyleSheet} from 'react-native';
import { Text } from 'react-native-paper';
import AudioInvoker from "../../components/audioPlayer/audioInvoker";
import { AudioPlaylistEntry } from "../../contexts/audioContext";
import FullWidthImage from "../../components/fullWidthImage/fullWidthImage";
import OrderedList from "../../components/orderedList/orderedList";
import { CombinedTheme } from "../../styles/theme";
import DidYouKnowTile from "../../components/didYouKnow/didYouKnowTile";
import EducationBoardPageBase from "../../components/educationBoardPageBase/educationBoardPageBase";

export default function EducationBoard1() {
    return <EducationBoardPageBase boardNumber={1}>
            <EducationBoardContent />
    </EducationBoardPageBase>
}

const playlist: AudioPlaylistEntry[] = [{
    title: "Tablica edukacyjna nr 1",
    subtitle: "(Nie taka) dzika rzeka Krzna",
    source: require("../../assets/boards/1/1.mp3")
}, {
    title: "Tablica edukacyjna nr 1",
    subtitle: "Co to są starorzecza?",
    source: require("../../assets/boards/1/2.mp3")
}, {
    title: "Tablica edukacyjna nr 1",
    subtitle: "Dlaczego reguluje się rzeki?",
    source: require("../../assets/boards/1/3.mp3")
}, {
    title: "Tablica edukacyjna nr 1",
    subtitle: "Czy wiesz, że?",
    source: require("../../assets/boards/1/4.mp3")
}];

function EducationBoardContent() {
    return <>
        <AudioInvoker index={0} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Z drogi, którą podążasz, co jakiś czas otwiera się widok na dolinę rzeki Krzny.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Krzna jest największym lewobrzeżnym dopływem Bugu. Od swoich źródeł w okolicach Łukowa do ujścia niedaleko Nepli pokonuje około 110 km. Przez większość tej trasy płynie uregulowanym korytem o łagodnych zakrętach, jednak nie zawsze tak było. Kiedyś rzeka wiła się jak wąż i co roku zmieniała swój bieg. Pamiątką z czasów przed regulacją Krzny są liczne, stopniowo zarastające starorzecza. Można je zobaczyć między innymi między Porosiukami a Białą Podlaską.
        </Text>
        <AudioInvoker index={1} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Co to są starorzecza?
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Starorzecza to inaczej jeziora przyrzeczne – fragmenty dawnego koryta rzeki, które zostały odcięte od obecnego nurtu. Mogą powstawać w sposób naturalny lub podczas regulacji rzeki (wtedy mówimy niekiedy o zbiornikach poregulacyjnych). Jak dokładnie się to odbywa? Spójrz na rysunek na tablicy „Powstawanie starorzeczy”.
        </Text>
        <FullWidthImage source={require("../../assets/boards/1/starorzecze_komiks.png")} style={{ marginTop: 8, marginBottom: 24 }} />
        <OrderedList items={[
            "Nurt rzeki biegnie bliżej jednego z brzegów, który jest podmywany i staje się coraz bardziej stromy.",
            "Przy drugiej stronie woda płynie wolniej i zbierają się tam osady. Drugi brzeg powiększa się i staje się coraz bardziej łagodny. Tworzy się zakole rzeki.",
            "Z czasem koryto rzeki wygina się coraz bardziej. Tworzy „pętlę” na rzece, zwaną meandrem.",
            "Przy okazji podniesienia poziomu wody, rzeka przelewa się przez brzegi i prostuje swój bieg. Może się też zdarzyć, że człowiek przekopuje szyję meandru, aby wyprostować rzekę. Dawny meander staje się starorzeczem.",
            "Starorzecze stopniowo zarasta. Ostatecznie zamienia się w błotniste zagłębienie, często wypełnione torfem."
        ]} style={{ marginBottom: 13 }}/>
        <AudioInvoker index={2} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Dlaczego reguluje się rzeki?
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Prace regulacyjne Krzny rozpoczęto w latach 30. XX w. Wielkim wysiłkiem, przy udziale wojska i więźniów wyprostowano wówczas koryto rzeki. Celem tych działań było przede wszystkim powiększenie powierzchni łąk użytkowych w dolinie Krzny. W tamtych czasach, a i często obecnie, regulację przeprowadza się także po to, aby rzeką mogły pływać barki lub łodzie lub żeby umożliwić zabudowę w bezpośrednim sąsiedztwie rzeki (np. w miastach). Większość rzek w Polsce jest dziś uregulowana – w całości lub na wybranym odcinku.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Niestety, dziś wiemy, że intensywna regulacja rzek rodzi wiele problemów. Jej negatywną konsekwencją jest zwiększenie ryzyka suszy – regulacja przyspiesza spływ wody, która natychmiast „ucieka” z doliny. Kolejnym negatywnym skutkiem jest zmniejszenie różnorodności biologicznej rzeki i otaczających ją terenów. Często również, wbrew intencjom człowieka, regulacja rzek powoduje pogorszenie bezpieczeństwa powodziowego – jeśli nie zapewnimy rzece naturalnej przestrzeni na okresowe wylewanie, w czasie dużych wezbrań woda może niszczyć domy i uprawy.
        </Text>
        <FullWidthImage source={require("../../assets/boards/1/starorzecze_ortofoto.png")} />
        <Text variant="labelSmall" style={styles.photoLabel}>
        fot. geoportal.gov.pl
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
            Na pierwszym zdjęciu satelitarnym powyżej widzisz uregulowaną Krznę na odcinku na odcinku pomiędzy Porosiukami a Białą Podlaską. Na drugim zdjęciu nieuregulowany odcinek rzeki przy ujściu Krzny do Bugu, pełny malowniczych meandrów i starorzeczy. 
        </Text>
        <AudioInvoker index={3} playlist={playlist} />
        <DidYouKnowTile title={"Czy wiesz, że?"}
                        description={"Starorzecza i ślepe odnogi rzek są domem dla wielu gatunków roślin i zwierząt. W porównaniu z korytem rzeki tworzą całkiem odmienne środowisko. Wody są tu żyźniejsze, cieplejsze, a nierzadko również bardziej przejrzyste niż wody w głównym korycie. Bardzo szybko rozwijają się tu zbiorowiska roślinności wodnej i szuwarowej, zapewniając schronienie dla różnych organizmów, w tym ryb, ptaków i owadów. Starorzecza pełnią rolę bogatych żerowisk, wylęgarni czy odchowalni licznych gatunków zwierząt."}
                        descriptionBold={"Kokoszka Gallinula Chloropus to ptak, który dobrze czuje się w nadrzecznych szuwarach."}
                        image={require("../../assets/educationBoard/kokoszka.png")}
                        imageLabel={"rys. Malwina Łazęcka-Malińska "}/>
    </>
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 12
    },
    text: {
        marginBottom: 16
    },
    photoLabel: {
        color: CombinedTheme.colors.neutral60,
        marginBottom: 24,
        marginTop: 16
    }
});