import * as React from "react"
import Svg, {SvgProps, Path} from "react-native-svg"
import {memo} from "react"


const LoopArrowIcon = (props: SvgProps) => (
    <Svg width="24" height="15" viewBox="0 0 24 15" fill="none" {...props}>
        <Path
            d="M12 0C5.376 0 0 2.688 0 6C0 8.688 3.528 10.956 8.4 11.724V14.148C8.4 14.688 9.048 14.952 9.42 14.568L12.768 11.22C13.008 10.98 13.008 10.608 12.768 10.368L9.42 7.02C9.33541 6.93708 9.22816 6.88105 9.11177 6.85898C8.99539 6.83691 8.87507 6.84979 8.76599 6.89599C8.65691 6.94218 8.56395 7.01964 8.49882 7.11858C8.43369 7.21753 8.3993 7.33354 8.4 7.452V9.276C4.62 8.604 2.4 6.996 2.4 6C2.4 4.728 6.048 2.4 12 2.4C17.952 2.4 21.6 4.728 21.6 6C21.6 6.792 20.16 8.016 17.616 8.808C17.124 8.964 16.8 9.42 16.8 9.936C16.8 10.74 17.58 11.328 18.336 11.088C21.732 10.032 24 8.148 24 6C24 2.688 18.624 0 12 0Z"
            fill="white"/>
    </Svg>

)

const Memo = memo(LoopArrowIcon)
export default Memo
