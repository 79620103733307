import { Alert, StyleSheet, View } from "react-native";
import React from "react";
import * as Location from 'expo-location';
import { Button, Text } from "react-native-paper";
import FullHeightLayout from "../../components/layout/fullHeightLayout";
import Svg, { Circle } from "react-native-svg";
import IconLocation from "../../components/icons/iconLocation";
import { useNavigation } from "@react-navigation/native";
import { CombinedTheme } from "../../styles/theme";
import useArModelPath from "../../hooks/useArModelPath";

export default function CheckLocationPage() {
    const navigation = useNavigation();
    // trigger downloading ar model
    // const path = useArModelPath();

    const requestLocationPermission = async () => {
        try {
            let response = await Location.getLastKnownPositionAsync();
            const latitude = response?.coords.latitude;
            const longitude = response?.coords.longitude;

            // if (latitude > 52.0509 || latitude < 51.9949) {
            //     Alert.alert("Niepoprawna lokalizacja", "Wygląda na to, że nie jesteś w Parku Radziwiłłów. Upewnij się że znajdujesz się na terenie parku oraz że masz włączone usługi lokalizacji i GPS.",
            //         [{ text: "Powrót", onPress: () => {} }]);
            //     return;
            // }

            // if (longitude > 23.1818 || longitude < 23.0715) {
            //     Alert.alert("Niepoprawna lokalizacja", "Wygląda na to, że nie jesteś w Parku Radziwiłłów. Upewnij się że znajdujesz się na terenie parku oraz że masz włączone usługi lokalizacji i GPS.",
            //         [{ text: "Powrót", onPress: () => { } }]);
            //     return;
            // }

            navigation.navigate("Ar-CheckWeather" as never);
        } catch (err) {
            Alert.alert("Nieznany błąd", "Proszę spróbować ponownie później. Nastąpi przekierowanie do strony głównej",
                [{ text: "Powrót", onPress: () => {} }]);
        }
    };

    const onNext = async () => {
        await requestLocationPermission();
    };

    const radius = 4;

    return <FullHeightLayout>
        <View style={{ justifyContent: "space-between", height: "100%" }}>
            <View style={{ justifyContent: "center", alignItems: "center", flex: 1 }}>
                <IconLocation />
                <Text variant="bodyLarge" style={{ marginVertical: 28 }}>
                    Lokalizacja
                </Text>
                <Text variant="headlineSmall" style={{ textAlign: "center" }}>
                    Upewnij się, że znajdujesz się na terenie Parku Radziwiłłów
                </Text>
            </View>
            <View>
                <Button mode="contained" onPress={onNext} style={{ width: "100%" }}>
                    Dalej
                </Button>
                <View style={{ flexDirection: "row", marginVertical: 30, alignSelf: "center" }}>
                    <Svg style={styles.dot} height={2 * radius} width={2 * radius}>
                        <Circle cx={radius} cy={radius} r={radius} />
                    </Svg>
                    <Svg style={styles.dotActive} height={2 * radius} width={2 * radius}>
                        <Circle cx={radius} cy={radius} r={radius} />
                    </Svg>
                    <Svg style={styles.dot} height={2 * radius} width={2 * radius}>
                        <Circle cx={radius} cy={radius} r={radius} />
                    </Svg>
                </View>
            </View>
        </View>
    </FullHeightLayout>
}

const styles = StyleSheet.create({
    dot: {
        marginHorizontal: 8,
        fill: CombinedTheme.colors.onSurfaceVariant
    },
    dotActive: {
        marginHorizontal: 8,
        fill: CombinedTheme.colors.primary
    }
})
