import React, { useState } from "react";
import { Linking, Platform, TouchableOpacity, View } from "react-native";
import Layout from "../components/layout/layout";
import { Text } from 'react-native-paper';
import ImageTile from "../components/imageTile/imageTile";
import QuizTile from "../components/quizTile/quizTile";
import IconAppStore from "../components/icons/iconAppStore";
import { LinearGradient } from "expo-linear-gradient";
import IconGooglePlay from "../components/icons/iconGooglePlay";
import StyleSheet from "react-native-media-query";

export default function HomePage() {
    const [modalVisible, setModalVisible] = useState(false);

    return <Layout hideBackButton={true}>
        <View style={styles.container} dataSet={{ media: ids.container }}>
            <View style={styles.tile} dataSet={{ media: ids.tile }}>
                <ImageTile title={"Witaj w audio-przewodniku Doliny Krzny w Białej Podlaskiej 👋"}
                           description={"Szlak Doliny Krzny to trasa pieszo-rowerowa na terenie miasta oraz gminy miejskiej Biała Podlaska."}
                           buttonText={"Odkryj szlak"}
                           image={require("../assets/homePage/trail.png")}
                           linkTo={"AboutTrail"}/>
            </View>

            {Platform.OS == "web" ?
                <View style={styles.downloadWrapper} dataSet={{ media: ids.downloadWrapper }}>
                    <LinearGradient colors={["#FAF3DD", "#FCEEC2"]} style={styles.download} dataSet={{ media: ids.download }}>
                        <Text variant="bodyMedium" style={styles.text} dataSet={{ media: ids.text }}>
                            Zainstaluj aplikację mobilną, aby śledzić swoje postępy podczas wycieczki na trasie oraz zobaczyć dodatkowe treści.
                        </Text>

                        <View style={{ flexDirection: "row", width: "100%", justifyContent: "center" }}>
                            <TouchableOpacity accessibilityLabel="Pobierz aplikację z Apple AppStore" onPress={() => Linking.openURL("https://apps.apple.com/us/app/dolina-krzny-audioprzewodnik/id1659201690")} style={styles.button}>
                                <IconAppStore />
                            </TouchableOpacity>
    
                            <TouchableOpacity accessibilityLabel="Pobierz aplikację z Google Play" onPress={() => Linking.openURL("https://play.google.com/store/apps/details?id=com.digimuth.bialapodlaskaaudiotour")} style={styles.button}>
                                <IconGooglePlay />
                            </TouchableOpacity>
                        </View>

                    </LinearGradient>
                </View>
            :
                <View style={styles.quizTile} dataSet={{ media: ids.quizTile }}>
                    <QuizTile modalVisible={modalVisible} setModalVisible={setModalVisible} />
                </View>
            }
        </View>
    </Layout>
}

const { ids, styles } = StyleSheet.create({
    container: {
        display: "flex",
        '@media (min-width: 800px)': {
            maxWidth: 960,
            margin: "auto",
            flexDirection: "row",
            flex: 1
        }
    },
    button: {
        width: 140,
        height: 50,
        justifyContent: "center",
        alignItems: "center"
    },
    text: {
        marginHorizontal: 10,
        textAlign: "center",
        '@media (min-width: 800px)': {
            marginHorizontal: 40,
            textAlign: "center"
        }
    },
    quizTile: {
        width: "100%",
        marginTop: 20,
        '@media (min-width: 800px)': {
            width: "50%",
            padding: 10,
            marginTop: 0
        }
    },
    downloadWrapper: {
        width: "100%",
        marginTop: 20,
        '@media (min-width: 800px)': {
            width: "50%",
            padding: 10,
            height: 480,
            marginTop: 0
        }
    },
    download: {
        padding: 20,
        width: "100%",
        height: "100%",
        borderRadius: 28,
        justifyContent: "center",
        alignItems: "center",
        gap: 20,
        '@media (min-width: 800px)': {
            width: "100%",
            height: "100%",
            borderRadius: 28,
            justifyContent: "center",
            alignItems: "center",
            gap: 40
        }
    },
    tile: {
        position: "relative",
        '@media (min-width: 800px)': {
            width: "50%",
            padding: 10
        }
    }
});
