import {Button, Text} from "react-native-paper";
import QuizBadge from "../icons/quizBadge";
import {StyleSheet, View} from "react-native";
import {LinearGradient} from "expo-linear-gradient";
import React from "react";

export default function QuizInitContent(props:QuizModalInitProps){
    return <LinearGradient colors={["#FAF3DD", "#FCEEC2"]} style={styles.modalView}>
        <Text variant={"headlineSmall"} style={{ textAlign: "center" }}>Quiz</Text>
        <QuizBadge style={styles.icon}/>
        <Text style={{textAlign: "center"}} variant={"bodyMedium"}>Gratulacje! Udało ci się przejść przez
            wszystkie punkty dostępne na trasie.
            Sprawdź swoją wiedzę zdobytą podczas podróży po szlaku.</Text>
        <View style={styles.buttonsWrapper}>
            <Button mode={"contained"} style={{ marginBottom: 16 }} onPress={() => props.setPhase(1)}>Rozpocznij quiz</Button>
            <Button mode={"outlined"} onPress={() => props.setModalVisible(false)}>
                <Text variant={"labelLarge"}>Może później</Text>
            </Button>
        </View>
    </LinearGradient>
}

interface QuizModalInitProps{
    modalVisible: boolean,
    setModalVisible: any,
    setPhase:any
}

const styles = StyleSheet.create({
    modalView: {
        marginHorizontal: 24,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 24,
        alignItems: "stretch",
        maxWidth: 600
    },
    icon: {
        marginVertical: 24,
        alignSelf: "center"
    },
    buttonsWrapper: {
        marginTop: 40,
        display: "flex",
        flexDirection: "column",
    }
});
