import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect, useState } from "react";
import { EventApi, EventDto } from "../api";
import useApi from "./useApi";
import * as Notifications from 'expo-notifications';
import moment from "moment";
import { Platform } from "react-native";
import { CombinedTheme } from "../styles/theme";

const eventsStorageKey = "whats_new_events";

export async function registerForPushNotificationsAsync() {  
    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.DEFAULT,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: CombinedTheme.colors.surface,
      });
    }
  
    if (Platform.OS !== "web") {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        return;
      }
    }
}

export const scheduleNotifications = async (events: EventDto[]) => {
    console.log("Clearing old notifications");
    await Notifications.cancelAllScheduledNotificationsAsync();
    console.log("Scheduling new notifications");

    const result = await Promise.all(events.map(e => {
        const trigger = moment(e.date).toDate();

        if (trigger < new Date()) {
            return;
        }

        return Notifications.scheduleNotificationAsync({
            content: {
                title: e.name || "Wkrótce rozpocznie się wydarzenie",
                subtitle: "Dowiedz się więcej w aplikacji",
                data: {
                    eventId: e.id
                }
            },
            trigger
        });
    }));

    console.log(`Scheduled ${result.filter(r => r).length} notifications`);
}

export default function useEvents() {
    
    const [events, setEvents] = useState<EventDto[]>();
    const api = useApi(EventApi);

    const updateNewsFromStorage = async () => {
        try {
            const response = await AsyncStorage.getItem(eventsStorageKey);

            if (response) {
                setEvents(JSON.parse(response));
                console.log("got from storage");
            }
        } catch (e) {
            console.log(e);
        }
    }

    const updateNewsFromApi = async () => {
        try {
            const response = await api.apiEventGet();
    
            setEvents(response.data);
            await AsyncStorage.setItem(eventsStorageKey, JSON.stringify(response.data));
            if (Platform.OS !== "web") {
                await scheduleNotifications(response.data);
            }
        } catch (e) {
            console.log(e);
            setEvents([]);
        }
    }

    useEffect(() => {
        let unmounted = false;

        updateNewsFromStorage().then(() => {
            if (unmounted) {
                return;
            }
            updateNewsFromApi();
        });

        return () => {
            unmounted = true
        };
    }, []);

    return events;
}