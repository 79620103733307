import Layout from "../components/layout/layout";
import {View, ViewProps} from "react-native";
import {Text} from "react-native-paper";
import TagTile from "../components/tagTile/tagTile";
import LengthIcon from "../components/icons/lengthIcon";
import BikeIcon from "../components/icons/bikeIcon";
import DistanceIcon from "../components/icons/distanceIcon";
import TrailTileList from "../components/trailTile/trailTileList";
import React from "react";
import Partners from "../components/partners/partners";
import AbpTabView from "../components/abpTabView/abpTabView";
import MapView from "../components/mapView/mapView";
import {ScrollView} from "react-native-gesture-handler";
import StyleSheet from "react-native-media-query";

const TrailTiles = (props: ViewProps) => (
    <ScrollView {...props} showsVerticalScrollIndicator={false}
                contentContainerStyle={{flex: 1, paddingTop: 20, marginHorizontal: 24}}>
        <TrailTileList/>
        <Partners/>
    </ScrollView>
);

const TrailMap = (props: ViewProps) => (
    <View {...props} style={{flexShrink: 1}}>
        <View style={styles.mapView} dataSet={{media: ids.mapView}}>
            <MapView/>
        </View>
        <Partners/>
    </View>
);

export default function AboutTrailPage() {

    return <Layout title="O szlaku"
                   subtitle="Szlak Doliny Krzny to trasa pieszo-rowerowa na terenie miasta oraz gminy wiejskiej Biała Podlaska">
        <View style={styles.tilesWrapper} dataSet={{ media: ids.tilesWrapper }}>
            <TagTile icon={<LengthIcon />} text={"11 km"} />
            <TagTile icon={<DistanceIcon />} text={"2 h 30 min"} />
            <TagTile icon={<BikeIcon />} text={"45 min"} />
        </View>
        <Text variant={"bodyMedium"}>
            Wędrując szlakiem o łącznej długości około 11 kilometrów poznajemy walory przyrodnicze doliny Krzny - lewego
            dopływu Bugu, największej rzeki w północnej części województwa lubelskiego. To piękne tereny z bogactwem
            fauny: ptaków, ryb, motyli, wśród nich również gatunków rzadkich i zagrożonych. Ponadto poznajemy procesy
            kształtujące dolinę rzeki oraz jej znaczenie jako ważnego szlaku migracji ptaków wodno-błotnych. Dowiadujemy
            się również wiele na temat historii Białej Podlaskiej.
        </Text>
        <Text variant={"titleMedium"} style={{marginVertical: 24}}>
            Wzdłuż trasy znajduje się 17 punktów wiedzy – tablic edukacyjnych z krótkimi tekstami, rysunkami, zdjęciami
            i grafikami. Poruszając się z zachodu na wschód:
        </Text>
        <AbpTabView tabs={{"Lista obiektów": TrailTiles, "Mapa trasy": TrailMap}}/>
    </Layout>
}

const {ids, styles} = StyleSheet.create({
    title: {
        fontSize: 14,
        lineHeight: 20,
        fontWeight: "500",
        fontFamily: "Inter_500Medium",
        textAlign: "center",
        fontStyle: "normal",
        letterSpacing: 0.1,
        textTransform: "uppercase",
        marginVertical: 8
    },
    header: {
        textAlign: "center"
    },
    tilesWrapper: {
        display: "flex",
        flexDirection: "row",
        marginVertical: 24,
        '@media (min-width: 800)': {
            alignSelf: "center"
        }
    },
    mapView: {
        height: 220,
        marginTop: 24,
        marginHorizontal: 24,
        '@media (min-width: 800)': {
            height: 450,
            marginTop: 24,
            marginHorizontal: 24
        }
    }
})
