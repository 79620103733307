import {StatusBar} from "expo-status-bar";
import React, {useState} from "react";
import {Button, TextInput, Title} from "react-native-paper";
import {ContactFormApi, ContactFormCategory} from "../api";
import useApi from "../hooks/useApi";
import {RouteProp, useNavigation, useRoute} from "@react-navigation/native";
import Layout from "../components/layout/layout";
import {StyleSheet, View} from "react-native";
import {Text} from "react-native-paper"

const emailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

function isEmailValid(email: string) {
    return emailRegex.test(email);
}

function isMessageValid(message: string) {
    return message.length > 5;
}

export default function ContactFormPage() {

    const route = useRoute<RouteProp<ParamList, 'ContactForm'>>();
    const navigation = useNavigation();

    const [email, setEmail] = useState<string>("");
    const [emailTouched, setEmailTouched] = useState<boolean>(false);
    const [fullName, setFullName] = useState<string>("");
    const [fullNameTouched, setFullNameTouched] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [messageTouched, setMessageTouched] = useState<boolean>(false);
    const [category, setCategory] = useState<ContactFormCategory>(route.params.category ?? "other");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const api = useApi(ContactFormApi);

    const onSubmit = async () => {
        setIsLoading(true);
        setCategory(route.params.category ?? "other");
        const formValues = {
            email,
            message,
            category
        };

        try {
            await api.apiContactFormPost(formValues);
        } catch (e: any) {
            console.error(JSON.stringify(e));
        } finally {
            setIsLoading(false);
            navigation.goBack();
            navigation.goBack();
        }
    };

    const validEmail = isEmailValid(email);
    const validMessage = isMessageValid(message);
    const validName = isMessageValid(fullName);

    return <Layout style={{height: "100%"}} title={"Kontakt"} subtitle={"Skontaktuj się z nami"}>
        <View style={styles.inputsWrapper}>
            <TextInput
                mode={"outlined"}
                outlineColor={"#747876"}
                activeOutlineColor={"#F1BC00"}
                textColor={"#1C1B1F"}
                keyboardType="default"
                textContentType="name"
                returnKeyType="next"
                autoFocus={true}
                label="Imię i Nazwisko"
                value={fullName}
                error={fullNameTouched && !validName}
                onChangeText={setFullName}
                onBlur={() => setFullNameTouched(true)}
                disabled={isLoading}
                maxLength={32}
                style={styles.textInput}
            />
            <TextInput
                placeholder={"E-mail"}
                mode={"outlined"}
                outlineColor={"#747876"}
                activeOutlineColor={"#F1BC00"}
                textColor={"#1C1B1F"}
                keyboardType="email-address"
                textContentType="emailAddress"
                returnKeyType="next"
                label="E-mail"
                value={email}
                error={emailTouched && !validEmail}
                onChangeText={e => setEmail(e.toLowerCase())}
                onBlur={() => setEmailTouched(true)}
                disabled={isLoading}
                style={styles.textInput}
            />
            <TextInput
                mode={"outlined"}
                outlineColor={"#747876"}
                activeOutlineColor={"#F1BC00"}
                textColor={"#1C1B1F"}
                label="Wiadomość"
                multiline={true}
                value={message}
                onChangeText={setMessage}
                error={messageTouched && !validMessage}
                maxLength={700}
                onBlur={() => setMessageTouched(true)}
                disabled={isLoading}
                dense={true}
                style={[styles.textInput, message.length > 33 ? {height: 84} : undefined]}
            />
            <Text variant={"bodySmall"} style={{marginLeft: 16}}> max. 700 znaków</Text>
        </View>
        <View style={styles.buttonWrapper}>
            <Button style={styles.submitButton} mode="contained" onPress={onSubmit} loading={isLoading}
                    disabled={!validEmail || !validMessage || isLoading}>
                Wyślij wiadomość
            </Button>
        </View>
        <StatusBar style="auto"/>
    </Layout>
}

const styles = StyleSheet.create({
    inputsWrapper: {
        display: "flex",
        flexDirection: "column",
        marginTop: 24
    },
    buttonWrapper: {
        // flex: 1,
        // display: "flex",
        // justifyContent: "flex-end",
    },
    textInput: {
        backgroundColor: "#FFFFFF",
        borderRadius: 4,
        height: 56,
        fontWeight: "400",
        fontSize: 16,
        marginTop: 8,
        color: "#1C1B1f"
    },
    submitButton: {
        marginTop: 16
    }
})

type ParamList = {
    ContactForm: {
        category: ContactFormCategory;
    };
};
