import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
import { memo } from "react"
import { CombinedTheme } from "../../styles/theme"

const Click = (props: SvgProps) => (
  <Svg
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <Path
      d="M9.714 0A5.149 5.149 0 0 0 4.57 5.143h1.714a3.429 3.429 0 1 1 6.857 0h1.715A5.15 5.15 0 0 0 9.713 0Z"
      fill={CombinedTheme.colors.tertiary}
    />
    <Path
      d="M16.57 24h-3.805c-.86 0-1.688-.324-2.321-.906l-7.886-7.257a1.716 1.716 0 0 1 .101-2.618 1.778 1.778 0 0 1 2.279.148l3.061 2.796V5.143a1.714 1.714 0 0 1 3.429 0v6a1.714 1.714 0 1 1 3.428 0V12a1.714 1.714 0 0 1 3.429 0v.857a1.714 1.714 0 0 1 3.429 0v6A5.143 5.143 0 0 1 16.57 24Z"
      fill={CombinedTheme.colors.tertiary}
    />
  </Svg>
)

const Memo = memo(Click)
export default Memo
