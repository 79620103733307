import {ImageBackground, StyleSheet, View} from "react-native";
import {Button, Text} from "react-native-paper";
import React from "react";
import {useNavigation} from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";

export default function ImageTile(props: imageTileProps) {

    const navigation = useNavigation();

    // todo linear gradient
    return <ImageBackground style={styles.tileWrapper} resizeMode={"cover"} source={props.image}>
        <LinearGradient colors={["rgba(15, 15, 15, 0)" , "rgba(15, 15, 15, 0.95)"]} style={{ flex: 1 }}>
            <View style={styles.textWrapper}>
                <Text variant={"headlineSmall"} style={{color: "#FFFFFF"}}>{props.title}</Text>
                <Text variant={"bodyMedium"} style={styles.description}>{props.description}</Text>
                <Button mode="contained" onPress={() => navigation.navigate(props.linkTo as never)}>
                    Odkryj szlak
                </Button>
            </View>
        </LinearGradient>
    </ImageBackground>

}

const styles = StyleSheet.create({
    tileWrapper: {
        borderRadius: 28,
        overflow: "hidden",
        height: 460
    },
    textWrapper: {
        flex: 1,
        padding: 24,
        justifyContent: "flex-end"
    },
    description: {
        color: "#FFFFFF",
        marginTop: 16,
        marginBottom: 24
    }
})

interface imageTileProps {
    title: string,
    description: string,
    buttonText: string,
    linkTo: string,
    image: any;
}
