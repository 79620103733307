
// autogenerated by yarn fetch-maps

interface MapData {
    image: number,
    tile: { x: number, y: number, z: number }
}

export const mapData: MapData[] = [
{
    image: require("../assets/maps/12-2309-1353.png"),
    tile: { x: 2309, y: 1353, z: 12 }
}, 
{
    image: require("../assets/maps/12-2310-1353.png"),
    tile: { x: 2310, y: 1353, z: 12 }
}, 
{
    image: require("../assets/maps/12-2311-1353.png"),
    tile: { x: 2311, y: 1353, z: 12 }
}, 
{
    image: require("../assets/maps/12-2312-1353.png"),
    tile: { x: 2312, y: 1353, z: 12 }
}, 
{
    image: require("../assets/maps/12-2309-1352.png"),
    tile: { x: 2309, y: 1352, z: 12 }
}, 
{
    image: require("../assets/maps/12-2310-1352.png"),
    tile: { x: 2310, y: 1352, z: 12 }
}, 
{
    image: require("../assets/maps/12-2311-1352.png"),
    tile: { x: 2311, y: 1352, z: 12 }
}, 
{
    image: require("../assets/maps/12-2312-1352.png"),
    tile: { x: 2312, y: 1352, z: 12 }
}, 
{
    image: require("../assets/maps/12-2309-1351.png"),
    tile: { x: 2309, y: 1351, z: 12 }
}, 
{
    image: require("../assets/maps/12-2310-1351.png"),
    tile: { x: 2310, y: 1351, z: 12 }
}, 
{
    image: require("../assets/maps/12-2311-1351.png"),
    tile: { x: 2311, y: 1351, z: 12 }
}, 
{
    image: require("../assets/maps/12-2312-1351.png"),
    tile: { x: 2312, y: 1351, z: 12 }
}, 
{
    image: require("../assets/maps/13-4618-2707.png"),
    tile: { x: 4618, y: 2707, z: 13 }
}, 
{
    image: require("../assets/maps/13-4619-2707.png"),
    tile: { x: 4619, y: 2707, z: 13 }
}, 
{
    image: require("../assets/maps/13-4620-2707.png"),
    tile: { x: 4620, y: 2707, z: 13 }
}, 
{
    image: require("../assets/maps/13-4621-2707.png"),
    tile: { x: 4621, y: 2707, z: 13 }
}, 
{
    image: require("../assets/maps/13-4622-2707.png"),
    tile: { x: 4622, y: 2707, z: 13 }
}, 
{
    image: require("../assets/maps/13-4623-2707.png"),
    tile: { x: 4623, y: 2707, z: 13 }
}, 
{
    image: require("../assets/maps/13-4624-2707.png"),
    tile: { x: 4624, y: 2707, z: 13 }
}, 
{
    image: require("../assets/maps/13-4625-2707.png"),
    tile: { x: 4625, y: 2707, z: 13 }
}, 
{
    image: require("../assets/maps/13-4618-2706.png"),
    tile: { x: 4618, y: 2706, z: 13 }
}, 
{
    image: require("../assets/maps/13-4619-2706.png"),
    tile: { x: 4619, y: 2706, z: 13 }
}, 
{
    image: require("../assets/maps/13-4620-2706.png"),
    tile: { x: 4620, y: 2706, z: 13 }
}, 
{
    image: require("../assets/maps/13-4621-2706.png"),
    tile: { x: 4621, y: 2706, z: 13 }
}, 
{
    image: require("../assets/maps/13-4622-2706.png"),
    tile: { x: 4622, y: 2706, z: 13 }
}, 
{
    image: require("../assets/maps/13-4623-2706.png"),
    tile: { x: 4623, y: 2706, z: 13 }
}, 
{
    image: require("../assets/maps/13-4624-2706.png"),
    tile: { x: 4624, y: 2706, z: 13 }
}, 
{
    image: require("../assets/maps/13-4625-2706.png"),
    tile: { x: 4625, y: 2706, z: 13 }
}, 
{
    image: require("../assets/maps/13-4618-2705.png"),
    tile: { x: 4618, y: 2705, z: 13 }
}, 
{
    image: require("../assets/maps/13-4619-2705.png"),
    tile: { x: 4619, y: 2705, z: 13 }
}, 
{
    image: require("../assets/maps/13-4620-2705.png"),
    tile: { x: 4620, y: 2705, z: 13 }
}, 
{
    image: require("../assets/maps/13-4621-2705.png"),
    tile: { x: 4621, y: 2705, z: 13 }
}, 
{
    image: require("../assets/maps/13-4622-2705.png"),
    tile: { x: 4622, y: 2705, z: 13 }
}, 
{
    image: require("../assets/maps/13-4623-2705.png"),
    tile: { x: 4623, y: 2705, z: 13 }
}, 
{
    image: require("../assets/maps/13-4624-2705.png"),
    tile: { x: 4624, y: 2705, z: 13 }
}, 
{
    image: require("../assets/maps/13-4625-2705.png"),
    tile: { x: 4625, y: 2705, z: 13 }
}, 
{
    image: require("../assets/maps/13-4618-2704.png"),
    tile: { x: 4618, y: 2704, z: 13 }
}, 
{
    image: require("../assets/maps/13-4619-2704.png"),
    tile: { x: 4619, y: 2704, z: 13 }
}, 
{
    image: require("../assets/maps/13-4620-2704.png"),
    tile: { x: 4620, y: 2704, z: 13 }
}, 
{
    image: require("../assets/maps/13-4621-2704.png"),
    tile: { x: 4621, y: 2704, z: 13 }
}, 
{
    image: require("../assets/maps/13-4622-2704.png"),
    tile: { x: 4622, y: 2704, z: 13 }
}, 
{
    image: require("../assets/maps/13-4623-2704.png"),
    tile: { x: 4623, y: 2704, z: 13 }
}, 
{
    image: require("../assets/maps/13-4624-2704.png"),
    tile: { x: 4624, y: 2704, z: 13 }
}, 
{
    image: require("../assets/maps/13-4625-2704.png"),
    tile: { x: 4625, y: 2704, z: 13 }
}, 
{
    image: require("../assets/maps/13-4618-2703.png"),
    tile: { x: 4618, y: 2703, z: 13 }
}, 
{
    image: require("../assets/maps/13-4619-2703.png"),
    tile: { x: 4619, y: 2703, z: 13 }
}, 
{
    image: require("../assets/maps/13-4620-2703.png"),
    tile: { x: 4620, y: 2703, z: 13 }
}, 
{
    image: require("../assets/maps/13-4621-2703.png"),
    tile: { x: 4621, y: 2703, z: 13 }
}, 
{
    image: require("../assets/maps/13-4622-2703.png"),
    tile: { x: 4622, y: 2703, z: 13 }
}, 
{
    image: require("../assets/maps/13-4623-2703.png"),
    tile: { x: 4623, y: 2703, z: 13 }
}, 
{
    image: require("../assets/maps/13-4624-2703.png"),
    tile: { x: 4624, y: 2703, z: 13 }
}, 
{
    image: require("../assets/maps/13-4625-2703.png"),
    tile: { x: 4625, y: 2703, z: 13 }
}, 
{
    image: require("../assets/maps/13-4618-2702.png"),
    tile: { x: 4618, y: 2702, z: 13 }
}, 
{
    image: require("../assets/maps/13-4619-2702.png"),
    tile: { x: 4619, y: 2702, z: 13 }
}, 
{
    image: require("../assets/maps/13-4620-2702.png"),
    tile: { x: 4620, y: 2702, z: 13 }
}, 
{
    image: require("../assets/maps/13-4621-2702.png"),
    tile: { x: 4621, y: 2702, z: 13 }
}, 
{
    image: require("../assets/maps/13-4622-2702.png"),
    tile: { x: 4622, y: 2702, z: 13 }
}, 
{
    image: require("../assets/maps/13-4623-2702.png"),
    tile: { x: 4623, y: 2702, z: 13 }
}, 
{
    image: require("../assets/maps/13-4624-2702.png"),
    tile: { x: 4624, y: 2702, z: 13 }
}, 
{
    image: require("../assets/maps/13-4625-2702.png"),
    tile: { x: 4625, y: 2702, z: 13 }
}, 
{
    image: require("../assets/maps/13-4618-2701.png"),
    tile: { x: 4618, y: 2701, z: 13 }
}, 
{
    image: require("../assets/maps/13-4619-2701.png"),
    tile: { x: 4619, y: 2701, z: 13 }
}, 
{
    image: require("../assets/maps/13-4620-2701.png"),
    tile: { x: 4620, y: 2701, z: 13 }
}, 
{
    image: require("../assets/maps/13-4621-2701.png"),
    tile: { x: 4621, y: 2701, z: 13 }
}, 
{
    image: require("../assets/maps/13-4622-2701.png"),
    tile: { x: 4622, y: 2701, z: 13 }
}, 
{
    image: require("../assets/maps/13-4623-2701.png"),
    tile: { x: 4623, y: 2701, z: 13 }
}, 
{
    image: require("../assets/maps/13-4624-2701.png"),
    tile: { x: 4624, y: 2701, z: 13 }
}, 
{
    image: require("../assets/maps/13-4625-2701.png"),
    tile: { x: 4625, y: 2701, z: 13 }
}, 
{
    image: require("../assets/maps/14-9236-5415.png"),
    tile: { x: 9236, y: 5415, z: 14 }
}, 
{
    image: require("../assets/maps/14-9237-5415.png"),
    tile: { x: 9237, y: 5415, z: 14 }
}, 
{
    image: require("../assets/maps/14-9238-5415.png"),
    tile: { x: 9238, y: 5415, z: 14 }
}, 
{
    image: require("../assets/maps/14-9239-5415.png"),
    tile: { x: 9239, y: 5415, z: 14 }
}, 
{
    image: require("../assets/maps/14-9240-5415.png"),
    tile: { x: 9240, y: 5415, z: 14 }
}, 
{
    image: require("../assets/maps/14-9241-5415.png"),
    tile: { x: 9241, y: 5415, z: 14 }
}, 
{
    image: require("../assets/maps/14-9242-5415.png"),
    tile: { x: 9242, y: 5415, z: 14 }
}, 
{
    image: require("../assets/maps/14-9243-5415.png"),
    tile: { x: 9243, y: 5415, z: 14 }
}, 
{
    image: require("../assets/maps/14-9244-5415.png"),
    tile: { x: 9244, y: 5415, z: 14 }
}, 
{
    image: require("../assets/maps/14-9245-5415.png"),
    tile: { x: 9245, y: 5415, z: 14 }
}, 
{
    image: require("../assets/maps/14-9246-5415.png"),
    tile: { x: 9246, y: 5415, z: 14 }
}, 
{
    image: require("../assets/maps/14-9247-5415.png"),
    tile: { x: 9247, y: 5415, z: 14 }
}, 
{
    image: require("../assets/maps/14-9248-5415.png"),
    tile: { x: 9248, y: 5415, z: 14 }
}, 
{
    image: require("../assets/maps/14-9249-5415.png"),
    tile: { x: 9249, y: 5415, z: 14 }
}, 
{
    image: require("../assets/maps/14-9250-5415.png"),
    tile: { x: 9250, y: 5415, z: 14 }
}, 
{
    image: require("../assets/maps/14-9236-5414.png"),
    tile: { x: 9236, y: 5414, z: 14 }
}, 
{
    image: require("../assets/maps/14-9237-5414.png"),
    tile: { x: 9237, y: 5414, z: 14 }
}, 
{
    image: require("../assets/maps/14-9238-5414.png"),
    tile: { x: 9238, y: 5414, z: 14 }
}, 
{
    image: require("../assets/maps/14-9239-5414.png"),
    tile: { x: 9239, y: 5414, z: 14 }
}, 
{
    image: require("../assets/maps/14-9240-5414.png"),
    tile: { x: 9240, y: 5414, z: 14 }
}, 
{
    image: require("../assets/maps/14-9241-5414.png"),
    tile: { x: 9241, y: 5414, z: 14 }
}, 
{
    image: require("../assets/maps/14-9242-5414.png"),
    tile: { x: 9242, y: 5414, z: 14 }
}, 
{
    image: require("../assets/maps/14-9243-5414.png"),
    tile: { x: 9243, y: 5414, z: 14 }
}, 
{
    image: require("../assets/maps/14-9244-5414.png"),
    tile: { x: 9244, y: 5414, z: 14 }
}, 
{
    image: require("../assets/maps/14-9245-5414.png"),
    tile: { x: 9245, y: 5414, z: 14 }
}, 
{
    image: require("../assets/maps/14-9246-5414.png"),
    tile: { x: 9246, y: 5414, z: 14 }
}, 
{
    image: require("../assets/maps/14-9247-5414.png"),
    tile: { x: 9247, y: 5414, z: 14 }
}, 
{
    image: require("../assets/maps/14-9248-5414.png"),
    tile: { x: 9248, y: 5414, z: 14 }
}, 
{
    image: require("../assets/maps/14-9249-5414.png"),
    tile: { x: 9249, y: 5414, z: 14 }
}, 
{
    image: require("../assets/maps/14-9250-5414.png"),
    tile: { x: 9250, y: 5414, z: 14 }
}, 
{
    image: require("../assets/maps/14-9236-5413.png"),
    tile: { x: 9236, y: 5413, z: 14 }
}, 
{
    image: require("../assets/maps/14-9237-5413.png"),
    tile: { x: 9237, y: 5413, z: 14 }
}, 
{
    image: require("../assets/maps/14-9238-5413.png"),
    tile: { x: 9238, y: 5413, z: 14 }
}, 
{
    image: require("../assets/maps/14-9239-5413.png"),
    tile: { x: 9239, y: 5413, z: 14 }
}, 
{
    image: require("../assets/maps/14-9240-5413.png"),
    tile: { x: 9240, y: 5413, z: 14 }
}, 
{
    image: require("../assets/maps/14-9241-5413.png"),
    tile: { x: 9241, y: 5413, z: 14 }
}, 
{
    image: require("../assets/maps/14-9242-5413.png"),
    tile: { x: 9242, y: 5413, z: 14 }
}, 
{
    image: require("../assets/maps/14-9243-5413.png"),
    tile: { x: 9243, y: 5413, z: 14 }
}, 
{
    image: require("../assets/maps/14-9244-5413.png"),
    tile: { x: 9244, y: 5413, z: 14 }
}, 
{
    image: require("../assets/maps/14-9245-5413.png"),
    tile: { x: 9245, y: 5413, z: 14 }
}, 
{
    image: require("../assets/maps/14-9246-5413.png"),
    tile: { x: 9246, y: 5413, z: 14 }
}, 
{
    image: require("../assets/maps/14-9247-5413.png"),
    tile: { x: 9247, y: 5413, z: 14 }
}, 
{
    image: require("../assets/maps/14-9248-5413.png"),
    tile: { x: 9248, y: 5413, z: 14 }
}, 
{
    image: require("../assets/maps/14-9249-5413.png"),
    tile: { x: 9249, y: 5413, z: 14 }
}, 
{
    image: require("../assets/maps/14-9250-5413.png"),
    tile: { x: 9250, y: 5413, z: 14 }
}, 
{
    image: require("../assets/maps/14-9236-5412.png"),
    tile: { x: 9236, y: 5412, z: 14 }
}, 
{
    image: require("../assets/maps/14-9237-5412.png"),
    tile: { x: 9237, y: 5412, z: 14 }
}, 
{
    image: require("../assets/maps/14-9238-5412.png"),
    tile: { x: 9238, y: 5412, z: 14 }
}, 
{
    image: require("../assets/maps/14-9239-5412.png"),
    tile: { x: 9239, y: 5412, z: 14 }
}, 
{
    image: require("../assets/maps/14-9240-5412.png"),
    tile: { x: 9240, y: 5412, z: 14 }
}, 
{
    image: require("../assets/maps/14-9241-5412.png"),
    tile: { x: 9241, y: 5412, z: 14 }
}, 
{
    image: require("../assets/maps/14-9242-5412.png"),
    tile: { x: 9242, y: 5412, z: 14 }
}, 
{
    image: require("../assets/maps/14-9243-5412.png"),
    tile: { x: 9243, y: 5412, z: 14 }
}, 
{
    image: require("../assets/maps/14-9244-5412.png"),
    tile: { x: 9244, y: 5412, z: 14 }
}, 
{
    image: require("../assets/maps/14-9245-5412.png"),
    tile: { x: 9245, y: 5412, z: 14 }
}, 
{
    image: require("../assets/maps/14-9246-5412.png"),
    tile: { x: 9246, y: 5412, z: 14 }
}, 
{
    image: require("../assets/maps/14-9247-5412.png"),
    tile: { x: 9247, y: 5412, z: 14 }
}, 
{
    image: require("../assets/maps/14-9248-5412.png"),
    tile: { x: 9248, y: 5412, z: 14 }
}, 
{
    image: require("../assets/maps/14-9249-5412.png"),
    tile: { x: 9249, y: 5412, z: 14 }
}, 
{
    image: require("../assets/maps/14-9250-5412.png"),
    tile: { x: 9250, y: 5412, z: 14 }
}, 
{
    image: require("../assets/maps/14-9236-5411.png"),
    tile: { x: 9236, y: 5411, z: 14 }
}, 
{
    image: require("../assets/maps/14-9237-5411.png"),
    tile: { x: 9237, y: 5411, z: 14 }
}, 
{
    image: require("../assets/maps/14-9238-5411.png"),
    tile: { x: 9238, y: 5411, z: 14 }
}, 
{
    image: require("../assets/maps/14-9239-5411.png"),
    tile: { x: 9239, y: 5411, z: 14 }
}, 
{
    image: require("../assets/maps/14-9240-5411.png"),
    tile: { x: 9240, y: 5411, z: 14 }
}, 
{
    image: require("../assets/maps/14-9241-5411.png"),
    tile: { x: 9241, y: 5411, z: 14 }
}, 
{
    image: require("../assets/maps/14-9242-5411.png"),
    tile: { x: 9242, y: 5411, z: 14 }
}, 
{
    image: require("../assets/maps/14-9243-5411.png"),
    tile: { x: 9243, y: 5411, z: 14 }
}, 
{
    image: require("../assets/maps/14-9244-5411.png"),
    tile: { x: 9244, y: 5411, z: 14 }
}, 
{
    image: require("../assets/maps/14-9245-5411.png"),
    tile: { x: 9245, y: 5411, z: 14 }
}, 
{
    image: require("../assets/maps/14-9246-5411.png"),
    tile: { x: 9246, y: 5411, z: 14 }
}, 
{
    image: require("../assets/maps/14-9247-5411.png"),
    tile: { x: 9247, y: 5411, z: 14 }
}, 
{
    image: require("../assets/maps/14-9248-5411.png"),
    tile: { x: 9248, y: 5411, z: 14 }
}, 
{
    image: require("../assets/maps/14-9249-5411.png"),
    tile: { x: 9249, y: 5411, z: 14 }
}, 
{
    image: require("../assets/maps/14-9250-5411.png"),
    tile: { x: 9250, y: 5411, z: 14 }
}, 
{
    image: require("../assets/maps/14-9236-5410.png"),
    tile: { x: 9236, y: 5410, z: 14 }
}, 
{
    image: require("../assets/maps/14-9237-5410.png"),
    tile: { x: 9237, y: 5410, z: 14 }
}, 
{
    image: require("../assets/maps/14-9238-5410.png"),
    tile: { x: 9238, y: 5410, z: 14 }
}, 
{
    image: require("../assets/maps/14-9239-5410.png"),
    tile: { x: 9239, y: 5410, z: 14 }
}, 
{
    image: require("../assets/maps/14-9240-5410.png"),
    tile: { x: 9240, y: 5410, z: 14 }
}, 
{
    image: require("../assets/maps/14-9241-5410.png"),
    tile: { x: 9241, y: 5410, z: 14 }
}, 
{
    image: require("../assets/maps/14-9242-5410.png"),
    tile: { x: 9242, y: 5410, z: 14 }
}, 
{
    image: require("../assets/maps/14-9243-5410.png"),
    tile: { x: 9243, y: 5410, z: 14 }
}, 
{
    image: require("../assets/maps/14-9244-5410.png"),
    tile: { x: 9244, y: 5410, z: 14 }
}, 
{
    image: require("../assets/maps/14-9245-5410.png"),
    tile: { x: 9245, y: 5410, z: 14 }
}, 
{
    image: require("../assets/maps/14-9246-5410.png"),
    tile: { x: 9246, y: 5410, z: 14 }
}, 
{
    image: require("../assets/maps/14-9247-5410.png"),
    tile: { x: 9247, y: 5410, z: 14 }
}, 
{
    image: require("../assets/maps/14-9248-5410.png"),
    tile: { x: 9248, y: 5410, z: 14 }
}, 
{
    image: require("../assets/maps/14-9249-5410.png"),
    tile: { x: 9249, y: 5410, z: 14 }
}, 
{
    image: require("../assets/maps/14-9250-5410.png"),
    tile: { x: 9250, y: 5410, z: 14 }
}, 
{
    image: require("../assets/maps/14-9236-5409.png"),
    tile: { x: 9236, y: 5409, z: 14 }
}, 
{
    image: require("../assets/maps/14-9237-5409.png"),
    tile: { x: 9237, y: 5409, z: 14 }
}, 
{
    image: require("../assets/maps/14-9238-5409.png"),
    tile: { x: 9238, y: 5409, z: 14 }
}, 
{
    image: require("../assets/maps/14-9239-5409.png"),
    tile: { x: 9239, y: 5409, z: 14 }
}, 
{
    image: require("../assets/maps/14-9240-5409.png"),
    tile: { x: 9240, y: 5409, z: 14 }
}, 
{
    image: require("../assets/maps/14-9241-5409.png"),
    tile: { x: 9241, y: 5409, z: 14 }
}, 
{
    image: require("../assets/maps/14-9242-5409.png"),
    tile: { x: 9242, y: 5409, z: 14 }
}, 
{
    image: require("../assets/maps/14-9243-5409.png"),
    tile: { x: 9243, y: 5409, z: 14 }
}, 
{
    image: require("../assets/maps/14-9244-5409.png"),
    tile: { x: 9244, y: 5409, z: 14 }
}, 
{
    image: require("../assets/maps/14-9245-5409.png"),
    tile: { x: 9245, y: 5409, z: 14 }
}, 
{
    image: require("../assets/maps/14-9246-5409.png"),
    tile: { x: 9246, y: 5409, z: 14 }
}, 
{
    image: require("../assets/maps/14-9247-5409.png"),
    tile: { x: 9247, y: 5409, z: 14 }
}, 
{
    image: require("../assets/maps/14-9248-5409.png"),
    tile: { x: 9248, y: 5409, z: 14 }
}, 
{
    image: require("../assets/maps/14-9249-5409.png"),
    tile: { x: 9249, y: 5409, z: 14 }
}, 
{
    image: require("../assets/maps/14-9250-5409.png"),
    tile: { x: 9250, y: 5409, z: 14 }
}, 
{
    image: require("../assets/maps/14-9236-5408.png"),
    tile: { x: 9236, y: 5408, z: 14 }
}, 
{
    image: require("../assets/maps/14-9237-5408.png"),
    tile: { x: 9237, y: 5408, z: 14 }
}, 
{
    image: require("../assets/maps/14-9238-5408.png"),
    tile: { x: 9238, y: 5408, z: 14 }
}, 
{
    image: require("../assets/maps/14-9239-5408.png"),
    tile: { x: 9239, y: 5408, z: 14 }
}, 
{
    image: require("../assets/maps/14-9240-5408.png"),
    tile: { x: 9240, y: 5408, z: 14 }
}, 
{
    image: require("../assets/maps/14-9241-5408.png"),
    tile: { x: 9241, y: 5408, z: 14 }
}, 
{
    image: require("../assets/maps/14-9242-5408.png"),
    tile: { x: 9242, y: 5408, z: 14 }
}, 
{
    image: require("../assets/maps/14-9243-5408.png"),
    tile: { x: 9243, y: 5408, z: 14 }
}, 
{
    image: require("../assets/maps/14-9244-5408.png"),
    tile: { x: 9244, y: 5408, z: 14 }
}, 
{
    image: require("../assets/maps/14-9245-5408.png"),
    tile: { x: 9245, y: 5408, z: 14 }
}, 
{
    image: require("../assets/maps/14-9246-5408.png"),
    tile: { x: 9246, y: 5408, z: 14 }
}, 
{
    image: require("../assets/maps/14-9247-5408.png"),
    tile: { x: 9247, y: 5408, z: 14 }
}, 
{
    image: require("../assets/maps/14-9248-5408.png"),
    tile: { x: 9248, y: 5408, z: 14 }
}, 
{
    image: require("../assets/maps/14-9249-5408.png"),
    tile: { x: 9249, y: 5408, z: 14 }
}, 
{
    image: require("../assets/maps/14-9250-5408.png"),
    tile: { x: 9250, y: 5408, z: 14 }
}, 
{
    image: require("../assets/maps/14-9236-5407.png"),
    tile: { x: 9236, y: 5407, z: 14 }
}, 
{
    image: require("../assets/maps/14-9237-5407.png"),
    tile: { x: 9237, y: 5407, z: 14 }
}, 
{
    image: require("../assets/maps/14-9238-5407.png"),
    tile: { x: 9238, y: 5407, z: 14 }
}, 
{
    image: require("../assets/maps/14-9239-5407.png"),
    tile: { x: 9239, y: 5407, z: 14 }
}, 
{
    image: require("../assets/maps/14-9240-5407.png"),
    tile: { x: 9240, y: 5407, z: 14 }
}, 
{
    image: require("../assets/maps/14-9241-5407.png"),
    tile: { x: 9241, y: 5407, z: 14 }
}, 
{
    image: require("../assets/maps/14-9242-5407.png"),
    tile: { x: 9242, y: 5407, z: 14 }
}, 
{
    image: require("../assets/maps/14-9243-5407.png"),
    tile: { x: 9243, y: 5407, z: 14 }
}, 
{
    image: require("../assets/maps/14-9244-5407.png"),
    tile: { x: 9244, y: 5407, z: 14 }
}, 
{
    image: require("../assets/maps/14-9245-5407.png"),
    tile: { x: 9245, y: 5407, z: 14 }
}, 
{
    image: require("../assets/maps/14-9246-5407.png"),
    tile: { x: 9246, y: 5407, z: 14 }
}, 
{
    image: require("../assets/maps/14-9247-5407.png"),
    tile: { x: 9247, y: 5407, z: 14 }
}, 
{
    image: require("../assets/maps/14-9248-5407.png"),
    tile: { x: 9248, y: 5407, z: 14 }
}, 
{
    image: require("../assets/maps/14-9249-5407.png"),
    tile: { x: 9249, y: 5407, z: 14 }
}, 
{
    image: require("../assets/maps/14-9250-5407.png"),
    tile: { x: 9250, y: 5407, z: 14 }
}, 
{
    image: require("../assets/maps/14-9236-5406.png"),
    tile: { x: 9236, y: 5406, z: 14 }
}, 
{
    image: require("../assets/maps/14-9237-5406.png"),
    tile: { x: 9237, y: 5406, z: 14 }
}, 
{
    image: require("../assets/maps/14-9238-5406.png"),
    tile: { x: 9238, y: 5406, z: 14 }
}, 
{
    image: require("../assets/maps/14-9239-5406.png"),
    tile: { x: 9239, y: 5406, z: 14 }
}, 
{
    image: require("../assets/maps/14-9240-5406.png"),
    tile: { x: 9240, y: 5406, z: 14 }
}, 
{
    image: require("../assets/maps/14-9241-5406.png"),
    tile: { x: 9241, y: 5406, z: 14 }
}, 
{
    image: require("../assets/maps/14-9242-5406.png"),
    tile: { x: 9242, y: 5406, z: 14 }
}, 
{
    image: require("../assets/maps/14-9243-5406.png"),
    tile: { x: 9243, y: 5406, z: 14 }
}, 
{
    image: require("../assets/maps/14-9244-5406.png"),
    tile: { x: 9244, y: 5406, z: 14 }
}, 
{
    image: require("../assets/maps/14-9245-5406.png"),
    tile: { x: 9245, y: 5406, z: 14 }
}, 
{
    image: require("../assets/maps/14-9246-5406.png"),
    tile: { x: 9246, y: 5406, z: 14 }
}, 
{
    image: require("../assets/maps/14-9247-5406.png"),
    tile: { x: 9247, y: 5406, z: 14 }
}, 
{
    image: require("../assets/maps/14-9248-5406.png"),
    tile: { x: 9248, y: 5406, z: 14 }
}, 
{
    image: require("../assets/maps/14-9249-5406.png"),
    tile: { x: 9249, y: 5406, z: 14 }
}, 
{
    image: require("../assets/maps/14-9250-5406.png"),
    tile: { x: 9250, y: 5406, z: 14 }
}, 
{
    image: require("../assets/maps/14-9236-5405.png"),
    tile: { x: 9236, y: 5405, z: 14 }
}, 
{
    image: require("../assets/maps/14-9237-5405.png"),
    tile: { x: 9237, y: 5405, z: 14 }
}, 
{
    image: require("../assets/maps/14-9238-5405.png"),
    tile: { x: 9238, y: 5405, z: 14 }
}, 
{
    image: require("../assets/maps/14-9239-5405.png"),
    tile: { x: 9239, y: 5405, z: 14 }
}, 
{
    image: require("../assets/maps/14-9240-5405.png"),
    tile: { x: 9240, y: 5405, z: 14 }
}, 
{
    image: require("../assets/maps/14-9241-5405.png"),
    tile: { x: 9241, y: 5405, z: 14 }
}, 
{
    image: require("../assets/maps/14-9242-5405.png"),
    tile: { x: 9242, y: 5405, z: 14 }
}, 
{
    image: require("../assets/maps/14-9243-5405.png"),
    tile: { x: 9243, y: 5405, z: 14 }
}, 
{
    image: require("../assets/maps/14-9244-5405.png"),
    tile: { x: 9244, y: 5405, z: 14 }
}, 
{
    image: require("../assets/maps/14-9245-5405.png"),
    tile: { x: 9245, y: 5405, z: 14 }
}, 
{
    image: require("../assets/maps/14-9246-5405.png"),
    tile: { x: 9246, y: 5405, z: 14 }
}, 
{
    image: require("../assets/maps/14-9247-5405.png"),
    tile: { x: 9247, y: 5405, z: 14 }
}, 
{
    image: require("../assets/maps/14-9248-5405.png"),
    tile: { x: 9248, y: 5405, z: 14 }
}, 
{
    image: require("../assets/maps/14-9249-5405.png"),
    tile: { x: 9249, y: 5405, z: 14 }
}, 
{
    image: require("../assets/maps/14-9250-5405.png"),
    tile: { x: 9250, y: 5405, z: 14 }
}, 
{
    image: require("../assets/maps/14-9236-5404.png"),
    tile: { x: 9236, y: 5404, z: 14 }
}, 
{
    image: require("../assets/maps/14-9237-5404.png"),
    tile: { x: 9237, y: 5404, z: 14 }
}, 
{
    image: require("../assets/maps/14-9238-5404.png"),
    tile: { x: 9238, y: 5404, z: 14 }
}, 
{
    image: require("../assets/maps/14-9239-5404.png"),
    tile: { x: 9239, y: 5404, z: 14 }
}, 
{
    image: require("../assets/maps/14-9240-5404.png"),
    tile: { x: 9240, y: 5404, z: 14 }
}, 
{
    image: require("../assets/maps/14-9241-5404.png"),
    tile: { x: 9241, y: 5404, z: 14 }
}, 
{
    image: require("../assets/maps/14-9242-5404.png"),
    tile: { x: 9242, y: 5404, z: 14 }
}, 
{
    image: require("../assets/maps/14-9243-5404.png"),
    tile: { x: 9243, y: 5404, z: 14 }
}, 
{
    image: require("../assets/maps/14-9244-5404.png"),
    tile: { x: 9244, y: 5404, z: 14 }
}, 
{
    image: require("../assets/maps/14-9245-5404.png"),
    tile: { x: 9245, y: 5404, z: 14 }
}, 
{
    image: require("../assets/maps/14-9246-5404.png"),
    tile: { x: 9246, y: 5404, z: 14 }
}, 
{
    image: require("../assets/maps/14-9247-5404.png"),
    tile: { x: 9247, y: 5404, z: 14 }
}, 
{
    image: require("../assets/maps/14-9248-5404.png"),
    tile: { x: 9248, y: 5404, z: 14 }
}, 
{
    image: require("../assets/maps/14-9249-5404.png"),
    tile: { x: 9249, y: 5404, z: 14 }
}, 
{
    image: require("../assets/maps/14-9250-5404.png"),
    tile: { x: 9250, y: 5404, z: 14 }
}, 
{
    image: require("../assets/maps/14-9236-5403.png"),
    tile: { x: 9236, y: 5403, z: 14 }
}, 
{
    image: require("../assets/maps/14-9237-5403.png"),
    tile: { x: 9237, y: 5403, z: 14 }
}, 
{
    image: require("../assets/maps/14-9238-5403.png"),
    tile: { x: 9238, y: 5403, z: 14 }
}, 
{
    image: require("../assets/maps/14-9239-5403.png"),
    tile: { x: 9239, y: 5403, z: 14 }
}, 
{
    image: require("../assets/maps/14-9240-5403.png"),
    tile: { x: 9240, y: 5403, z: 14 }
}, 
{
    image: require("../assets/maps/14-9241-5403.png"),
    tile: { x: 9241, y: 5403, z: 14 }
}, 
{
    image: require("../assets/maps/14-9242-5403.png"),
    tile: { x: 9242, y: 5403, z: 14 }
}, 
{
    image: require("../assets/maps/14-9243-5403.png"),
    tile: { x: 9243, y: 5403, z: 14 }
}, 
{
    image: require("../assets/maps/14-9244-5403.png"),
    tile: { x: 9244, y: 5403, z: 14 }
}, 
{
    image: require("../assets/maps/14-9245-5403.png"),
    tile: { x: 9245, y: 5403, z: 14 }
}, 
{
    image: require("../assets/maps/14-9246-5403.png"),
    tile: { x: 9246, y: 5403, z: 14 }
}, 
{
    image: require("../assets/maps/14-9247-5403.png"),
    tile: { x: 9247, y: 5403, z: 14 }
}, 
{
    image: require("../assets/maps/14-9248-5403.png"),
    tile: { x: 9248, y: 5403, z: 14 }
}, 
{
    image: require("../assets/maps/14-9249-5403.png"),
    tile: { x: 9249, y: 5403, z: 14 }
}, 
{
    image: require("../assets/maps/14-9250-5403.png"),
    tile: { x: 9250, y: 5403, z: 14 }
}, 
{
    image: require("../assets/maps/14-9236-5402.png"),
    tile: { x: 9236, y: 5402, z: 14 }
}, 
{
    image: require("../assets/maps/14-9237-5402.png"),
    tile: { x: 9237, y: 5402, z: 14 }
}, 
{
    image: require("../assets/maps/14-9238-5402.png"),
    tile: { x: 9238, y: 5402, z: 14 }
}, 
{
    image: require("../assets/maps/14-9239-5402.png"),
    tile: { x: 9239, y: 5402, z: 14 }
}, 
{
    image: require("../assets/maps/14-9240-5402.png"),
    tile: { x: 9240, y: 5402, z: 14 }
}, 
{
    image: require("../assets/maps/14-9241-5402.png"),
    tile: { x: 9241, y: 5402, z: 14 }
}, 
{
    image: require("../assets/maps/14-9242-5402.png"),
    tile: { x: 9242, y: 5402, z: 14 }
}, 
{
    image: require("../assets/maps/14-9243-5402.png"),
    tile: { x: 9243, y: 5402, z: 14 }
}, 
{
    image: require("../assets/maps/14-9244-5402.png"),
    tile: { x: 9244, y: 5402, z: 14 }
}, 
{
    image: require("../assets/maps/14-9245-5402.png"),
    tile: { x: 9245, y: 5402, z: 14 }
}, 
{
    image: require("../assets/maps/14-9246-5402.png"),
    tile: { x: 9246, y: 5402, z: 14 }
}, 
{
    image: require("../assets/maps/14-9247-5402.png"),
    tile: { x: 9247, y: 5402, z: 14 }
}, 
{
    image: require("../assets/maps/14-9248-5402.png"),
    tile: { x: 9248, y: 5402, z: 14 }
}, 
{
    image: require("../assets/maps/14-9249-5402.png"),
    tile: { x: 9249, y: 5402, z: 14 }
}, 
{
    image: require("../assets/maps/14-9250-5402.png"),
    tile: { x: 9250, y: 5402, z: 14 }
}, 
{
    image: require("../assets/maps/14-9236-5401.png"),
    tile: { x: 9236, y: 5401, z: 14 }
}, 
{
    image: require("../assets/maps/14-9237-5401.png"),
    tile: { x: 9237, y: 5401, z: 14 }
}, 
{
    image: require("../assets/maps/14-9238-5401.png"),
    tile: { x: 9238, y: 5401, z: 14 }
}, 
{
    image: require("../assets/maps/14-9239-5401.png"),
    tile: { x: 9239, y: 5401, z: 14 }
}, 
{
    image: require("../assets/maps/14-9240-5401.png"),
    tile: { x: 9240, y: 5401, z: 14 }
}, 
{
    image: require("../assets/maps/14-9241-5401.png"),
    tile: { x: 9241, y: 5401, z: 14 }
}, 
{
    image: require("../assets/maps/14-9242-5401.png"),
    tile: { x: 9242, y: 5401, z: 14 }
}, 
{
    image: require("../assets/maps/14-9243-5401.png"),
    tile: { x: 9243, y: 5401, z: 14 }
}, 
{
    image: require("../assets/maps/14-9244-5401.png"),
    tile: { x: 9244, y: 5401, z: 14 }
}, 
{
    image: require("../assets/maps/14-9245-5401.png"),
    tile: { x: 9245, y: 5401, z: 14 }
}, 
{
    image: require("../assets/maps/14-9246-5401.png"),
    tile: { x: 9246, y: 5401, z: 14 }
}, 
{
    image: require("../assets/maps/14-9247-5401.png"),
    tile: { x: 9247, y: 5401, z: 14 }
}, 
{
    image: require("../assets/maps/14-9248-5401.png"),
    tile: { x: 9248, y: 5401, z: 14 }
}, 
{
    image: require("../assets/maps/14-9249-5401.png"),
    tile: { x: 9249, y: 5401, z: 14 }
}, 
{
    image: require("../assets/maps/14-9250-5401.png"),
    tile: { x: 9250, y: 5401, z: 14 }
}];