import * as React from "react"
import Svg, {
    SvgProps,
    Path,
    Defs,
    LinearGradient,
    Stop,
} from "react-native-svg"
import { memo } from "react"

const IconSun = (props: SvgProps) => (
    <Svg
        width={188}
        height={188}
        viewBox="0 0 188 188"
        fill="none"
        {...props}
    >
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M69.5 96.0032C91.3152 96.0032 109 78.3185 109 56.5032C109 34.688 91.3152 17.0032 69.5 17.0032C47.6848 17.0032 30 34.688 30 56.5032C30 78.3185 47.6848 96.0032 69.5 96.0032ZM69.5 97.0032C91.8675 97.0032 110 78.8708 110 56.5032C110 34.1357 91.8675 16.0032 69.5 16.0032C47.1325 16.0032 29 34.1357 29 56.5032C29 78.8708 47.1325 97.0032 69.5 97.0032Z"
            fill="black"
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M69.5001 15.9997L69.604 0L70.604 0.00649207L70.5001 16.0062L69.5001 15.9997Z"
            fill="#1C1B1F"
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M69.5001 15.9997L69.604 0L70.604 0.00649207L70.5001 16.0062L69.5001 15.9997Z"
            fill="#D0BCFF"
            fillOpacity={0.05}
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.6064 34.4363L21.75 26.4363L22.25 25.5703L36.1064 33.5703L35.6064 34.4363Z"
            fill="#1C1B1F"
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.6064 34.4363L21.75 26.4363L22.25 25.5703L36.1064 33.5703L35.6064 34.4363Z"
            fill="#D0BCFF"
            fillOpacity={0.05}
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.5842 70.4862L16.1294 74.6273L15.8706 73.6614L31.3254 69.5203L31.5842 70.4862Z"
            fill="#1C1B1F"
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.5842 70.4862L16.1294 74.6273L15.8706 73.6614L31.3254 69.5203L31.5842 70.4862Z"
            fill="#D0BCFF"
            fillOpacity={0.05}
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M101.75 33.5703L115.606 25.5703L116.106 26.4363L102.25 34.4363L101.75 33.5703Z"
            fill="#1C1B1F"
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M101.75 33.5703L115.606 25.5703L116.106 26.4363L102.25 34.4363L101.75 33.5703Z"
            fill="#D0BCFF"
            fillOpacity={0.05}
        />
        <Path
            d="M54 140H141L188 188H0L54 140Z"
            fill="url(#paint0_linear_320_5126)"
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M127.881 43.5739L127.9 43.1639L124.351 43.037C99.947 42.1643 78.4717 56.831 73.6938 77.5611C31.8022 70.794 11.1039 120.335 48.5949 138.34L52.0506 140H133.086V139.59L133.164 139.994C165.304 135.321 180.18 112.706 178.927 90.4897C177.675 68.2768 160.298 46.3966 127.935 43.1662L127.881 43.5739Z"
            fill="#F8DD85"
        />
        <Defs>
            {/*// @ts-ignore*/}
            <LinearGradient
                id="paint0_linear_320_5126"
                x1={94}
                y1={140}
                x2={94}
                y2={188}
                gradientUnits="userSpaceOnUse"
            >
                <Stop stopColor="#FCEDC4" />
                <Stop offset={1} stopColor="#FCEEC2" stopOpacity={0} />
            </LinearGradient>
        </Defs>
    </Svg>
)

const Memo = memo(IconSun)
export default Memo
