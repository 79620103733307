import * as React from 'react';
import {StyleSheet} from 'react-native';
import { Text } from 'react-native-paper';
import AudioInvoker from "../../components/audioPlayer/audioInvoker";
import { AudioPlaylistEntry } from "../../contexts/audioContext";
import FullWidthImage from "../../components/fullWidthImage/fullWidthImage";
import OrderedList from "../../components/orderedList/orderedList";
import { CombinedTheme } from "../../styles/theme";
import DidYouKnowTile from "../../components/didYouKnow/didYouKnowTile";
import EducationBoardPageBase from "../../components/educationBoardPageBase/educationBoardPageBase";
import BirdAudioInvoker from '../../components/audioPlayer/birdAudioInvoker';

export default function EducationBoard3() {
    return <EducationBoardPageBase boardNumber={3}>
            <EducationBoardContent />
    </EducationBoardPageBase>
}

const playlist: AudioPlaylistEntry[] = [{
    title: "Tablica edukacyjna nr 3",
    subtitle: "Jaki to ptak?",
    source: require("../../assets/boards/3/1.mp3")
}, {
    title: "Tablica edukacyjna nr 3",
    subtitle: "Potrzos",
    source: require("../../assets/boards/3/1.1.mp3")
}, {
    title: "Tablica edukacyjna nr 3",
    subtitle: "Sroka",
    source: require("../../assets/boards/3/1.2.mp3")
}, {
    title: "Tablica edukacyjna nr 3",
    subtitle: "Sójka",
    source: require("../../assets/boards/3/1.3.mp3")
}, {
    title: "Tablica edukacyjna nr 3",
    subtitle: "Błotniak stawowy",
    source: require("../../assets/boards/3/1.4.mp3")
}, {
    title: "Tablica edukacyjna nr 3",
    subtitle: "Czajka",
    source: require("../../assets/boards/3/1.5.mp3")
}, {
    title: "Tablica edukacyjna nr 3",
    subtitle: "Gąsiorek",
    source: require("../../assets/boards/3/1.6.mp3")
}, {
    title: "Tablica edukacyjna nr 3",
    subtitle: "Czy wiesz, że?",
    source: require("../../assets/boards/3/2.mp3")
}];

function EducationBoardContent() {
    return <>
        <AudioInvoker index={0} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Pokonując Szlak Doliny Krzny, warto uważnie się rozglądać. W tych okolicach można spotkać wiele gatunków ptaków. Niektóre z nich przedstawiono na rysunkach na tablicy edukacyjnej.
        </Text>
        <Text variant="titleMedium" style={styles.heading}>
        Miłego wypatrywania!
        </Text>
        <BirdAudioInvoker index={1} playlist={playlist} image={require("../../assets/boards/3/potrzos.png")} />
        <BirdAudioInvoker index={2} playlist={playlist} image={require("../../assets/boards/3/sroka.png")} />
        <BirdAudioInvoker index={3} playlist={playlist} image={require("../../assets/boards/3/sojka.png")} />
        <BirdAudioInvoker index={4} playlist={playlist} image={require("../../assets/boards/3/blotniak.png")} />
        <BirdAudioInvoker index={5} playlist={playlist} image={require("../../assets/boards/3/czajka.png")} />
        <BirdAudioInvoker index={6} playlist={playlist} image={require("../../assets/boards/3/gasiorek.png")} />
        <Text variant="labelSmall" style={styles.photoLabel}>
        rys. Malwina Łazęcka-Malińska
        </Text>
        <AudioInvoker index={7} playlist={playlist} />
        <DidYouKnowTile title={"Czy wiesz, że?"}
            description={"Błotniak stawowy to przedstawiciel ptaków drapieżnych, jakie można spotkać nad Krzną. Błotniaki stawowe preferują tereny otwarte, okolice zbiorników wodnych, szuwary i torfowiska. W przeciwieństwie do większości ptaków drapieżnych zakładają gniazda na ziemi — w trzcinach, wysokich trawach, a ostatnio także w zbożu czy rzepaku."}
        />
    </>
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 12
    },
    text: {
        marginBottom: 16
    },
    photoLabel: {
        color: CombinedTheme.colors.neutral60,
        marginBottom: 0,
        marginTop: 4
    }
});