import * as React from 'react';
import {StyleSheet} from 'react-native';
import { Text } from 'react-native-paper';
import AudioInvoker from "../../components/audioPlayer/audioInvoker";
import { AudioPlaylistEntry } from "../../contexts/audioContext";
import FullWidthImage from "../../components/fullWidthImage/fullWidthImage";
import OrderedList from "../../components/orderedList/orderedList";
import { CombinedTheme } from "../../styles/theme";
import DidYouKnowTile from "../../components/didYouKnow/didYouKnowTile";
import EducationBoardPageBase from "../../components/educationBoardPageBase/educationBoardPageBase";
import TreeCard from '../../components/treeCard/treeCard';

export default function EducationBoard9() {
    return <EducationBoardPageBase boardNumber={9}>
            <EducationBoardContent />
    </EducationBoardPageBase>
}

const playlist: AudioPlaylistEntry[] = [{
    title: "Tablica edukacyjna nr 9",
    subtitle: "Zaproś owady do ogrodu",
    source: require("../../assets/boards/9/1.mp3")
}, {
    title: "Tablica edukacyjna nr 9",
    subtitle: "Przykłady roślin dostarczających owadom pokarmu",
    source: require("../../assets/boards/9/2.mp3")
}, {
    title: "Tablica edukacyjna nr 9",
    subtitle: "Hotel dla zapylaczy",
    source: require("../../assets/boards/9/3.mp3")
}];

function EducationBoardContent() {
    return <>
        <AudioInvoker index={0} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Pszczoły, motyle, trzmiele i inne zapylacze to owady, których warunki życia są coraz trudniejsze.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Intensywne rolnictwo, stosowanie środków ochrony roślin i ograniczanie powierzchni naturalnych siedlisk doprowadziło do dramatycznego spadku liczby zapylaczy. To poważne zagrożenie dla całego życia na Ziemi. Praca owadów takich jak pszczoły pozwala na rozmnażanie się wielu gatunków roślin, które z kolei stanowią pożywienie zwierząt. Dotyczy to również pokarmu spożywanego przez ludzi: nawet jedna trzecia tego, co jemy, to rośliny uprawiane właśnie dzięki zapylaczom.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Jeżeli chcesz pomóc owadom w Twoim ogrodzie, przede wszystkim ogranicz sztuczne nawożenie i stosowanie pestycydów. Zadbaj też o obecność roślin miododajnych, czyli gatunków o barwnych i wonnych kwiatach, dostarczających owadom pokarmu — nektaru, spadzi i pyłku.
        </Text>
        <TreeCard
            title="Murarka ogrodowa"
            subtitle="Osmia rufa"
            image={require("../../assets/boards/9/murarka.png")}
        />
        <TreeCard
            title="Miesierka dwuzębna"
            subtitle="Megachile ericetorum"
            image={require("../../assets/boards/9/miesierka.png")}
        />
        <TreeCard
            title="Trzmiel rudy"
            subtitle="Bombus pascuorum"
            image={require("../../assets/boards/9/trzmiel.png")}
        />
        <AudioInvoker index={1} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Przykłady takich roślin możesz zobaczyć na tablicy.
        </Text>
        <FullWidthImage source={require("../../assets/boards/9/ogrod.png")} style={{ marginTop: 8 }} accessibilityLabel="Ilustracja przedstawiająca przykłady roślin sprzyjających owadom. Są to między innymi: Drzewa: czeremcha zwyczajna,	jarząb pospolity, lipa drobnolistna, wierzba, drzewa owocowe: np.: jabłonie, grusze, śliwy, wiśnie. Krzewy i pnącza: berberys, kruszyna pospolita, malina właściwa, porzeczka czarna, wiciokrzew pomorski. Rośliny zielne: chaber bławatek, facelia błękitna, koniczyna łąkowa, macierzanka piaskowa, mniszek lekarski, wrzos zwyczajny, zioła, np.: oregano, szałwia, tymianek" />
        <Text variant="labelSmall" style={styles.photoLabel}>
        rys. Zofia Sobolewska
        </Text>
        <AudioInvoker index={2} playlist={playlist} />
        <DidYouKnowTile title={"Hotel dla owadów"}
                        description={"Pomocne będzie także stworzenie zakątków do rozwoju mniej spektakularnych stadiów owada (czyli jaj, gąsienic i larw). Porządkując ogród, zostaw gdzieś na uboczu kupkę gałęzi, trochę ściętych trzcin albo szyszek. Możesz też zbudować specjalny hotel dla owadów."}
                        descriptionBoldAfterImage={"Na tablicy możesz zobaczyć jak wykonać prosty hotel dla zapylaczy z pęczka roślin o pustej łodydze (np. trzcina, bambus). Dla zabezpieczenia przed deszczem dobrze jest umieścić pęczek np. w przeciętej butelce albo puszce. Łodygi powinny być ciasno upakowane i przycięte tak, aby nie wystawały poza butelkę. Hotel zawieś pod okapem domu albo na gałęzi drzewa."}
                        image={require("../../assets/boards/9/hotel.png")} />
    </>
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 12
    },
    text: {
        marginBottom: 16
    },
    photoLabel: {
        color: CombinedTheme.colors.neutral60,
        marginBottom: 24,
        marginTop: 16
    }
});