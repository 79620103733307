/* tslint:disable */
/* eslint-disable */
/**
 * Digimuth.BialaPodlaska.Audiotour.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AnalyticsEventCreateDto
 */
export interface AnalyticsEventCreateDto {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsEventCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEventCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsEventCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEventCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsEventCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEventCreateDto
     */
    'eventType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEventCreateDto
     */
    'userId'?: string | null;
}
/**
 * 
 * @export
 * @interface AnalyticsEventDetailsDto
 */
export interface AnalyticsEventDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsEventDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEventDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsEventDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEventDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsEventDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEventDetailsDto
     */
    'eventType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEventDetailsDto
     */
    'userId'?: string | null;
}
/**
 * 
 * @export
 * @interface AnalyticsEventDto
 */
export interface AnalyticsEventDto {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsEventDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEventDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsEventDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEventDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsEventDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEventDto
     */
    'eventType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEventDto
     */
    'userId'?: string | null;
}
/**
 * 
 * @export
 * @interface AnalyticsEventUpdateDto
 */
export interface AnalyticsEventUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsEventUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEventUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsEventUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEventUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsEventUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEventUpdateDto
     */
    'eventType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEventUpdateDto
     */
    'userId'?: string | null;
}
/**
 * 
 * @export
 * @interface AnalyticsSummaryEntryDto
 */
export interface AnalyticsSummaryEntryDto {
    /**
     * 
     * @type {string}
     * @memberof AnalyticsSummaryEntryDto
     */
    'event'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsSummaryEntryDto
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsSummaryEntryDto
     */
    'distinctCount'?: number;
}
/**
 * 
 * @export
 * @interface ApplicationUserActivationDto
 */
export interface ApplicationUserActivationDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    'token'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserDetailsDto
 */
export interface ApplicationUserDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    'emailConfirmed'?: boolean;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ApplicationUserDetailsDto
     */
    'roles'?: Array<ApplicationUserRole> | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserDto
 */
export interface ApplicationUserDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDto
     */
    'emailConfirmed'?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationUserRegisterDto
 */
export interface ApplicationUserRegisterDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    'emailConfirmed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    'password'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    'withInvitation'?: boolean;
    /**
     * 
     * @type {ApplicationUserRole}
     * @memberof ApplicationUserRegisterDto
     */
    'role'?: ApplicationUserRole;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ApplicationUserRole = {
    Admin: 'Admin'
} as const;

export type ApplicationUserRole = typeof ApplicationUserRole[keyof typeof ApplicationUserRole];


/**
 * 
 * @export
 * @enum {string}
 */

export const ContactFormCategory = {
    FeedbackApplication: 'FeedbackApplication',
    Partnership: 'Partnership',
    MediaAndAdvertisement: 'MediaAndAdvertisement',
    Other: 'Other'
} as const;

export type ContactFormCategory = typeof ContactFormCategory[keyof typeof ContactFormCategory];


/**
 * 
 * @export
 * @interface ContactFormMessageDto
 */
export interface ContactFormMessageDto {
    /**
     * 
     * @type {string}
     * @memberof ContactFormMessageDto
     */
    'email': string;
    /**
     * 
     * @type {ContactFormCategory}
     * @memberof ContactFormMessageDto
     */
    'category': ContactFormCategory;
    /**
     * 
     * @type {string}
     * @memberof ContactFormMessageDto
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface ContactFormRecipientCreateDto
 */
export interface ContactFormRecipientCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ContactFormRecipientCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactFormRecipientCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactFormRecipientCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactFormRecipientCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContactFormRecipientCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormRecipientCreateDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {ContactFormCategory}
     * @memberof ContactFormRecipientCreateDto
     */
    'category'?: ContactFormCategory;
}
/**
 * 
 * @export
 * @interface ContactFormRecipientDetailsDto
 */
export interface ContactFormRecipientDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ContactFormRecipientDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactFormRecipientDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactFormRecipientDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactFormRecipientDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContactFormRecipientDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormRecipientDetailsDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {ContactFormCategory}
     * @memberof ContactFormRecipientDetailsDto
     */
    'category'?: ContactFormCategory;
}
/**
 * 
 * @export
 * @interface ContactFormRecipientDto
 */
export interface ContactFormRecipientDto {
    /**
     * 
     * @type {number}
     * @memberof ContactFormRecipientDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactFormRecipientDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactFormRecipientDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactFormRecipientDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContactFormRecipientDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormRecipientDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {ContactFormCategory}
     * @memberof ContactFormRecipientDto
     */
    'category'?: ContactFormCategory;
}
/**
 * 
 * @export
 * @interface ContactFormRecipientUpdateDto
 */
export interface ContactFormRecipientUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ContactFormRecipientUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactFormRecipientUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactFormRecipientUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactFormRecipientUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContactFormRecipientUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormRecipientUpdateDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {ContactFormCategory}
     * @memberof ContactFormRecipientUpdateDto
     */
    'category'?: ContactFormCategory;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Direction = {
    Up: 'Up',
    Down: 'Down'
} as const;

export type Direction = typeof Direction[keyof typeof Direction];


/**
 * 
 * @export
 * @interface EndForgotPasswordDto
 */
export interface EndForgotPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof EndForgotPasswordDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndForgotPasswordDto
     */
    'token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndForgotPasswordDto
     */
    'newPassword'?: string | null;
}
/**
 * 
 * @export
 * @interface EventCreateDto
 */
export interface EventCreateDto {
    /**
     * 
     * @type {number}
     * @memberof EventCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    'streamUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EventCreateDto
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventCreateDto
     */
    'eventPhotosIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<FileInfoDto>}
     * @memberof EventCreateDto
     */
    'eventPhotos'?: Array<FileInfoDto> | null;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    'hostName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventCreateDto
     */
    'port'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    'fragment'?: string | null;
}
/**
 * 
 * @export
 * @interface EventDetailsDto
 */
export interface EventDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof EventDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EventDetailsDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventDetailsDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventDetailsDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDetailsDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDetailsDto
     */
    'streamUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EventDetailsDto
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventDetailsDto
     */
    'eventPhotosIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<FileInfoDto>}
     * @memberof EventDetailsDto
     */
    'eventPhotos'?: Array<FileInfoDto> | null;
    /**
     * 
     * @type {string}
     * @memberof EventDetailsDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventDetailsDto
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventDetailsDto
     */
    'hostName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventDetailsDto
     */
    'port'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EventDetailsDto
     */
    'fragment'?: string | null;
}
/**
 * 
 * @export
 * @interface EventDto
 */
export interface EventDto {
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'streamUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EventDto
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventDto
     */
    'eventPhotosIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<FileInfoDto>}
     * @memberof EventDto
     */
    'eventPhotos'?: Array<FileInfoDto> | null;
}
/**
 * 
 * @export
 * @interface EventUpdateDto
 */
export interface EventUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof EventUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateDto
     */
    'streamUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EventUpdateDto
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventUpdateDto
     */
    'eventPhotosIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<FileInfoDto>}
     * @memberof EventUpdateDto
     */
    'eventPhotos'?: Array<FileInfoDto> | null;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateDto
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateDto
     */
    'hostName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventUpdateDto
     */
    'port'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateDto
     */
    'fragment'?: string | null;
}
/**
 * 
 * @export
 * @interface FileDetailsDto
 */
export interface FileDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof FileDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FileDetailsDto
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileDetailsDto
     */
    'contentType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileDetailsDto
     */
    'eventPhotoPosition'?: number;
}
/**
 * 
 * @export
 * @interface FileDto
 */
export interface FileDto {
    /**
     * 
     * @type {number}
     * @memberof FileDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FileDto
     */
    'eventPhotoPosition'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileDto
     */
    'eventId'?: number | null;
}
/**
 * 
 * @export
 * @interface FileInfoDto
 */
export interface FileInfoDto {
    /**
     * 
     * @type {number}
     * @memberof FileInfoDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDto
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDto
     */
    'eventPhotoPosition'?: number;
}
/**
 * 
 * @export
 * @interface FileUpdateDto
 */
export interface FileUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof FileUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FileUpdateDto
     */
    'eventPhotoPosition'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileUpdateDto
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileUpdateDto
     */
    'eventId'?: number | null;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LoginDto
     */
    'rememberMe'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OperationType = {
    Add: 'Add',
    Remove: 'Remove',
    Replace: 'Replace',
    Move: 'Move',
    Copy: 'Copy',
    Test: 'Test',
    Invalid: 'Invalid'
} as const;

export type OperationType = typeof OperationType[keyof typeof OperationType];


/**
 * 
 * @export
 * @interface StartForgotPasswordDto
 */
export interface StartForgotPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof StartForgotPasswordDto
     */
    'email'?: string | null;
}

/**
 * AnalyticsEventApi - axios parameter creator
 * @export
 */
export const AnalyticsEventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsEventGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnalyticsEvent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsEventIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAnalyticsEventIdDelete', 'id', id)
            const localVarPath = `/api/AnalyticsEvent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsEventIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAnalyticsEventIdGet', 'id', id)
            const localVarPath = `/api/AnalyticsEvent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AnalyticsEventUpdateDto} [analyticsEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsEventIdPatch: async (id: number, analyticsEventUpdateDto?: AnalyticsEventUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAnalyticsEventIdPatch', 'id', id)
            const localVarPath = `/api/AnalyticsEvent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analyticsEventUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AnalyticsEventUpdateDto} [analyticsEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsEventIdPut: async (id: number, analyticsEventUpdateDto?: AnalyticsEventUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAnalyticsEventIdPut', 'id', id)
            const localVarPath = `/api/AnalyticsEvent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analyticsEventUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AnalyticsEventCreateDto} [analyticsEventCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsEventPost: async (analyticsEventCreateDto?: AnalyticsEventCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnalyticsEvent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analyticsEventCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsEventSummaryByDateGet: async (beginDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnalyticsEvent/summaryByDate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (beginDate !== undefined) {
                localVarQueryParameter['beginDate'] = (beginDate as any instanceof Date) ?
                    (beginDate as any).toISOString() :
                    beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsEventSummaryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnalyticsEvent/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsEventApi - functional programming interface
 * @export
 */
export const AnalyticsEventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticsEventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticsEventGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnalyticsEventDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticsEventGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticsEventIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticsEventIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticsEventIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsEventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticsEventIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AnalyticsEventUpdateDto} [analyticsEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticsEventIdPatch(id: number, analyticsEventUpdateDto?: AnalyticsEventUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsEventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticsEventIdPatch(id, analyticsEventUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AnalyticsEventUpdateDto} [analyticsEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticsEventIdPut(id: number, analyticsEventUpdateDto?: AnalyticsEventUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsEventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticsEventIdPut(id, analyticsEventUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AnalyticsEventCreateDto} [analyticsEventCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticsEventPost(analyticsEventCreateDto?: AnalyticsEventCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsEventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticsEventPost(analyticsEventCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticsEventSummaryByDateGet(beginDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnalyticsSummaryEntryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticsEventSummaryByDateGet(beginDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticsEventSummaryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnalyticsSummaryEntryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticsEventSummaryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalyticsEventApi - factory interface
 * @export
 */
export const AnalyticsEventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyticsEventApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsEventGet(options?: any): AxiosPromise<Array<AnalyticsEventDto>> {
            return localVarFp.apiAnalyticsEventGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsEventIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAnalyticsEventIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsEventIdGet(id: number, options?: any): AxiosPromise<AnalyticsEventDetailsDto> {
            return localVarFp.apiAnalyticsEventIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AnalyticsEventUpdateDto} [analyticsEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsEventIdPatch(id: number, analyticsEventUpdateDto?: AnalyticsEventUpdateDto, options?: any): AxiosPromise<AnalyticsEventDetailsDto> {
            return localVarFp.apiAnalyticsEventIdPatch(id, analyticsEventUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AnalyticsEventUpdateDto} [analyticsEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsEventIdPut(id: number, analyticsEventUpdateDto?: AnalyticsEventUpdateDto, options?: any): AxiosPromise<AnalyticsEventDetailsDto> {
            return localVarFp.apiAnalyticsEventIdPut(id, analyticsEventUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AnalyticsEventCreateDto} [analyticsEventCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsEventPost(analyticsEventCreateDto?: AnalyticsEventCreateDto, options?: any): AxiosPromise<AnalyticsEventDetailsDto> {
            return localVarFp.apiAnalyticsEventPost(analyticsEventCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsEventSummaryByDateGet(beginDate?: string, endDate?: string, options?: any): AxiosPromise<Array<AnalyticsSummaryEntryDto>> {
            return localVarFp.apiAnalyticsEventSummaryByDateGet(beginDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsEventSummaryGet(options?: any): AxiosPromise<Array<AnalyticsSummaryEntryDto>> {
            return localVarFp.apiAnalyticsEventSummaryGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyticsEventApi - object-oriented interface
 * @export
 * @class AnalyticsEventApi
 * @extends {BaseAPI}
 */
export class AnalyticsEventApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEventApi
     */
    public apiAnalyticsEventGet(options?: AxiosRequestConfig) {
        return AnalyticsEventApiFp(this.configuration).apiAnalyticsEventGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEventApi
     */
    public apiAnalyticsEventIdDelete(id: number, options?: AxiosRequestConfig) {
        return AnalyticsEventApiFp(this.configuration).apiAnalyticsEventIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEventApi
     */
    public apiAnalyticsEventIdGet(id: number, options?: AxiosRequestConfig) {
        return AnalyticsEventApiFp(this.configuration).apiAnalyticsEventIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AnalyticsEventUpdateDto} [analyticsEventUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEventApi
     */
    public apiAnalyticsEventIdPatch(id: number, analyticsEventUpdateDto?: AnalyticsEventUpdateDto, options?: AxiosRequestConfig) {
        return AnalyticsEventApiFp(this.configuration).apiAnalyticsEventIdPatch(id, analyticsEventUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AnalyticsEventUpdateDto} [analyticsEventUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEventApi
     */
    public apiAnalyticsEventIdPut(id: number, analyticsEventUpdateDto?: AnalyticsEventUpdateDto, options?: AxiosRequestConfig) {
        return AnalyticsEventApiFp(this.configuration).apiAnalyticsEventIdPut(id, analyticsEventUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsEventCreateDto} [analyticsEventCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEventApi
     */
    public apiAnalyticsEventPost(analyticsEventCreateDto?: AnalyticsEventCreateDto, options?: AxiosRequestConfig) {
        return AnalyticsEventApiFp(this.configuration).apiAnalyticsEventPost(analyticsEventCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEventApi
     */
    public apiAnalyticsEventSummaryByDateGet(beginDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return AnalyticsEventApiFp(this.configuration).apiAnalyticsEventSummaryByDateGet(beginDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsEventApi
     */
    public apiAnalyticsEventSummaryGet(options?: AxiosRequestConfig) {
        return AnalyticsEventApiFp(this.configuration).apiAnalyticsEventSummaryGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationUserApi - axios parameter creator
 * @export
 */
export const ApplicationUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserActivatePost: async (applicationUserActivationDto?: ApplicationUserActivationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserActivationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserBeginForgotPasswordPost: async (startForgotPasswordDto?: StartForgotPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/beginForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startForgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserEndForgotPasswordPost: async (endForgotPasswordDto?: EndForgotPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/endForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(endForgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdDelete', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdGet', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPatch: async (id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdPatch', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPut: async (id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdPut', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdRolesPost: async (id: number, applicationUserRole?: Array<ApplicationUserRole>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdRolesPost', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}/roles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPost: async (applicationUserRegisterDto?: ApplicationUserRegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationUserApi - functional programming interface
 * @export
 */
export const ApplicationUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserActivatePost(applicationUserActivationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdPatch(id, applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdPut(id, applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdRolesPost(id, applicationUserRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserPost(applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationUserApi - factory interface
 * @export
 */
export const ApplicationUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationUserApiFp(configuration)
    return {
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserActivatePost(applicationUserActivationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGet(options?: any): AxiosPromise<Array<ApplicationUserDto>> {
            return localVarFp.apiApplicationUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdGet(id: number, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdPatch(id, applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdPut(id, applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserIdRolesPost(id, applicationUserRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserPost(applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationUserApi - object-oriented interface
 * @export
 * @class ApplicationUserApi
 * @extends {BaseAPI}
 */
export class ApplicationUserApi extends BaseAPI {
    /**
     * 
     * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserActivatePost(applicationUserActivationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserGet(options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdDelete(id: number, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdGet(id: number, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdPatch(id, applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdPut(id, applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<ApplicationUserRole>} [applicationUserRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdRolesPost(id, applicationUserRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserPost(applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginCallbackGet: async (returnUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/loginCallback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [loginPageUrl] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginGet: async (loginPageUrl?: string, returnUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (loginPageUrl !== undefined) {
                localVarQueryParameter['loginPageUrl'] = loginPageUrl;
            }

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginPost: async (returnUrl?: string, loginDto?: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [postLogoutUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLogoutGet: async (postLogoutUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (postLogoutUrl !== undefined) {
                localVarQueryParameter['postLogoutUrl'] = postLogoutUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLoginCallbackGet(returnUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLoginCallbackGet(returnUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [loginPageUrl] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLoginGet(loginPageUrl?: string, returnUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLoginGet(loginPageUrl, returnUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLoginPost(returnUrl?: string, loginDto?: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLoginPost(returnUrl, loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [postLogoutUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLogoutGet(postLogoutUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLogoutGet(postLogoutUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginCallbackGet(returnUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLoginCallbackGet(returnUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [loginPageUrl] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginGet(loginPageUrl?: string, returnUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLoginGet(loginPageUrl, returnUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginPost(returnUrl?: string, loginDto?: LoginDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLoginPost(returnUrl, loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [postLogoutUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLogoutGet(postLogoutUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLogoutGet(postLogoutUrl, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLoginCallbackGet(returnUrl?: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLoginCallbackGet(returnUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [loginPageUrl] 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLoginGet(loginPageUrl?: string, returnUrl?: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLoginGet(loginPageUrl, returnUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [returnUrl] 
     * @param {LoginDto} [loginDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLoginPost(returnUrl?: string, loginDto?: LoginDto, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLoginPost(returnUrl, loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [postLogoutUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLogoutGet(postLogoutUrl?: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLogoutGet(postLogoutUrl, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactFormApi - axios parameter creator
 * @export
 */
export const ContactFormApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ContactFormMessageDto} [contactFormMessageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactFormPost: async (contactFormMessageDto?: ContactFormMessageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ContactForm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactFormMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactFormApi - functional programming interface
 * @export
 */
export const ContactFormApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactFormApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ContactFormMessageDto} [contactFormMessageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactFormPost(contactFormMessageDto?: ContactFormMessageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactFormPost(contactFormMessageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactFormApi - factory interface
 * @export
 */
export const ContactFormApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactFormApiFp(configuration)
    return {
        /**
         * 
         * @param {ContactFormMessageDto} [contactFormMessageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactFormPost(contactFormMessageDto?: ContactFormMessageDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiContactFormPost(contactFormMessageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactFormApi - object-oriented interface
 * @export
 * @class ContactFormApi
 * @extends {BaseAPI}
 */
export class ContactFormApi extends BaseAPI {
    /**
     * 
     * @param {ContactFormMessageDto} [contactFormMessageDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormApi
     */
    public apiContactFormPost(contactFormMessageDto?: ContactFormMessageDto, options?: AxiosRequestConfig) {
        return ContactFormApiFp(this.configuration).apiContactFormPost(contactFormMessageDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactFormRecipientApi - axios parameter creator
 * @export
 */
export const ContactFormRecipientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactFormRecipientGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ContactFormRecipient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactFormRecipientIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiContactFormRecipientIdDelete', 'id', id)
            const localVarPath = `/api/ContactFormRecipient/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactFormRecipientIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiContactFormRecipientIdGet', 'id', id)
            const localVarPath = `/api/ContactFormRecipient/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ContactFormRecipientUpdateDto} [contactFormRecipientUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactFormRecipientIdPatch: async (id: number, contactFormRecipientUpdateDto?: ContactFormRecipientUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiContactFormRecipientIdPatch', 'id', id)
            const localVarPath = `/api/ContactFormRecipient/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactFormRecipientUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ContactFormRecipientUpdateDto} [contactFormRecipientUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactFormRecipientIdPut: async (id: number, contactFormRecipientUpdateDto?: ContactFormRecipientUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiContactFormRecipientIdPut', 'id', id)
            const localVarPath = `/api/ContactFormRecipient/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactFormRecipientUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactFormRecipientCreateDto} [contactFormRecipientCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactFormRecipientPost: async (contactFormRecipientCreateDto?: ContactFormRecipientCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ContactFormRecipient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactFormRecipientCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactFormRecipientApi - functional programming interface
 * @export
 */
export const ContactFormRecipientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactFormRecipientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactFormRecipientGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactFormRecipientDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactFormRecipientGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactFormRecipientIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactFormRecipientIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactFormRecipientIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactFormRecipientDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactFormRecipientIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ContactFormRecipientUpdateDto} [contactFormRecipientUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactFormRecipientIdPatch(id: number, contactFormRecipientUpdateDto?: ContactFormRecipientUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactFormRecipientDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactFormRecipientIdPatch(id, contactFormRecipientUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ContactFormRecipientUpdateDto} [contactFormRecipientUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactFormRecipientIdPut(id: number, contactFormRecipientUpdateDto?: ContactFormRecipientUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactFormRecipientDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactFormRecipientIdPut(id, contactFormRecipientUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContactFormRecipientCreateDto} [contactFormRecipientCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactFormRecipientPost(contactFormRecipientCreateDto?: ContactFormRecipientCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactFormRecipientDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactFormRecipientPost(contactFormRecipientCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactFormRecipientApi - factory interface
 * @export
 */
export const ContactFormRecipientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactFormRecipientApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactFormRecipientGet(options?: any): AxiosPromise<Array<ContactFormRecipientDto>> {
            return localVarFp.apiContactFormRecipientGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactFormRecipientIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiContactFormRecipientIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactFormRecipientIdGet(id: number, options?: any): AxiosPromise<ContactFormRecipientDetailsDto> {
            return localVarFp.apiContactFormRecipientIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ContactFormRecipientUpdateDto} [contactFormRecipientUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactFormRecipientIdPatch(id: number, contactFormRecipientUpdateDto?: ContactFormRecipientUpdateDto, options?: any): AxiosPromise<ContactFormRecipientDetailsDto> {
            return localVarFp.apiContactFormRecipientIdPatch(id, contactFormRecipientUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ContactFormRecipientUpdateDto} [contactFormRecipientUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactFormRecipientIdPut(id: number, contactFormRecipientUpdateDto?: ContactFormRecipientUpdateDto, options?: any): AxiosPromise<ContactFormRecipientDetailsDto> {
            return localVarFp.apiContactFormRecipientIdPut(id, contactFormRecipientUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactFormRecipientCreateDto} [contactFormRecipientCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactFormRecipientPost(contactFormRecipientCreateDto?: ContactFormRecipientCreateDto, options?: any): AxiosPromise<ContactFormRecipientDetailsDto> {
            return localVarFp.apiContactFormRecipientPost(contactFormRecipientCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactFormRecipientApi - object-oriented interface
 * @export
 * @class ContactFormRecipientApi
 * @extends {BaseAPI}
 */
export class ContactFormRecipientApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormRecipientApi
     */
    public apiContactFormRecipientGet(options?: AxiosRequestConfig) {
        return ContactFormRecipientApiFp(this.configuration).apiContactFormRecipientGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormRecipientApi
     */
    public apiContactFormRecipientIdDelete(id: number, options?: AxiosRequestConfig) {
        return ContactFormRecipientApiFp(this.configuration).apiContactFormRecipientIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormRecipientApi
     */
    public apiContactFormRecipientIdGet(id: number, options?: AxiosRequestConfig) {
        return ContactFormRecipientApiFp(this.configuration).apiContactFormRecipientIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ContactFormRecipientUpdateDto} [contactFormRecipientUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormRecipientApi
     */
    public apiContactFormRecipientIdPatch(id: number, contactFormRecipientUpdateDto?: ContactFormRecipientUpdateDto, options?: AxiosRequestConfig) {
        return ContactFormRecipientApiFp(this.configuration).apiContactFormRecipientIdPatch(id, contactFormRecipientUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ContactFormRecipientUpdateDto} [contactFormRecipientUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormRecipientApi
     */
    public apiContactFormRecipientIdPut(id: number, contactFormRecipientUpdateDto?: ContactFormRecipientUpdateDto, options?: AxiosRequestConfig) {
        return ContactFormRecipientApiFp(this.configuration).apiContactFormRecipientIdPut(id, contactFormRecipientUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactFormRecipientCreateDto} [contactFormRecipientCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormRecipientApi
     */
    public apiContactFormRecipientPost(contactFormRecipientCreateDto?: ContactFormRecipientCreateDto, options?: AxiosRequestConfig) {
        return ContactFormRecipientApiFp(this.configuration).apiContactFormRecipientPost(contactFormRecipientCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventApi - axios parameter creator
 * @export
 */
export const EventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Event/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEventIdDelete', 'id', id)
            const localVarPath = `/api/Event/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEventIdGet', 'id', id)
            const localVarPath = `/api/Event/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EventUpdateDto} [eventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventIdPatch: async (id: number, eventUpdateDto?: EventUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEventIdPatch', 'id', id)
            const localVarPath = `/api/Event/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EventUpdateDto} [eventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventIdPut: async (id: number, eventUpdateDto?: EventUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEventIdPut', 'id', id)
            const localVarPath = `/api/Event/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EventCreateDto} [eventCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventPost: async (eventCreateDto?: EventCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventAllGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventAllGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EventUpdateDto} [eventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventIdPatch(id: number, eventUpdateDto?: EventUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventIdPatch(id, eventUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EventUpdateDto} [eventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventIdPut(id: number, eventUpdateDto?: EventUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventIdPut(id, eventUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EventCreateDto} [eventCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventPost(eventCreateDto?: EventCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventPost(eventCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventAllGet(options?: any): AxiosPromise<Array<EventDto>> {
            return localVarFp.apiEventAllGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventGet(options?: any): AxiosPromise<Array<EventDto>> {
            return localVarFp.apiEventGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEventIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventIdGet(id: number, options?: any): AxiosPromise<EventDetailsDto> {
            return localVarFp.apiEventIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EventUpdateDto} [eventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventIdPatch(id: number, eventUpdateDto?: EventUpdateDto, options?: any): AxiosPromise<EventDetailsDto> {
            return localVarFp.apiEventIdPatch(id, eventUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EventUpdateDto} [eventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventIdPut(id: number, eventUpdateDto?: EventUpdateDto, options?: any): AxiosPromise<EventDetailsDto> {
            return localVarFp.apiEventIdPut(id, eventUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EventCreateDto} [eventCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventPost(eventCreateDto?: EventCreateDto, options?: any): AxiosPromise<EventDetailsDto> {
            return localVarFp.apiEventPost(eventCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public apiEventAllGet(options?: AxiosRequestConfig) {
        return EventApiFp(this.configuration).apiEventAllGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public apiEventGet(options?: AxiosRequestConfig) {
        return EventApiFp(this.configuration).apiEventGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public apiEventIdDelete(id: number, options?: AxiosRequestConfig) {
        return EventApiFp(this.configuration).apiEventIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public apiEventIdGet(id: number, options?: AxiosRequestConfig) {
        return EventApiFp(this.configuration).apiEventIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EventUpdateDto} [eventUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public apiEventIdPatch(id: number, eventUpdateDto?: EventUpdateDto, options?: AxiosRequestConfig) {
        return EventApiFp(this.configuration).apiEventIdPatch(id, eventUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EventUpdateDto} [eventUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public apiEventIdPut(id: number, eventUpdateDto?: EventUpdateDto, options?: AxiosRequestConfig) {
        return EventApiFp(this.configuration).apiEventIdPut(id, eventUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EventCreateDto} [eventCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public apiEventPost(eventCreateDto?: EventCreateDto, options?: AxiosRequestConfig) {
        return EventApiFp(this.configuration).apiEventPost(eventCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileEventIdEventPhotosGet: async (eventId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('apiFileEventIdEventPhotosGet', 'eventId', eventId)
            const localVarPath = `/api/File/{eventId}/eventPhotos`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/File`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileIdDelete', 'id', id)
            const localVarPath = `/api/File/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdFileStreamGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileIdFileStreamGet', 'id', id)
            const localVarPath = `/api/File/{id}/FileStream`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileIdGet', 'id', id)
            const localVarPath = `/api/File/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FileUpdateDto} [fileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdPatch: async (id: number, fileUpdateDto?: FileUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileIdPatch', 'id', id)
            const localVarPath = `/api/File/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FileUpdateDto} [fileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdPut: async (id: number, fileUpdateDto?: FileUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileIdPut', 'id', id)
            const localVarPath = `/api/File/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} eventId 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilePositionIdEventIdDirectionPatch: async (id: number, eventId: number, direction: Direction, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFilePositionIdEventIdDirectionPatch', 'id', id)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('apiFilePositionIdEventIdDirectionPatch', 'eventId', eventId)
            // verify required parameter 'direction' is not null or undefined
            assertParamExists('apiFilePositionIdEventIdDirectionPatch', 'direction', direction)
            const localVarPath = `/api/File/position/{id}/{eventId}/{direction}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)))
                .replace(`{${"direction"}}`, encodeURIComponent(String(direction)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [eventId] 
         * @param {number} [eventPhotoPosition] 
         * @param {File} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilePost: async (eventId?: number, eventPhotoPosition?: number, file?: File, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/File`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (eventId !== undefined) { 
                localVarFormParams.append('EventId', eventId as any);
            }
    
            if (eventPhotoPosition !== undefined) { 
                localVarFormParams.append('EventPhotoPosition', eventPhotoPosition as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileEventIdEventPhotosGet(eventId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileInfoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileEventIdEventPhotosGet(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileIdFileStreamGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileIdFileStreamGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FileUpdateDto} [fileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileIdPatch(id: number, fileUpdateDto?: FileUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileIdPatch(id, fileUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FileUpdateDto} [fileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileIdPut(id: number, fileUpdateDto?: FileUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileIdPut(id, fileUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} eventId 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilePositionIdEventIdDirectionPatch(id: number, eventId: number, direction: Direction, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileInfoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilePositionIdEventIdDirectionPatch(id, eventId, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [eventId] 
         * @param {number} [eventPhotoPosition] 
         * @param {File} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilePost(eventId?: number, eventPhotoPosition?: number, file?: File, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilePost(eventId, eventPhotoPosition, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileEventIdEventPhotosGet(eventId: number, options?: any): AxiosPromise<Array<FileInfoDto>> {
            return localVarFp.apiFileEventIdEventPhotosGet(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileGet(options?: any): AxiosPromise<Array<FileDto>> {
            return localVarFp.apiFileGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdFileStreamGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileIdFileStreamGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdGet(id: number, options?: any): AxiosPromise<FileDetailsDto> {
            return localVarFp.apiFileIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {FileUpdateDto} [fileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdPatch(id: number, fileUpdateDto?: FileUpdateDto, options?: any): AxiosPromise<FileDetailsDto> {
            return localVarFp.apiFileIdPatch(id, fileUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {FileUpdateDto} [fileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdPut(id: number, fileUpdateDto?: FileUpdateDto, options?: any): AxiosPromise<FileDetailsDto> {
            return localVarFp.apiFileIdPut(id, fileUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} eventId 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilePositionIdEventIdDirectionPatch(id: number, eventId: number, direction: Direction, options?: any): AxiosPromise<Array<FileInfoDto>> {
            return localVarFp.apiFilePositionIdEventIdDirectionPatch(id, eventId, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [eventId] 
         * @param {number} [eventPhotoPosition] 
         * @param {File} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilePost(eventId?: number, eventPhotoPosition?: number, file?: File, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<FileDetailsDto> {
            return localVarFp.apiFilePost(eventId, eventPhotoPosition, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileEventIdEventPhotosGet(eventId: number, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileEventIdEventPhotosGet(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileGet(options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileIdDelete(id: number, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileIdFileStreamGet(id: number, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileIdFileStreamGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileIdGet(id: number, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {FileUpdateDto} [fileUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileIdPatch(id: number, fileUpdateDto?: FileUpdateDto, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileIdPatch(id, fileUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {FileUpdateDto} [fileUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileIdPut(id: number, fileUpdateDto?: FileUpdateDto, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileIdPut(id, fileUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} eventId 
     * @param {Direction} direction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFilePositionIdEventIdDirectionPatch(id: number, eventId: number, direction: Direction, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFilePositionIdEventIdDirectionPatch(id, eventId, direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [eventId] 
     * @param {number} [eventPhotoPosition] 
     * @param {File} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFilePost(eventId?: number, eventPhotoPosition?: number, file?: File, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFilePost(eventId, eventPhotoPosition, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StreamApi - axios parameter creator
 * @export
 */
export const StreamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} dir 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamEvtDirFileNameGet: async (dir: string, fileName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dir' is not null or undefined
            assertParamExists('streamEvtDirFileNameGet', 'dir', dir)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('streamEvtDirFileNameGet', 'fileName', fileName)
            const localVarPath = `/Stream/evt/{dir}/{fileName}`
                .replace(`{${"dir"}}`, encodeURIComponent(String(dir)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamEvtIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('streamEvtIdGet', 'id', id)
            const localVarPath = `/Stream/evt/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StreamApi - functional programming interface
 * @export
 */
export const StreamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StreamApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} dir 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamEvtDirFileNameGet(dir: string, fileName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamEvtDirFileNameGet(dir, fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamEvtIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamEvtIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StreamApi - factory interface
 * @export
 */
export const StreamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StreamApiFp(configuration)
    return {
        /**
         * 
         * @param {string} dir 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamEvtDirFileNameGet(dir: string, fileName: string, options?: any): AxiosPromise<void> {
            return localVarFp.streamEvtDirFileNameGet(dir, fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamEvtIdGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.streamEvtIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StreamApi - object-oriented interface
 * @export
 * @class StreamApi
 * @extends {BaseAPI}
 */
export class StreamApi extends BaseAPI {
    /**
     * 
     * @param {string} dir 
     * @param {string} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreamApi
     */
    public streamEvtDirFileNameGet(dir: string, fileName: string, options?: AxiosRequestConfig) {
        return StreamApiFp(this.configuration).streamEvtDirFileNameGet(dir, fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreamApi
     */
    public streamEvtIdGet(id: number, options?: AxiosRequestConfig) {
        return StreamApiFp(this.configuration).streamEvtIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


