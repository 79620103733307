import * as React from 'react';
import {StyleSheet} from 'react-native';
import { Text } from 'react-native-paper';
import AudioInvoker from "../../components/audioPlayer/audioInvoker";
import { AudioPlaylistEntry } from "../../contexts/audioContext";
import FullWidthImage from "../../components/fullWidthImage/fullWidthImage";
import OrderedList from "../../components/orderedList/orderedList";
import { CombinedTheme } from "../../styles/theme";
import DidYouKnowTile from "../../components/didYouKnow/didYouKnowTile";
import EducationBoardPageBase from "../../components/educationBoardPageBase/educationBoardPageBase";

export default function EducationBoard2() {
    return <EducationBoardPageBase boardNumber={2}>
            <EducationBoardContent />
    </EducationBoardPageBase>
}

const playlist: AudioPlaylistEntry[] = [{
    title: "Tablica edukacyjna nr 2",
    subtitle: "Knieja czy plantacja?",
    source: require("../../assets/boards/2/1.mp3")
}, {
    title: "Tablica edukacyjna nr 2",
    subtitle: "Skąd tu tyle sosen?",
    source: require("../../assets/boards/2/2.mp3")
}, {
    title: "Tablica edukacyjna nr 2",
    subtitle: "Czy wiesz, że?",
    source: require("../../assets/boards/2/3.mp3")
}];

function EducationBoardContent() {
    return <>
        <AudioInvoker index={0} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Jesteś na skraju lasu, który rozciąga się między Białą Podlaską a Porosiukami. 
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Lasy w tej okolicy — na południe i zachód od Białej — to w większości młode lasy i bory mieszane, w których dominują sosny. Można tu spotkać także dęby, brzozy i olchy, rzadziej — świerki. Drzewa mają średnio ok. 60 lat (jest to też wiek przeciętnego drzewa w Polsce), a wynika to z historii. Przed II wojną światową tutejsze lasy były regularnie karczowane, grunty uprawiano, a po kilku latach sadzono nowe drzewa. Eksploatacja prowadzona w czasie wojny miała charakter rabunkowy, to znaczy taki, który nie obejmował ponownych nasadzeń. Stan środowiska zaczął poprawiać się od lat 50. XX w., przy czym nadal mamy tu do czynienia z lasami gospodarczymi, w których prowadzone są wyręby.
        </Text>
        <AudioInvoker index={1} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Skąd tu tyle sosen?
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Najpospolitszym drzewem w polskich lasach jest sosna zwyczajna, która według szacunków Lasów Państwowych zajmuje ponad 60% powierzchni leśnej naszego kraju (ok. 70% w nadleśnictwie Biała Podlaska). Od lat sosny są chętnie sadzone przez leśników, którzy upodobali sobie ten gatunek z racji małych wymagań oraz szybkiego wzrostu masy drzew. Sosna jako roślina pionierska zasiedla nawet nieurodzajne gleby i piaski, jest też odporna na mrozy. Sadzenie sosen ma jednak swoje wady. Zrzucone igliwie sosnowe przy rozkładzie zakwasza glebę, co utrudnia rozwój gatunkom liściastym, sprzyjającym bogactwu gatunkowemu i długofalowej aktywności lasu. Jednorodny las iglasty łatwo ulega przesuszeniu, a unoszące się w powietrzu żywiczne olejki sosny zapalają sie już przy 50°C, przez co wzrasta ryzyko pożaru. Każda uprawa jednego gatunku (monokultura) jest też nieodporna na gradacje (czyli masowe rozmnożenie się) owadów żywiących się daną rośliną.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Sosnowe plantacje zapewniają odpowiednie warunki tylko określonym gatunkom zwierząt. W dojrzałym, bardziej naturalnym lesie, bogactwo życia i materii organicznej buduje się przez stulecia m.in. na skutek odkładania się dużych zasobów martwego drewna. Taki las, pełen zakamarków, dziupli i pożywienia jest ostoją wielu ptaków żywiących się owadami żerującymi na drzewach, co sprzyja równowadze ekologicznej. Runo leśne jest tu gęstsze i bardziej wilgotne, a zwierzyna — bardziej różnorodna.
        </Text>
        <FullWidthImage accessibilityLabel='Przekrój przez igłę sosnową' source={require("../../assets/boards/2/pinus-folium.png")} style={{ marginTop: 8, marginBottom: 0 }} />
        <Text variant="labelSmall" style={styles.photoLabel}>
        fot. Zuzanna K.Filutowska
        </Text>
        <Text variant="bodySmall" style={styles.text}>
        Przekrój przez igłę sosnową. Czarny kolor wskazuje miejsca, w których zgromadzona jest żywica.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        W naturalnym lesie obieg materii jest zamknięty. Martwe pnie, gałęzie, owoce, nasiona i liście są rozkładane przez bakterie i grzyby. Składniki odżywcze powstałe w wyniku rozkładu trafiają z powrotem do ziemi, by zasilać nowe rośliny. W lesie gospodarczym obieg ten jest przerwany, ponieważ duża część materii jest wywożona z lasu. Prowadzi to do zubożenia środowiska.
        </Text>
        <AudioInvoker index={2} playlist={playlist} />
        <DidYouKnowTile title={"Czy wiesz, że?"}
            description={"Lasy pełnią ważną funkcję w przyrodzie — łagodzą klimat, gromadzą wodę, podtrzymują życie wielu gatunków. Pomagają przeciwdziałać globalnemu ociepleniu: po pierwsze, wiążą i magazynują węgiel, po drugie — mają zdolność wychwytywania dwutlenku węgla z atmosfery. Szacuje się, że co roku lasy w Unii Europejskiej mogą pochłaniać ok. 9% całkowitej emisji gazów cieplarnianych emitowanych przez państwa UE.   "}
        />
    </>
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 12
    },
    text: {
        marginBottom: 16
    },
    photoLabel: {
        color: CombinedTheme.colors.neutral60,
        marginBottom: 8,
        marginTop: 4
    }
});