import {FAB, Portal, Snackbar} from "react-native-paper";
import {Platform, StyleSheet, View} from "react-native";
import {CombinedTheme} from "../../styles/theme";
import {useNavigation} from "@react-navigation/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import useAudioPlayer from "../../hooks/useAudioPlayer";
import { LinearGradient } from "expo-linear-gradient";
import { useState } from "react";

export default function QrCodeButton(props: { visited: boolean }) {

    if (Platform.OS === "web") {
        return null;
    }

    const { bottom } = useSafeAreaInsets();

    const { current, playerHidden, playbackStatus } = useAudioPlayer();

    const navigation = useNavigation();
    const [infoVisible, setInfoVisible] = useState(true);

    const onPress = () => {
        navigation.navigate("QrCodeScanner" as never);
    }

    if (props.visited) {
        return null;
    }

    let bottomPlacement = 0;

    if (current) {
        if (playerHidden) {
            if (!playbackStatus?.isLoaded || playbackStatus.isPlaying) {
                bottomPlacement = bottom + 48;
            }
        } else {
            bottomPlacement = bottom + 98;
        }
    }

    return <Portal>
        <LinearGradient
            colors={["rgba(251, 250, 248, 0)", CombinedTheme.colors.lightSurface]}
            style={[styles.container, { bottom: bottomPlacement }]}>
            <Snackbar visible={infoVisible} onDismiss={() => setInfoVisible(false)} wrapperStyle={styles.snackbarWrapper} duration={6000}>
                Jesteś na miejscu? Zamelduj się skanując kod QR z tablicy!
            </Snackbar>
            <FAB
                style={[styles.button, { marginBottom: bottomPlacement ? 0 : bottom, position: "relative" }]}
                icon={""}
                label={"Zeskanuj kod QR"}
                customSize={40}
                onPress={onPress}
            />
        </LinearGradient>
    </Portal>
}

const styles = StyleSheet.create({
    button: {
        backgroundColor: CombinedTheme.colors.primary,
        zIndex: 1,
        borderRadius: 100
    },
    snackbarWrapper: {
        position: "relative"
    },
    container: {
        position: 'absolute',
        width: "100%",
        paddingHorizontal: 24,
        paddingVertical: 16,
        alignItems: "stretch"
    }
})
