import { SafeAreaView, useSafeAreaInsets } from "react-native-safe-area-context";
import { Text } from "react-native-paper";
import { View, StyleSheet, TouchableOpacity, Linking } from "react-native";
import React from "react";
import Logo from "../components/icons/logo";
import Icon from "react-native-paper/src/components/Icon";
import { useNavigation } from "@react-navigation/native";

export default function MenuModal() {

    const navigation = useNavigation();
    const { top, bottom, left, right } = useSafeAreaInsets();

    const onClose = () => {
        navigation.goBack();
    }

    const onHomepage = () => {
        navigation.goBack();
        navigation.navigate("Home" as never);
    }

    const onAbout = () => {
        navigation.goBack();
        navigation.navigate("AboutTrail" as never);
    }

    const onContact = () => {
        navigation.goBack();
        navigation.navigate("ContactUsInitial" as never);
    }

    const onNews = () => {
        navigation.goBack();
        navigation.navigate("NewsPage" as never)
    }

    const onPrivacy = () => {
        Linking.openURL("https://dolinakrzny.bialapodlaska.pl/privacy.html");
    }

    return <View style={[styles.container, { marginTop: top + 8, marginBottom: bottom, marginLeft: left, marginRight: right }]}>
        <View style={styles.topBar}>
            <View style={{ width: 26 }} />
            <Logo width={44} height={44} />
            <TouchableOpacity accessibilityLabel="Zamknij menu" onPress={onClose}>
                <Icon source="close" size={26} />
            </TouchableOpacity>
        </View>
        <View style={styles.menu}>
            <TouchableOpacity style={styles.menuItem} onPress={onHomepage}>
                <Text variant="headlineMedium">Strona główna</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.menuItem} onPress={onAbout}>
                <Text variant="headlineMedium">Szlak Doliny Krzny</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.menuItem} onPress={onNews}>
                <Text variant="headlineMedium">Wydarzenia</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.menuItem} onPress={onContact}>
                <Text variant="headlineMedium">Kontakt</Text>
            </TouchableOpacity>
        </View>
        <View style={styles.bottomBar}>
            <TouchableOpacity style={styles.privacyItem} onPress={onPrivacy}>
                <Text variant="bodyMedium">Polityka prywatności</Text>
            </TouchableOpacity>
        </View>
    </View>
}

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 26,
        flex: 1
    },
    topBar: {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    menu: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    menuItem: {
        marginBottom: 24
    },
    bottomBar: {
        paddingBottom: 14,
        justifyContent: "center",
        alignItems: "center",
    },
    privacyItem: {

    }
})
