import * as React from 'react';
import {StyleSheet} from 'react-native';
import { Text } from 'react-native-paper';
import AudioInvoker from "../../components/audioPlayer/audioInvoker";
import { AudioPlaylistEntry } from "../../contexts/audioContext";
import FullWidthImage from "../../components/fullWidthImage/fullWidthImage";
import OrderedList from "../../components/orderedList/orderedList";
import { CombinedTheme } from "../../styles/theme";
import DidYouKnowTile from "../../components/didYouKnow/didYouKnowTile";
import EducationBoardPageBase from "../../components/educationBoardPageBase/educationBoardPageBase";
import TreeCard from '../../components/treeCard/treeCard';

export default function EducationBoard14() {
    return <EducationBoardPageBase boardNumber={14}>
            <EducationBoardContent />
    </EducationBoardPageBase>
}

const playlist: AudioPlaylistEntry[] = [{
    title: "Tablica edukacyjna nr 14",
    subtitle: "Co tu pływa?",
    source: require("../../assets/boards/14/1.mp3")
}];

function EducationBoardContent() {
    return <>
        <AudioInvoker index={0} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Znajdujesz się przy ujściu Klukówki do Krzny. W 2014 r. powstał tu jaz, czyli urządzenie piętrzące wodę.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Przy jazie możesz zobaczyć przepławkę dla ryb — jest to pierwsza tego typu konstrukcja zbudowana w województwie lubelskim. Przepławka składa się z kanału letniego i zimowego i umożliwia pokonanie jazu rybom wędrownym.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        W badaniach w 2010 r. w Krznie stwierdzono występowanie aż 21 gatunków ryb. Najliczniejsze okazały się płocie, oprócz nich są tu spore i często długowieczne ryby, takie jak szczupak, miętus, lin, kleń, jaź, okoń. Z innych ryb znaleziono krąpie i ukleje oraz mniejsze cierniki i cierniczki, kozy, kiełbie, a także jelce. Wreszcie ciekawymi, bo rzadkimi gatunkami ryb w Krznie, są bolenie, wzdręgi i różanki.
        </Text>
        <TreeCard
            title="Szczupak"
            subtitle="Esox lucius"
            image={require("../../assets/boards/14/szczupak.png")}
            description={["Długość: 50-100 cm"]}
        />
        <TreeCard
            title="Różanka"
            subtitle="Rhodeus sericeus"
            image={require("../../assets/boards/14/rozanka.png")}
            description={["Długość: 9-11 cm"]}
        />
        <TreeCard
            title="Kiełb"
            subtitle="Gobio gobio"
            image={require("../../assets/boards/14/kielb.png")}
            description={["Długość: 8-15 cm"]}
        />
        <TreeCard
            title="Ukleja"
            subtitle="Alburnus alburnus"
            image={require("../../assets/boards/14/ukleja.png")}
            description={["Długość: 10-20 cm"]}
        />
        <TreeCard
            title="Kleń"
            subtitle="Squalis cephalus"
            image={require("../../assets/boards/14/klen.png")}
            description={["Długość: 50-70 cm"]}
        />
        <TreeCard
            title="Płoć"
            subtitle="Rutilus rutilus"
            image={require("../../assets/boards/14/ploc.png")}
            description={["Długość: 30-40 cm"]}
        />
        <Text variant="labelSmall" style={styles.photoLabel}>
        rys. Malwina Łazęcka-Malińska
        </Text>
        <Text variant="bodySmall">
            Źródło: T. Penczak, Ł. Głowacki, G. Zięba, L. Marszał, W. Galicka, S. Tybulczuk, M. Tszydel, B. Janic, „Ichtiofauna dorzecza Krzny", Roczniki Naukowe PZW 2011, t. 24, s. 69-96.
        </Text>
    </>
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 12
    },
    text: {
        marginBottom: 16
    },
    photoLabel: {
        color: CombinedTheme.colors.neutral60,
        marginBottom: 24,
        marginTop: 16
    }
});