// modified https://github.com/kesha-antonov/react-native-zoom-reanimated/blob/main/index.js
import React, { useCallback, useMemo, useState } from 'react'
import { View, StyleSheet, StyleProp, ViewStyle, LayoutChangeEvent, TouchableOpacity } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withSpring, runOnJS } from 'react-native-reanimated'
import { GestureDetector, Gesture, GestureHandlerRootView } from 'react-native-gesture-handler'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Button, IconButton } from 'react-native-paper'

function Zoom ({ style, contentContainerStyle, children, onClose }: ZoomProps) {
  const baseScale = useSharedValue(1)
  const pinchScale = useSharedValue(1)
  const lastScale = useSharedValue(1)
  const [isZoomedIn, setIsZoomedIn] = useState(false)
  const isPanGestureEnabled = useSharedValue(false)
  const { top, left, bottom } = useSafeAreaInsets();

  const containerDimensions = useSharedValue({ width: 0, height: 0 })
  const contentDimensions = useSharedValue({ width: 1, height: 1 })

  const opacity = useSharedValue(1)
  const translateX = useSharedValue(0)
  const translateY = useSharedValue(0)
  const lastOffsetX = useSharedValue(0)
  const lastOffsetY = useSharedValue(0)

  const getContentContainerSize = useCallback(() => {
    return ({
      width: containerDimensions.value.width,
      height: contentDimensions.value.height * containerDimensions.value.width / contentDimensions.value.width,
    })
  }, [])

  const zoomIn = useCallback(() => {
    const { width, height } = getContentContainerSize()

    // TODO: MAKE SMARTER CHOISE BASED ON AVAILABLE FREE VERTICAL SPACE
    let newScale = width > height ? width / height * 0.8 : height / width * 0.8;
    if (newScale < 1.8)
      newScale = 1.8
    else if (newScale > 3)
      newScale = 3

    lastScale.value = newScale

    baseScale.value = withSpring(newScale, { overshootClamping: true })
    pinchScale.value = withSpring(1, { overshootClamping: true })

    const newOffsetX = 0
    lastOffsetX.value = newOffsetX

    const newOffsetY = 0
    lastOffsetY.value = newOffsetY

    translateX.value = newOffsetX
    translateY.value = newOffsetY

    setIsZoomedIn(true);
    isPanGestureEnabled.value = true
  }, [baseScale, pinchScale, lastOffsetX, lastOffsetY, translateX, translateY, isZoomedIn, lastScale])

  const zoomOut = useCallback(() => {
    const newScale = 1
    lastScale.value = newScale
    baseScale.value = withSpring(newScale, { overshootClamping: true })
    pinchScale.value = withSpring(1, { overshootClamping: true })

    const newOffsetX = 0
    lastOffsetX.value = newOffsetX

    const newOffsetY = 0
    lastOffsetY.value = newOffsetY

    translateX.value = withSpring(newOffsetX)
    translateY.value = withSpring(newOffsetY)

    setIsZoomedIn(false);

    isPanGestureEnabled.value = false
  }, [baseScale, pinchScale, lastOffsetX, lastOffsetY, translateX, translateY, lastScale, isZoomedIn])

  const handlePanOutside = useCallback(() => {
    const { width, height } = getContentContainerSize()
    const maxOffset = {
      x: width * lastScale.value < containerDimensions.value.width ? 0 : ((width * lastScale.value - containerDimensions.value.width) / 2) / lastScale.value,
      y: height * lastScale.value < containerDimensions.value.height ? 0 : ((height * lastScale.value - containerDimensions.value.height) / 2) / lastScale.value,
    }

    const isPanedXOutside = lastOffsetX.value > maxOffset.x || lastOffsetX.value < -maxOffset.x
    if (isPanedXOutside) {
      const newOffsetX = lastOffsetX.value >= 0 ? maxOffset.x : -maxOffset.x
      lastOffsetX.value = newOffsetX

      translateX.value = withSpring(newOffsetX)
    } else {
      translateX.value = lastOffsetX.value
    }

    const isPanedYOutside = lastOffsetY.value > maxOffset.y || lastOffsetY.value < -maxOffset.y
    if (isPanedYOutside) {
      const newOffsetY = lastOffsetY.value >= 0 ? maxOffset.y : -maxOffset.y
      lastOffsetY.value = newOffsetY

      translateY.value = withSpring(newOffsetY)
    } else {
      translateY.value = lastOffsetY.value
    }
  }, [lastOffsetX, lastOffsetY, lastScale, translateX, translateY])

  const onDoubleTap = useCallback(() => {
    if (isZoomedIn)
      zoomOut()
    else
      zoomIn()
  }, [zoomIn, zoomOut, isZoomedIn])

  const onLayout = useCallback(({ nativeEvent: { layout: { width, height } } }: LayoutChangeEvent) => {
    containerDimensions.value = {
      width,
      height,
    }
  }, [])

  const onLayoutContent = useCallback(({ nativeEvent: { layout: { width, height } } }: LayoutChangeEvent) => {
    contentDimensions.value = {
      width,
      height,
    }
  }, [])

  const onPinchEnd = useCallback((scale: number) => {
    const newScale = Math.min(lastScale.value * scale, 4);
    lastScale.value = newScale
    if (newScale > 1) {
      setIsZoomedIn(true);
      baseScale.value = newScale
      pinchScale.value = 1

      handlePanOutside()
      isPanGestureEnabled.value = true
    } else {
      zoomOut()
    }
  }, [lastScale, baseScale, pinchScale, handlePanOutside, zoomOut, isZoomedIn])

  const zoomGestures = useMemo(() => {
    const tapGesture = Gesture.Tap()
      .numberOfTaps(2)
      .onEnd(() => {
        runOnJS(onDoubleTap)()
      })

    const panGesture = Gesture.Pan()
      .onUpdate(({ translationX, translationY }) => {
        translateX.value = lastOffsetX.value + translationX / lastScale.value;
        translateY.value = lastOffsetY.value + translationY / lastScale.value;

        const maxDown = containerDimensions.value.height / 2;
        const threshold = maxDown * .5;
        const offset = (translationY / lastScale.value) - threshold;
        const maxOverThreshold = maxDown - threshold;
        const newOpacity = 1 - (offset / maxOverThreshold);

        if (offset > 0) {
          opacity.value = newOpacity;
        } else {
          opacity.value = 1;
        }
      })
      .onEnd(({ translationX, translationY }) => {
        lastOffsetX.value += translationX / lastScale.value
        lastOffsetY.value += translationY / lastScale.value

        if (lastOffsetY.value > ((containerDimensions.value.height / 2) * .8)) {
          runOnJS(onClose)();
        } else {
          opacity.value = 1;
        }

        runOnJS(handlePanOutside)()
      })
      .onTouchesMove((e, state) => {
        if (isPanGestureEnabled.value)
          state.activate()
        else
          state.fail()
      })
      .minDistance(0)
      .minPointers(1)
      .maxPointers(2)

    const pinchGesture = Gesture.Pinch()
      .onUpdate(({ scale }) => {
        pinchScale.value = scale
        isPanGestureEnabled.value = true
      })
      .onEnd(({ scale }) => {
        pinchScale.value = scale

        runOnJS(onPinchEnd)(scale)
      });

    return Gesture.Race(tapGesture, Gesture.Simultaneous(pinchGesture, panGesture))
  }, [handlePanOutside, lastOffsetX, lastOffsetY, onDoubleTap, onPinchEnd, isPanGestureEnabled, pinchScale, translateX, translateY, lastScale])

  const animContentContainerStyle = useAnimatedStyle(() => ({
    transform: [
      { scale: Math.min(baseScale.value * pinchScale.value, 4) },
      { translateX: translateX.value },
      { translateY: translateY.value },
    ],
    opacity: opacity.value
  }));

  return <View
        style={[styles.container, style]}
        onLayout={onLayout}
        collapsable={false}
      >
    <GestureHandlerRootView style={{ flex: 1, width: '100%', height: '100%', justifyContent: "center" }}>
      <GestureDetector gesture={zoomGestures}>
        <Animated.View
          style={[animContentContainerStyle, contentContainerStyle]}
          onLayout={onLayoutContent}
        >
          {children}
        </Animated.View>
      </GestureDetector>
    </GestureHandlerRootView>
    <View style={[styles.zoomButtons, { bottom }]}>
      <IconButton
        mode="contained"
        disabled={!isZoomedIn}
        selected={true}
        accessibilityLabel="Oddal"
        onPress={() => zoomOut()}
        icon="magnify-minus-outline" />
      <IconButton
        mode="contained"
        disabled={isZoomedIn}
        selected={true}
        accessibilityLabel="Przybliż"
        onPress={() => zoomIn()}
        icon="magnify-plus-outline" />
    </View>
    <IconButton
      mode="contained"
      selected={true}
      accessibilityLabel="Zamknij podgląd"
      onPress={() => onClose()}
      icon="close"
      style={[{top, left}, styles.closeButton]} />
  </View>
}

interface ZoomProps {
  children?: JSX.Element,
  style: StyleProp<ViewStyle>,
  contentContainerStyle: StyleProp<ViewStyle>,
  onClose: () => any
}

export default Zoom

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "red",
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  closeButton: {
    position: "absolute",
    marginLeft: 24,
    marginTop: 24,
    zIndex: 10
  },
  zoomButtons: {
    position: "absolute",
    flexDirection: "row"
  }
});