import * as React from "react"
import Svg, { SvgProps, Defs, ClipPath, Path, G } from "react-native-svg"
import { memo } from "react"

const SvgComponent = (props: SvgProps) => (
  <Svg
    data-name="Group 2"
    width={191.835}
    height={44}
    viewBox={"0 0 191.835 44"}
    {...props}
  >
    <Defs>
      <ClipPath id="a">
        <Path data-name="Rectangle 6" fill="#252930" d="M0 0h191.835v44H0z" />
      </ClipPath>
    </Defs>
    <G
      data-name="Group 1"
      style={{
        clipPath: "url(#a)",
      }}
      fill="#252930"
    >
      <Path
        data-name="Path 1"
        d="M86.971 16.916h-.066L82.447 4.96h-3.7v15.032h2.383v-7.309q0-2.446-.186-5.243h.088l4.655 12.552h2.316l4.82-12.531h.088q-.044.38-.1 2.179t-.061 2.92v7.432h2.525V4.963h-3.711z"
      />
      <Path
        data-name="Path 2"
        d="M112.823 14.451q0 3.65-3.711 3.65a3.962 3.962 0 0 1-2.832-.884 3.645 3.645 0 0 1-.922-2.744v-9.51h-2.635v9.683a5.179 5.179 0 0 0 1.658 4.071 6.734 6.734 0 0 0 4.644 1.48 8.113 8.113 0 0 0 3.452-.673 4.973 4.973 0 0 0 2.208-1.923 5.482 5.482 0 0 0 .763-2.914V4.963h-2.625z"
      />
      <Path
        data-name="Path 3"
        d="M132.8 6.69V4.963h-11.045v2.108h7.872l-8.092 11.215v1.706h11.484v-2.107h-8.344z"
      />
      <Path
        data-name="Path 4"
        d="M139.235 11.264h-6.216v1.923h6.216v6.805h9.08v-2.086h-6.456v-4.719h6.049v-2.056h-6.049V7.04h6.456V4.963h-9.08z"
      />
      <Path
        data-name="Path 5"
        d="M165.114 14.451q0 3.65-3.711 3.65a3.962 3.962 0 0 1-2.832-.884 3.645 3.645 0 0 1-.922-2.744v-9.51h-2.635v9.683a5.179 5.179 0 0 0 1.658 4.071 6.733 6.733 0 0 0 4.644 1.48 8.114 8.114 0 0 0 3.453-.673 4.972 4.972 0 0 0 2.207-1.923 5.482 5.482 0 0 0 .763-2.914V4.963h-2.625z"
      />
      <Path
        data-name="Path 6"
        d="m188.028 4.963-4.585 11.953h-.066L178.92 4.96h-3.7v15.032h2.382v-7.308q0-2.446-.186-5.243h.088l4.655 12.551h2.316l4.82-12.531h.088q-.045.38-.1 2.179t-.06 2.92v7.432h2.52V4.963z"
      />
      <Path
        data-name="Path 7"
        d="M71.071 31.624h-6.657l.005-26.6H46.581L36.818 0 26.583 5.02 8.989 5v37.692h55.43l-.005-8.862h6.657zM17.86 40.491h-6.666V33.83h6.667zm24.389 0H31.155v-7.938s.027-1.408.03-1.487a5.526 5.526 0 0 1 11.03-.152c.009.13.034 1.639.034 1.639zm11.089 0h-8.883V33.83h8.883zm8.869 0h-6.663V33.83h6.663zm0-8.867H44.461v-.319a7.755 7.755 0 0 0-15.509 0v.319h-6.658v2.206h6.659v6.66h-8.886V18.313H17.86v13.311h-6.666V16.089h42.144V29.4h2.206V13.883h-44.35V7.227h15.959l9.641-4.74 9.2 4.736h16.213z"
      />
      <Path data-name="Rectangle 1" d="M0 13.876h6.651v2.207H0z" />
      <Path
        data-name="Path 8"
        d="M82.734 29.681a1.432 1.432 0 0 1-.5 1.179 2.223 2.223 0 0 1-1.434.41h-.51v1.837h-.8v-4.91h1.414a2.139 2.139 0 0 1 1.378.376 1.349 1.349 0 0 1 .458 1.108m-2.448.913h.426a1.531 1.531 0 0 0 .907-.215.783.783 0 0 0 .288-.672.762.762 0 0 0-.258-.631 1.279 1.279 0 0 0-.806-.209h-.557z"
      />
      <Path
        data-name="Path 9"
        d="M89.105 30.646a2.687 2.687 0 0 1-.6 1.863 2.519 2.519 0 0 1-3.405.005 2.7 2.7 0 0 1-.6-1.876 2.66 2.66 0 0 1 .6-1.867 2.21 2.21 0 0 1 1.711-.652 2.164 2.164 0 0 1 1.692.662 2.68 2.68 0 0 1 .6 1.864m-3.748 0a2.2 2.2 0 0 0 .366 1.375 1.491 1.491 0 0 0 2.165.006 2.788 2.788 0 0 0 0-2.751 1.278 1.278 0 0 0-1.076-.467 1.3 1.3 0 0 0-1.09.467 2.182 2.182 0 0 0-.368 1.371"
      />
      <Path
        data-name="Path 10"
        d="M91.191 33.107v-1.679l-.3.171-.3-.52.6-.36v-2.522h.8v2.042l.735-.44.309.531-1.044.618v1.471h2.079v.688z"
      />
      <Path
        data-name="Path 11"
        d="M99.812 28.197v3.177a1.887 1.887 0 0 1-.233.952 1.557 1.557 0 0 1-.675.628 2.343 2.343 0 0 1-1.056.22 1.977 1.977 0 0 1-1.42-.483 1.751 1.751 0 0 1-.507-1.33v-3.163h.806v3.106a1.244 1.244 0 0 0 .282.9 1.165 1.165 0 0 0 .866.288q1.135 0 1.135-1.192v-3.1z"
      />
      <Path
        data-name="Path 12"
        d="M106.093 30.605a2.447 2.447 0 0 1-.675 1.859 2.712 2.712 0 0 1-1.945.643H102.1v-4.91h1.518a2.51 2.51 0 0 1 1.823.632 2.358 2.358 0 0 1 .651 1.776m-.846.027q0-1.763-1.649-1.763h-.695v3.563h.571a1.579 1.579 0 0 0 1.773-1.8"
      />
      <Path
        data-name="Path 13"
        d="M112.269 33.107h-.984l-2.415-3.912h-.025l.017.219q.047.624.047 1.142v2.552h-.728v-4.91h.974l2.407 3.892h.021c0-.051-.014-.239-.027-.562s-.02-.576-.02-.758v-2.572h.736z"
      />
      <Path data-name="Rectangle 2" d="M114.597 28.197h.803v4.91h-.803z" />
      <Path
        data-name="Path 14"
        d="M122.087 30.646a2.685 2.685 0 0 1-.6 1.863 2.52 2.52 0 0 1-3.405.005 2.7 2.7 0 0 1-.6-1.876 2.66 2.66 0 0 1 .6-1.867 2.209 2.209 0 0 1 1.711-.652 2.166 2.166 0 0 1 1.693.662 2.683 2.683 0 0 1 .6 1.864m-3.748 0a2.2 2.2 0 0 0 .366 1.375 1.491 1.491 0 0 0 2.165.006 2.787 2.787 0 0 0 0-2.751 1.277 1.277 0 0 0-1.076-.467 1.3 1.3 0 0 0-1.09.467 2.182 2.182 0 0 0-.368 1.371"
      />
      <Path
        data-name="Path 15"
        d="M128.703 33.107h-.873l-.833-2.928c-.036-.128-.081-.312-.135-.552s-.086-.408-.1-.5q-.033.215-.109.558t-.126.51l-.813 2.915h-.873l-.635-2.458-.645-2.451h.82l.7 2.861a12.261 12.261 0 0 1 .235 1.215c.025-.19.062-.4.111-.638s.094-.426.134-.571l.8-2.868h.8l.82 2.882a10.847 10.847 0 0 1 .248 1.2 8.772 8.772 0 0 1 .246-1.228l.7-2.855h.813z"
      />
      <Path
        data-name="Path 16"
        d="M134.486 33.107h-2.777v-4.91h2.777v.679h-1.974v1.337h1.85v.672h-1.85v1.541h1.974z"
      />
      <Path
        data-name="Path 17"
        d="M138.587 30.444h1.807v2.465a5.29 5.29 0 0 1-.852.2 6.015 6.015 0 0 1-.881.061 2.215 2.215 0 0 1-1.719-.66 2.657 2.657 0 0 1-.6-1.862 2.484 2.484 0 0 1 .682-1.856 2.563 2.563 0 0 1 1.878-.669 3.653 3.653 0 0 1 1.457.3l-.282.668a2.836 2.836 0 0 0-1.2-.275 1.608 1.608 0 0 0-1.242.494 1.906 1.906 0 0 0-.457 1.35 2.013 2.013 0 0 0 .411 1.369 1.488 1.488 0 0 0 1.184.468 3.873 3.873 0 0 0 .833-.1v-1.265h-1.018z"
      />
      <Path
        data-name="Path 18"
        d="M146.963 30.646a2.685 2.685 0 0 1-.6 1.863 2.52 2.52 0 0 1-3.405.005 2.7 2.7 0 0 1-.6-1.876 2.66 2.66 0 0 1 .6-1.867 2.209 2.209 0 0 1 1.711-.652 2.166 2.166 0 0 1 1.693.662 2.683 2.683 0 0 1 .6 1.864m-3.748 0a2.2 2.2 0 0 0 .366 1.375 1.49 1.49 0 0 0 2.164.006 2.787 2.787 0 0 0 0-2.751 1.276 1.276 0 0 0-1.076-.467 1.3 1.3 0 0 0-1.09.467 2.185 2.185 0 0 0-.367 1.371"
      />
      <Path
        data-name="Path 19"
        d="M155.118 29.681a1.433 1.433 0 0 1-.5 1.179 2.223 2.223 0 0 1-1.434.41h-.51v1.837h-.8v-4.91h1.41a2.139 2.139 0 0 1 1.379.376 1.35 1.35 0 0 1 .458 1.108m-2.448.913h.427a1.531 1.531 0 0 0 .907-.215.783.783 0 0 0 .288-.672.763.763 0 0 0-.258-.631 1.278 1.278 0 0 0-.806-.209h-.557z"
      />
      <Path
        data-name="Path 20"
        d="M161.49 30.646a2.683 2.683 0 0 1-.6 1.863 2.519 2.519 0 0 1-3.405.005 2.7 2.7 0 0 1-.6-1.876 2.66 2.66 0 0 1 .6-1.867 2.209 2.209 0 0 1 1.711-.652 2.165 2.165 0 0 1 1.693.662 2.68 2.68 0 0 1 .6 1.864m-3.748 0a2.2 2.2 0 0 0 .366 1.375 1.49 1.49 0 0 0 2.164.006 2.786 2.786 0 0 0 0-2.751 1.278 1.278 0 0 0-1.076-.467 1.3 1.3 0 0 0-1.09.467 2.182 2.182 0 0 0-.368 1.371"
      />
      <Path
        data-name="Path 21"
        d="M167.568 30.605a2.446 2.446 0 0 1-.675 1.859 2.711 2.711 0 0 1-1.944.643h-1.373v-4.91h1.518a2.511 2.511 0 0 1 1.823.632 2.359 2.359 0 0 1 .651 1.776m-.846.027q0-1.763-1.649-1.763h-.7v3.563h.571a1.579 1.579 0 0 0 1.773-1.8"
      />
      <Path
        data-name="Path 22"
        d="M169.655 33.107v-4.91h.8v4.222h2.078v.688z"
      />
      <Path
        data-name="Path 23"
        d="m177.46 33.107-.49-1.363h-1.877l-.48 1.363h-.846l1.837-4.93h.873l1.837 4.93zm-.7-2.055-.46-1.336q-.05-.135-.139-.423t-.123-.424a7.48 7.48 0 0 1-.265.9l-.443 1.28z"
      />
      <Path
        data-name="Path 24"
        d="M182.824 31.774a1.234 1.234 0 0 1-.474 1.027 2.058 2.058 0 0 1-1.306.373 3.113 3.113 0 0 1-1.364-.259v-.759a3.712 3.712 0 0 0 .714.249 3.022 3.022 0 0 0 .7.091 1.117 1.117 0 0 0 .7-.181.591.591 0 0 0 .227-.487.609.609 0 0 0-.208-.467 3.122 3.122 0 0 0-.859-.453 2.274 2.274 0 0 1-.947-.621 1.314 1.314 0 0 1-.276-.839 1.175 1.175 0 0 1 .437-.967 1.811 1.811 0 0 1 1.172-.352 3.437 3.437 0 0 1 1.4.309l-.255.655a3.07 3.07 0 0 0-1.169-.276.9.9 0 0 0-.591.17.556.556 0 0 0-.2.448.635.635 0 0 0 .08.328.834.834 0 0 0 .265.257 4.641 4.641 0 0 0 .665.319 3.655 3.655 0 0 1 .793.42 1.228 1.228 0 0 1 .369.44 1.321 1.321 0 0 1 .118.577"
      />
      <Path data-name="Rectangle 3" d="M184.805 28.197h.803v4.91h-.803z" />
      <Path
        data-name="Path 25"
        d="m190.985 33.107-.49-1.363h-1.88l-.48 1.363h-.85l1.837-4.93h.873l1.837 4.93zm-.7-2.055-.46-1.336q-.05-.135-.139-.423t-.122-.424a7.479 7.479 0 0 1-.265.9l-.443 1.28z"
      />
      <Path
        data-name="Path 26"
        d="M84.77 42.63h-.873l-.833-2.928q-.054-.192-.135-.552t-.1-.5q-.034.215-.109.558t-.126.51l-.813 2.915h-.873l-.635-2.458-.644-2.451h.819l.7 2.861a12.338 12.338 0 0 1 .235 1.215c.025-.19.061-.4.111-.638s.094-.425.134-.571l.8-2.868h.8l.819 2.882a10.84 10.84 0 0 1 .248 1.2 8.771 8.771 0 0 1 .242-1.222l.7-2.855h.813z"
      />
      <Path
        data-name="Path 27"
        d="M90.594 37.72h1.457a2.763 2.763 0 0 1 1.466.3 1.033 1.033 0 0 1 .452.933 1.135 1.135 0 0 1-.221.716.994.994 0 0 1-.638.359v.034a1.269 1.269 0 0 1 .761.384 1.164 1.164 0 0 1 .243.778 1.26 1.26 0 0 1-.462 1.036 1.985 1.985 0 0 1-1.284.374h-1.773zm.8 2.028h.772a1.324 1.324 0 0 0 .736-.16.611.611 0 0 0 .232-.542.543.543 0 0 0-.25-.5 1.561 1.561 0 0 0-.794-.154h-.7zm0 .652v1.555h.853a1.256 1.256 0 0 0 .76-.193.714.714 0 0 0 .257-.61.647.647 0 0 0-.262-.568 1.387 1.387 0 0 0-.8-.185z"
      />
      <Path data-name="Rectangle 4" d="M96.167 37.72h.803v4.91h-.803z" />
      <Path
        data-name="Path 28"
        d="m102.343 42.63-.49-1.363h-1.877l-.48 1.363h-.846l1.837-4.93h.873l1.837 4.93zm-.7-2.055-.46-1.336q-.05-.135-.139-.423t-.122-.424a7.553 7.553 0 0 1-.265.9l-.443 1.28z"
      />
      <Path
        data-name="Path 29"
        d="M104.879 42.63v-1.679l-.3.171-.3-.52.6-.36V37.72h.8v2.042l.735-.44.309.531-1.044.618v1.471h2.078v.688z"
      />
      <Path
        data-name="Path 30"
        d="M112.425 42.63h-2.777v-4.91h2.777v.679h-1.97v1.337h1.85v.672h-1.85v1.542h1.974z"
      />
      <Path
        data-name="Path 31"
        d="M113.896 44.001a1.563 1.563 0 0 1-.551-.084v-.675a2.056 2.056 0 0 0 .49.071q.658 0 .658-.833v-4.758h.806v4.731a1.667 1.667 0 0 1-.358 1.151 1.334 1.334 0 0 1-1.046.4"
      />
      <Path
        data-name="Path 32"
        d="M123.674 39.202a1.432 1.432 0 0 1-.5 1.179 2.221 2.221 0 0 1-1.434.41h-.511v1.837h-.8v-4.91h1.414a2.137 2.137 0 0 1 1.379.376 1.348 1.348 0 0 1 .458 1.108m-2.448.913h.427a1.531 1.531 0 0 0 .907-.215.783.783 0 0 0 .288-.672.761.761 0 0 0-.258-.631 1.277 1.277 0 0 0-.806-.209h-.557z"
      />
      <Path
        data-name="Path 33"
        d="M130.046 40.169a2.683 2.683 0 0 1-.6 1.863 2.519 2.519 0 0 1-3.405.005 2.706 2.706 0 0 1-.6-1.876 2.658 2.658 0 0 1 .6-1.867 2.207 2.207 0 0 1 1.711-.652 2.164 2.164 0 0 1 1.692.662 2.681 2.681 0 0 1 .6 1.864m-3.748 0a2.2 2.2 0 0 0 .366 1.375 1.49 1.49 0 0 0 2.164.006 2.786 2.786 0 0 0 0-2.751 1.278 1.278 0 0 0-1.076-.467 1.3 1.3 0 0 0-1.09.467 2.183 2.183 0 0 0-.368 1.371"
      />
      <Path
        data-name="Path 34"
        d="M136.123 40.128a2.445 2.445 0 0 1-.675 1.859 2.711 2.711 0 0 1-1.944.643h-1.373v-4.91h1.518a2.511 2.511 0 0 1 1.823.632 2.36 2.36 0 0 1 .651 1.776m-.846.027q0-1.763-1.649-1.763h-.7v3.563h.572a1.579 1.579 0 0 0 1.773-1.8"
      />
      <Path data-name="Path 35" d="M138.21 42.63v-4.91h.8v4.222h2.079v.688z" />
      <Path
        data-name="Path 36"
        d="m146.012 42.63-.49-1.363h-1.877l-.481 1.363h-.846l1.837-4.93h.873l1.837 4.93zm-.7-2.055-.46-1.336q-.05-.135-.139-.423t-.123-.424a7.558 7.558 0 0 1-.265.9l-.443 1.28z"
      />
      <Path
        data-name="Path 37"
        d="M151.378 41.297a1.234 1.234 0 0 1-.474 1.027 2.058 2.058 0 0 1-1.306.373 3.121 3.121 0 0 1-1.364-.258v-.759a3.709 3.709 0 0 0 .714.249 3.022 3.022 0 0 0 .7.091 1.119 1.119 0 0 0 .7-.181.592.592 0 0 0 .226-.487.609.609 0 0 0-.208-.467 3.121 3.121 0 0 0-.859-.453 2.273 2.273 0 0 1-.947-.621 1.313 1.313 0 0 1-.275-.839 1.176 1.176 0 0 1 .436-.967 1.811 1.811 0 0 1 1.172-.352 3.437 3.437 0 0 1 1.4.309l-.255.655a3.068 3.068 0 0 0-1.169-.276.9.9 0 0 0-.591.17.556.556 0 0 0-.2.448.635.635 0 0 0 .081.328.833.833 0 0 0 .265.257 4.642 4.642 0 0 0 .665.319 3.648 3.648 0 0 1 .793.42 1.227 1.227 0 0 1 .369.44 1.321 1.321 0 0 1 .118.577"
      />
      <Path
        data-name="Path 38"
        d="M157.109 42.63h-.931l-1.541-2.223-.474.386v1.837h-.8v-4.91h.8v2.344q.329-.4.655-.776l1.326-1.568h.914q-1.286 1.512-1.844 2.153z"
      />
      <Path data-name="Rectangle 5" d="M158.789 37.72h.803v4.91h-.803z" />
      <Path
        data-name="Path 39"
        d="M164.697 42.63h-2.777v-4.91h2.777v.679h-1.975v1.337h1.851v.672h-1.851v1.542h1.975z"
      />
      <Path
        data-name="Path 40"
        d="M166.168 44.001a1.567 1.567 0 0 1-.551-.084v-.675a2.06 2.06 0 0 0 .49.071q.659 0 .659-.833v-4.758h.806v4.731a1.669 1.669 0 0 1-.358 1.151 1.334 1.334 0 0 1-1.046.4"
      />
    </G>
  </Svg>
)

const Memo = memo(SvgComponent)
export default Memo
