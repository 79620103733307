import Carousel, {ICarouselInstance} from "react-native-reanimated-carousel";
import {ImageBackground, Modal, StyleSheet, TouchableOpacity, View, Image, ImageSourcePropType} from "react-native";
import ArrowLeft from "../icons/arrowLeft";
import {Surface, Text} from "react-native-paper";
import ArrowRight from "../icons/arrowRight";
import * as React from "react";
import {useState} from "react";
import {LinearGradient} from "expo-linear-gradient";
import { useWindowDimensions } from "react-native";
import Zoom from "../zoom/zoom";
import CheckIcon from "../icons/checkIcon";

export default function ImageCarousel(props: ImageCarouselProps) {
    const [viewDimensions, setViewDimensions] = useState<{width: number}>(useWindowDimensions());
    const {images} = props;
    const carouselRef = React.useRef<ICarouselInstance>(null);
    const [currIdx, setCurrIdx] = useState<number>(1);
    const [isChanging, setIsChanging] = useState<boolean>(false);
    const height = viewDimensions.width / 1.797;
    const [modalVisible, setModalVisible] = useState(false);

    if (props.images.length === 1) {
        return <View style={{position: "relative", height}} onLayout={(event) => {
            if (event.nativeEvent.layout.width) {
                setViewDimensions({ width: event.nativeEvent.layout.width });
            }
        }}>
            <View
                style={{
                    justifyContent: 'center',
                }}>
                <ImageBackground style={{height: height, width: viewDimensions.width}} source={images[0]}>
                    {props.showGradient && <LinearGradient style={{flex: 1, justifyContent: 'center'}}
                                                        colors={["rgba(15, 15, 15, 0)", "rgba(15, 15, 15, 0.85)"]}/>}
                </ImageBackground>
            </View>
            <View style={styles.overlay} pointerEvents="box-none">
                <TouchableOpacity style={styles.centerPressable} onPress={() => setModalVisible(true)}>
                    <Text style={[styles.carouselIndex, isChanging ? styles.carouselIndexDuringChange : null]}
                        variant={"labelLarge"}>{currIdx}/{images.length}
                    </Text>
                </TouchableOpacity>
            </View>
            {props.visited && <Surface elevation={1} style={styles.visitedTile}>
                <LinearGradient colors={["#f1bc000d", "#f1bc000d"]} style={styles.visitedTileContent}>
                    <CheckIcon />
                    <Text style={styles.visitedTileText} variant={"labelLarge"}>Zameldowano</Text>
                </LinearGradient>
            </Surface>}

            <Modal
                visible={modalVisible}
                transparent={true}>
                {modalVisible && <Zoom
                    onClose={() => setModalVisible(false)}
                    style={{ backgroundColor: "rgba(0, 0, 0, .5)" }}
                    contentContainerStyle={{ width: "100%", alignItems: "center" }}>
                    <Image source={images[0]} style={{height: height, width: viewDimensions.width, resizeMode: "contain"}} />
                </Zoom>}
            </Modal>
        </View>
    }

    return <View style={{position: "relative", marginRight: -24}} onLayout={(event) => {
            if (event.nativeEvent.layout.width) {
                setViewDimensions({ width: event.nativeEvent.layout.width });
            }
        }}>
        <Carousel
            ref={carouselRef}
            style={styles.carousel}
            loop
            width={viewDimensions.width}
            height={height}
            autoPlay={false}
            data={[...new Array(images.length).keys()]}
            scrollAnimationDuration={600}
            onSnapToItem={(idx) => {
                setCurrIdx(idx + 1);
                setIsChanging(false);
            }}
            renderItem={({index}) => (
                <View
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                    }}>
                    <ImageBackground style={{height: height, width: viewDimensions.width}} source={images[index]}>
                        {props.showGradient && <LinearGradient style={{flex: 1, justifyContent: 'center'}}
                                                               colors={["rgba(15, 15, 15, 0)", "rgba(15, 15, 15, 0.85)"]}/>}
                    </ImageBackground>
                </View>
            )}
        />
        <View style={styles.overlay} pointerEvents="box-none">
            <TouchableOpacity
                style={styles.arrow}
                accessibilityLabel="Poprzednie zdjęcie"
                onPress={() => {
                    setIsChanging(true);
                    carouselRef.current?.scrollTo({count: -1, animated: true});
                }}>
                <ArrowLeft/>
            </TouchableOpacity>
            <TouchableOpacity style={styles.centerPressable} onPress={() => setModalVisible(true)}>
                <Text style={[styles.carouselIndex, isChanging ? styles.carouselIndexDuringChange : null]}
                    variant={"labelLarge"}>{currIdx}/{images.length}
                </Text>
            </TouchableOpacity>
            <TouchableOpacity
                style={styles.arrow}
                accessibilityLabel="Następne zdjęcie"
                onPress={() => {
                    setIsChanging(true);
                    carouselRef.current?.scrollTo({count: 1, animated: true});
                }}>
                <ArrowRight/>
            </TouchableOpacity>
        </View>
        {props.visited && <Surface elevation={1} style={styles.visitedTile}>
            <LinearGradient colors={["#f1bc000d", "#f1bc000d"]} style={styles.visitedTileContent}>
                <CheckIcon />
                <Text style={styles.visitedTileText} variant={"labelLarge"}>Zameldowano</Text>
            </LinearGradient>
        </Surface>}

        <Modal
            visible={modalVisible}
            transparent={true}>
            {modalVisible && <Zoom
                onClose={() => setModalVisible(false)}
                style={{ backgroundColor: "rgba(0, 0, 0, .5)" }}
                contentContainerStyle={{ width: "100%", alignItems: "center" }}>
                 <Image source={images[currIdx - 1]} style={{height: height, width: viewDimensions.width, resizeMode: "contain"}} />
            </Zoom>}
        </Modal>
    </View>
}

interface ImageCarouselProps {
    images: ImageSourcePropType[],
    showGradient?: boolean,
    visited: boolean
}

const styles = StyleSheet.create({
    carousel: {
    },
    centerPressable: {
        flex: 1,
        alignSelf: "stretch",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    carouselIndex: {
        color: "#FFFFFF"
    },
    carouselIndexDuringChange: {
        opacity: .5
    },
    overlay: {
        width: "100%",
        height: "100%",
        position: "absolute",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingHorizontal: 30,
        paddingVertical: 16
    },
    arrow: {
        paddingHorizontal: 15,
        paddingVertical: 40,
        alignItems: "center",
        justifyContent: "center"
    },
    visitedTileText: {
        marginLeft: 11,
    },
    visitedTileContent: {
        paddingLeft: 11,
        paddingRight: 16,
        paddingVertical: 6,
        borderRadius: 8,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    visitedTile: {
        backgroundColor: "#FFFFFF",
        borderRadius: 8,
        position: "absolute",
        bottom: 16,
        left: 16,
    }
})
