import { StatusBar } from "expo-status-bar";
import React, { ReactNode } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Text } from "react-native-paper";
import Header from "../header/header";

export default function FullHeightLayout(props: LayoutProps) {

    return <>
        <StatusBar style="auto" />
        <Header hideBackButton={props.hideBackButton} />
        <ScrollView contentContainerStyle={[styles.container, props.style]} alwaysBounceVertical={false}>
            {props.title && props.subtitle ? <View style={styles.titleWrapper}>
                <Text style={styles.title}>{props.title}</Text>
                <Text variant="headlineSmall" style={styles.subtitle}>{props.subtitle}</Text>
            </View> : <View style={{marginBottom: 24}} />}
            {props.children}
        </ScrollView>
    </>
}

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 24,
        paddingBottom: 32,
        height: "100%"
    },
    titleWrapper: {
    },
    title: {
        fontSize: 14,
        lineHeight: 20,
        fontWeight: "500",
        fontFamily: "Inter_500Medium",
        textAlign: "center",
        fontStyle: "normal",
        letterSpacing: 0.1,
        textTransform: "uppercase",
        marginVertical: 8
    },
    subtitle: {
        textAlign: "center"
    }
});

interface LayoutProps {
    children: ReactNode,
    style?: StyleProp<ViewStyle>,
    title?: string,
    subtitle?: string,
    hideBackButton?: boolean
}
