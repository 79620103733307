import { AnalyticsEventApi } from "../../api";
import useApi from "../../hooks/useApi";
import uuid from 'react-native-uuid';
import { useEffect } from "react";

// this will just provide web app open analytics event
export default function BranchProvider(props: { children: JSX.Element }) {
    const api = useApi(AnalyticsEventApi);

    const postAnalyticsEvent = async (eventType: string) => {
        let fetchUUID = localStorage.getItem('deviceguid');
        const userGuid = fetchUUID || uuid.v4().toString();

        if (!fetchUUID) {
            await localStorage.setItem('deviceguid', JSON.stringify(userGuid));
        }

        await api.apiAnalyticsEventPost({ userId: userGuid, eventType });
        console.log(`Posted ${eventType} to analytics`)
    }

    useEffect(() => {
        postAnalyticsEvent("web-open")
    }, []);

    return props.children;
}