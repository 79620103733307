import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import { Text } from 'react-native-paper';
import AudioInvoker from "../../components/audioPlayer/audioInvoker";
import { AudioPlaylistEntry } from "../../contexts/audioContext";
import { CombinedTheme } from "../../styles/theme";
import DidYouKnowTile from "../../components/didYouKnow/didYouKnowTile";
import EducationBoardPageBase from "../../components/educationBoardPageBase/educationBoardPageBase";
import Icon from 'react-native-paper/src/components/Icon';
import TreeCard from '../../components/treeCard/treeCard';

export default function EducationBoard7() {
    return <EducationBoardPageBase boardNumber={7}>
            <EducationBoardContent />
    </EducationBoardPageBase>
}

const playlist: AudioPlaylistEntry[] = [{
    title: "Tablica edukacyjna nr 7",
    subtitle: "Ścieżka wśród drzew",
    source: require("../../assets/boards/5/1.mp3")
}, {
    title: "Tablica edukacyjna nr 7",
    subtitle: "Budki dla ptaków",
    source: require("../../assets/boards/5/2.mp3")
}];

function EducationBoardContent() {
    return <>
        <AudioInvoker index={0} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Witamy na ścieżce wśród drzew w parku „Małpi Gaj” w Białej Podlaskiej. To miejsce, gdzie możesz spojrzeć na świat z wysoka i odpocząć wśród zieleni.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Jakie drzewa można tu podziwiać? Najwyższe drzewa o najgrubszych pniach to topole białe. Tuż przed Tobą rośnie dąb szypułkowy. Łatwo dostrzeżesz tu także klony jesionolistne i lipy drobnolistne. Na tablicy znajdziesz kilka informacji o tych drzewach.
        </Text>
        <TreeCard
            title="Topola biała"
            subtitle="Populus alba"
            image={require("../../assets/boards/5/topola.png")}
            description={[
                "Siedlisko: tereny nadrzeczne, przydrożne, lasy łęgowe",
                "Wysokość: do 35 m",
                "Długość życia: do 200 lat",
                "Znaki szczególne: Potężne, wyniosłe drzewo. Na wiosnę poznasz je po białych puchatych nasionach."
            ]}
        />
        <TreeCard
            title="Lipa drobnolistna"
            subtitle="Tilia cordata"
            image={require("../../assets/boards/5/lipa.png")}
            description={[
                "Siedlisko: lasy mieszane, parki, aleje",
                "Wysokość: do 30 m",
                "Długość życia: nawet ponad 1000 lat",
                "Znaki szczególne: Duże drzewo o kształtnej, regularnej koronie. Późną wiosną obsypane drobnymi kwiatkami o słodkim zapachu."
            ]}
        />
        <TreeCard
            title="Dąb szypułkowy"
            subtitle="Quercus robur"
            image={require("../../assets/boards/5/dab.png")}
            description={[
                "Siedlisko: lasy mieszane, dąbrowy, lasy łęgowe",
                "Wysokość: do 45 m",
                "Długość życia: nawet ponad 1000 lat",
                "Znaki szczególne: Dostojne drzewo o rozłożystych konarach. Żołędziami żywią się ptaki, myszy, wiewiórki i dziki."
            ]}
        />
        <TreeCard
            title="Klon jesionolistny"
            subtitle="Acer negundo"
            image={require("../../assets/boards/5/klon.png")}
            description={[
                "Siedlisko: tereny nadrzeczne, nieużytki",
                "Wysokość: do 15 m",
                "Długość życia: do 100 lat",
                "Znaki szczególne: Pochodzi z Ameryki Północnej i Środkowej, w Polsce jest uznawany za gatunek inwazyjny."
            ]}
        />
        <Text variant="labelSmall" style={styles.photoLabel}>
        rys. Zofia Sobolewska
        </Text>
        <AudioInvoker index={1} playlist={playlist} />
        <DidYouKnowTile title={"Budki dla ptaków"}
                        skipDescriptionWrapper={true}
                        description={[
                            <Text style={{ marginTop: 16, marginBottom: 24 }} variant={"bodyMedium"}><Icon source="check" size={18} /> W miastach często brakuje starych drzewostanów z dużą liczbą naturalnych dziupli, które są schronieniem dla wielu gatunków ptaków. Aby pomóc ptakom w naszych parkach i ogrodach, warto powiesić budki lęgowe.</Text>,
                            <View style={{ display: "flex" }}>
                                <Text variant="bodyMedium"><Icon source="check" size={18} /> Obok tablicy możesz zobaczyć budki różnych typów i rozmiarów. Przetestowane, bezpieczne typy budek oznacza się literami.</Text>
                                <Text variant="bodySmall" style={{ marginTop: 10 }}><Icon source="circle-medium" size={14}/> budka A1 będzie dobra dla modraszki;</Text>
                                <Text variant="bodySmall" style={{ marginTop: 6 }}><Icon source="circle-medium" size={14}/> w budce A chętnie zamieszkają: bogatka, mazurek, wróbel, pleszka, kowalik, muchołówka żałobna i białoszyja lub krętogłów;</Text>
                                <Text variant="bodySmall" style={{ marginTop: 6 }}><Icon source="circle-medium" size={14}/> budka B przyciągnie szpaka;</Text>
                                <Text variant="bodySmall" style={{ marginTop: 6 }}><Icon source="circle-medium" size={14}/> budka D — kawkę;</Text>
                                <Text variant="bodySmall" style={{ marginTop: 6 }}><Icon source="circle-medium" size={14}/> budka E to duża budka dla puszczyka, kaczki krzyżówki, gągoła lub nurogęsi;</Text>
                                <Text variant="bodySmall" style={{ marginTop: 6 }}><Icon source="circle-medium" size={14}/> P to półotwarta budka dla półdziuplaków takich jak muchołówka szara, pliszka siwa, kopciuszek (do stosowania w miejscach osłoniętych, na budynkach);</Text>
                                <Text variant="bodySmall" style={{ marginTop: 6 }}><Icon source="circle-medium" size={14}/> P2 to budka dla półdziuplaków z dwoma otworami (dobra w miejscach bardziej eksponowanych).</Text>
                                <Text variant="bodyMedium" style={{ marginTop: 10 }}>W ogrodzie najlepiej sprawdzą się budki A1, A, B i P.</Text>
                            </View>,
                        ]}
                        descriptionAfterImage={[
                            <><Icon source="check" size={18} /> Budka nie powinna być malowana syntetycznymi impregnatami.</>,
                            <><Icon source="check" size={18} /> Przednia ścianka budki musi być wyjmowana — umożliwi to jej wyczyszczenie.</>,
                            <><Icon source="check" size={18} /> Zaleca się mocowanie budek do drzewa na gwoździe (nie wkręty), przy pomocy drewnianej listwy.</>,
                            <><Icon source="check" size={18} /> Wysokość mocowania i odległość między budkami należy dostosować do przyzwyczajeń danego gatunku.</>,
                            <><Icon source="check" size={18} /> Budki należy wieszać jesienią lub zimą.</>,
                            <><Icon source="check" size={18} /> Co roku, jesienią, należy wyczyścić wnętrze budki i dokonać niezbędnych napraw.</>,
                        ]}
                        image={require("../../assets/boards/5/budka.png")} />
    </>
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 12
    },
    text: {
        marginBottom: 16
    },
    photoLabel: {
        color: CombinedTheme.colors.neutral60,
        marginBottom: 24,
        marginTop: 16
    }
});