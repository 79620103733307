import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
import { memo } from "react"

const Previous = (props: SvgProps) => (
  <Svg
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <Path d="M0 0h3.333v20H0V0Zm5.833 10L20 20V0L5.833 10Z" fill="#40342A" />
  </Svg>
)

const Memo = memo(Previous)
export default Memo
