import * as React from "react"
import { memo } from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const IconGps = (props: SvgProps) => (
    <Svg
        width={162}
        height={162}
        fill="none"
        {...props}
    >
        <Path
            d="M81.038 152.326c39.372 0 71.289-31.917 71.289-71.289S120.41 9.748 81.038 9.748 9.749 41.665 9.749 81.038c0 39.371 31.917 71.288 71.289 71.288Z"
            stroke="#1C1B1F"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M81.038 152.326c39.372 0 71.289-31.917 71.289-71.289S120.41 9.748 81.038 9.748 9.749 41.665 9.749 81.038c0 39.371 31.917 71.288 71.289 71.288Z"
            stroke="#D0BCFF"
            strokeOpacity={0.05}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M62.573 12.168 59.595 1m39.907 148.906L102.48 161m47.426-98.427 11.168-3.016M12.168 99.464 1 102.48"
            stroke="#1C1B1F"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M62.573 12.168 59.595 1m39.907 148.906L102.48 161m47.426-98.427 11.168-3.016M12.168 99.464 1 102.48"
            stroke="#D0BCFF"
            strokeOpacity={0.05}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="m106.228 61.026-15.875 42.879c-1.002 2.71-3.89 4.102-6.448 3.107a4.933 4.933 0 0 1-2.786-2.811l-5.032-12.72a9.9 9.9 0 0 0-5.213-5.471l-12.112-5.283c-2.52-1.101-3.667-4.155-2.566-6.821a5.253 5.253 0 0 1 2.846-2.878l40.842-16.666c2.578-1.053 5.44.27 6.386 2.957a5.476 5.476 0 0 1-.04 3.707h-.002Z"
            fill="#F8DD85"
        />
    </Svg>

)

const Memo = memo(IconGps)
export default Memo
