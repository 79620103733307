import {Button, Text} from "react-native-paper";
import QuizBadge from "../icons/quizBadge";
import {StyleSheet, View} from "react-native";
import {LinearGradient} from "expo-linear-gradient";
import React from "react";
import {useNavigation} from "@react-navigation/native";
import { QuizAnswerResult } from "../../pages/quizModal";


export default function QuizFinishContent(props: QuizModalFinishProps) {

    const navigation = useNavigation();

    return <LinearGradient colors={["#FAF3DD", "#FCEEC2"]} style={styles.modalView}>
        <Text variant={"headlineSmall"}>Gratulacje!</Text>
        <QuizBadge style={styles.icon}/>
        <Text style={{textAlign: "center"}} variant={"bodyMedium"}>Udało ci się ukończyć quiz z następującym
            wynikiem:</Text>
        <Text variant={"headlineSmall"} style={{marginBottom: 24}}>{props.points}/10</Text>
        <View style={styles.buttonsWrapper}>
            <Button mode={"contained"} onPress={() => {
                props.setModalVisible(false);
                navigation.navigate("QuizSummary" as never, {
                    answers: props.userAnswers
                } as never)
            }}>Sprawdź swoje odpowiedzi</Button>
            <Button mode={"outlined"} onPress={() => props.setModalVisible(false)} style={{ marginTop: 16 }}>
                <Text variant={"labelLarge"}>Zakończ</Text>
            </Button>
        </View>
    </LinearGradient>
}

interface QuizModalFinishProps {
    modalVisible: boolean,
    setModalVisible: any,
    points: number;
    userAnswers: QuizAnswerResult[];
}

const styles = StyleSheet.create({
    modalView: {
        marginHorizontal: 24,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 24,
        alignItems: "center",
        maxWidth: 600
    },
    icon: {
        marginVertical: 24
    },
    buttonsWrapper: {
        marginTop: 15,
        display: "flex",
        flexDirection: "column",
        width: "100%"
    }
});
