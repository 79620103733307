import { StatusBar } from "expo-status-bar";
import React, { ReactNode, useEffect } from "react";
import { Platform, StyleProp, View, ViewStyle } from "react-native";
import { Text } from "react-native-paper";
import Header from "../header/header";
import { ScrollView } from "react-native-gesture-handler";
import StyleSheet from "react-native-media-query";
import { useNavigation } from "@react-navigation/native";

export default function Layout(props: LayoutProps) {
    const ref = React.useRef<ScrollView>(null);

    if (Platform.OS === "web") {
        const navigation = useNavigation();
    
        useEffect(() => {
            console.log("scrolling to top");
            window.scrollTo(0, 0);
        }, [navigation]);
    }

    const style: any = Platform.OS === "web" ? { overflowY: "overlay" } : {};
    const contentStyle: any = Platform.OS === "web" ? { height: "100%" } : {};

    return <>
        <StatusBar style="auto" />
        <Header hideBackButton={props.hideBackButton} />
        <ScrollView ref={ref} removeClippedSubviews={false} overScrollMode="never"
                    contentContainerStyle={[ contentStyle, props.style ]} alwaysBounceVertical={false} style={style}>
            <View style={styles.container} dataSet={{ media: ids.container }}>
                {props.title && props.subtitle ?
                    <View>
                        <Text style={styles.title} dataSet={{ media: ids.title }}>{props.title}</Text>
                        <Text variant="headlineSmall" style={styles.subtitle} dataSet={{ media: ids.subtitle }}>{props.subtitle}</Text>
                    </View>
                : null}
                {props.children}
            </View>
        </ScrollView>
    </>
}

const { ids, styles } = StyleSheet.create({
    container: {
        paddingHorizontal: 24,
        paddingBottom: 32,
        width: "100%",
        overflowX: "hidden",
        '@media (min-width: 800px)': {
            alignSelf: "center",
            maxWidth: 800,
            margin: "auto"
        },
        '@media (min-width: 960px)': {
            alignSelf: "center",
            maxWidth: 960,
            margin: "auto"
        }, 
        '@media (min-width: 1401px)': {
            marginTop: 39
        }
    },
    title: {
        fontSize: 14,
        lineHeight: 20,
        fontWeight: "500",
        fontFamily: "Inter_500Medium",
        textAlign: "center",
        fontStyle: "normal",
        letterSpacing: 0.1,
        textTransform: "uppercase",
        marginVertical: 8
    },
    subtitle: {
        textAlign: "center"
    }
});

interface LayoutProps {
    children: ReactNode,
    style?: StyleProp<ViewStyle>,
    title?: string,
    subtitle?: string | JSX.Element,
    hideBackButton?: boolean
}
