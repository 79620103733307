import * as React from 'react';
import {StyleSheet, Image} from 'react-native';
import { Text } from 'react-native-paper';
import AudioInvoker from "../../components/audioPlayer/audioInvoker";
import { AudioPlaylistEntry } from "../../contexts/audioContext";
import { CombinedTheme } from "../../styles/theme";
import DidYouKnowTile from "../../components/didYouKnow/didYouKnowTile";
import EducationBoardPageBase from "../../components/educationBoardPageBase/educationBoardPageBase";
import BirdAudioInvoker from '../../components/audioPlayer/birdAudioInvoker';

export default function EducationBoard6() {
    return <EducationBoardPageBase boardNumber={6}>
            <EducationBoardContent />
    </EducationBoardPageBase>
}

const playlist: AudioPlaylistEntry[] = [{
    title: "Tablica edukacyjna nr 6",
    subtitle: "Dziuplaki",
    source: require("../../assets/boards/7/1.mp3")
}, {
    title: "Tablica edukacyjna nr 6",
    subtitle: "Siniak",
    source: require("../../assets/boards/7/1.1.mp3")
}, {
    title: "Tablica edukacyjna nr 6",
    subtitle: "Dudek",
    source: require("../../assets/boards/7/1.2.mp3")
}, {
    title: "Tablica edukacyjna nr 6",
    subtitle: "Dzięcioł duży",
    source: require("../../assets/boards/7/1.3.mp3")
}, {
    title: "Tablica edukacyjna nr 6",
    subtitle: "Dzięcioł czarny",
    source: require("../../assets/boards/7/1.4.mp3")
}, {
    title: "Tablica edukacyjna nr 6",
    subtitle: "Kowalik",
    source: require("../../assets/boards/7/1.5.mp3")
}, {
    title: "Tablica edukacyjna nr 6",
    subtitle: "Pełzacz ogrodowy",
    source: require("../../assets/boards/7/1.6.mp3")
}, {
    title: "Tablica edukacyjna nr 6",
    subtitle: "Krętogłów",
    source: require("../../assets/boards/7/1.7.mp3")
}, {
    title: "Tablica edukacyjna nr 6",
    subtitle: "Czy wiesz, że?",
    source: require("../../assets/boards/7/2.mp3")
}];

function EducationBoardContent() {
    return <>
        <AudioInvoker index={0} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Dziuplaki to ptaki, które świetnie się czują w szczelinach, wnękach i innych dobrze ukrytych zakamarkach. 
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Większość dziuplaków gnieździ się w naturalnych dziuplach. Niektóre, takie jak dzięcioły, samodzielnie wykuwają swoje dziuple w pniach drzew. Jeszcze inne wybierają nory w ziemi lub szczeliny skalne. Dziuplakom w mieście możemy pomóc, wieszając budki lęgowe o odpowiedniej konstrukcji.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Dziuplakami są m.in. wróble, sikory, dzięcioły i większość sów. Niektóre z dziuplaków, jakie możesz spotkać nad Krzną, przedstawiono na rysunkach znajdujących się na tablicy.
        </Text>
        <BirdAudioInvoker index={1} playlist={playlist} image={require("../../assets/boards/7/siniak.png")} />
        <BirdAudioInvoker index={2} playlist={playlist} image={require("../../assets/boards/7/dzieciol1.png")} />
        <BirdAudioInvoker index={3} playlist={playlist} image={require("../../assets/boards/7/dzieciol2.png")} />
        <BirdAudioInvoker index={4} playlist={playlist} image={require("../../assets/boards/7/kowalik.png")} />
        <BirdAudioInvoker index={5} playlist={playlist} image={require("../../assets/boards/7/kretoglow.png")} />
        <BirdAudioInvoker index={6} playlist={playlist} image={require("../../assets/boards/7/pelzacz.png")} />
        <BirdAudioInvoker index={7} playlist={playlist} image={require("../../assets/boards/7/kretoglow.png")} />
        <Text variant="labelSmall" style={styles.photoLabel}>
        rys. Malwina Łazęcka-Malińska
        </Text>
        <AudioInvoker index={8} playlist={playlist} />
        <DidYouKnowTile title={"Czy wiesz, że?"}
            description={"Jaja dziuplaków mają na ogół jasny, jednolity kolor. Ułatwia to odnalezienie jaj w ciemnej dziupli."}
            image={require("../../assets/boards/7/dziupla.png")}
            imageLabel="Przekrój przez dziuplę dzięcioła"
        />
    </>
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 12
    },
    text: {
        marginBottom: 16
    },
    photoLabel: {
        color: CombinedTheme.colors.neutral60,
        marginBottom: 24,
        marginTop: 16
    }
});