import React from "react";
import { View, StyleSheet, Image } from "react-native";
import { Text } from "react-native-paper";

export default function Partners() {
    return <View style={styles.partnersWrapper}>
        <Text style={styles.partnersTitle} variant={"titleMedium"}>
            Partnerzy szlaku
        </Text>
        <View style={styles.crestsWrapper}>
            <View style={styles.crest}>
                <Image resizeMode={"contain"} style={styles.crestImage}
                       source={require("../../assets/communityCrest.png")} />
                <Text style={styles.crestName} variant={"bodyMedium"}>
                    Gmina Biała Podlaska
                </Text>
            </View>
            <View style={styles.crest}>
                <Image style={styles.crestImage}
                       source={require("../../assets/cityCrest.png")} />
                <Text style={styles.crestName} variant={"bodyMedium"}>
                    Miasto Biała Podlaska
                </Text>
            </View>
        </View>
    </View>
}

const styles = StyleSheet.create({
    partnersWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    partnersTitle: {
        margin: 30
    },
    crestsWrapper: {
        display: "flex",
        flexDirection: "row",
        width: "100%"
    },
    crest: {
        display: "flex",
        flexDirection: "column",
        flex: 1
    },
    crestImage: {
        height: 96,
        width: "auto",
        resizeMode: "contain"
    },
    crestName: {
        marginTop: 20,
        alignSelf: "center"
    }
});