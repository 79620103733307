import { useNavigation, useRoute } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import React, { useEffect, useState } from "react";
import { View, StyleSheet, StyleProp, ViewStyle, Platform } from "react-native";
import { ActivityIndicator, Button, IconButton, Modal, Portal, Surface, Text } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Icon from "react-native-paper/src/components/Icon";
import WebGLPanorama, { Hotspot } from "./webGLPanorama";
import Click from "../icons/click";
import Phone from "../icons/phone";
import ArrowsOut from "../icons/arrowsOut";
import ArrowsIn from "../icons/arrowsIn";
import MapView from "../mapView/mapView";
import useAudioPlayer from "../../hooks/useAudioPlayer";
import { EducationPointData, educationPointsData } from "../../data/educationPoints";
import { CombinedTheme } from "../../styles/theme";
import { DeviceMotion } from 'expo-sensors';
import ObjectIndicator from "../objectIndicator/objectIndicator";

export default function PanoramaViewer() {

    const route = useRoute();
    const boardId = (route.params! as any).id as number;
    const navigation = useNavigation();
    const { top, left, right } = useSafeAreaInsets();
    const [isVisible, setIsVisible] = useState(true);
    const [showMinimap, setShowMinimap] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [usingSensors, setUsingSensors] = useState(false);
    const [sensorsAvailable, setSensorsAvailable] = useState(true);
    const [sensorsLoading, setSensorsLoading] = useState(false);

    const educationPointData = educationPointsData[boardId];
    const panoramaData = educationPointData.panorama;
    const image = panoramaData.source;
    const hotspots: Hotspot[] = [];

    if (panoramaData.nextHotspot) {
        hotspots.push({
            positionRelativeToCamera: panoramaData.nextHotspot,
            onPress: () => {
                setIsLoading(true);
                navigation.setParams({ id: boardId + 1 } as never);
            },
            description: `${boardId + 2}. ${educationPointsData[boardId + 1].subtitle}`
        });
    }

    if (panoramaData.previousHotspot) {
        hotspots.push({
            positionRelativeToCamera: panoramaData.previousHotspot,
            onPress: () => {
                setIsLoading(true);
                navigation.setParams({ id: boardId - 1 } as never);
            },
            description: `${boardId}. ${educationPointsData[boardId - 1].subtitle}`
        });
    }

    useEffect(() => {
        setIsLoading(true);
        const timeout = setTimeout(() => setIsLoading(false), 1);

        return () => clearTimeout(timeout);
    }, [boardId]);

    const usingSensorsClick = async () => {
        if (!usingSensors) {
            setSensorsLoading(true);

            const permission = await DeviceMotion.requestPermissionsAsync();
            if (!permission.granted) {
                setUsingSensors(false);
                setSensorsLoading(false);

                if (!permission.canAskAgain) {
                    setSensorsAvailable(false);
                }

                return;
            }

            const available = await DeviceMotion.isAvailableAsync();

            if (!available) {
                setSensorsAvailable(false);

                return;
            }

            setUsingSensors(true);
            setSensorsLoading(false);

            return;
        }

        setUsingSensors(false);
    }

    const closeIntroduction = async () => {
        setIsVisible(false);

        if (Platform.OS === "web") {
            setSensorsAvailable(false);

            return;
        }

        const permission = await DeviceMotion.requestPermissionsAsync();
        if (!permission.granted) {
            setUsingSensors(false);
            setSensorsLoading(false);

            if (!permission.canAskAgain) {
                setSensorsAvailable(false);
            }

            return;
        }

        const available = await DeviceMotion.isAvailableAsync();

        if (!available) {
            setSensorsAvailable(false);

            return;
        }
    }

    return <View style={styles.container}>
        {isLoading ? <ActivityIndicator /> : <WebGLPanorama image={image} hotspots={hotspots} useSensors={usingSensors} />}
        <View style={styles.overlay} pointerEvents="box-none">
            <View style={{ top: showMinimap ? 80 : 0 }}>
                <IconButton
                    mode="contained"
                    selected={true}
                    accessibilityLabel="Zamknij widok 360"
                    onPress={() => navigation.goBack()}
                    size={36}
                    icon={(props) => <Icon {...props} source="arrow-left" size={24} />}
                    style={[{top, left}, styles.backButton]} />
                <IconButton
                    mode="contained-tonal"
                    selected={true}
                    accessibilityLabel={showMinimap ? "Ukryj mapę" : "Pokaż mapę"}
                    onPress={() => setShowMinimap(!showMinimap)}
                    size={36}
                    icon={(props) => showMinimap ? <ArrowsOut width={24} height={24} /> : <ArrowsIn width={24} height={24} />}
                    style={[{top, right}, styles.extendButton]} />
                {sensorsAvailable && <IconButton
                    mode="contained-tonal"
                    selected={true}
                    accessibilityLabel={usingSensors ? "Użyj czujników urządzenia do przesuwania widoku" : "Użyj gestów do przesuwania widoku"}
                    onPress={usingSensorsClick}
                    disabled={sensorsLoading}
                    size={36}
                    icon={(props) => !usingSensors ? <Phone width={24} height={24} /> : <Click width={24} height={24} />}
                    style={[{top: top + 64, right}, styles.extendButton]} />}
            </View>
            <Minimap visible={showMinimap} board={educationPointData} boardId={boardId} />
        </View>
        <Portal>
            <Modal visible={isVisible} onDismiss={() => setIsVisible(false)} contentContainerStyle={styles.modalOutsideContainer}>
                <LinearGradient colors={["#FAF3DD", "#FCEEC2"]} style={styles.modalContainer}>
                    <Text variant="headlineSmall" style={styles.modalHeadline}>
                        Jak korzystać z wirtualnej wycieczki?
                    </Text>
                    <Text variant="bodyMedium" style={styles.modalText}>
                        {Platform.OS === "web" ? "Poruszaj się po trasie przesuwając myszką lub palcem po ekranie. Wypróbuj aplikację mobilną, aby wykorzystać czujniki urządzenia w celu wirtualnego zwiedzania" : "Poruszaj się po trasie przesuwając palcem po ekranie lub obracając urządzenie."}
                    </Text>
                    <View style={styles.iconsContainer}>
                        <Surface style={styles.iconWrapper}>
                            <Click width={32} height={32} style={styles.icon} />
                        </Surface>
                        <Surface style={styles.iconWrapper}>
                            <Phone width={32} height={32} style={styles.icon} />
                        </Surface>
                    </View>
                    <Button mode="contained" style={styles.modalButton} onPress={closeIntroduction}>
                        Rozpocznij zwiedzanie
                    </Button>
                </LinearGradient>
            </Modal>
        </Portal>
    </View>
}

function Minimap({ visible, boardId, board }: { visible: boolean, boardId: number, board: EducationPointData }) {

    const { current } = useAudioPlayer();
    let { top, bottom } = useSafeAreaInsets();
    const navigation = useNavigation();

    const [mapViewStyle, setMapViewStyle] = useState<StyleProp<ViewStyle>>({
        ...styles.mapView,
        bottom
    });

    useEffect(() => {
        setMapViewStyle({
            ...styles.mapView,
            bottom
        })
    }, [bottom]);

    if (!visible) {
        return null;
    }

    if (current) {
        bottom += 98;
    }

    return <>
        <LinearGradient
            pointerEvents="box-none"
            colors={["rgba(251, 250, 248, 0)", CombinedTheme.colors.lightSurface]}
            style={[styles.minimapContainer]} />
        <ObjectIndicator
            style={{ top }}
            direction={"Tu jesteś"}
            title={board.subtitle}
            image={board.carouselImages[0]}
            onPress={() => navigation.navigate("EducationBoard" + (boardId + 1) as never)}
        />
        <MapView zoom={14} latitude={board.coordinates.latitude} longitude={board.coordinates.longitude} style={mapViewStyle} />
    </>
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    overlay: {
        flex: 1,
        width: "100%",
        height: "100%",
        position: "absolute"
    },
    backButton: {
        margin: 24,
        position: "absolute",
    },
    extendButton: {
        margin: 24,
        position: "absolute",
    },
    modalOutsideContainer: {
        borderRadius: 28,
        marginHorizontal: 24,
        maxWidth: 724,
        alignSelf: "center"
    },
    modalContainer: {
        borderRadius: 28,
        padding: 24,
    },
    modalHeadline: {
        textAlign: "center"
    },
    modalText: {
        textAlign: "center",
        marginTop: 16
    },
    modalButton: {
        marginTop: 24
    },
    iconsContainer: {
        flexDirection: "row",
        marginTop: 24,
        justifyContent: "center"
    },
    iconWrapper: {
        marginHorizontal: 12,
        padding: 16,
        borderRadius: 100
    },
    icon: {
    },
    mapView: {
        height: "20%",
        position: "absolute"
    },
    minimapContainer: {
        width: "100%",
        position: "absolute",
        bottom: 0,
        height: "30%"
    }
});