import { ImageSourcePropType } from "react-native";
import { HotspotPosition } from "../components/panorama/webGLPanorama";
import Constants from "expo-constants";

export interface EducationPointData {
    carouselImages: ImageSourcePropType[],
    subtitle: string,
    description: string,
    coordinates: { latitude: number, longitude: number },
    panorama: { source: ImageSourcePropType, previousHotspot?: HotspotPosition, nextHotspot?: HotspotPosition }
}

const baseUrl = Constants.expoConfig!.extra!.webBaseUrl;

export const educationPointsData: EducationPointData[] = [
    {
        carouselImages: [require("../assets/boards/1/foto.jpg"), require("../assets/boards/1/foto2.jpg")],
        subtitle: "(Nie taka) dzika rzeka Krzna" ,
        description: "Z tablicy dowiemy się podstawowych informacji o Krznie, a także czym są starorzecza i dlaczego reguluje się rzeki.",
        coordinates: {
            latitude: 52.0242241,
            longitude: 23.0762029
        },
        panorama: {
            source: { uri: baseUrl + "/panorama/1.jpg" },
            nextHotspot: {
                degreesVertical: -10,
                degreesHorizontal: 17
            },
        },
    },
    {
        carouselImages: [require("../assets/boards/2/foto.jpg")],
        subtitle: "„Knieja czy plantacja?\" – ul. Borowa",
        description: "Tablica informuje nas o lasach występujących na terenie gminy Biała Podlaska i na obrzeżach miasta.",
        coordinates: {
            latitude: 52.0225736,
            longitude: 23.0865133
        },
        panorama: {
            source: { uri: baseUrl + "/panorama/2.jpg" },
            previousHotspot: {
                degreesVertical: -10,
                degreesHorizontal: -5
            },
            nextHotspot: {
                degreesVertical: -10,
                degreesHorizontal: 177
            },
        },
    },
    {
        carouselImages: [require("../assets/boards/3/foto.jpg"), require("../assets/boards/3/foto2.jpg")],        
        subtitle: "„Jaki to ptak?\" – platforma widokowa",
        description: "Na tablicy zobaczymy, a także usłyszymy głosy, kilku gatunków ptaków zamieszkujących dolinę Krzny.",
        coordinates: {
            latitude: 52.0250691,
            longitude: 23.0996025
        },
        panorama: {
            source: { uri: baseUrl + "/panorama/3.jpg" },
            previousHotspot: {
                degreesVertical: -20,
                degreesHorizontal: -5
            },
            nextHotspot: {
                degreesVertical: -20,
                degreesHorizontal: 130
            },
        },
    },
    {
        carouselImages: [require("../assets/boards/4/fotografia.jpg"), require("../assets/boards/4/foto2.jpg"), require("../assets/boards/4/foto3.jpg"), require("../assets/boards/4/lokalizacja_palacu4.jpg")],        
        subtitle: "„Pałac nad Krzną\" – rejon Parku Radziwiłłów",
        description: "Znajdujemy się w sąsiedztwie najważniejszego zabytku Białej Podlaskiej. Dowiemy się nieco o historii miasta i siedziby rodu Radziwiłłów – jego niegdysiejszych właścicieli.",
        coordinates: {
            latitude: 52.028921,
            longitude: 23.1147033
        },
        panorama: {
            source: { uri: baseUrl + "/panorama/4.jpg" },
            previousHotspot: {
                degreesVertical: -10,
                degreesHorizontal: -90
            },
            nextHotspot: {
                degreesVertical: -10,
                degreesHorizontal: 187
            },
        },
    },
    {
        carouselImages: [require("../assets/boards/6/foto.jpg")],        
        subtitle: "„Ptaki parków i ogrodów\" – park edukacyjny",
        description: "To miejsce idealne dla najmłodszych odwiedzających. Poza uzyskaniem ciekawych wiadomości na temat przyrody można tu aktywnie spędzić czas korzystając z placu zabaw i napowietrznej ścieżki wśród drzew.",
        coordinates: {
            latitude: 52.028571,
            longitude: 23.120030
        },
        panorama: {
            source: { uri: baseUrl + "/panorama/5.jpg" },
            previousHotspot: {
                degreesVertical: -10,
                degreesHorizontal: -68
            },
            nextHotspot: {
                degreesVertical: -10,
                degreesHorizontal: 100
            },
        },
    },
    {
        carouselImages: [require("../assets/boards/7/foto.jpg")],
        subtitle: "„Dziuplaki\" – park edukacyjny",
        description: "W tym punkcie zobaczymy i usłyszymy ptaki lęgnące się w dziuplach.",
        coordinates: {
            latitude: 52.028722,
            longitude: 23.119764
        },
        panorama: {
            source: { uri: baseUrl + "/panorama/6.jpg" },
            previousHotspot: {
                degreesVertical: -10,
                degreesHorizontal: 205
            },
            nextHotspot: {
                degreesVertical: -10,
                degreesHorizontal: -3
            },
        },
    },
    {
        carouselImages: [require("../assets/boards/5/foto1.jpg"), require("../assets/boards/5/foto2.jpg"), require("../assets/boards/5/foto3.jpg")],
        subtitle: "„Ścieżka wśród drzew\" – park edukacyjny, na ścieżce wśród drzew",
        description: "To miejsce idealne dla najmłodszych odwiedzających. Poza uzyskaniem ciekawych wiadomości na temat przyrody można tu aktywnie spędzić czas korzystając z placu zabaw i napowietrznej ścieżki wśród drzew.",
        coordinates: {
            latitude: 52.028699,
            longitude: 23.120382
        },
        panorama: {
            source: { uri: baseUrl + "/panorama/7.jpg" },
            previousHotspot: {
                degreesVertical: -40,
                degreesHorizontal: -120
            },
            nextHotspot: {
                degreesVertical: -30,
                degreesHorizontal: -180
            },
        },
    },
    {
        carouselImages: [require("../assets/boards/8/foto.jpg")],
        subtitle: "„O czym szumią wierzby?\" – szkółka przyrodnicza",
        description: "Tutaj dowiemy się ciekawych rzeczy o wierzbie – drzewie charakterystycznym dla polskiego krajobrazu.",
        coordinates: {
            latitude: 52.028139,
            longitude: 23.120905
        },
        panorama: {
            source: { uri: baseUrl + "/panorama/8.jpg" },
            previousHotspot: {
                degreesVertical: -10,
                degreesHorizontal: -95
            },
            nextHotspot: {
                degreesVertical: -10,
                degreesHorizontal: 160
            },
        },
    },
    {
        carouselImages: [require("../assets/boards/9/foto.jpg")],
        subtitle: "„Zaproś owady do ogrodu\" – szkółka przyrodnicza",
        description: "Tablica powie nam wiele ciekawostek o życiu owadów, ich znaczeniu dla naszego życia oraz o tym, jak możemy pomóc tym niedocenianym zwierzętom.",
        coordinates: {
            latitude: 52.028211,
            longitude: 23.121098
        },
        panorama: {
            source: { uri: baseUrl + "/panorama/9.jpg" },
            previousHotspot: {
                degreesVertical: -10,
                degreesHorizontal: -120
            },
            nextHotspot: {
                degreesVertical: -10,
                degreesHorizontal: -30
            },
        },
    },
    {
        carouselImages: [require("../assets/boards/10/foto.jpg")],
        subtitle: "„Zaproś ptaki do ogrodu\" – szkółka przyrodnicza",
        description: "Jak zachęcić ptaki do zamieszkania w naszym ogrodzie? Tego dowiemy się w tym miejscu.",
        coordinates: {
            latitude: 52.028261,
            longitude: 23.121297
        },
        panorama: {
            source: { uri: baseUrl + "/panorama/10.jpg" },
            previousHotspot: {
                degreesVertical: -10,
                degreesHorizontal: -120
            },
            nextHotspot: {
                degreesVertical: -10,
                degreesHorizontal: -165
            },
        },
    },
    {
        carouselImages: [require("../assets/boards/11/foto.jpg")],        
        subtitle: "„Derkacz\" – rejon przejazdu rowerowego w Al. Tysiąclecia",
        description: "Derkacz to rzadki, wędrowny ptak, gniazdujący na nadrzecznych łąkach wschodniej Polski. Jego obecność stanowi wskaźnik dobrego stanu środowiska. Jest bardzo skryty i płochliwy, dlatego być może nawet o nim nie słyszeliście – teraz macie okazję go poznać.",
        coordinates: {
            latitude: 52.028624,
            longitude: 23.129690
        },
        panorama: {
            source: { uri: baseUrl + "/panorama/11.jpg" },
            previousHotspot: {
                degreesVertical: -10,
                degreesHorizontal: -140
            },
            nextHotspot: {
                degreesVertical: -10,
                degreesHorizontal: 125
            },
        },
    },
    {
        carouselImages: [require("../assets/boards/12/foto.jpg")],        
        subtitle: "„Bialska Kolejka\" – rejon mostu w Al. Jana Pawła II",
        description: "Zapoznamy się z nieistniejącą już linią kolejki wąskotorowej, która w czasach sprzed popularyzacji samochodu stanowiła najważniejszy środek transportu w Białej Podlaskiej i okolicy.",
        coordinates: {
            latitude: 52.030280,
            longitude: 23.137313
        },
        panorama: {
            source: { uri: baseUrl + "/panorama/12.jpg" },
            previousHotspot: {
                degreesVertical: -10,
                degreesHorizontal: -70
            },
            nextHotspot: {
                degreesVertical: -10,
                degreesHorizontal: 125
            },
        },
    },
    {
        carouselImages: [require("../assets/boards/13/foto.jpg")],
        subtitle: "„Zaprzyjaźnij się z bobrem\"– ul. Brzegowa przy dawnym ujęciu wody",
        description: "Któż nie zna największego gryzonia Polski, budowniczego tam? Kilkanaście lat temu niemal wymarły u nas gatunek powrócił, również do doliny Krzny.",
        coordinates: {
            latitude: 52.033124,
            longitude: 23.160245
        },
        panorama: {
            source: { uri: baseUrl + "/panorama/13.jpg" },
            previousHotspot: {
                degreesVertical: -10,
                degreesHorizontal: -5
            },
            nextHotspot: {
                degreesVertical: -10,
                degreesHorizontal: -115
            },
        },
    },
    {
        carouselImages: [require("../assets/boards/14/foto.jpg")],        
        subtitle: "„Co tu pływa?\"– rejon jazu nad Krzną",
        description: "Znajdujemy się przy ujściu rzeki Klukówki do Krzny. W miejscu tym znajduje się przepławka – urządzenie pozwalające żyjącym tu rybom pokonać przeszkodę. Zapoznajmy się z mieszkańcami tych wód.",
        coordinates: {
            latitude: 52.032419,
            longitude: 23.169879
        },
        panorama: {
            source: { uri: baseUrl + "/panorama/14.jpg" },
            previousHotspot: {
                degreesVertical: -10,
                degreesHorizontal: 42
            },
            nextHotspot: {
                degreesVertical: -10,
                degreesHorizontal: -138
            },
        },
    },
    {
        carouselImages: [require("../assets/boards/15/foto.jpg")],        
        subtitle: "„Ptasia autostrada\" – wiata do obserwacji ptaków przy jazie",
        description: "Tutaj znajduje się ukryty punkt obserwacyjny, czyli coś dla miłośników podziwiania zwierząt w ich naturalnym środowisku.",
        coordinates: {
            latitude: 52.032920,
            longitude: 23.170104
        },
        panorama: {
            source: { uri: baseUrl + "/panorama/15.jpg" },
            previousHotspot: {
                degreesVertical: -10,
                degreesHorizontal: 205
            },
            nextHotspot: {
                degreesVertical: -10,
                degreesHorizontal: 170
            },
        },
    },
    {
        carouselImages: [require("../assets/boards/16/foto.jpg")],
        subtitle: "„Czerwończyk Fioletek i inne motyle\" – rejon ul. ks. Brzóski",
        description: "Punkt wiedzy na temat żyjących tu motyli, w tym niezwykle rzadko spotykanego gatunku, którego cykl życiowy ściśle związany jest z rzeką.",
        coordinates: {
            latitude: 52.038445,
            longitude: 23.169680
        },
        panorama: {
            source: { uri: baseUrl + "/panorama/16.jpg" },
            previousHotspot: {
                degreesVertical: -10,
                degreesHorizontal: 118
            },
            nextHotspot: {
                degreesVertical: -10,
                degreesHorizontal: -67
            },
        },
    },
    {
        carouselImages: [require("../assets/boards/17/foto.jpg")],       
        subtitle: "„Ptaki stawów i szuwarów\" – platforma widokowa przy stawach",
        description: "Znajdujemy się w najcenniejszej przyrodniczo części szlaku, to dom wielu gatunków zwierząt, które możemy obserwować z platformy widokowej.",
        coordinates: {
            latitude: 52.047988,
            longitude: 23.159807
        },
        panorama: {
            source: { uri: baseUrl + "/panorama/17.jpg" },
            previousHotspot: {
                degreesVertical: -10,
                degreesHorizontal: -140
            }
        },
    }
];