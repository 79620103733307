import * as React from 'react';
import {StyleSheet} from 'react-native';
import { Text } from 'react-native-paper';
import AudioInvoker from "../../components/audioPlayer/audioInvoker";
import { AudioPlaylistEntry } from "../../contexts/audioContext";
import FullWidthImage from "../../components/fullWidthImage/fullWidthImage";
import OrderedList from "../../components/orderedList/orderedList";
import { CombinedTheme } from "../../styles/theme";
import DidYouKnowTile from "../../components/didYouKnow/didYouKnowTile";
import EducationBoardPageBase from "../../components/educationBoardPageBase/educationBoardPageBase";

export default function EducationBoard11() {
    return <EducationBoardPageBase boardNumber={11}>
            <EducationBoardContent />
    </EducationBoardPageBase>
}

const playlist: AudioPlaylistEntry[] = [{
    title: "Tablica edukacyjna nr 11",
    subtitle: "Derkacz",
    source: require("../../assets/boards/11/1.mp3")
}, {
    title: "Tablica edukacyjna nr 11",
    subtitle: "Pierwszy skrzypek",
    source: require("../../assets/boards/11/2.mp3")
}, {
    title: "Tablica edukacyjna nr 11",
    subtitle: "Łąka dla derkacza",
    source: require("../../assets/boards/11/3.mp3")
}, {
    title: "Tablica edukacyjna nr 11",
    subtitle: "Czy wiesz, że?",
    source: require("../../assets/boards/11/4.mp3")
}];

function EducationBoardContent() {
    return <>
        <AudioInvoker index={0} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Przed Tobą rozciągają się łąki zamieszkiwane przez niezwykłego ptaka – derkacza.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Derkacz wyglądem przypomina nieco przepiórkę, ale jest znacznie od niej większy. To ptak skryty, przemieszczający się głównie pieszo w bujnej trawie. Zdecydowanie łatwiej jest go usłyszeć niż zobaczyć. Adam Mickiewicz w „Panu Tadeuszu” ujął to tak:
        </Text>
        <Text variant="bodyMedium" style={styles.quote}>
        Tam derkacz wrzasnął z łąki, szukać go daremnie,  
        </Text>
        <Text variant="bodyMedium" style={styles.quote}>
        Bo on szybuje w trawie jako szczupak w Niemnie
        </Text>
        <Text variant="labelSmall" style={styles.photoLabel}>
        A. Mickiewicz, „Pan Tadeusz, czyli ostatni zajazd na Litwie [...]", Warszawa 1998, s. 44.
        </Text>
        <AudioInvoker index={1} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Pierwszy skrzypek
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Derkacze zimują w Afryce i przylatują do Polski na początku maja. Pierwsze zjawiają się samce i głośnym wołaniem, głównie nocą i wcześnie rano, wabią przylatujące później samice. Głos derkacza to dwusylabowy terkot, przypominający „der der” (stąd polska nazwa) lub „kreks kreks” (stąd nazwa łacińska — Crex crex). Jest bardzo donośny — usłyszysz go nawet z odległości kilometra. 
        </Text>
        <AudioInvoker index={2} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Łąka dla derkacza
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Ulubionym siedliskiem derkacza są wilgotne łąki. Aby łąka pozostała łąką, trzeba ją kosić. Niestety, współczesne metody koszenia są poważnym zagrożeniem dla derkaczy. Ptaki te niechętnie latają i zwykle próbują uciec, biegnąc przed mechaniczną kosiarką, co bywa dla nich zgubne. Co gorsza, takie maszyny pracują szybko, kosząc na raz ogromne połacie łąki. To sprawia, że derkacze nie mogą znaleźć odpowiedniego miejsca do lęgów lub, nieświadome zagrożenia, zakładają gniazda na trasie kosiarki.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Mechanizacja rolnictwa, zbyt wczesny termin pierwszego pokosu i osuszanie łąk sprawiły, że w krajach Europy Zachodniej derkacz prawie całkowicie wyginął. W Polsce nadal możemy cieszyć się jego charakterystycznym głosem. Najliczniej występuje we wschodniej części kraju, gdzie zachowało się jeszcze tradycyjne, ekstensywne rolnictwo. Inwentaryzacja ptaków w dolinie Krzny na odcinku od ujścia Klukówki do ujścia Krzny do Bugu, przeprowadzona w 2011 r., wykazała obecność co najmniej stu samców. To wynik świadczący o jednym z największych zagęszczeń tego gatunku w Polsce.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Derkacza uznaje się za gatunek wskaźnikowy — spadek jego liczebności może być dla nas pierwszym sygnałem mówiącym o pogorszeniu się stanu całego ekosystemu. To dlatego derkacz znalazł się na liście gatunków, dla których tworzy się obszary specjalnej ochrony w ramach Europejskiej Sieci Ekologicznej Natura 2000.
        </Text>
        <AudioInvoker index={3} playlist={playlist} />
        <DidYouKnowTile title={"Czy wiesz, że?"}
                        description={"W niektórych parkach narodowych, krajobrazowych czy rezerwatach podtrzymuje się tradycję ręcznego koszenia łąk. Taka metoda koszenia sprzyja wielu gatunkom ptaków."}
                        image={require("../../assets/boards/11/derkacze.png")}
                        imageLabel={"rys. Malwina Łazęcka-Malińska "}/>
    </>
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 12
    },
    text: {
        marginBottom: 16
    },
    photoLabel: {
        color: CombinedTheme.colors.neutral60,
        marginBottom: 8,
        marginTop: 16
    },
    quote: {
        paddingHorizontal: 24,
        paddingBottom: 2,
        fontFamily: "Lora_500Medium"
    }
});