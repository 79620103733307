import React, { useRef } from "react";
import { useRoute } from "@react-navigation/native";

export default function PlayerPage({ url }: { url?: string }) {
    const route = useRoute();
    const params = (route.params as any);
    const player = `
        <html style="overflow: hidden" lang="en">
        <body style="margin: 0">
            <script src="https://cdn.jsdelivr.net/npm/hls.js@1"></script>
            <video autoplay id="video" controls style="width: 100%"></video>
            <script>
                var video = document.getElementById('video');
                var videoSrc = '${url ? url : params.url}';
                if (Hls.isSupported()) {
                    var hls = new Hls();
                    hls.loadSource(videoSrc);
                    hls.attachMedia(video);
                }
                else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                    video.src = videoSrc;
                }
            </script>
        </body>
        </html>`;

    const ref = useRef<HTMLIFrameElement>(null);
    const onLoad = (e: any) => {
        setTimeout(() => {
            if (ref.current != null) {
                ref.current.style.height = `${ref.current.contentWindow!.document.body.scrollHeight}px`;
            }
        }, 1000);
    };

    return <div style={{ height: "100%" }}>
        <iframe onLoad={onLoad} ref={ref} srcDoc={player} style={{ height: "100%", width: "100%", border: "none" }}>
        </iframe>
    </div>;
}