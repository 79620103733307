export interface QuizQuestionType {
    title: string,
    image?: number,
    answers: QuizAnswer[],
}

export interface QuizAnswer {
    value: "correct" | "false1" | "false2",
    answer: string,
}

export const quizQuestions: QuizQuestionType[] = [
    {
        title: "Dopływ jakiej rzeki stanowi rzeka Krzna?",
        image: require("../assets/boards/1/foto.jpg"),
        answers: [
            {
                value: "correct",
                answer: "Bugu"
            },
            {
                value: "false1",
                answer: "Odry"
            },
            {
                value: "false2",
                answer: "Wisły"
            }
        ]
    },
    {
        title: "Który z poniższych gatunków drzew jest najczęściej występującym drzewem w polskich lasach?",
        answers: [
            {
                value: "correct",
                answer: "Sosna zwyczajna"
            },
            {
                value: "false1",
                answer: "Świerk pospolity"
            },
            {
                value: "false2",
                answer: "Jodła pospolita"
            }
        ]
    },
    {
        title: "Jakiej długości jest rzeka Krzna?",
        answers: [
            {
                value: "correct",
                answer: "Około 110 km"
            },
            {
                value: "false1",
                answer: "Około 150 km"
            },
            {
                value: "false2",
                answer: "Około 80 km"
            }
        ]
    },
    {
        title: "Jakie z wymienionych funkcji można przypisać lasom?",
        answers: [
            {
                value: "correct",
                answer: "Obie odpowiedzi są poprawne"
            },
            {
                value: "false1",
                answer: "Przeciwdziałanie globalnemu ociepleniu dzięki pochłanianiu gazów cieplarnianych"
            },
            {
                value: "false2",
                answer: "Gromadzenie wody"
            }
        ]
    },
    {
        title: "Który z poniżej wymienionych gatunków ptaków zakłada swoje gniazda na ziemi?",
        answers: [
            {
                value: "correct",
                answer: "Błotniak stawowy"
            },
            {
                value: "false1",
                answer: "Potrzos"
            },
            {
                value: "false2",
                answer: "Sójka"
            }
        ]
    },
    {
        title: "Pałac nad Krzną przez ponad 200 lat stanowił siedzibę słynnego magnackiego rodu. Jakiego?",
        answers: [
            {
                value: "correct",
                answer: "Rodu Radziwiłłów"
            },
            {
                value: "false1",
                answer: "Rodu Czartoryskich"
            },
            {
                value: "false2",
                answer: "Rodu Zamoyskich"
            }
        ]
    },
    {
        title: "Które z poniższych gatunków drzew charakteryzują się najdłuższą długością życia?",
        answers: [
            {
                value: "correct",
                answer: "Dąb szypułkowy i lipa drobnolistna"
            },
            {
                value: "false1",
                answer: "Dąb szypułkowy i topola biała"
            },
            {
                value: "false2",
                answer: "Topola biała i klon jesionolistny"
            }
        ]
    },
    {
        title: "Co to za gatunek drzewa?",
        image: require("../assets/boards/5/klon.png"),
        answers: [
            {
                value: "correct",
                answer: "Klon jesionolistny"
            },
            {
                value: "false1",
                answer: "Dąb szypułkowy"
            },
            {
                value: "false2",
                answer: "Topola biała"
            }
        ]
    },
    {
        title: "Co to za gatunek drzewa?",
        image: require("../assets/boards/5/lipa.png"),
        answers: [
            {
                value: "correct",
                answer: "Lipa drobnolistna"
            },
            {
                value: "false1",
                answer: "Klon jesionolistny"
            },
            {
                value: "false2",
                answer: "Dąb szypułkowy"
            }
        ]
    },
    {
        title: "Co to za ptak?",
        image: require("../assets/boards/6/rudzik.png"),
        answers: [
            {
                value: "correct",
                answer: "Rudzik"
            },
            {
                value: "false1",
                answer: "Wróbel"
            },
            {
                value: "false2",
                answer: "Szpak"
            }
        ]
    },
    {
        title: "Które z poniższych gatunków ptaków określamy \"dziuplakami\"?",
        answers: [
            {
                value: "correct",
                answer: "Dzięcioł czarny i kowalik"
            },
            {
                value: "false1",
                answer: "Dzięcioł czarny i kos"
            },
            {
                value: "false2",
                answer: "Bogatka i szpak"
            }
        ]
    },
    {
        title: "Jaki gatunek owada znajduje się na przedstawionej ilustracji?",
        image: require("../assets/boards/9/trzmiel.png"),
        answers: [
            {
                value: "correct",
                answer: "Trzmiel rudy"
            },
            {
                value: "false1",
                answer: "Miesierka dwuzębna"
            },
            {
                value: "false2",
                answer: "Murarka ogrodowa"
            }
        ]
    },
    {
        title: "Jak nazywa się proces przystosowania się zwierząt lub roślin w środowisku silnie przekształconym przez człowieka?",
        answers: [
            {
                value: "correct",
                answer: "Synantropizacja"
            },
            {
                value: "false1",
                answer: "Antropopresja"
            },
            {
                value: "false2",
                answer: "Synurbizacja"
            }
        ]
    },
    {
        title: "Co to za ptak?",
        image: require("../assets/boards/6/mazurek.png"),
        answers: [
            {
                value: "correct",
                answer: "Mazurek"
            },
            {
                value: "false1",
                answer: "Modraszka"
            },
            {
                value: "false2",
                answer: "Bogatka"
            }
        ]
    },
    {
        title: "Stosując jakie praktyki ogrodnicze zaprosisz ptaki do ogrodu?",
        answers: [
            {
                value: "correct",
                answer: "Sadząc drzewa i krzewy owocowe"
            },
            {
                value: "false1",
                answer: "kosząc trawnik i stosując pestycydy"
            },
            {
                value: "false2",
                answer: "Stosując monokulturę żywotnika zachodniego wzdłuż ogrodzenia"
            }
        ]
    },
    {
        title: "Jak nazywamy proces regularnego obcinania pędów wierzby?",
        image: require("../assets/boards/8/wierzbaglowiasta.png"),
        answers: [
            {
                value: "correct",
                answer: "Ogławianie"
            },
            {
                value: "false1",
                answer: "Cięcie korekcyjne"
            },
            {
                value: "false2",
                answer: "Cięcie sanitarne"
            }
        ]
    },
    {
        title: "Ulubione środowisko derkacza to:",
        image: require("../assets/boards/11/derkacze.png"),
        answers: [
            {
                value: "correct",
                answer: "Wilgotne łąki"
            },
            {
                value: "false1",
                answer: "Lasy"
            },
            {
                value: "false2",
                answer: "Pobliże osad ludzkich"
            }
        ]
    },
    {
        title: "Bialska kolejka wąskotorowa powstała:",
        image: require("../assets/boards/12/foto2.jpg"),
        answers: [
            {
                value: "correct",
                answer: "w czasie I wojny światowej"
            },
            {
                value: "false1",
                answer: "w czasie II wojny światowej"
            },
            {
                value: "false2",
                answer: "w dwudziestoleciu międzywojennym"
            }
        ]
    },
    {
        title: "Charakterystyczną cechą bobrów jest między innymi:",
        image: require("../assets/boards/13/bobr.png"),
        answers: [
            {
                value: "correct",
                answer: "Oczy chronione specjalną przezroczystą powieką"
            },
            {
                value: "false1",
                answer: "Puszysty ogon"
            },
            {
                value: "false2",
                answer: "Trójkątne uszy"
            }
        ]
    },
    {
        title: "Jak nazywają się domy bobrów?",
        image: require("../assets/boards/13/zeremie.png"),
        answers: [
            {
                value: "correct",
                answer: "Żeremie"
            },
            {
                value: "false1",
                answer: "Kotlinki"
            },
            {
                value: "false2",
                answer: "Barłogi"
            }
        ]
    },
    {
        title: "Co to za ryba?",
        image: require("../assets/boards/14/rozanka.png"),
        answers: [
            {
                value: "correct",
                answer: "Różanka"
            },
            {
                value: "false1",
                answer: "Kleń"
            },
            {
                value: "false2",
                answer: "Płoć"
            }
        ]
    },
    {
        title: "Co to za ryba?",
        image: require("../assets/boards/14/szczupak.png"),
        answers: [
            {
                value: "correct",
                answer: "Szczupak"
            },
            {
                value: "false1",
                answer: "Różanka"
            },
            {
                value: "false2",
                answer: "Ukleja"
            }
        ]
    },
    {
        title: "Czym żywi się gąsienica motyla czerwończyka fioletka?",
        image: require("../assets/boards/16/rdest.png"),
        answers: [
            {
                value: "correct",
                answer: "Rdestem wężownikiem"
            },
            {
                value: "false1",
                answer: "Buddleją Dawida"
            },
            {
                value: "false2",
                answer: "Nachyłkiem wielokwiatowym"
            }
        ]
    },
    {
        title: "Co to za motyl?",
        image: require("../assets/boards/16/fioletek.png"),
        answers: [
            {
                value: "correct",
                answer: "Czerwończyk fioletek"
            },
            {
                value: "false1",
                answer: "Rusałka osetnik"
            },
            {
                value: "false2",
                answer: "Rusałka admirał"
            }
        ]
    },
    {
        title: "Cechy charakterystyczne czapli siwej to:",
        image: require("../assets/boards/17/czapla.png"),
        answers: [
            {
                value: "correct",
                answer: "Esowato wygięta szyja"
            },
            {
                value: "false1",
                answer: "Jaskrawe ubarwienie"
            },
            {
                value: "false2",
                answer: "Pomarańczowe nogi"
            }
        ]
    },
    {
        title: "Który z tych gatunków ptaków charakteryzuje zielona głowa?",
        image: require("../assets/boards/17/foto.jpg"),
        answers: [
            {
                value: "correct",
                answer: "Kaczkę krzyżówkę"
            },
            {
                value: "false1",
                answer: "Śmieszkę"
            },
            {
                value: "false2",
                answer: "Łyskę"
            }
        ]
    },
    {
        title: "Co to za ptak?",
        image: require("../assets/boards/17/zimorodek.png"),
        answers: [
            {
                value: "correct",
                answer: "Zimorodek"
            },
            {
                value: "false1",
                answer: "Remiz"
            },
            {
                value: "false2",
                answer: "Bekas krzyk"
            }
        ]
    },
    {
        title: "Jaki to gatunek drzewa?",
        image: require("../assets/boards/5/dab.png"),
        answers: [
            {
                value: "correct",
                answer: "Dąb szypułkowy"
            },
            {
                value: "false1",
                answer: "Lipa drobnolistna"
            },
            {
                value: "false2",
                answer: "Topola biała"
            }
        ]
    },
    {
        title: "W jakim stylu został zaprojektowany ogród na terenie rezydencji Radziwiłłów?",
        answers: [
            {
                value: "correct",
                answer: "włoskim"
            },
            {
                value: "false1",
                answer: "francuskim"
            },
            {
                value: "false2",
                answer: "angielskim"
            }
        ]
    },
    {
        title: "W którym roku rozpoczęto budowę pałacu Radziwiłłów?",
        image: require("../assets/boards/4/fotografia.jpg"),
        answers: [
            {
                value: "correct",
                answer: "W 1622 roku"
            },
            {
                value: "false1",
                answer: "W 1702 roku"
            },
            {
                value: "false2",
                answer: "W 1717 roku"
            }
        ]
    },
    {
        title: "Owoce dębu szypułkowego to:",
        answers: [
            {
                value: "correct",
                answer: "Żołędzie"
            },
            {
                value: "false1",
                answer: "Kasztany"
            },
            {
                value: "false2",
                answer: "Szyszki"
            }
        ]
    },
    {
        title: "W naturalnym lesie obieg materii jest:",
        answers: [
            {
                value: "correct",
                answer: "Zamknięty"
            },
            {
                value: "false1",
                answer: "Otwarty"
            },
            {
                value: "false2",
                answer: "Półotwarty"
            }
        ]
    },
    {
        title: "Które zdanie jest prawdziwe?",
        answers: [
            {
                value: "correct",
                answer: "W procesie powstawania starorzeczy z coraz bardziej wyginającego się zakola rzeki powstaje meander."
            },
            {
                value: "false1",
                answer: "W procesie powstawania starorzeczy z coraz bardziej wyginającego się meandra powstaje zakole rzeki."
            },
            {
                value: "false2",
                answer: "Dawny meander nie może stać się starorzeczem"
            }
        ]
    },
    {
        title: "Które zdanie jest prawdziwe?",
        image: require("../assets/boards/13/bobr.png"),
        answers: [
            {
                value: "correct",
                answer: "Bobry potrafią ściąć drzewo do jednego metra średnicy"
            },
            {
                value: "false1",
                answer: "Bobry potrafią ściąć drzewo do dwóch metrów średnicy"
            },
            {
                value: "false2",
                answer: "Bobry potrafią ściąć drzewo do dwóch i pół metra średnicy"
            }
        ]
    },
    {
        title: "Które zdanie jest prawdziwe?",
        image: require("../assets/boards/8/wierzbaglowiasta.png"),
        answers: [
            {
                value: "correct",
                answer: "W Polsce rośnie prawie 30 gatunków wierzby"
            },
            {
                value: "false1",
                answer: "W Polsce rośnie prawie 15 gatunków wierzby"
            },
            {
                value: "false2",
                answer: "W Polsce rośnie prawie 40 gatunków wierzby"
            }
        ]
    }
]