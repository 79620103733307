import * as React from "react"
import Svg, { SvgProps, G, Path, Defs, ClipPath } from "react-native-svg"
import { memo } from "react"
import { CombinedTheme } from "../../styles/theme"

const Phone = (props: SvgProps) => (
  <Svg
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <G clipPath="url(#a)">
      <Path
        d="M8.571 0a3.429 3.429 0 0 0-3.428 3.429v3.574a2.572 2.572 0 0 1 1.568 3.283h1.003a2.571 2.571 0 1 1 0 5.143H6.711a2.571 2.571 0 0 1-1.568 3.282v1.86A3.429 3.429 0 0 0 8.57 24h6.858a3.428 3.428 0 0 0 3.428-3.429v-1.86a2.57 2.57 0 0 1-1.568-3.282h-1.003a2.571 2.571 0 1 1 0-5.143h1.003a2.57 2.57 0 0 1 1.568-3.283V3.429A3.428 3.428 0 0 0 15.43 0H8.57Zm7.715 12h4.788l-1.967-1.965a.858.858 0 0 1 1.214-1.213l3.429 3.428c.16.16.249.376.25.602v.01a.857.857 0 0 1-.247.597l-.005.005-3.429 3.429a.857.857 0 1 1-1.212-1.214l1.967-1.965h-4.788a.855.855 0 0 1-.606-1.463.857.857 0 0 1 .606-.251Zm-8.572 1.714a.857.857 0 1 0 0-1.714H2.926l1.967-1.965a.858.858 0 1 0-1.214-1.213L.25 12.25l-.003.005a.854.854 0 0 0-.247.597v.01c.001.226.091.441.25.6l3.429 3.429a.858.858 0 0 0 1.214-1.212l-1.967-1.965h4.788Z"
        fill={CombinedTheme.colors.tertiary}
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)

const Memo = memo(Phone)
export default Memo
