import Constants from "expo-constants";
import React from "react";
import {StyleSheet, View} from "react-native";
import { Text } from "react-native-paper";
import { EventDto } from "../../api";
import NewsTile from "./newsTile";

export default function NewsTileList(props: NewsTileListProps) {
    const apiUrl = Constants.expoConfig!.extra!.apiBaseUrl;

    const getImageUrl = (id: number) => {
        return apiUrl + `/api/File/${id}/FileStream`;
    }

    return <View style={styles.listWrapper}>
        {!props.tiles.length && <Text variant="bodyLarge" style={{ textAlign: "center" }}>Brak nadchodzących wydarzeń</Text>}
        {props.tiles.map((newsData: EventDto, idx: number) => {
            const firstParagraph = newsData.description!.split("\n")[0];
            return <NewsTile
                item={newsData}
                date={newsData.date!}
                header={newsData.name!}
                firstParagraph={firstParagraph}
                image={{ uri: getImageUrl(newsData.eventPhotosIds![0]) }}
                key={idx} />
        })}
    </View>
}

interface NewsTileListProps {
    tiles: EventDto[];
}

const styles = StyleSheet.create({
    listWrapper: {
        display: "flex",
        flex: 1,
        paddingTop: 20,
        marginHorizontal: 24,
        gap: 24,
    }
})
