import React, { useEffect, useState } from "react";
import { ImageProps, Image, View, useWindowDimensions, Modal, TouchableOpacity } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import Zoom from "../zoom/zoom";

export default function FullWidthImage(props: ImageProps) {
    
    const [modalVisible, setModalVisible] = useState(false);

    const [size, setSize] = useState<{ width: number, height: number }>();

    const [viewDimensions, setViewDimensions] = useState<{ width: number, height: number }>(useWindowDimensions());

    useEffect(() => {
        Image.getSize(props.source as string, (w, h) => setSize({ width: w, height: h }));
    }, [props.source]);

    const scaleFactor = size ? size.width / (viewDimensions.width - 2 * 24) : undefined;

    return <>
        <View onLayout={event => setViewDimensions(event.nativeEvent.layout)}>
            {!size
                ? <ActivityIndicator /> :
                <TouchableOpacity onPress={() => setModalVisible(true)}>
                    <Image {...props} resizeMode="contain" progressiveRenderingEnabled={true} style={[{ width: "100%",  height: size.height / scaleFactor! }, props.style]} />
                </TouchableOpacity>}
        </View>
        <Modal
            visible={modalVisible}
            transparent={true}>
            <Zoom
                onClose={() => setModalVisible(false)}
                style={{ backgroundColor: "rgba(0, 0, 0, .5)" }}
                contentContainerStyle={{ backgroundColor: "white" }}>
                {size && <Image {...props} resizeMode="contain" progressiveRenderingEnabled={true} style={[{ height: size.height / scaleFactor! }, props.style]} />}
            </Zoom>
        </Modal>
    </>
}