import * as React from "react"
import Svg, {SvgProps, Path} from "react-native-svg"
import {memo} from "react"


const ArrowRightIcon = (props: SvgProps) => (
    <Svg width="12" height="20" viewBox="0 0 12 20" fill="none" {...props}>
        <Path d="M1.88755 20L0.112549 18.225L8.33755 10L0.112549 1.775L1.88755 0L11.8875 10L1.88755 20Z" fill="white"/>
    </Svg>

)

const Memo = memo(ArrowRightIcon)
export default Memo
