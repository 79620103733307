import Layout from "../../components/layout/layout";
import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from "react-native-paper";
import ImageCarousel from "../../components/imageCarousel/ImageCarousel";
import {educationPointsData} from "../../data/educationPoints";
import ObjectIndicator from "../../components/objectIndicator/objectIndicator";
import VirtualView from "../../components/virutalView/virtualView";
import QrCodeButton from "../../components/qrCode/qrCodeButton";
import AbpTabView from "../../components/abpTabView/abpTabView";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import MapView from "../mapView/mapView";
import { useBoardVisited } from "../../hooks/visitHooks";

export default function EducationBoardPageBase(props: EducationBoardPageBaseProps) {
    const navigation = useNavigation();
    const isFocused = useIsFocused();

    const index = props.boardNumber - 1;
    const boardData = educationPointsData[index];

    const previousBoard = educationPointsData[index - 1];
    const nextBoard = educationPointsData[index + 1];
    const visited = useBoardVisited(index);

    return <>
        {isFocused && <QrCodeButton visited={visited} />}
        <Layout title={"Tablica edukacyjna nr " + props.boardNumber} subtitle={<>{boardData.subtitle}</>}>
            {/* <View style={{ marginTop: 16, marginLeft: -24 }}>
                <ImageCarousel images={boardData.carouselImages} visited={visited} showGradient={true} />
            </View> */}
            <AbpTabView style={{ marginTop: 8 }} tabs={{
                "Zdjęcia": () =><ImageCarousel images={boardData.carouselImages} visited={visited}
                    showGradient={true} />,
                "Widok 360°": () => <VirtualView boardId={index} />
            }} />
            {props.children}

            <View style={styles.mapWrapper}>
                <Text variant="titleMedium" style={styles.mapTitle}>Mapa</Text>
                <MapView zoom={14} latitude={boardData.coordinates.latitude} longitude={boardData.coordinates.longitude} style={styles.map} />
            </View>
            
            <View style={{ paddingTop: 40, paddingBottom: 164}}>

                {nextBoard && <ObjectIndicator
                    direction={"Następny obiekt"}
                    title={nextBoard.subtitle}
                    image={nextBoard.carouselImages[0]}
                    onPress={() => navigation.navigate("EducationBoard" + (props.boardNumber + 1) as never)}
                />}

                {previousBoard && <ObjectIndicator
                    direction={"Poprzedni obiekt"}
                    title={previousBoard.subtitle}
                    image={previousBoard.carouselImages[0]}
                    onPress={() => navigation.navigate("EducationBoard" + (props.boardNumber - 1) as never)}
                />}
            </View>
        </Layout>
    </>
}

interface EducationBoardPageBaseProps {
    boardNumber: number,
    children: JSX.Element
}

const styles = StyleSheet.create({
    map: {
        height: 217
    },
    mapWrapper: {
        paddingTop: 40,
        marginHorizontal: -24
    },
    mapTitle: {
        marginBottom: 14,
        marginHorizontal: 24
    }
});
