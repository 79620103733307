import {ImageBackground, Linking, StyleSheet, View} from "react-native";
import {Button, Surface, Text} from "react-native-paper";
import NavigateIcon from "../icons/navigateIcon";
import {LinearGradient} from "expo-linear-gradient";
import CheckIcon from "../icons/checkIcon";
import {NavigationProp, useNavigation} from "@react-navigation/native";
import { CombinedTheme } from "../../styles/theme";
import { EducationPointData } from "../../data/educationPoints";
import { useBoardVisited } from "../../hooks/visitHooks";

export default function TrailTileModal(props: TrailTileProps) {

    const navigation = props.navigation ?? useNavigation();
    const visited = useBoardVisited(props.boardNumber - 1);

    const openCoordinates = () => {
        const url = `https://www.google.com/maps/dir/?api=1&destination=${props.boardData.coordinates.latitude},${props.boardData.coordinates.longitude}`;
        
        Linking.openURL(url!);
    }

    const onGoToPointPress = () => {
        props.onPress?.();
        navigation!.navigate("EducationBoard" + props.boardNumber as never);
    }

    return <Surface style={styles.tileWrapper} elevation={1}>
        <LinearGradient colors={["#f1bc000d", "#f1bc000d"]}>
            <ImageBackground style={styles.image} source={props.boardData.carouselImages[0]}>
                {visited && <Surface elevation={1} style={styles.visitedTile}>
                    <LinearGradient colors={["#f1bc000d", "#f1bc000d"]} style={styles.visitedTileContent}>
                        <CheckIcon />
                        <Text style={styles.visitedTileText} variant={"labelLarge"}>Zameldowano</Text>
                    </LinearGradient>
                </Surface>}
            </ImageBackground>
            <View style={styles.textWrapper}>
                <Text variant={"labelLarge"}>Tablica edukacyjna nr {props.boardNumber}</Text>
                <Text variant={"titleMedium"} style={{marginVertical: 8}}>{props.boardData.subtitle}</Text>
                <Text variant={"bodyMedium"}>{props.boardData.description}</Text>
                <View style={styles.buttonsWrapper}>
                    <Button style={styles.openCoordinatesButton} mode={"outlined"} onPress={openCoordinates}>
                        <Text variant={"labelLarge"} style={styles.buttonText}>Nawiguj <NavigateIcon/></Text>
                    </Button>
                    <Button style={styles.goToPointButton} mode={"contained"} onPress={onGoToPointPress}>
                        <Text variant={"labelLarge"}>Przejdź do punktu</Text>
                    </Button>
                </View>
            </View>
        </LinearGradient>
    </Surface>

}

const styles = StyleSheet.create({
    visitedTile: {
        backgroundColor: "#FFFFFF",
        borderRadius: 8,
        position: "absolute",
        bottom: 16,
        right: 16,
    },
    tileWrapper: {
        position: "relative",
        borderRadius: 12,
        overflow: "hidden",
        marginBottom: 24,
        backgroundColor: CombinedTheme.colors.lightSurface
    },
    textWrapper: {
        padding: 16
    },
    buttonsWrapper: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "flex-end"
    },
    buttonText: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 10
    },
    openCoordinatesButton: {
        marginRight: 16,
        marginTop: 24
    },
    goToPointButton: {
        marginTop: 24
    },
    image: {
        width: "100%",
        height: 170
    },
    visitedTileText: {
        marginLeft: 11,
    },
    visitedTileContent: {
        paddingLeft: 11,
        paddingRight: 16,
        paddingVertical: 6,
        borderRadius: 8,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    }
})


export interface TrailTileProps {
    boardNumber: number,
    boardData: EducationPointData,
    navigation?: NavigationProp<ReactNavigation.RootParamList>,
    onPress?: () => any
}