import { Alert, StyleSheet, View } from "react-native";
import React from "react";
import { Button, Text } from "react-native-paper";
import FullHeightLayout from "../../components/layout/fullHeightLayout";
import Svg, { Circle } from "react-native-svg";
import { useNavigation } from "@react-navigation/native";
import IconSun from "../../components/icons/iconSun";
import { CombinedTheme } from "../../styles/theme";
import { Camera } from 'expo-camera';

export default function CheckWeatherPage() {
    const navigation = useNavigation();
    // trigger downloading ar model
    // const path = useArModelPath();
    const [permissionState, requestPermission] = Camera.useCameraPermissions();

    const onNext = async () => {
        // Navigate to AR view
        if (permissionState?.granted) {
            navigation.navigate("ArPage" as never);

            return;
        }

        if (permissionState?.canAskAgain) {
            requestPermission().then(r => {
                if (r.granted) {
                    navigation.navigate("ArPage" as never);

                    return;
                }
                Alert.alert("Błąd uprawnień", "Ta funkcjonalność wymaga dostępu do aparatu", [{ text: "Powrót", onPress: () => {} }]);
            });

            return;
        }

        Alert.alert("Błąd uprawnień", "Ta funkcjonalność wymaga dostępu do aparatu", [{ text: "Powrót", onPress: () => {} }]);
    };

    const radius = 4;

    return <FullHeightLayout>
        <View style={{ justifyContent: "space-between", height: "100%" }}>
            <View style={{ justifyContent: "center", alignItems: "center", flex: 1 }}>
                <IconSun />
                <Text variant="bodyLarge" style={{ marginVertical: 28 }}>
                    Oświetlenie
                </Text>
                <Text variant="headlineSmall" style={{ textAlign: "center" }}>
                    Upewnij się, że panują dobre warunki oświetleniowe
                </Text>
            </View>
            <View>
                <Button mode="contained" onPress={onNext} style={{ width: "100%" }}>
                    Przejdź do widoku AR
                </Button>
                <View style={{ flexDirection: "row", marginVertical: 30, alignSelf: "center" }}>
                    <Svg style={styles.dot} height={2 * radius} width={2 * radius}>
                        <Circle cx={radius} cy={radius} r={radius} />
                    </Svg>
                    <Svg style={styles.dot} height={2 * radius} width={2 * radius}>
                        <Circle cx={radius} cy={radius} r={radius} />
                    </Svg>
                    <Svg style={styles.dotActive} height={2 * radius} width={2 * radius}>
                        <Circle cx={radius} cy={radius} r={radius} />
                    </Svg>
                </View>
            </View>
        </View>
    </FullHeightLayout>
}

const styles = StyleSheet.create({
    dot: {
        marginHorizontal: 8,
        fill: CombinedTheme.colors.onSurfaceVariant
    },
    dotActive: {
        marginHorizontal: 8,
        fill: CombinedTheme.colors.primary
    }
})
