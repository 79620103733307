import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused } from "@react-navigation/native";
import { useEffect, useState } from "react";

const boardVisitKey = "boardVisit_";
let visitedInMemory: { [key: number]: boolean } = {};

export function useBoardVisited(index: number) {
    const [isVisited, setIsVisited] = useState(visitedInMemory[index]);
    const isFocused = useIsFocused();

    useEffect(() => {
        if (isVisited !== visitedInMemory[index]) {
            setIsVisited(visitedInMemory[index]);
        }
    }, [isFocused]);

    useEffect(() => {
        if (index in visitedInMemory) {
            setIsVisited(visitedInMemory[index]);
        }

        (async () => {
            try {
            const value = await AsyncStorage.getItem(boardVisitKey + index);

            if (value !== null) {
                visitedInMemory[index] = !!value;
                setIsVisited(!!value);
            }
          } catch(e) {
            console.log("Error reading from AsyncStorage", e);
          }
        })();
    }, []);

    return isVisited;
}

export function useAllBoardVisited() {
    const [visited, setVisited] = useState(Object.keys(visitedInMemory).map(v => +v));
    const isFocused = useIsFocused();

    useEffect(() => {
        setVisited(Object.keys(visitedInMemory).map(v => +v));
    }, [isFocused]);

    useEffect(() => {

        (async () => {
            const keyArray = Array.from(Array(17)).map((_, i) => boardVisitKey + i);
            try {
                const values = await AsyncStorage.multiGet(keyArray);

                if (values !== null) {
                    setVisited(values.filter(v => v[1]).map(v => {
                        console.log("values from storage", v[0]);
                        const index = +(v[0].replace(boardVisitKey, ""));
                        visitedInMemory[index] = !!v[1];
                        return index;
                    }));
                }
            } catch(e) {
                console.log("Error reading from AsyncStorage", e);
            }
            })();
    }, []);

    return visited;
}

export function useNumberOfBoardsVisited() {
    const [visitedCount, setVisitedCount] = useState(Object.keys(visitedInMemory).length);
    const isFocused = useIsFocused();

    useEffect(() => {
        setVisitedCountFromStorage();
    }, [isFocused]);

    const setVisitedCountFromStorage = async () => {
        try {
            const value = await AsyncStorage.getAllKeys();

            if (value !== null) {
                setVisitedCount(value.filter(v => v.startsWith(boardVisitKey)).length);
            }
        } catch(e) {
            console.log("Error reading keys from AsyncStorage", e);
        }
    }

    useEffect(() => {
        setVisitedCountFromStorage();
    }, []);

    return visitedCount;
}

export async function markBoardAsVisited(index: number) {
    visitedInMemory[index] = true;

    try {
        await AsyncStorage.setItem(boardVisitKey + index, true.toString());
    } catch (e) {
        console.log("Error saving to AsyncStorage", e);
    }
}