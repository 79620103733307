import * as React from 'react';
import {StyleSheet} from 'react-native';
import { Text } from 'react-native-paper';
import AudioInvoker from "../../components/audioPlayer/audioInvoker";
import { AudioPlaylistEntry } from "../../contexts/audioContext";
import FullWidthImage from "../../components/fullWidthImage/fullWidthImage";
import { CombinedTheme } from "../../styles/theme";
import DidYouKnowTile from "../../components/didYouKnow/didYouKnowTile";
import EducationBoardPageBase from "../../components/educationBoardPageBase/educationBoardPageBase";
import MuseumLogo from '../../components/icons/museumLogo';
import VirtualView from '../../components/virutalView/virtualView';
import ArVirtualViewCta from '../../components/virutalView/arVirtualViewCta';

export default function EducationBoard4() {
    return <EducationBoardPageBase boardNumber={4}>
            <EducationBoardContent />
    </EducationBoardPageBase>
}

const playlist: AudioPlaylistEntry[] = [{
    title: "Tablica edukacyjna nr 4",
    subtitle: "„Pałac nad Krzną\" – rejon Parku Radziwiłłów",
    source: require("../../assets/boards/4/1.mp3")
}, {
    title: "Tablica edukacyjna nr 4",
    subtitle: "„Mappa miasta Białłej\"",
    source: require("../../assets/boards/4/2.mp3")
}, {
    title: "Tablica edukacyjna nr 4",
    subtitle: "Historia nieistniejącego pałacu",
    source: require("../../assets/boards/4/3.mp3")
}];

function EducationBoardContent() {
    return <>
        <AudioInvoker index={0} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Znajdujesz się na przedpolach najważniejszego zabytku Białej Podlaskiej – rezydencji Radziwiłłów. Nie wszyscy wiedzą, że w czasach Radziwiłłów miejsce to wyglądało zupełnie inaczej.
        </Text>
        <AudioInvoker index={1} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        „Mappa miasta Białłej"
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Spójrz na mapę z końca XVIII w. Mapa pochodzi z tzw. Atlasu Radziwiłłowskiego, znajdującego się w zbiorach Towarzystwa Naukowego Płockiego. Opracowana została przez Leonarda Targońskiego w 1777 r. Tu, gdzie teraz stoisz, płynął szeroki kanał zasilający fosę, która otaczała ufortyfikowaną rezydencję. Takie ukształtowanie terenu czyniło siedzibę Radziwiłłów trudno dostępną. Wyspy u podnóża pałacu służyły jako zwierzyniec — hodowano tu jelenie, sarny i żubry. Dalej na wschód Krzna rozdzielała się na cztery kanały, przy których znajdowały się młyny wodne oraz folusz, czyli zakład obrabiający sukno.
        </Text>
        <FullWidthImage source={require("../../assets/boards/4/mapa.png")} style={{ marginTop: 8, marginBottom: 0 }} />
        <Text variant="labelSmall" style={styles.photoLabel}>
        rys. Leonard Targoński | Towarzystwo Naukowe Płockie
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Obok magnackiej rezydencji i sąsiadującego z nią folwarku powoli rozwijało się miasto. Ukazana na mapie Biała liczyła około trzystu domów i miała charakter niewielkiej rolniczej miejscowości. Centrum osady był kwadratowy rynek — dzisiejszy Plac Wolności. Znajdowało się tu kilka klasztorów, które można rozpoznać po otaczających je ogrodach. Błonia nad często wylewającą Krzną służyły jako pastwiska, stały tu też miejskie stodoły (ze względów pożarowych sytuowane nieco na uboczu).
        </Text>
        <AudioInvoker index={2} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Historia nieistniejącego pałacu
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Popatrz teraz na zespół pałacowy Radziwiłłów. Mapa pokazuje go w czasach największej świetności. Jak już wspomniano, rezydencja posiadała elementy obronne: fosę i wały ziemne z bastionami. Wewnątrz umocnień znajdowały się budynki o reprezentacyjnym charakterze, zaplanowane tak, aby olśnić widza. Po przejściu przez bramę ze sklepionym łącznikiem („szyją”), oczom odwiedzającego ukazywał się jeden z największych dziedzińców w ówczesnej Polsce. Po dwóch stronach dziedzińca znajdowały się oficyny, na wprost — pałac, a od wschodu — ogród w stylu włoskim. Na terenie rezydencji była też kaplica, biblioteka, teatr i zabudowania gospodarcze (m.in. kuchnia, stajnia, wozownia).
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Niestety, najważniejszy element założenia — pałac — nie zachował się do dziś. Jego budowę rozpoczęto w 1622 r. Architektura budynku była twórczym rozwinięciem panujących ówcześnie wzorców kształtowania rezydencji. Skalę pałacu można wyobrazić sobie, oglądając odtworzony niedawno obrys jego murów. Jak wyglądał, wiemy dzięki planom, rycinom oraz jedynemu zachowanemu zdjęciu wykonanemu niedługo przed jego rozebraniem przez Eliasza Stumanna przed 1864 r.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        To właśnie tutaj toczyło się ponad 200 lat historii Radziwiłłów w Białej. Radziwiłłowie byli jednym z najpotężniejszych rodów magnackich Rzeczypospolitej Obojga Narodów. Słynęli z bogactwa, ułańskiej fantazji, ekstrawagancji, ale też okrucieństwa. W bialskiej rezydencji przyjmowali ważnych gości, między innymi króla Jana Kazimierza czy parę królewską Jana III Sobieskiego i Marię Kazimierę d’Arquien. Tu prowadzili interesy, rozwijali działalność gospodarczą, spędzali czas na polowaniach i rozrywkach. Pod koniec XVIII w. ród Radziwiłłów zaczął tracić na znaczeniu. W XIX w. siedziba została opuszczona przez magnatów i zaczęła popadać w ruinę. W 1883 r. pałac rozebrano na cegłę.
        </Text>
        <Text variant="titleMedium" style={styles.heading}>
        Aby dowiedzieć się więcej o Radziwiłłach, historii ich rezydencji, miasta i regionu, skręć ze szlaku i odwiedź Muzeum Południowego Podlasia w Białej Podlaskiej.
        </Text>
        <ArVirtualViewCta />
        <DidYouKnowTile title={"Muzeum Południowego Podlasia w Białej Podlaskiej"}
                        description={"Tekst tablicy powstał przy współpracy z Muzeum Południowego Podlasia w Białej Podlaskiej"}
                        customComponent={<MuseumLogo />}/>
    </>
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 12
    },
    text: {
        marginBottom: 16
    },
    photoLabel: {
        color: CombinedTheme.colors.neutral60,
        marginBottom: 24,
        marginTop: 8
    }
});