import * as React from "react"
import Svg, {SvgProps, Path} from "react-native-svg"
import {memo} from "react"


const ArrowLeftIcon = (props: SvgProps) => (
    <Svg width="12" height="20" viewBox="0 0 12 20" fill="none" {...props}>
        <Path d="M10.1125 -1.55175e-07L11.8875 1.775L3.66245 10L11.8875 18.225L10.1125 20L0.112452 10L10.1125 -1.55175e-07Z" fill="white"/>
    </Svg>

)

const Memo = memo(ArrowLeftIcon)
export default Memo
