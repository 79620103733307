import * as React from 'react';
import {StyleSheet} from 'react-native';
import { Text } from 'react-native-paper';
import AudioInvoker from "../../components/audioPlayer/audioInvoker";
import { AudioPlaylistEntry } from "../../contexts/audioContext";
import FullWidthImage from "../../components/fullWidthImage/fullWidthImage";
import OrderedList from "../../components/orderedList/orderedList";
import { CombinedTheme } from "../../styles/theme";
import DidYouKnowTile from "../../components/didYouKnow/didYouKnowTile";
import EducationBoardPageBase from "../../components/educationBoardPageBase/educationBoardPageBase";
import BirdAudioInvoker from '../../components/audioPlayer/birdAudioInvoker';

export default function EducationBoard15() {
    return <EducationBoardPageBase boardNumber={15}>
            <EducationBoardContent />
    </EducationBoardPageBase>
}

const playlist: AudioPlaylistEntry[] = [{
    title: "Tablica edukacyjna nr 15",
    subtitle: "Ptasia autostrada",
    source: require("../../assets/boards/15/1.mp3")
}, {
    title: "Tablica edukacyjna nr 15",
    subtitle: "Bekas kszyk",
    source: require("../../assets/boards/15/1.1.mp3")
}, {
    title: "Tablica edukacyjna nr 15",
    subtitle: "Łęczak",
    source: require("../../assets/boards/15/1.2.mp3")
}, {
    title: "Tablica edukacyjna nr 15",
    subtitle: "Błotniak stawowy",
    source: require("../../assets/boards/15/1.3.mp3")
}, {
    title: "Tablica edukacyjna nr 15",
    subtitle: "Gęś białoczelna",
    source: require("../../assets/boards/15/1.4.mp3")
}, {
    title: "Tablica edukacyjna nr 15",
    subtitle: "Gęgawa",
    source: require("../../assets/boards/15/1.5.mp3")
}, {
    title: "Tablica edukacyjna nr 15",
    subtitle: "Żuraw",
    source: require("../../assets/boards/15/1.6.mp3")
}];

function EducationBoardContent() {
    return <>
        <AudioInvoker index={0} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Witamy w punkcie obserwacji przyrody przy ujściu Klukówki do Krzny. Na dole znajduje się czatownia, gdzie możesz z ukrycia podziwiać najbliższe otoczenie. Taras nad czatownią to dobre miejsce do obserwacji ptaków migrujących wiosną i jesienią.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Dolina Krzny to ważny w skali regionu szlak migracji ptaków wędrownych. Co roku wzdłuż Krzny wędrują tysiące gęsi i kaczek, a także liczne stada ptaków z rzędu siewkowych. Można tu zobaczyć także żurawie, błotniaki, a nawet orliki krzykliwe i grubodziobe. Wiosenne rozlewiska rzeki — zwłaszcza te nieco na wschód od Białej Podlaskiej, między wsiami Husinka i Kijowiec — są miejscem odpoczynku ptaków wodno-błotnych, podążających na bagna w dolinie Prypeci lub dalej na wschód. Przedstawiamy kilka często obserwowanych tu gatunków.
        </Text>
        <Text variant="titleMedium" style={styles.heading}>
        Miłego wypatrywania!
        </Text>
        <BirdAudioInvoker index={1} playlist={playlist} image={require("../../assets/boards/15/bekas.png")} />
        <BirdAudioInvoker index={2} playlist={playlist} image={require("../../assets/boards/15/leczak.png")} />
        <BirdAudioInvoker index={3} playlist={playlist} image={require("../../assets/boards/15/blotniak.png")} />
        <BirdAudioInvoker index={4} playlist={playlist} image={require("../../assets/boards/15/ges.png")} />
        <BirdAudioInvoker index={5} playlist={playlist} image={require("../../assets/boards/15/gegawa.png")} />
        <BirdAudioInvoker index={6} playlist={playlist} image={require("../../assets/boards/15/zuraw.png")} />
        <Text variant="labelSmall" style={styles.photoLabel}>
        rys. Malwina Łazęcka-Malińska
        </Text>
    </>
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 12
    },
    text: {
        marginBottom: 16
    },
    photoLabel: {
        color: CombinedTheme.colors.neutral60,
        marginBottom: 0,
        marginTop: 4
    }
});