import React, { useEffect, useState } from "react";
import { View, StyleSheet, Platform, TouchableOpacity } from "react-native";
import { ActivityIndicator, IconButton, Surface, Text } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import useAudioPlayer from "../../hooks/useAudioPlayer";
import Next from "../icons/next";
import PauseButton from "../icons/pauseButton";
import PlayButton from "../icons/playButton";
import Previous from "../icons/previous";
import {Slider} from '@miblanchard/react-native-slider';
import { CombinedTheme } from "../../styles/theme";

export default function AudioPlayer() {

    const { bottom, left, right } = useSafeAreaInsets();

    const {
        current,
        resume,
        playbackStatus,
        pause,
        playlist,
        play,
        replay,
        seek,
        setPlayerHidden,
        playerHidden
    } = useAudioPlayer();

    const [isLoading, setIsLoading] = useState<boolean>();

    if (current?.index === undefined || !playlist) {
        return null;
    }

    const onPlayButtonPress = async () => {
        setIsLoading(true);

        try {
            await resume();
        } finally {
            setIsLoading(false);
        }
    }

    const playPrevious = async () => {
        setIsLoading(true);

        try {
            if (playbackStatus?.isLoaded) {
                if (playbackStatus.positionMillis > 2000) {
                    await replay();
                    return;
                }
            }
            await play(current.index! - 1);
        } finally {
            setIsLoading(false);
        }
    }

    const playNext = async () => {
        setIsLoading(true);

        try {
            await play(current.index! + 1);
        } finally {
            setIsLoading(false);
        }
    }

    const playButton = (() => {
        
        if (isLoading || (playbackStatus?.isLoaded && playbackStatus.isBuffering && playbackStatus.shouldPlay)) {
            return <View style={{ width: 48, height: 48, alignItems: "center", justifyContent: "center" }}>
                <ActivityIndicator />
            </View>;
        }

        if (!playbackStatus?.isLoaded || !playbackStatus?.isPlaying) {
            return <TouchableOpacity onPress={onPlayButtonPress} accessibilityLabel="Wznów odtwarzanie">
                <Surface style={{ borderRadius: 48 }}>
                    <PlayButton />
                </Surface>
            </TouchableOpacity>;
        }

        if (playbackStatus.isPlaying) {
            return <TouchableOpacity onPress={pause} accessibilityLabel="Wstrzymaj odtwarzanie">
                <Surface style={{ borderRadius: 48 }}>
                    <PauseButton />
                </Surface>
            </TouchableOpacity>;
        }
    })();

    const onSeek = async (value: number) => {
        await seek(Math.round(value));
    }

    const progress = playbackStatus?.isLoaded ? playbackStatus.positionMillis : 0;
    const currentPlaylistElement = playlist[current.index];
    const { title, subtitle } = currentPlaylistElement;
    const isPreviousDisabled = (current.index || 0) === 0 && (!playbackStatus?.isLoaded || playbackStatus.positionMillis <= 2000);
    const isNextDisabled = current.index + 1 >= playlist.length;

    return <Surface elevation={1} style={{ bottom, left, right, position: Platform.OS === "web" ? "fixed" : "relative" } as any}>
        {!playerHidden && <Surface elevation={1} style={{ position: "absolute", top: -45, right: right + 16 }}>
            <View style={{ flex: 1, width: "100%", height: "100%", backgroundColor: "rgba(241, 188, 0, 0.1)" }}>
                <IconButton icon="chevron-down" accessibilityLabel="Zmniejsz odtwarzacz" onPress={() => setPlayerHidden(true)} size={16} />
            </View>
        </Surface>}
        {(!playerHidden || !playbackStatus?.isLoaded || playbackStatus.isPlaying) && <TouchableOpacity onPress={() => setPlayerHidden(!playerHidden)} accessibilityLabel="Powiększ odtwarzacz" style={[styles.container]}>
            <ProgressSlider trackClickable={!playerHidden} progress={progress} maximum={playbackStatus?.isLoaded ? playbackStatus?.durationMillis || Number.MAX_VALUE : Number.MAX_VALUE} onSeek={onSeek} />
            <View style={[styles.content, playerHidden ? { paddingTop: 12, paddingBottom: 8 } : {}]}>
                <View style={styles.description}>
                    <Text numberOfLines={1} variant="titleSmall">{subtitle}</Text>
                    <Text numberOfLines={1} style={styles.title}>{title}</Text>
                </View>
                {!playerHidden && <View style={styles.buttonsContainer}>
                    <TouchableOpacity style={{ marginRight: 24 }} onPress={playPrevious} disabled={isPreviousDisabled}>
                        <Previous style={{ opacity: isPreviousDisabled ? .5 : 1 }} />
                    </TouchableOpacity>
                    {playButton}
                    <TouchableOpacity style={{ marginLeft: 24 }} onPress={playNext} disabled={isNextDisabled}>
                        <Next style={{ opacity: isNextDisabled ? .5 : 1 }} />
                    </TouchableOpacity>
                </View>}
            </View>
        </TouchableOpacity>}
    </Surface>
}

function ProgressSlider({progress, maximum, onSeek, trackClickable}: {progress: number, maximum: number, onSeek: (v: number) => Promise<any>, trackClickable: boolean}) {

    const [isSliding, setIsSliding] = useState(true);
    const [slidingValue, setSlidingValue] = useState(0);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsSliding(false);
        }, 0);

        return () => clearTimeout(timeout);
    }, []);

    const onValueChange = async (value: number) => {
        setIsSliding(false);
        await onSeek(value)
    }

    return <Slider
        trackClickable={trackClickable}
        value={isSliding ? slidingValue : progress}
        containerStyle={styles.slider}
        maximumValue={maximum}
        animateTransitions={true}
        minimumTrackTintColor={CombinedTheme.colors.primary}
        maximumTrackTintColor={CombinedTheme.colors.outlineVariant}
        thumbTintColor={CombinedTheme.colors.primary}
        thumbTouchSize={{ height: 20, width: 40 }}
        renderAboveThumbComponent={() => isSliding ? <Text style={styles.sliderLabel} variant="labelSmall">{formatMillis(slidingValue)}</Text> : null}
        onValueChange={v => setSlidingValue(v as number)}
        onSlidingStart={() => setIsSliding(true)}
        onSlidingComplete={v => onValueChange(v as number)}
        thumbStyle={{ width: 12, height: 12, display: isSliding ? "flex" : "none" }}
        trackStyle={{ borderRadius: 0 }}
    />;
}

function formatMillis(input: number) {
    const seconds = Math.round(input / 1000);

    return `${Math.floor(seconds / 60)}:${(seconds % 60).toString().padStart(2, "0")}`;
}

const styles = StyleSheet.create({
    container: {
        width: "100%",
        backgroundColor: "rgba(241, 188, 0, 0.1)"
    },
    buttonsContainer: {
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: 32
    },
    description: {
        justifyContent: "center",
        flexShrink: 1
    },
    content: {
        flexDirection: "row",
        padding: 24,
        justifyContent: "space-between"
    },
    title: {
        fontFamily: "Inter_500Medium",
        fontSize: 11,
        lineHeight: 16,
        letterSpacing: .5,
        textTransform: "uppercase"
    },
    sliderLabel: {
        marginLeft: -5,
        marginBottom: 10
    },
    slider: {
        position: "absolute",
        top: -10,
        zIndex: 1,
        width: "100%",
        height: 20
    }
});