import React from "react";
import { Linking, Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { Button, Text } from "react-native-paper";
import { useNavigation } from '@react-navigation/native';
import EuIcons from "../components/icons/euIcons";
import WelcomeLayout from "../components/layout/welcomeLayout";
import Logo from "../components/icons/logo";
import IconAppStore from "../components/icons/iconAppStore";
import IconGooglePlay from "../components/icons/iconGooglePlay";

export default function WelcomePage() {

    const navigation = useNavigation();

    return <WelcomeLayout style={styles.container}>
        <Logo style={styles.image}/>
        <View style={{ marginTop: 32 }}>
            <Text variant="headlineMedium" style={{textAlign: "center"}}>Audio-przewodnik dla ścieżki edukacyjnej w
                Dolinie Krzny w Białej Podlaskiej</Text>
                <Button mode="contained" onPress={() => navigation.navigate("Home" as never)} style={{marginTop: 40}}>
                    Rozpocznij
                </Button>
        </View>

        <View style={{ flexDirection: "row", width: "100%", justifyContent: "center", marginVertical: 20 }}>
            {Platform.OS != "web" ? null : <>
                <TouchableOpacity accessibilityLabel="Pobierz aplikację mobilną z Apple AppStore" onPress={() => Linking.openURL("https://apps.apple.com/us/app/dolina-krzny-audioprzewodnik/id1659201690")} style={styles.button}>
                    <IconAppStore />
                </TouchableOpacity>
    
                <TouchableOpacity accessibilityLabel="Pobierz aplikację mobilną z Google Play" onPress={() => Linking.openURL("https://play.google.com/store/apps/details?id=com.digimuth.bialapodlaskaaudiotour")} style={styles.button}>
                    <IconGooglePlay />
                </TouchableOpacity>
            </>}
        </View>

        <View>
            <Text variant="bodySmall" style={styles.euText}>
                "Wykorzystanie walorów przyrodniczych doliny Krzny do celów edukacyjnych i turystycznych" - projekt
                współfinansowany ze środków Europejskiego Funduszu Rozwoju Regionalnego w ramach Regionalnego Programu
                Operacyjnego Województwa Lubelskiego na lata 2014-2020.
            </Text>
            <EuIcons style={styles.icons}/>
        </View>
    </WelcomeLayout>
}

const styles = StyleSheet.create({
    image: {
        width: "50%",
        flexGrow: 1,
        resizeMode: "contain",
        alignSelf: "center"
    },
    container: {
        justifyContent: "space-between",
    },
    euText: {
        textAlign: "center",
        marginBottom: 16,
        maxWidth: 840,
        alignSelf: "center"
    },
    icons: {
        width: "100%",
        marginBottom: Platform.OS === "web" ? 32 : 16,
        flex: 1,
        maxWidth: 640,
        alignSelf: "center"
    },
    button: {
        width: 140,
        height: 50,
        justifyContent: "center",
        alignItems: "center"
    }
});
