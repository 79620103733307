import { ImageBackground, Linking, Platform, StyleSheet, TouchableOpacity, View} from "react-native";
import {LinearGradient} from "expo-linear-gradient";
import {Button, Text} from "react-native-paper"
import {useNavigation} from "@react-navigation/native";
import ArIcon from "../icons/arIcon";
import IconAppStore from "../icons/iconAppStore";
import IconGooglePlay from "../icons/iconGooglePlay";

export default function ArVirtualViewCta() {

    const navigation = useNavigation();

    return <View style={{ flex: 1, marginHorizontal: -24}}>
        <ImageBackground style={[styles.image]}
                                source={require("../../assets/boards/4/fotografia.jpg")}>
            <LinearGradient style={[styles.imageContent]} colors={["rgba(15, 15, 15, 0.05)", "rgba(15, 15, 15, 0.85)"]}>
                <Text style={{color: "#FFFFFF"}} variant={"labelLarge"}> Zobacz Pałac w technologii AR {Platform.OS === "web" && "dzięki aplikacji mobilnej"}</Text>
                <ArIcon style={styles.arrowIcon}/>


                {Platform.OS === "web" ? <View style={{ flexDirection: "row", width: "100%", justifyContent: "center" }}>
                    <TouchableOpacity accessibilityLabel="Pobierz aplikację z Apple AppStore" onPress={() => Linking.openURL("https://apps.apple.com/us/app/dolina-krzny-audioprzewodnik/id1659201690")} style={styles.button}>
                        <IconAppStore />
                    </TouchableOpacity>

                    <TouchableOpacity accessibilityLabel="Pobierz aplikację z Google Play" onPress={() => Linking.openURL("https://play.google.com/store/apps/details?id=com.digimuth.bialapodlaskaaudiotour")} style={styles.button}>
                        <IconGooglePlay />
                    </TouchableOpacity>
                </View>
                : <Button onPress={() => navigation.navigate("Ar-CheckGps" as never)} mode={"contained"}>Przejdź do widoku AR</Button>}
            </LinearGradient>
        </ImageBackground>
    </View>
}

const styles = StyleSheet.create({
    image: {
        display: "flex",
        justifyContent: "center",
        marginBottom: 24,
        aspectRatio: 1.797,
        resizeMode: "cover",
        width: "100%"
    },
    imageContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flex: 1
    },
    arrowIcon: {
        marginTop: 13,
        marginBottom: 20
    },
    button: {
        width: 140,
        height: 50,
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1
    },
})

