import {StyleSheet, View, ImageBackground, StyleProp, ViewStyle, TouchableOpacity} from "react-native";
import {Surface, Text, TouchableRipple} from "react-native-paper";
import {LinearGradient} from "expo-linear-gradient";
import { CombinedTheme } from "../../styles/theme";
import React from "react";

export default function ObjectIndicator(props: ObjectIndicatorProps) {
    return         <Surface elevation={1} style={[styles.surface, props.style]}>
            <TouchableRipple borderless={true} onPress={props.onPress} style={{ zIndex: 1, borderRadius: 12 }} accessibilityLabel={`${props.direction} - ${props.title}`}>
                <LinearGradient
                    colors={["rgba(241, 188, 0, 0.05)", "rgba(241, 188, 0, 0.05))"]}
                    style={styles.wrapper}
                >
                    <View style={styles.textContainer}>
                        <Text style={styles.directionText} variant={"labelLarge"}>
                            {props.direction}
                        </Text>
                        <Text style={styles.titleText} variant={"titleMedium"}>
                            {props.title}
                        </Text>
                    </View>
                    <ImageBackground style={styles.image} source={props.image}/>
                </LinearGradient>
            </TouchableRipple>
        </Surface>
}

interface ObjectIndicatorProps {
    direction: string,
    title: string,
    image: any,
    style?: StyleProp<ViewStyle>,
    onPress: () => void;
}

const styles = StyleSheet.create({
    surface: {
        borderRadius: 12,
        marginBottom: 24,
        backgroundColor: CombinedTheme.colors.lightSurface
    },
    wrapper: {
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: 12,
    },
    textContainer: {
        marginTop: 24,
        marginLeft: 16,
        flexShrink: 1,
        paddingRight: 16
    },
    directionText: {
    },
    titleText: {
        marginTop: 8,
        marginBottom: 16
    },
    image: {
        borderBottomRightRadius: 12,
        borderTopRightRadius: 12,
        overflow: "hidden",
        width: 80,
        height: "100%"
    }
});
