import * as React from "react"
import Svg, {SvgProps, Path} from "react-native-svg"
import {memo} from "react"


const CheckIcon = (props: SvgProps) => (
    <Svg width="12" height="10" viewBox="0 0 12 10" fill="none" {...props}>
        <Path d="M4 9.4L0 5.4L1.4 4L4 6.6L10.6 0L12 1.4L4 9.4Z" fill="#F1BC00"/>
    </Svg>
)

const Memo = memo(CheckIcon)
export default Memo
