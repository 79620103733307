import { View, StyleSheet, StyleProp, ViewProps, ViewStyle } from "react-native";
import { Text } from "react-native-paper";
import Icon from "react-native-paper/src/components/Icon";

export default function UnorderedList(props: { items: string[], style?: StyleProp<ViewStyle> }) {
    return <View style={[props.style]}>
        {props.items.map((value, i) => <View key={i} style={styles.container}>
            <Text variant="bodyMedium" style={styles.number}><Icon source="circle-medium" size={16} /></Text>
            <Text variant="bodyMedium" style={styles.text}>{value}</Text>
        </View>)}
    </View>
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        marginBottom: 3,
    },
    number: {
        paddingTop: 2,
        width: 16,
        textAlign: "left",
        fontSize: 13
    },
    text: {
        flex: 1
    }
})