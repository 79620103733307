import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
import { memo } from "react"

const PlayButton = (props: SvgProps) => (
  <Svg
    width={48}
    height={48}
    viewBox="0 0 32 32"
    {...props}
  >
    <Path
      fill="#F3C32A"
      d="M16 0c8.8 0 16 7.2 16 16s-7.2 16-16 16S0 24.8 0 16 7.2 0 16 0z"
    />
    <Path fill="#1C1B1F" d="M11.1 24V8l13.7 8-13.7 8z" />
  </Svg>
)

const Memo = memo(PlayButton)
export default Memo
