import { useEffect, useState } from "react";
import * as FileSystem from 'expo-file-system';
import { DownloadResumable } from "expo-file-system";
import { useIsFocused } from "@react-navigation/native";
import { Platform } from "react-native";

const targetFilePath = FileSystem.cacheDirectory + (Platform.OS === "android" ? "palac-podlaska.glb" : "palac-podlaska.usdz");
let downloadResumable: DownloadResumable | undefined;
const url = Platform.OS === "android" ? "https://dolinakrzny.bialapodlaska.pl/palacpodlaska.glb" : "https://dolinakrzny.bialapodlaska.pl/model-Podlaska14-31.usdz";

export default function useArModelPath() {
    const [modelPath, setModelPath] = useState<string>();
    const isFocused = useIsFocused();
    const [progress, setProgress] = useState(0);

    const getDownloadResumable = () => {
        return FileSystem.createDownloadResumable(url, targetFilePath, {  }, p => { setProgress(100 * p.totalBytesWritten / p.totalBytesExpectedToWrite)});
    };

    useEffect(() => {

        if (!isFocused) {
            return;
        }

        FileSystem.getInfoAsync(targetFilePath).then(info => {

            if (info.exists) {
                console.log("file exists, skipping", info);
                setModelPath(targetFilePath);

                return;
            }

            if (downloadResumable) {
                console.log("resuming");
                downloadResumable.resumeAsync().then(() => setModelPath(targetFilePath)).catch(() => {
                    console.log("resume failed, trying to download again");
                    downloadResumable = getDownloadResumable();
                    downloadResumable.downloadAsync().then(() => setModelPath(targetFilePath));
                });
            } else {
                console.log("starting download");
                downloadResumable = getDownloadResumable();
                downloadResumable.downloadAsync().then(() => setModelPath(targetFilePath));
            }
        })

        return () => {
            downloadResumable?.pauseAsync();
            console.log("paused", downloadResumable);
        }
    }, [isFocused]);

    return { modelPath, progress };
}