import * as React from 'react';
import {StyleSheet} from 'react-native';
import { Text } from 'react-native-paper';
import AudioInvoker from "../../components/audioPlayer/audioInvoker";
import { AudioPlaylistEntry } from "../../contexts/audioContext";
import FullWidthImage from "../../components/fullWidthImage/fullWidthImage";
import OrderedList from "../../components/orderedList/orderedList";
import { CombinedTheme } from "../../styles/theme";
import DidYouKnowTile from "../../components/didYouKnow/didYouKnowTile";
import EducationBoardPageBase from "../../components/educationBoardPageBase/educationBoardPageBase";
import UnorderedList from '../../components/orderedList/unorderedList';

export default function EducationBoard10() {
    return <EducationBoardPageBase boardNumber={10}>
            <EducationBoardContent />
    </EducationBoardPageBase>
}

const playlist: AudioPlaylistEntry[] = [{
    title: "Tablica edukacyjna nr 10",
    subtitle: "Zaproś ptaki do ogrodu",
    source: require("../../assets/boards/10/1.mp3")
}, {
    title: "Tablica edukacyjna nr 10",
    subtitle: "Rośliny sprzyjające ptakom",
    source: require("../../assets/boards/10/2.mp3")
}, {
    title: "Tablica edukacyjna nr 10",
    subtitle: "Jak pomóc ptakom?",
    source: require("../../assets/boards/10/3.mp3")
}, {
    title: "Tablica edukacyjna nr 10",
    subtitle: "Czy wiesz, że?",
    source: require("../../assets/boards/10/4.mp3")
}];

function EducationBoardContent() {
    return <>
        <AudioInvoker index={0} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Co zrobić, aby Twój ogród stał się ptasią ostoją? Zapewnij ptakom dostęp do nasion, owoców, owadów, a także dobrych kryjówek. 
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Jeśli w Twoim ogrodzie naturalnie rośnie oset, łopian czy pokrzywa, nie walcz z tymi „chwastami”! W trakcie koszenia zostaw choćby niewielki łan wysokich roślin zielnych — ściągniesz do ogrodu ptaki żywiące się ich nasionami, takie jak szczygły. Posadź też kilka drzew lub krzewów owocowych, które staną się naturalną stołówką dla wielu ptaków, np. zięby, gila czy jemiołuszki. Nie zapominaj o roślinach miododajnych, które przyciągną owady — ulubiony pokarm sikorki, rudzika, kosa (więcej informacji na ten temat znajdziesz na tablicy obok). W ogrodzie przyjaznym ptakom nie powinno też zabraknąć gęstych krzewów i pnączy, w których ptaki mogą się chować i zakładać gniazda.
        </Text>
        <AudioInvoker index={1} playlist={playlist} />
        <Text variant="bodyMedium" style={styles.heading}>
        Przykłady roślin sprzyjających ptakom to:
        </Text>
        <Text variant="titleMedium" style={styles.heading}>
        Drzewa
        </Text>
        <UnorderedList items={[
            "Cis pospolity",
            "Dąb szypułkowy",
            "Jarząb pospolity",
            "Olsza czarna",
            "Wierzba",
            "Drzewa owocowe: wiśnie, czereśnie, rajskie jabłonie"
        ]} style={{ marginBottom: 13 }}/>
        <Text variant="titleMedium" style={styles.heading}>
        Krzewy
        </Text>
        <UnorderedList items={[
            "Bez czarny",
            "Bukszpan zwyczajny",
            "Dereń jadalny",
            "Głóg dwuszyjkowy",
            "Jałowiec pospolity",
            "Kalina koralowa",
            "Róża dzika",
            "Typowe krzewy owocowe, np.: malina, jeżyna, porzeczka"
        ]} style={{ marginBottom: 13 }}/>
        <Text variant="titleMedium" style={styles.heading}>
        Pnącza
        </Text>
        <UnorderedList items={[
            "Bluszcz pospolity",
            "Powojnik",
            "Wiciokrzew pomorski",
            "Winobluszcz",
            "Winorośl"
        ]} style={{ marginBottom: 13 }}/>
        <Text variant="titleMedium" style={styles.heading}>
        Rośliny zielne
        </Text>
        <UnorderedList items={[
            "Dziurawiec zwyczajny",
            "Krwawnik pospolity",
            "Łopian",
            "Oset",
            "Rdest ptasi",
            "Wyka ptasia"
        ]} style={{ marginBottom: 13 }}/>
        <AudioInvoker index={2} playlist={playlist} />
        <Text variant="bodyMedium" style={styles.text}>
        Dodatkowo, aby pomóc ptakom, możesz powiesić budki lęgowe, a także zadbać o stały dostęp do wody. Prosty wodopój zrobisz z płytkiego naczynia wypełnionego wodą do wysokości 2–3 cm. Ustaw je w cieniu i regularnie wymieniaj wodę. Jeśli włożysz do naczynia kilka kamieni albo kawałek drewna, wodopój będzie mógł służyć także owadom
        </Text>
        <FullWidthImage source={require("../../assets/boards/10/ogrody.png")} style={{ marginTop: 8, marginBottom: 0 }} />
        <Text variant="labelSmall" style={styles.photoLabel}>
        rys. Zofia Sobolewska
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Koszony często trawnik i rząd iglaków nie zapewniają wystarczającej ilości kryjówek i pokarmu. Zróżnicowany ogród z udziałem wyższych i niższych roślin zielnych, gęstych krzewów, drzew owocowych będzie dobrym schronieniem dla ptaków.
        </Text>
        <AudioInvoker index={3} playlist={playlist} />
        <DidYouKnowTile title={"Czy wiesz, że?"}
                        description={"Jemiołuszka to przelotny ptak z północy, którego można spotkać w Polsce od listopada do kwietnia. Jemiołuszki często obsiadają drzewa owocowe w grupach liczących od kilkunastu do kilkudziesięciu osobników. Lubią większość drobnych owoców takich jak jagody głogu, rokitnika czy tarniny, jednak ich największym przysmakiem są owoce jarzębu pospolitego."}
                        image={require("../../assets/boards/10/jemioluszki.png")}
                        imageLabel={"rys. Malwina Łazęcka-Malińska "}/>
    </>
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 12
    },
    text: {
        marginBottom: 16
    },
    photoLabel: {
        color: CombinedTheme.colors.neutral60,
        marginBottom: 24,
        marginTop: 8
    }
});