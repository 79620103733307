import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
import { memo } from "react"
import { CombinedTheme } from "../../styles/theme"

const ArrowsIn = (props: SvgProps) => (
  <Svg
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.22 23.78a.752.752 0 0 0 1.064 0l6.164-6.164v4.165a.752.752 0 1 0 1.505 0V15.8a.752.752 0 0 0-.753-.753H2.22a.752.752 0 1 0 0 1.505h4.165L.22 22.716a.752.752 0 0 0 0 1.064ZM23.78.22a.752.752 0 0 0-1.064 0l-6.164 6.164V2.219a.752.752 0 0 0-1.505 0V8.2a.752.752 0 0 0 .753.753h5.981a.752.752 0 1 0 0-1.505h-4.165l6.164-6.164a.752.752 0 0 0 0-1.064Z"
      fill={CombinedTheme.colors.tertiary}
    />
  </Svg>
)

const Memo = memo(ArrowsIn)
export default Memo
