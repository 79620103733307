import React, {useEffect, useState} from "react";
import {Modal, View} from "react-native";
import {StyleSheet} from "react-native";
import QuizInitContent from "../components/quizModalContents/quizInitContent";
import QuizQuestion, {shuffleArray} from "../components/quizModalContents/quizQuestion";
import {QuizAnswer, quizQuestions, QuizQuestionType} from "../data/quizQuestions";
import QuizFinishContent from "../components/quizModalContents/quizFinishContent";
import AsyncStorage from "@react-native-async-storage/async-storage";

export interface QuizAnswerResult {
    title: string,
    answer: QuizAnswer,
    image: number | undefined
}

export default function QuizModal(props: QuizModalProps) {

    const [quizPhase, setQuizPhase] = useState<number>(0);
    const [currentQuestion, setCurrentQuestion] = useState<number>(0);
    const [points, setPoints] = useState<number>(0);
    const [questions, setQuestions] = useState<QuizQuestionType[]>([]);
    const [userAnswers, setUserAnswers] = useState<QuizAnswerResult[]>([]);

    const storeScore = async (score: number) => {
        try {
            const value = await AsyncStorage.getItem('@topScore')
            if (value !== null) {
                if (score > +value) {
                    await AsyncStorage.setItem('@topScore', score + "")
                    console.log(score);
                }
            } else {
                await AsyncStorage.setItem('@topScore', score + "")
                console.log(score);
            }
        } catch (e) {
            // error reading value
        }

    }

    const nextQuestion = (answer: string) => {
        setPoints(points + +(answer === "correct"))
        if (currentQuestion >= 9) {
            setQuizPhase(2)
            storeScore(points + +(answer === "correct"));
        } else {
            setCurrentQuestion(currentQuestion + 1);
        }
        const questionObject = quizQuestions[currentQuestion];

        const userAnswer = {
            title: questionObject.title,
            image: questionObject.image,
            answer: questionObject.answers.filter(q => q.value === answer)[0]
        };

        setUserAnswers([...userAnswers, userAnswer]);
    }

    useEffect(() => {
        setQuestions(shuffleArray(quizQuestions).slice(0, 10));
        setQuizPhase(0);
        setPoints(0);
        setCurrentQuestion(0);
        setUserAnswers([]);
    }, [props.modalVisible])

    return <Modal
        animationType="fade"
        transparent={true}
        visible={props.modalVisible}
        onRequestClose={() => {
            props.setModalVisible(!props.modalVisible);
        }}
    >
        <View style={styles.modalBackground}>
            {quizPhase === 0 && <QuizInitContent setPhase={setQuizPhase} {...props}/>}
            {quizPhase === 1 && <QuizQuestion questionNumber={currentQuestion} onNextQuestionPress={nextQuestion}
                                              question={questions[currentQuestion]}
                                              setPhase={setQuizPhase} {...props}/>}
            {quizPhase === 2 && <QuizFinishContent userAnswers={userAnswers} points={points} {...props}/>}
        </View>
    </Modal>
}

const styles = StyleSheet.create({
    modalBackground: {
        height: "100%",
        width: "100%",
        backgroundColor: "#00000080",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
});

export interface QuizModalProps {
    modalVisible: boolean,
    setModalVisible: any,
}
