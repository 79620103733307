import * as React from 'react';
import {StyleSheet} from 'react-native';
import { Text } from 'react-native-paper';
import AudioInvoker from "../../components/audioPlayer/audioInvoker";
import { AudioPlaylistEntry } from "../../contexts/audioContext";
import { CombinedTheme } from "../../styles/theme";
import DidYouKnowTile from "../../components/didYouKnow/didYouKnowTile";
import EducationBoardPageBase from "../../components/educationBoardPageBase/educationBoardPageBase";
import BirdAudioInvoker from '../../components/audioPlayer/birdAudioInvoker';

export default function EducationBoard17() {
    return <EducationBoardPageBase boardNumber={17}>
            <EducationBoardContent />
    </EducationBoardPageBase>
}

const playlist: AudioPlaylistEntry[] = [{
    title: "Tablica edukacyjna nr 17",
    subtitle: "Ptaki stawów i szuwarów",
    source: require("../../assets/boards/17/1.mp3")
}, {
    title: "Tablica edukacyjna nr 17",
    subtitle: "Zimorodek",
    source: require("../../assets/boards/17/1.1.mp3")
}, {
    title: "Tablica edukacyjna nr 17",
    subtitle: "Remiz",
    source: require("../../assets/boards/17/1.2.mp3")
}, {
    title: "Tablica edukacyjna nr 17",
    subtitle: "Łyska",
    source: require("../../assets/boards/17/1.3.mp3")
}, {
    title: "Tablica edukacyjna nr 17",
    subtitle: "Krzyżówka",
    source: require("../../assets/boards/17/1.4.mp3")
}, {
    title: "Tablica edukacyjna nr 17",
    subtitle: "Czapla",
    source: require("../../assets/boards/17/1.5.mp3")
}, {
    title: "Tablica edukacyjna nr 17",
    subtitle: "Śmieszka",
    source: require("../../assets/boards/17/1.6.mp3")
}, {
    title: "Tablica edukacyjna nr 17",
    subtitle: "Czy wiesz, że?",
    source: require("../../assets/boards/17/2.mp3")
}, {
    title: "Tablica edukacyjna nr 17",
    subtitle: "Czy wiesz, że?",
    source: require("../../assets/boards/17/3.mp3")
}];

function EducationBoardContent() {
    return <>
        <AudioInvoker index={0} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Witamy na stawach w dolinie Klukówki.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        To teren o dużych walorach przyrodniczych. Najcenniejszy jest zarastający staw północny, przy którym się znajdujesz. Porośnięty bujną roślinnością szuwarową, jest wspaniałą kryjówką dla wielu gatunków ptaków wodno-błotnych. Niektóre gatunki ptaków, które możesz tu obserwować, przedstawiamy na rysunkach obok.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Czatownia umożliwia obserwacje z ukrycia bez niepokojenia ptaków. Prosimy o zachowanie ciszy.
        </Text>
        <Text variant="titleMedium" style={styles.heading}>
        Miłego wypatrywania!
        </Text>
        <BirdAudioInvoker index={1} playlist={playlist} image={require("../../assets/boards/17/zimorodek.png")} />
        <BirdAudioInvoker index={2} playlist={playlist} image={require("../../assets/boards/17/remiz.png")} />
        <BirdAudioInvoker index={3} playlist={playlist} image={require("../../assets/boards/17/lyska.png")} />
        <BirdAudioInvoker index={4} playlist={playlist} image={require("../../assets/boards/17/krzyzowka.png")} />
        <BirdAudioInvoker index={5} playlist={playlist} image={require("../../assets/boards/17/czapla.png")} />
        <BirdAudioInvoker index={6} playlist={playlist} image={require("../../assets/boards/17/smieszka.png")} />
        <AudioInvoker index={7} playlist={playlist} />
        <DidYouKnowTile title={"Czy wiesz, że?"}
                        alternativeColors={true}
                        description={"Czapla siwa to ptak zbliżony wielkością do bociana. Łatwo ją rozpoznać po biało-szarym upierzeniu, esowato wygiętej szyi i ozdobnym czarnym piórom na głowie, tworzącym charakterystyczny czub. Zazwyczaj czaple spędzają dzień na samotnym polowaniu, brodząc w płytkiej wodzie. Wieczorem wracają do kolonii zwanej czaplińcem, gdzie może się znajdować nawet kilkaset gniazd."}
                        image={require("../../assets/boards/17/czapla.png")}/>
        <AudioInvoker index={8} playlist={playlist} />
        <DidYouKnowTile title={"Czy wiesz, że?"}
                        description={"Zimorodek to jeden z najbarwniej upierzonych polskich ptaków. Mimo jego pięknych, intensywnych kolorów, niełatwo go dostrzec. Zimorodek jest bardzo szybki i zwinny, a większość czasu spędza, wypatrując z ukrycia zdobyczy. Żywi się rybami i wodnymi owadami. W polowaniu i nurkowaniu pomaga mu wyjątkowo duży (jak na tak małego ptaka) dziób."}
                        image={require("../../assets/boards/17/zimorodek.png")}/>
        <Text variant="labelSmall" style={styles.photoLabel}>
        rys. Malwina Łazęcka-Malińska
        </Text>
    </>
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 12
    },
    text: {
        marginBottom: 16
    },
    photoLabel: {
        color: CombinedTheme.colors.neutral60,
        marginTop: 16
    }
});