import * as React from 'react';
import {StyleSheet} from 'react-native';
import { Text } from 'react-native-paper';
import AudioInvoker from "../../components/audioPlayer/audioInvoker";
import { AudioPlaylistEntry } from "../../contexts/audioContext";
import FullWidthImage from "../../components/fullWidthImage/fullWidthImage";
import OrderedList from "../../components/orderedList/orderedList";
import { CombinedTheme } from "../../styles/theme";
import DidYouKnowTile from "../../components/didYouKnow/didYouKnowTile";
import EducationBoardPageBase from "../../components/educationBoardPageBase/educationBoardPageBase";

export default function EducationBoard13() {
    return <EducationBoardPageBase boardNumber={13}>
            <EducationBoardContent />
    </EducationBoardPageBase>
}

const playlist: AudioPlaylistEntry[] = [{
    title: "Tablica edukacyjna nr 13",
    subtitle: "Zaprzyjaźnij się z bobrem",
    source: require("../../assets/boards/13/1.mp3")
}, {
    title: "Tablica edukacyjna nr 13",
    subtitle: "Mistrzowie wielu dyscyplin",
    source: require("../../assets/boards/13/2.mp3")
}, {
    title: "Tablica edukacyjna nr 13",
    subtitle: "Człowiek i bóbr",
    source: require("../../assets/boards/13/3.mp3")
}];

function EducationBoardContent() {
    return <>
        <AudioInvoker index={0} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Podgryzione drzewa, które można zobaczyć nad Krzną, to nieomylny znak obecności bobrów.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Te największe polskie gryzonie żyją w rzekach, starorzeczach, kanałach i jeziorach. Są zwierzętami o unikatowych zdolnościach budowlanych. Podobnie jak ludzie, potrafią przekształcać krajobraz na wielką skalę i dostosowywać środowisko do swoich potrzeb. Za pomocą tam bobry zatrzymują wodę i tworzą rozlewiska, w których mogą swobodnie pływać, budować żeremia i gromadzić zapasy. Są w nich niewidoczne dla ewentualnych drapieżników.
        </Text>
        <AudioInvoker index={1} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Mistrzowie wielu dyscyplin
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        W swoich konstrukcjach bobry stosują tak wyrafinowane urządzenia, jak stopnie, podobne do naszych schodów, czy kanały wentylacyjne. Niezwykła jest też ich sprawność w ścinaniu drzew, nawet bardzo grubych (do metra średnicy). Bobry większość czasu spędzają w wodzie, więc to świetni pływacy, przystosowani do długiego nurkowania. Pod wodą serce bobra zwalnia, oczy są chronione specjalną przezroczystą powieką, a pysk, uszy i nozdrza zatykane fałdkami skóry. Bobry mają także rozwinięty język — używają mowy ciała, dotknięć i dźwięków. Sygnałem ostrzegającym przed drapieżnikami jest klaskanie ogonem o lustro wody.
        </Text>
        <FullWidthImage source={require("../../assets/boards/13/bobr.png")} style={{ marginTop: 8 }} />
        <Text variant="labelSmall" style={styles.photoLabel}>
        rys. Zofia Sobolewska
        </Text>
        <AudioInvoker index={2} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Człowiek i bóbr
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Przez stulecia ludzie polowali na bobry dla ich mięsa i futra. Populacja bobrów w Polsce, a także w całej Europie, stawała się coraz mniejsza, aż w końcu, w połowie XX w. bobry były na skraju wymarcia. Dzięki wytrwałej pracy naukowców nad hodowlą i reintrodukcją bobrów gatunek udało się odbudować. Dziś bobry mają się dość dobrze, choć — niestety — są często traktowane przez ludzi jak szkodniki. W Polsce notuje się przypadki burzenia ich tam czy nawet zabijania zwierząt.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Bobrowa działalność rzeczywiście może powodować pewne straty — zdarza się, że bobry podtapiają uprawy albo niszczą wały przeciwpowodziowe. Musimy jednak pamiętać, że w ogólnym rozrachunku praca bobrów przynosi nam ogromne długoterminowe korzyści. Mówiąc w skrócie, bobry naprawiają to, co człowiek popsuł nadmierną regulacją wód i osuszaniem bagien. Tworzone przez nie rozlewiska i mokradła to nie tylko dzikie ostoje, ale też naturalne systemy małej retencji, zapobiegające powodziom i suszom. Tamy bobrowe mają też właściwości podobne do filtrów, dzięki czemu woda opuszczająca rozlewisko jest czystsza i bardziej klarowna. Bobry skutecznie renaturyzują rzeki i są naszymi sojusznikami w walce ze zmianami klimatu.
        </Text>
        
        <DidYouKnowTile title={"Czy wiesz, że?"}
                        style={{ marginTop: 16 }}
                        description={"Żeremie to dom bobrów zbudowany z gałęzi i mułu, otoczony wodą. Mieszka tu grupa od czterech do dziesięciu zwierząt - rodziców i do dwóch pokoleń potomstwa. Obok znajduje się zimowy magazyn pożywienia - zazwyczają są to wierzbowe lub topolowe gałęzie (bobry żywią się ich korą i łykiem). Położona niżej tama tworzy rozlewisko. Woda utrzymuje się tu na wysokim poziomie, a bobry mogą łatwo dostać się do nowych drzew i ścinać je bezpośrednio do wody, co umożliwia ich dalszy transport."}
                        image={require("../../assets/boards/13/zeremie.png")}/>
        <DidYouKnowTile title={"Czy wiesz, że?"}
                        style={{ marginTop: 24 }}
                        alternativeColors={true}
                        description={"Działalność bobrów przynosi nam więcej korzyści niż szkód. Ze szkodami możemy sobie poradzić. Istnieje wiele sposobów zapobiegania zniszczeniom: drzewa, brzegi stawów czy wały przeciwpowodziowe możemy zabezpieczać siatką (1), przepusty drogowe - kratą lub ogrodzeniem. W miejscach, gdzie nadmierne podtopienia byłyby wyjątkowo niekorzystne, stosuje się rury umożliwiające przepływ wody przez tamę (2)."}
                        image={require("../../assets/boards/13/tama.png")}/>
    </>
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 12
    },
    text: {
        marginBottom: 16
    },
    photoLabel: {
        color: CombinedTheme.colors.neutral60,
        marginBottom: 24,
        marginTop: 16
    }
});