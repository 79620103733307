import React, {useEffect, useState} from "react"
import {LayoutChangeEvent, Platform, StyleProp, useWindowDimensions, View, ViewProps, ViewStyle} from "react-native";
import {ActivityIndicator, Divider} from "react-native-paper"
import {TabBar, TabView} from "react-native-tab-view"
import {CombinedTheme} from "../../styles/theme";

function AbpTabBar(props: any) {
    return <>
        <TabBar
            {...props}
            style={{backgroundColor: "transparent"}}
            activeColor={CombinedTheme.colors.primary}
            inactiveColor={CombinedTheme.colors.onSurfaceVariant}
            tabStyle={{padding: 0}}
            getLabelText={scene => scene.route.key}
            pressColor={"transparent"}
            labelStyle={{textTransform: "none", fontFamily: "Inter_600SemiBold"}}
            indicatorStyle={{
                backgroundColor: CombinedTheme.colors.primary,
                height: 3,
                width: Platform.OS === "web" ? "30%" : "29%",
                marginLeft: Platform.OS === "web" ? "10%" : "8%"
            }}
        />
        <Divider style={{backgroundColor: CombinedTheme.colors.surfaceVariant}}/>
    </>;
}

export default function AbpTabView(props: AbpTabViewProps) {
    const [index, setIndex] = React.useState(0);
    const [routes, setRoutes] = React.useState(Object.keys(props.tabs).map(t => ({key: t, title: t})));
    const [height, setHeight] = useState<number | string | undefined>("auto");
    const [sceneHeights, setSceneHeights] = useState<{ [name: string]: number | undefined }>({});
    const dimensions = useWindowDimensions();

    useEffect(() => {
        setRoutes(Object.keys(props.tabs).map(t => ({key: t, title: t})));
    }, [props.tabs]);

    const renderScene = ({route}: { route: { key: string } }) => {
        return React.createElement(props.tabs[route.key], {
            onLayout: (event: LayoutChangeEvent) => {

                if (routes[index].key !== route.key) {
                    return;
                }

                const elementHeight = event.nativeEvent.layout.height;

                if (elementHeight < 50) {
                    return;
                }

                const newHeights = {...sceneHeights};
                newHeights[route.key] = elementHeight;
                setSceneHeights(newHeights);
            }
        });
    }

    const onIndexChange = (i: number) => {
        setHeight("auto");
        setIndex(i);
    }

    useEffect(() => {
        setHeight("auto");
    }, [dimensions]);

    useEffect(() => {
        setHeight(sceneHeights[routes[index].key] || "auto");
    }, [index, sceneHeights]);

    return <View style={{flex: 1, width: "100%"}}>
        <TabView
            lazy
            renderLazyPlaceholder={() => <ActivityIndicator/>}
            style={[{marginHorizontal: dimensions.width > 960 ? 0 : -24, overflow:"hidden"}, props.style]}
            sceneContainerStyle={{height}}
            navigationState={{index, routes}}
            renderTabBar={AbpTabBar}
            renderScene={renderScene as any}
            swipeEnabled={false}
            onIndexChange={onIndexChange}
        />
    </View>
}

interface AbpTabViewProps {
    tabs: { [name: string]: (props: ViewProps) => JSX.Element },
    style?: StyleProp<ViewStyle>
}
