import * as React from 'react';
import {StyleSheet} from 'react-native';
import { Text } from 'react-native-paper';
import AudioInvoker from "../../components/audioPlayer/audioInvoker";
import { AudioPlaylistEntry } from "../../contexts/audioContext";
import FullWidthImage from "../../components/fullWidthImage/fullWidthImage";
import OrderedList from "../../components/orderedList/orderedList";
import { CombinedTheme } from "../../styles/theme";
import DidYouKnowTile from "../../components/didYouKnow/didYouKnowTile";
import EducationBoardPageBase from "../../components/educationBoardPageBase/educationBoardPageBase";
import TreeCard from '../../components/treeCard/treeCard';

export default function EducationBoard16() {
    return <EducationBoardPageBase boardNumber={16}>
            <EducationBoardContent />
    </EducationBoardPageBase>
}

const playlist: AudioPlaylistEntry[] = [{
    title: "Tablica edukacyjna nr 16",
    subtitle: "Czerwończyk fioletek i inne motyle",
    source: require("../../assets/boards/16/1.mp3")
}];

function EducationBoardContent() {
    return <>
        <AudioInvoker index={0} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        W tej okolicy w 2010 r. przyrodnicy zaobserwowali kilkanaście osobników rzadkiego motyla ‒ czerwończyka fioletka.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Między innymi dla ochrony tego właśnie owada powołano obszar Natura 2000 „Dolina Krzny” na południe od wsi Kijowiec (ok. 17 km stąd). Ten ściśle chroniony motyl upodobał sobie mokre łąki w dolinach rzecznych. Aby się rozwijać, potrzebuje rośliny żywicielskiej — rdestu wężownika lub rdestu żyworodnego, których liśćmi żywi się, będąc jeszcze w stadium gąsienicy. Dorosłe motyle stosują szerszą dietę — oprócz nektarów wierzby, jaskra, gęsiówki i niezapominajki nie gardzą także... padliną. 
        </Text>
        <FullWidthImage source={require("../../assets/boards/16/rdest.png")} style={{ marginBottom: 16 }} />
        <Text variant="bodySmall" style={styles.text}>
            Rdest wężownik - roślina żywicielska czerwończyka fioletka.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Motyl ten zawdzięcza swoją nazwę fioletowemu połyskowi plam i cętek na pomarańczowych skrzydłach. W zależności od płci motyla, a nawet pokolenia w roku, proporcje tych kolorów znacznie się różnią, przy czym największy połysk mają samce, szczególnie wiosenne. Czerwończyk fioletek jest zagrożony wyginięciem — liczebność jego światowej populacji stale spada. Siedliska tego motyla wymagają ochrony czynnej: utrzymania łąk przez rzadkie koszenie lub ekstensywny wypas. Zagrożeniem dla czerwończyka jest zarówno intensywne używanie łąki (zbyt częste koszenie lub wypas), jak i samoistne zarastanie łąk nieużytkowanych. 
        </Text>
        <TreeCard title="Czerwończyk fioletek" subtitle="Lycaena helle" image={require("../../assets/boards/16/fioletek.png")} />
        <Text variant="titleMedium" style={[styles.heading, { marginTop: 8 }]}>
        Na okolicznych łąkach i nieużytkach możesz spotkać także inne, bardziej pospolite motyle:
        </Text>
        <TreeCard title="Rusałka pawik" subtitle="Aglais io" image={require("../../assets/boards/16/pawik.png")} />
        <TreeCard title="Rusałka osetnik" subtitle="Vanessa cardui" image={require("../../assets/boards/16/osetnik.png")} />
        <TreeCard title="Rusałka admirał" subtitle="Vanessa atalanta" image={require("../../assets/boards/16/admiral.png")} />      
        <Text variant="labelSmall" style={styles.photoLabel}>
        rys. Zofia Sobolewska
        </Text>  
    </>
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 12
    },
    text: {
        marginBottom: 16
    },
    photoLabel: {
        color: CombinedTheme.colors.neutral60,
        marginBottom: 0,
        marginTop: 4
    }
});