import Layout from "../components/layout/layout";
import {ContactFormCategory} from "../api";
import {RouteProp, useRoute} from "@react-navigation/native";
import { QuizAnswerResult } from "./quizModal";
import { View, StyleSheet, Image } from "react-native";
import { Text } from "react-native-paper";
import Icon from "react-native-paper/src/components/Icon";
import { CombinedTheme } from "../styles/theme";

export default function QuizSummaryPage(){

    const { params } = useRoute<RouteProp<ParamList, 'QuizSummary'>>();

    const answers = params.answers as unknown as QuizAnswerResult[];

    return <Layout title={"Twoje odpowiedzi"} subtitle={"Sprawdź poprawność swoich odpowiedzi"}>
        {answers.map((a, i) => <View key={i} style={styles.responseContainer}>
            <Text variant="titleSmall" style={{ marginBottom: 8 }}>{i + 1}. {a.title}</Text>
            {a.image && <Image style={styles.imageWrapper} source={a.image}/>}
            <Text variant="labelLarge" style={styles.answer}>
                {a.answer.value === "correct" ? <Icon source="check" size={14} color={CombinedTheme.colors.primary10} /> : <Icon source="close" size={14} color={CombinedTheme.colors.primary10} />}
                {" " + a.answer.answer}
            </Text>
        </View>)}
    </Layout>
}


type ParamList = {
    QuizSummary: {
        answers: string[];
    };
};

const styles = StyleSheet.create({
    responseContainer: {
        marginTop: 32
    },
    answer: {
        marginTop: 8
    },
    imageWrapper: {
        resizeMode: "contain",
        height: 100,
        width: "100%"   
    }
});