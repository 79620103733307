import * as React from 'react';
import {StyleSheet} from 'react-native';
import { Text } from 'react-native-paper';
import AudioInvoker from "../../components/audioPlayer/audioInvoker";
import { AudioPlaylistEntry } from "../../contexts/audioContext";
import FullWidthImage from "../../components/fullWidthImage/fullWidthImage";
import OrderedList from "../../components/orderedList/orderedList";
import { CombinedTheme } from "../../styles/theme";
import DidYouKnowTile from "../../components/didYouKnow/didYouKnowTile";
import EducationBoardPageBase from "../../components/educationBoardPageBase/educationBoardPageBase";

export default function EducationBoard8() {
    return <EducationBoardPageBase boardNumber={8}>
            <EducationBoardContent />
    </EducationBoardPageBase>
}

const playlist: AudioPlaylistEntry[] = [{
    title: "Tablica edukacyjna nr 8",
    subtitle: "O czym szumią wierzby?",
    source: require("../../assets/boards/8/1.mp3")
}, {
    title: "Tablica edukacyjna nr 8",
    subtitle: "Wierzba w twoim ogrodzie",
    source: require("../../assets/boards/8/2.mp3")
}, {
    title: "Tablica edukacyjna nr 8",
    subtitle: "Czy wiesz, że?",
    source: require("../../assets/boards/8/3.mp3")
}];

function EducationBoardContent() {
    return <>
        <AudioInvoker index={0} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Wędrując Szlakiem Doliny Krzny, z pewnością dostrzegłeś nad rzeką niejedną wierzbę. To niezwykłe drzewo można uznać za symbol polskiego krajobrazu.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        W Polsce rośnie prawie trzydzieści gatunków wierzby, z czego najczęściej spotykane są wierzba biała, wierzba krucha czy wierzba iwa. Wierzba purpurowa (wiklina) i wierzba wiciowa (konopianka) to popularne gatunki krzewiaste. Wierzby to rośliny pionierskie, dobrze czujące się w wilgotnych miejscach. Są wytrzymałe i radzą sobie nawet w najtrudniejszych warunkach. Na wiele sposobów przyczyniają się do poprawy stanu środowiska. Jako jedne z najwcześniej kwitnących drzew, wierzby są ważnym źródłem pokarmu dla owadów zapylających: pszczół i trzmieli. Pomagają także w rekultywacji gleby — sadzi się je między innymi na terenach zdegradowanych w wyniku  oczyszczania ścieków.
        </Text>
        <AudioInvoker index={1} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Wierzba w twoim ogrodzie
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Wierzba to wspaniała roślina do ogrodu. Posadzona w formie krzewu lub drzewa zazieleni się już wczesną wiosną i jako jedna z ostatnich zrzuci liście jesienią. Z formowanych żywych pędów możemy stworzyć zielone ogrodzenie lub altanę. Gałązki wierzby mogą nam posłużyć do budowy plecionych płotów, tradycyjnie stosowanych w wielu regionach Polski. Sprawdzą się one jako ogrodzenie grządek, umocnienie brzegów czy skarp.
        </Text>
        <FullWidthImage source={require("../../assets/boards/8/altana.jpg")} style={{ marginTop: 8, marginBottom: 24 }} />
        <Text variant="labelSmall" style={styles.photoLabel}>
        fot. Żywa Architektura | www.zywaarchitektura.pl
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Wierzbowe pędy przyjmują się w każdej glebie i szybko rosną.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        Jeśli masz dostęp do krzewów wierzby, w stosunkowo prosty sposób możesz wykonać „żywy płot”. Jak to zrobić? Na ziemi wytycz linię ogrodzenia. Do wykonania konstrukcji najlepsze będą świeżo ścięte gałęzie wierzby wiciowej, które jeszcze nie wypuściły pączków. Gałęzie powinny mieć ok. 2 m. Posadź je w wykopanym rowku. Pędy możesz umieszczać w ziemi pionowo lub pod kątem. Łączenia wykonaj, wiążąc pędy sznurkiem. Po posadzeniu regularnie podlewaj. Gdy wierzba się ukorzeni i zazieleni, podlewanie nie jest konieczne (chyba że panuje susza). Jesienią możesz poprawić konstrukcję, tak aby przybrała pożądany kształt, a nowe gałązki dopleść do starych lub poprzycinać. Podobną metodą, przy użyciu dłuższych pędów, wykonasz altanę lub pergolę.
        </Text>
        <FullWidthImage source={require("../../assets/boards/8/zywoplot.png")} style={{ marginTop: 8, marginBottom: 24 }} accessibilityLabel="Ilustracja - sadzenie żywego płotu wierzbowego" />
        <Text variant="labelSmall" style={styles.photoLabel}>
        rys. Zofia Sobolewska
        </Text>
        <AudioInvoker index={2} playlist={playlist} />
        <DidYouKnowTile title={"Czy wiesz, że?"}
                        description={[
                            "Wierzba „głowiasta” to nie odrębny gatunek, ale forma wierzby białej lub wierzby kruchej, ukształtowana ręką człowieka. Swój charakterystyczny dla krajobrazu Mazowsza i części Podlasia kształt drzewo to zawdzięcza ogławianiu, czyli regularnemu ścinaniu wierzbowych gałęzi. Wierzba jest jedynym drzewem, które znosi takie zabiegi, przy czym nie pozostaje to dla niej obojętne – wierzby ogłowione żyją krócej niż te w stanie naturalnym.",
                            "Ogławianie było kiedyś sposobem na regularne pozyskiwanie opału i gałęzi do produkcji płotów. Obecnie ta tradycja zamiera. Co ciekawe, niektórzy przyrodnicy opowiadają się za podtrzymaniem tego obyczaju. Obfitująca w dziuple i szczeliny wierzbowa „głowa” jest bowiem miejscem gniazdowania wielu gatunków ptaków. Dziuple wierzby upodobał sobie także rzadki chrząszcz, pachnica dębowa, relikt dawnych puszcz. Owad ten świetnie się czuje w starych próchniejących drzewach."
                        ]}
                        image={require("../../assets/boards/8/wierzbaglowiasta.png")}
                        descriptionBoldAfterImage="Wierzbę głowiastą spotkamy naczęściej przy rzekach, kanałach lub wzdłuż polnych dróg." />
    </>
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 12
    },
    text: {
        marginBottom: 16
    },
    photoLabel: {
        color: CombinedTheme.colors.neutral60,
        marginBottom: 24,
        marginTop: 0
    }
});