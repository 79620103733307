import React, { useState } from "react";
import { ImageSourcePropType, StyleSheet, TouchableOpacity, Image } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import Icon from "react-native-paper/src/components/Icon";
import { AudioPlaylistEntry } from "../../contexts/audioContext";
import useAudioPlayer from "../../hooks/useAudioPlayer";
import { CombinedTheme } from "../../styles/theme";

const playIcon = <Icon source="play-circle" size={40} color={CombinedTheme.colors.primary10} />;

const pauseIcon = <Icon source="pause-circle" size={40} color={CombinedTheme.colors.primary10} />;

export default function BirdAudioInvoker(props: BirdAudioInvokerProps) {

    const {
        current,
        play,
        playbackStatus,
        pause,
        playlist
    } = useAudioPlayer();

    const [isLoading, setIsLoading] = useState<boolean>();

    const onPlayButtonPress = async () => {
        setIsLoading(true);

        try {
            await play(props.index, props.playlist);
        } finally {
            setIsLoading(false);
        }
    }

    const buttonState = (() => {

        if (!current || playlist !== props.playlist || current.index !== props.index) {
            return PlaybackState.Paused;
        }
        
        if (isLoading || (playbackStatus?.isLoaded && playbackStatus.isBuffering && playbackStatus.shouldPlay)) {
            return PlaybackState.Loading;
        }

        if (!playbackStatus?.isLoaded || !playbackStatus?.isPlaying) {
            return PlaybackState.Paused;
        }

        if (playbackStatus.isPlaying) {
            return PlaybackState.Playing;
        }
    })();

    const onPress = async () => {
        if (buttonState === PlaybackState.Paused) {
            await onPlayButtonPress();

            return;
        }

        await pause();
    }

    return <TouchableOpacity style={styles.container} disabled={buttonState === PlaybackState.Loading} onPress={onPress}>
        <Image source={props.image} style={styles.image} />
        <Text variant="titleMedium" style={styles.label}>{props.playlist[props.index].subtitle}</Text>
        {buttonState === PlaybackState.Loading
        ? <ActivityIndicator size={40} color={CombinedTheme.colors.primary10} />
        : buttonState === PlaybackState.Playing ? pauseIcon : playIcon}
    </TouchableOpacity>
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: CombinedTheme.colors.outlineVariant,
        borderRadius: 12,
        marginVertical: 8,
        alignItems: "center",
        paddingRight: 22
    },
    label: {
        paddingLeft: 24,
        flex: 1
    },
    image: {
        resizeMode: "contain",
        borderRightColor: CombinedTheme.colors.outlineVariant,
        borderRightWidth: 1,
        height: "100%",
        minHeight: 80,
        width: 112,
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: 12,
    }
});

enum PlaybackState {
    Playing,
    Paused,
    Loading
}

interface BirdAudioInvokerProps {
    playlist: AudioPlaylistEntry[],
    index: number,
    image: ImageSourcePropType
}
