import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
import { memo } from "react"

const Next = (props: SvgProps) => (
  <Svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M20 20h-3.333V0H20v20Zm-5.833-10L0 0v20l14.167-10Z"
      fill="#40342A"
    />
  </Svg>
)

const Memo = memo(Next)
export default Memo
