import { StatusBar } from "expo-status-bar";
import React from "react";
import { SafeAreaView } from "react-native";
import ARViewContainer from "../components/arView/arViewContainer";

export default function ARPage() {
    return <SafeAreaView style={{ flex: 1 }}>
        <ARViewContainer />
        <StatusBar style="auto" />
    </SafeAreaView>
}