import * as React from "react"
import Svg, {SvgProps, Path, Circle} from "react-native-svg"
import {memo} from "react"


const quizBadgeIcon = (props: SvgProps) => (
    <Svg {...props} width="174" height="117" viewBox="0 0 174 117" fill="none">
        <Path d="M67.5 115.5V79.5H109V115.5L90.5 103L67.5 115.5Z" fill="#FBFAF8"/>
        <Path d="M67.5 115.5V79.5H109V115.5L90.5 103L67.5 115.5Z" stroke="#1C1B1F"/>
        <Path d="M67.5 115.5V79.5H109V115.5L90.5 103L67.5 115.5Z" stroke="#D0BCFF" strokeOpacity="0.05"/>
        <Circle cx="88" cy="44" r="40.5" fill="#FBFAF8"/>
        <Circle cx="88" cy="44" r="40.5" stroke="#1C1B1F"/>
        <Circle cx="88" cy="44" r="40.5" stroke="#D0BCFF" strokeOpacity="0.05"/>
        <Circle cx="88" cy="44" r="28" fill="#F8DD85"/>
        <Circle cx="144.5" cy="9.5" r="2.5" fill="#F1BC00"/>
        <Circle cx="3" cy="42" r="3" fill="#F1BC00"/>
        <Circle cx="34" cy="65" r="2" fill="#1C1B1F"/>
        <Circle cx="34" cy="65" r="2" fill="#D0BCFF" fillOpacity="0.05"/>
        <Path d="M24.7956 0.552921L32.144 7.90139L22.1058 10.5911L24.7956 0.552921Z" fill="#1C1B1F"/>
        <Path d="M24.7956 0.552921L32.144 7.90139L22.1058 10.5911L24.7956 0.552921Z" fill="#D0BCFF" fillOpacity="0.05"/>
        <Path d="M171.418 28.2941L173.659 36.6593L165.294 34.4178L171.418 28.2941Z" fill="#1C1B1F"/>
        <Path d="M171.418 28.2941L173.659 36.6593L165.294 34.4178L171.418 28.2941Z" fill="#D0BCFF" fillOpacity="0.05"/>
        <Circle cx="147" cy="53" r="2" fill="#1C1B1F"/>
        <Circle cx="147" cy="53" r="2" fill="#D0BCFF" fillOpacity="0.05"/>
    </Svg>


)

const Memo = memo(quizBadgeIcon)
export default Memo
