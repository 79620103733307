import { ViewProps } from "react-native";

export default function ARView(props: ARViewProps) {
    return <></>
}

interface ARViewProps extends ViewProps {
    anchorX: number,
    anchorY: number,
    anchorZ: number,
    anchorScale: number,
    anchorRotation: number,
    onAnchorCreated?: () => any,
    onARError?: (event: { nativeEvent: { message: string }}) => any,
    onModelLoading?: (event: { nativeEvent: { loading: boolean } }) => any
}