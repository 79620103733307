import React from "react";
import { ImageSourcePropType } from "react-native";
import { Surface, Text } from "react-native-paper";
import FullWidthImage from "../fullWidthImage/fullWidthImage";

export default function TreeCard(props: TreeCardProps) {
    return <Surface style={{ borderRadius: 12, padding: 12, marginTop: 12, marginBottom: 12 }}>
        <Text variant="headlineMedium">{props.title}</Text>
        <Text variant="labelMedium" style={{ marginTop: 4 }}>{props.subtitle}</Text>
        <FullWidthImage source={props.image} />
        {props.description?.map((d, i) => <Text key={i} variant="labelLarge" style={{ padding: 8 }}>
            {d}
        </Text>)}
    </Surface>
}

interface TreeCardProps {
    title: string,
    subtitle: string,
    image: ImageSourcePropType,
    description?: string[]
}