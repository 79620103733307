import React, { useState } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import Icon from "react-native-paper/src/components/Icon";
import { AudioPlaylistEntry } from "../../contexts/audioContext";
import useAudioPlayer from "../../hooks/useAudioPlayer";
import { CombinedTheme } from "../../styles/theme";

const playIcon = <Icon source="play-circle" size={18} color={CombinedTheme.colors.primary10} />;

const pauseIcon = <Icon source="pause-circle" size={18} color={CombinedTheme.colors.primary10} />;

export default function AudioInvoker(props: AudioInvokerProps) {

    const {
        current,
        play,
        playbackStatus,
        pause,
        playlist
    } = useAudioPlayer();

    const [isLoading, setIsLoading] = useState<boolean>();

    const onPlayButtonPress = async () => {
        setIsLoading(true);

        try {
            await play(props.index, props.playlist);
        } finally {
            setIsLoading(false);
        }
    }

    const buttonState = (() => {

        if (!current || playlist !== props.playlist || current.index !== props.index) {
            return PlaybackState.Paused;
        }
        
        if (isLoading || (playbackStatus?.isLoaded && playbackStatus.isBuffering && playbackStatus.shouldPlay)) {
            return PlaybackState.Loading;
        }

        if (!playbackStatus?.isLoaded || !playbackStatus?.isPlaying) {
            return PlaybackState.Paused;
        }

        if (playbackStatus.isPlaying) {
            return PlaybackState.Playing;
        }
    })();

    const onPress = async () => {
        if (buttonState === PlaybackState.Paused) {
            await onPlayButtonPress();

            return;
        }

        await pause();
    }

    return <TouchableOpacity style={styles.container} disabled={buttonState === PlaybackState.Loading} onPress={onPress}>
        <Text variant="labelLarge" style={styles.label}>Odsłuchaj audio</Text>
        {buttonState === PlaybackState.Loading
        ? <ActivityIndicator size={18} color={CombinedTheme.colors.primary10} />
        : buttonState === PlaybackState.Playing ? pauseIcon : playIcon}
    </TouchableOpacity>
}

const styles = StyleSheet.create({
    container: {
        alignSelf: "flex-start",
        flexDirection: "row",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: CombinedTheme.colors.primary,
        borderRadius: 100,
        paddingVertical: 10,
        paddingHorizontal: 16,
        alignItems: "center",
        marginBottom: 16,
        marginTop: 24
    },
    buttonContent: {
        flexDirection: "row-reverse",
    },
    label: {
        color: CombinedTheme.colors.primary10,
        paddingRight: 8
    }
});

enum PlaybackState {
    Playing,
    Paused,
    Loading
}

interface AudioInvokerProps {
    playlist: AudioPlaylistEntry[],
    index: number
}
