import Constants from "expo-constants";
import { useCallback, useEffect, useState } from "react";
import { Configuration } from "../api";
import { BaseAPI } from "../api/base";

export function getApi<T extends BaseAPI>(c: new (config: Configuration) => T) {
    const apiUrl = Constants.expoConfig!.extra!.apiBaseUrl;

    return new c(new Configuration({ basePath: apiUrl }));
}

export default function useApi<T extends BaseAPI>(c: new (config: Configuration) => T): T {

    const getApiCallback = useCallback(() => {
        return getApi(c);
    }, [c]);

    const [api, setApi] = useState<T>(() => getApiCallback());

    useEffect(() => {
        setApi(getApiCallback());
    }, [getApiCallback]);

    return api;
}   