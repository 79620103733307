import * as React from "react";
import { ActivityIndicator, Text } from "react-native-paper";
import { Platform, StyleSheet, View } from "react-native";
import { useRoute } from "@react-navigation/native";
import Layout from "../layout/layout";
import ImageCarousel from "../imageCarousel/ImageCarousel";
import moment from "moment";
import WebView, { WebViewMessageEvent } from "react-native-webview";
import PlayerPage from "../../pages/playerPage";
import useEvents from "../../hooks/useEvents";
import Constants from "expo-constants";
import { useState } from "react";

export default function NewsTileDetails() {
    const apiUrl = Constants.expoConfig!.extra!.apiBaseUrl;
    const [height, setHeight] = useState(400);

    const getImageUrl = (id: number) => {
        return apiUrl + `/api/File/${id}/FileStream`;
    }

    const route = useRoute();
    const newsId = (route.params as any).id;
    const item = useEvents()?.filter(e => e.id == newsId)[0];

    if (!item) {
        return <ActivityIndicator />
    }

    const firstParagraph = item.description!.split("\n")[0];

    const onMessage = (event: WebViewMessageEvent) => {
        setHeight(Number(event.nativeEvent.data))
    }

    const injectedJs = `
        const iframe = document.getElementsByTagName('iframe')[0];
        const vid = iframe.contentWindow.document.getElementsByTagName('video')[0];
        vid.addEventListener('play', () => {
            setTimeout(() => {
                window.ReactNativeWebView.postMessage(iframe.scrollHeight);
            }, 500);
        })
    `;

    return <Layout title={"Wydarzenie"} subtitle={item.name!}>
        <View style={{ marginHorizontal: -24, marginVertical: 24 }}>
            <ImageCarousel images={item.eventPhotosIds!.map(id => ({ uri: getImageUrl(id)}))} visited={false}
                           showGradient={true} />
        </View>
        <Text variant="labelLarge">{moment(item.date).format("yyyy-MM-DD HH:mm")}</Text>
        <Text variant="titleMedium" style={styles.firstParagraph}>{firstParagraph}</Text>
        <Text variant="bodyMedium">{item.description?.replace(firstParagraph, "")}</Text>
        
        <Text>{item.streamUrl}</Text>

        {item.streamUrl ?
            <>
                <Text variant="titleLarge" style={{ marginTop: 20 }}>Kamera na żywo</Text>

                {Platform.OS == "web" ?
                    <div>
                        <PlayerPage url={item.streamUrl} />
                    </div>
                :
                    <WebView style={{ flex: 1, height: height }}
                             originWhitelist={["*"]}
                             onMessage={onMessage}
                             injectedJavaScript={injectedJs}
                             source={{ uri: `${Constants.expoConfig?.extra!.webBaseUrl}/player?url=${item.streamUrl}` }}>
                    </WebView>
                }
            </>
        : null}
    </Layout>
}

const styles = StyleSheet.create({
    firstParagraph: {
        marginBottom: 12,
        marginTop: 12
    }
})
