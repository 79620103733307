import * as React from "react"
import Svg, {SvgProps, Path} from "react-native-svg"
import {memo} from "react"


const LengthIcon = (props: SvgProps) => (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
        <Path
            d="M3.5 14.5C4.32843 14.5 5 13.8284 5 13C5 12.1716 4.32843 11.5 3.5 11.5C2.67157 11.5 2 12.1716 2 13C2 13.8284 2.67157 14.5 3.5 14.5Z"
            stroke="#3C2F03" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <Path
            d="M12.5 4C13.3284 4 14 3.32843 14 2.5C14 1.67157 13.3284 1 12.5 1C11.6716 1 11 1.67157 11 2.5C11 3.32843 11.6716 4 12.5 4Z"
            stroke="#3C2F03" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <Path
            d="M8 13H11.375C12.0712 13 12.7389 12.7234 13.2312 12.2312C13.7234 11.7389 14 11.0712 14 10.375C14 9.67881 13.7234 9.01113 13.2312 8.51884C12.7389 8.02656 12.0712 7.75 11.375 7.75H5.375C4.67881 7.75 4.01113 7.47344 3.51884 6.98116C3.02656 6.48887 2.75 5.82119 2.75 5.125C2.75 4.42881 3.02656 3.76113 3.51884 3.26884C4.01113 2.77656 4.67881 2.5 5.375 2.5H8"
            stroke="#3C2F03" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>

)

const Memo = memo(LengthIcon)
export default Memo
