import { Alert, StyleSheet, View } from "react-native";
import React from "react";
import * as Location from 'expo-location';
import { Button, Text } from "react-native-paper";
import FullHeightLayout from "../../components/layout/fullHeightLayout";
import Svg, { Circle } from "react-native-svg";
import { useNavigation } from "@react-navigation/native";
import IconGps from "../../components/icons/iconGps";
import { CombinedTheme } from "../../styles/theme";
import useArModelPath from "../../hooks/useArModelPath";

export default function CheckGpsPage() {
    const navigation = useNavigation();
    // trigger downloading ar model
    // const path = useArModelPath();

    const requestLocationPermission = async () => {
        try {
            let response = await Location.requestForegroundPermissionsAsync();
            if (response.status === 'granted') {
                Location.getCurrentPositionAsync();
                navigation.navigate("Ar-CheckLocation" as never);
            } else {
                Alert.alert("Błąd lokalizacji", "Ta funkcjonalność nie jest dostępna bez dostępu do danych o lokalizacji urządenia",
                    [{ text: "Powrót", onPress: () => {}}]);
            }
        } catch (err) {
            Alert.alert("Nieznany błąd", "Proszę spróbować ponownie później",
                [{ text: "Powrót", onPress: () => {}}]);
        }
    };

    const onNext = async () => {
        await requestLocationPermission();
    };

    const radius = 4;

    return <FullHeightLayout>
        <View style={{ justifyContent: "space-between", height: "100%" }}>
            <View style={{ justifyContent: "center", alignItems: "center", flex: 1 }}>
                <IconGps />
                <Text variant="bodyLarge" style={{ marginVertical: 28 }}>
                    GPS
                </Text>
                <Text variant="headlineSmall" style={{ textAlign: "center" }}>
                    Sprawdź czy masz włączony GPS w telefonie
                </Text>
            </View>
            <View>
                <Button mode="contained" onPress={onNext}>
                    Dalej
                </Button>
                <View style={{ flexDirection: "row", marginVertical: 30, alignSelf: "center" }}>
                    <Svg style={styles.dotActive} height={2 * radius} width={2 * radius}>
                        <Circle cx={radius} cy={radius} r={radius} />
                    </Svg>
                    <Svg style={styles.dot} height={2 * radius} width={2 * radius}>
                        <Circle cx={radius} cy={radius} r={radius} />
                    </Svg>
                    <Svg style={styles.dot} height={2 * radius} width={2 * radius}>
                        <Circle cx={radius} cy={radius} r={radius} />
                    </Svg>
                </View>
            </View>
        </View>
    </FullHeightLayout>
}

const styles = StyleSheet.create({
    dot: {
        marginHorizontal: 8,
        fill: CombinedTheme.colors.onSurfaceVariant
    },
    dotActive: {
        marginHorizontal: 8,
        fill: CombinedTheme.colors.primary
    }
})
