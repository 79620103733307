import {Surface, TouchableRipple} from "react-native-paper";
import {StyleSheet, TouchableOpacity, View} from "react-native";
import {Text} from "react-native-paper"
import ContactArrow from "../icons/contactArrow";
import {useNavigation, useRoute} from "@react-navigation/native";
import {ContactFormCategory} from "../../api";

export default function ContactUsCategoryTile(props: ContactUsTileProps) {

    const navigation = useNavigation();

    const onPress = () => {
        navigation.navigate('ContactForm' as never, {
            category: props.category,
        } as never)
    }

    return <Surface style={styles.tileWrapper} elevation={1}>
        <TouchableRipple onPress={onPress} style={{ zIndex: 1, borderRadius: 12 }} borderless={true}>
            <View style={styles.content}>
                <Text variant={"bodyMedium"}>{props.categoryName}</Text>
                <ContactArrow/>
            </View>
        </TouchableRipple>
    </Surface>
}

interface ContactUsTileProps {
    categoryName: string,
    category: ContactFormCategory
}

const styles = StyleSheet.create({
    tileWrapper: {
        height: 56,
        width: "100%",
        backgroundColor: "#FFFFFF",
        borderRadius: 12,
        marginBottom: 16
    },
    content: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingHorizontal: 24,
        paddingVertical: 18
    }
})
