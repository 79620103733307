import * as React from 'react';
import {StyleSheet} from 'react-native';
import { Text } from 'react-native-paper';
import AudioInvoker from "../../components/audioPlayer/audioInvoker";
import { AudioPlaylistEntry } from "../../contexts/audioContext";
import FullWidthImage from "../../components/fullWidthImage/fullWidthImage";
import OrderedList from "../../components/orderedList/orderedList";
import { CombinedTheme } from "../../styles/theme";
import DidYouKnowTile from "../../components/didYouKnow/didYouKnowTile";
import EducationBoardPageBase from "../../components/educationBoardPageBase/educationBoardPageBase";
import MuseumLogo from '../../components/icons/museumLogo';

export default function EducationBoard12() {
    return <EducationBoardPageBase boardNumber={12}>
            <EducationBoardContent />
    </EducationBoardPageBase>
}

const playlist: AudioPlaylistEntry[] = [{
    title: "Tablica edukacyjna nr 12",
    subtitle: "Bialska kolejka wąskotorowa",
    source: require("../../assets/boards/12/1.mp3")
}, {
    title: "Tablica edukacyjna nr 12",
    subtitle: "Cytat - A. Królik - „Moje dzieciństwo na ciuchci\"",
    source: require("../../assets/boards/12/2.mp3")
}, {
    title: "Tablica edukacyjna nr 12",
    subtitle: "Zakończenie",
    source: require("../../assets/boards/12/3.mp3")
}];

function EducationBoardContent() {
    return <>
        <AudioInvoker index={0} playlist={playlist} />
        <Text variant="titleMedium" style={styles.heading}>
        Przed Tobą nieczynny most kolejowy na Krznie. Zbudowany został w latach 50 – tych XX wieku. Kiedyś jeździła tędy bialska kolejka wąskotorowa.
        </Text>
        <Text variant="bodyMedium" style={styles.text}>
        W 1915 r. wojska niemieckie zajęły południowe Podlasie. Krótko potem, aby wywozić stąd znaczne ilości drewna i produktów rolnych, okupanci wybudowali pierwszą linię kolei wąskotorowej na trasie: Biała Podlaska — Stasinów. Następnie dobudowano odgałęzienie do miejscowości Klonownica Duża. Po odzyskaniu niepodległości kolejka przeszła pod zarząd polskich kolejarzy i została ponownie rozbudowana: uruchomiono tory do Konstantynowa i Janowa Podlaskiego. Z inicjatywy barona Stanisława Rosenwertha powstała także linia do Cieleśnicy, która obsługiwała znajdujące się w jego majątku olejarnię, młyn, gorzelnię i elektrownię. Długość całej trasy wynosiła 57 km. W dwudziestoleciu międzywojennym towarowa kolejka zaczęła być wykorzystywana do przewozów osobowych. W czasach, gdy głównymi środkami lokomocji były wóz konny i własne nogi, było to niemałe udogodnienie dla mieszkańców.
        </Text>
        <FullWidthImage source={require("../../assets/boards/12/mapa.png")} style={{ marginTop: 4, marginBottom: 8 }} />
        <Text variant="bodyMedium" style={styles.text}>
        W trakcie II wojny światowej, pod okupacją niemiecką, wąskotorówka była ponownie używana do rabunkowej eksploatacji podlaskich lasów i pól. Po wojnie kolejka szybko wróciła do normalnego funkcjonowania. Do Białej ściągnięto nowe parowozy, dokonano też niezbędnych usprawnień — m.in. zbudowano nowy most na Krznie, przy którym właśnie stoisz. Kolejka cieszyła się popularnością. Jak wspomina świadek tamtych czasów, Andrzej Królik, w swojej książce „Moje dzieciństwo na ciuchci”:
        </Text>
        <AudioInvoker index={1} playlist={playlist} />
        <Text variant="bodyMedium" style={styles.quote}>
        „W latach pięćdziesiątych kolejka wąskotorowa w Białej Podlaskiej była środkiem transportu, który [...] nie miał na naszym terenie konkurencji. Transport samochodowy dopiero się organizował, samochodów ciężarowych było jak na lekarstwo. Przewoziła więc „ciuchcia” bardzo dużo towarów w obie strony jak i pasażerów. Do Białej Podlaskiej wiozła kredę z pokładów w Kornicy, zboże, ziemniaki, buraki cukrowe, cykorię, słomę lnianą, żywiec, drewno z okolicznych lasów i wyroby wytwórni win i likierów w Cieleśnicy. [...] Ponadto jechał surowy spirytus w stalowych beczkach o pojemności 300 litrów i kiszone ogórki z przeznaczeniem na cały kraj. W kierunku Konstantynowa i Janowa Podlaskiego jechały zaś wszystkie towary stanowiące zaopatrzenie Gminnych Spółdzielni [ówczesnych sklepów]. Znaczący udział w przewozach stanowiły przewozy pasażerskie. [...] Szczególnie latem powodzenie miały wyjazdy „na grzyby” do lasów Kijowca czy Rokitna. [...] Z „ciuchci” żartowano, ale była ona [...] bardzo lubiana. Krążyła anegdota, że z jadącej kolejki można było wysiąść, nazbierać grzybów i jeszcze wskoczyć do ostatniego wagonu.
        </Text>
        <Text variant="labelSmall" style={styles.photoLabel}>
        A. Królik, „Moje dzieciństwo na ciuchci", Biała Podlaska 2008, s. 53.
        </Text>
        <AudioInvoker index={2} playlist={playlist} />
        <Text variant="bodyMedium" style={styles.text}>
        Wraz z rozwojem motoryzacji bialska kolejka zaczęła tracić na znaczeniu. W latach 70. XX w., na mocy odgórnej decyzji, rozebrano ją jako nierentowną.
        </Text>
        <FullWidthImage source={require("../../assets/boards/12/foto2.jpg")} style={{ marginTop: 8 }} />
        <Text variant="labelMedium" style={styles.photoLabel}>
        Budowa kolejki w 1916 r. Prace nadzoruje niemiecki żołnierz. Tory układano w miarę wyrębu lasu.
        </Text>
        <FullWidthImage source={require("../../assets/boards/12/foto4.jpg")} />
        <Text variant="labelMedium" style={styles.photoLabel}>
        Stary drewniany most kolejowy na Krznie. Na jego miejscu w latach 50. XX w. zbudowano most stalowy, przy którym właśnie stoisz. Zdjęcie z 1923 r.
        </Text>
        <FullWidthImage source={require("../../assets/boards/12/foto3.jpg")} />
        <Text variant="labelMedium" style={styles.photoLabel}>
        Podróż bialską kolejką. Zdjęcie z 1944 r.
        </Text>
        <FullWidthImage source={require("../../assets/boards/12/foto.jpg")} />
        <Text variant="labelMedium" style={styles.photoLabel}>
        Stalowy most na Krznie krótko po wybudowaniu. Do samego końca bialska kolejka była zasilana przez lokomotywy parowe.
        </Text>
        <DidYouKnowTile title={"Muzeum Południowego Podlasia w Białej Podlaskiej"}
                        description="Tekst tablicy powstał przy współpracy z Muzeum Południowego Podlasia w Białej Podlaskiej"
                        customComponent={<MuseumLogo />}/>
        <Text variant="labelSmall" style={styles.photoLabel}>
        Źródło zdjęć: A. Królik, „Moje dzieciństwo na ciuchci", Biała Podlaska 2008.
        </Text>
    </>
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 12
    },
    text: {
        marginBottom: 16
    },
    photoLabel: {
        color: CombinedTheme.colors.neutral60,
        marginBottom: 24,
        marginTop: 8
    },
    quote: {
        paddingHorizontal: 24,
        paddingBottom: 2,
        fontFamily: "Lora_500Medium"
    }
});