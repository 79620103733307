import {View, StyleSheet, Image, StyleProp, ViewStyle} from "react-native";
import {LinearGradient} from "expo-linear-gradient";
import {Text} from "react-native-paper"
import FullWidthImage from "../fullWidthImage/fullWidthImage";

export default function DidYouKnowTile(props: DidYouKnowTileComponentProps) {

    const gradientColors = props.alternativeColors ? ["rgba(0, 144, 240, 0.12)", "rgba(0, 144, 240, 0.09)"] : ["rgba(241, 188, 0, 0.12)", "rgba(241, 188, 0, 0.09)"];

    const descriptions = props.description instanceof Array ? props.description : [props.description];
    const descriptionsAfterImage = props.descriptionAfterImage instanceof Array ? props.descriptionAfterImage : [props.descriptionAfterImage];

    return <LinearGradient style={[styles.tileWrapper, props.style]} colors={gradientColors}>
        <Text variant={"headlineSmall"}>{props.title}</Text>
        {props.skipDescriptionWrapper ? descriptions : descriptions.map((d, i) =>
            <Text key={i} style={{ marginTop: 16, marginBottom: (props.descriptionBold || props.image || props.customComponent || props.imageLabel) ? 24 : 0}} variant={"bodyMedium"}>
                {d}
            </Text>)}
        {props.descriptionBold && <Text variant={"titleMedium"}>{props.descriptionBold}</Text>}
        {props.image && <FullWidthImage style={styles.image} source={props.image}/>}
        {props.descriptionBoldAfterImage && <Text variant={"titleMedium"}>{props.descriptionBoldAfterImage}</Text>}
        {props.customComponent}
        {props.imageLabel && <Text variant={"labelSmall"}>{props.imageLabel}</Text>}
        {props.descriptionAfterImage && descriptionsAfterImage.map((d, i) =>
            <Text key={i} style={{ marginTop: 16, marginBottom: (props.descriptionBold || props.image || props.customComponent || props.imageLabel) ? 24 : 0}} variant={"bodyMedium"}>
                {d}
            </Text>)}
    </LinearGradient>
}

interface DidYouKnowTileComponentProps {
    title: string,
    description: string | string[] | JSX.Element | JSX.Element[],
    skipDescriptionWrapper?: boolean,
    descriptionAfterImage?: string | string[] | JSX.Element | JSX.Element[],
    descriptionBold?: string,
    descriptionBoldAfterImage?: string,
    image?: number,
    customComponent?: JSX.Element,
    imageLabel?: string;
    alternativeColors?: boolean;
    style?: StyleProp<ViewStyle>;
}

const styles = StyleSheet.create({
    tileWrapper: {
        padding: 24,
        borderRadius: 28
    },
    image: {
        marginBottom: 16,
        height: 257,
        width: "100%",
        resizeMode: "contain"
    }
})
