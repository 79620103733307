import { DefaultTheme } from "@react-navigation/native";
import { adaptNavigationTheme, MD3LightTheme } from "react-native-paper";

const { LightTheme } = adaptNavigationTheme({ light: DefaultTheme });

const colorOverrides = {
    primary: "#F1BC00",
    onPrimary: "#000000",
    surface: "#F4EFF4",
    surfaceVariant: "#C4C2C5",
    onSurface: "#313033",
    onSurfaceVariant: "#CAC4D0",
    outline: "#F1BC00",
    outlineVariant: "#B0ADB1",
    primary10: "#3C2F03",
    lightSurface: "#FBFAF8",
    neutral60: "#88848B",
    lightGray: "#FBFAF8",
    tertiary: "#40342A",
    secondaryContainer: "#FFFFFF",
    lightOnSurface: "#1C1B1F",
    lightOnSurfaceVariant: "#49454F"
};

const CombinedTheme = {
    ...MD3LightTheme,
    ...LightTheme,
    colors: {
        ...MD3LightTheme.colors,
        ...LightTheme.colors,
        ...colorOverrides
    },
    fonts: {
        ...MD3LightTheme.fonts,
        headlineSmall: {
            ...MD3LightTheme.fonts.headlineSmall,
            fontFamily: "Lora_500Medium"
        },
        headlineMedium: {
            ...MD3LightTheme.fonts.headlineMedium,
            fontFamily: "Lora_500Medium"
        },
        bodyLarge: {
            ...MD3LightTheme.fonts.bodyLarge,
            fontFamily: "Inter_400Regular"
        },
        bodyMedium: {
            ...MD3LightTheme.fonts.bodyMedium,
            fontFamily: "Inter_400Regular"
        },
        titleSmall: {
            ...MD3LightTheme.fonts.titleSmall,
            fontFamily: "Inter_600SemiBold"
        },
        titleMedium: {
            ...MD3LightTheme.fonts.titleMedium,
            fontFamily: "Inter_600SemiBold"
        },
        labelLarge: {
            ...MD3LightTheme.fonts.labelLarge,
            fontFamily: "Inter_500Medium"
        },
        labelSmall: {
            ...MD3LightTheme.fonts.labelSmall,
            fontFamily: "Inter_500Medium",
            color: colorOverrides.neutral60
        }
    }
}

export { CombinedTheme };
