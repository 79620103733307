import {useNavigation} from "@react-navigation/native";
import {View, StyleSheet, TouchableOpacity} from "react-native";
import {CombinedTheme} from "../styles/theme";
import {LinearGradient} from "expo-linear-gradient";
import Logo from "../components/icons/logo";
import React, {useEffect, useState} from "react";
import {Surface, Text, TouchableRipple} from "react-native-paper";

export default function MenuSider({height}: any) {
    const navigation = useNavigation();
    const [location, setLocation] = useState<string>("");

    useEffect(() => {
        setLocation(window.location.pathname);
    }, [window.location.pathname]);

    const onHomepage = () => {
        navigation.goBack();
        navigation.navigate("Home" as never);
    }

    const onAbout = () => {
        navigation.goBack();
        navigation.navigate("AboutTrail" as never);
    }

    const onContact = () => {
        navigation.goBack();
        navigation.navigate("ContactUsInitial" as never);
    }

    const onNews = () => {
        navigation.goBack();
        navigation.navigate("NewsPage" as never)
    }

    const homeSelected = location === "/home";
    const aboutSelected = location === "/about-trail" || location.startsWith("/board");
    const newsSelected = location.startsWith("/news");
    const contactSelected = location.startsWith("/contact");

    return <Surface elevation={1} style={[styles.container, {height: height, backgroundColor: "white"}]}>
            <LinearGradient colors={["rgba(241, 188, 0, 0.05)", "rgba(241, 188, 0, 0.05)"]}
                            style={[styles.container, {height: height}]}>
            <Logo width={90} height={90} style={styles.logo}/>
            <TouchableRipple onPress={onHomepage}>
                <View style={homeSelected ? styles.selectedItemWrapper : undefined}>
                    <View style={homeSelected ? styles.selectedItem : undefined}/>
                    <Text variant="headlineMedium" style={[styles.text, homeSelected ? styles.textSelected : null]}>Strona główna</Text>
                </View>
            </TouchableRipple>
            <TouchableRipple onPress={onAbout}>
                <View style={aboutSelected ? styles.selectedItemWrapper : undefined}>
                    <View style={aboutSelected ? styles.selectedItem : undefined}/>
                    <Text variant="headlineMedium" style={[styles.text, aboutSelected ? styles.textSelected : null]}>Szlak Doliny Krzny</Text>
                </View>
            </TouchableRipple>
            <TouchableRipple onPress={onNews}>
                <View style={newsSelected ? styles.selectedItemWrapper : undefined}>
                    <View style={newsSelected ? styles.selectedItem : undefined}/>
                    <Text variant="headlineMedium" style={[styles.text, newsSelected ? styles.textSelected : null]}>Wydarzenia</Text>
                </View>
            </TouchableRipple>
            <TouchableRipple style={styles.contactMenuItem} onPress={onContact}>
                <View style={contactSelected ? styles.selectedItemWrapper : undefined}>
                    <View style={contactSelected ? styles.selectedItem : undefined}/>
                    <Text variant="headlineMedium" style={[styles.text, contactSelected ? styles.textSelected : null]}>Kontakt</Text>
                </View>
            </TouchableRipple>
        </LinearGradient>
    </Surface>
}

const styles = StyleSheet.create({
    container: {
        position: "absolute",
        left: 0,
        width: 240
    },
    logo: {
        width: "100%",
        margin: 0,
        marginTop: 39,
        marginBottom: 43
    },
    text: {
        fontFamily: "Inter_600SemiBold",
        fontWeight: "600",
        fontSize: 16,
        lineHeight: 24,
        marginLeft: 24,
        padding: 10,
        color: CombinedTheme.colors.lightOnSurfaceVariant
    },
    textSelected: {
        color: CombinedTheme.colors.lightOnSurface
    },
    contactMenuItem: {
        position: "absolute",
        bottom: 43,
        width: 240
    },
    selectedItemWrapper: {
        height: 48,
        backgroundColor: "#FCEEC2",
        flex: 1,
        flexDirection: "row"
    },
    selectedItem: {
        width: 2,
        backgroundColor: CombinedTheme.colors.primary
    }
})
