import Layout from "../components/layout/layout";
import NewsTileList from "../components/newsTile/newsTileList";
import React from "react";
import { View } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import useEvents from "../hooks/useEvents";

export default function WhatsNewPage() {
    const events = useEvents();

    return <Layout title={"wydarzenia"} subtitle={"Co nowego"}>
        <View style={{ marginTop: 24 }}>
           {!events ? <ActivityIndicator /> : <NewsTileList tiles={events}/>}
        </View>
    </Layout>
}
