import * as React from "react"
import Svg, {SvgProps, Path} from "react-native-svg"
import {memo} from "react"


const NavigateIcon = (props: SvgProps) => (
    <Svg width="14" height="14" viewBox="0 0 14 14" {...props}>
        <Path d="M10.9525 3.0475L7.7725 10.645L6.7825 8.08L6.5425 7.4575L5.9275 7.2175L3.355 6.22L10.9525 3.0475ZM13.75 0.25L0.25 5.8975V6.625L5.38 8.62L7.375 13.75H8.095L13.75 0.25Z" fill="#3C2F03"/>
    </Svg>
)

const Memo = memo(NavigateIcon)
export default Memo
