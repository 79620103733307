import React from "react";
import {StyleSheet, View} from "react-native";
import {Text} from "react-native-paper";

export default function TagTile(props: TagTileProps) {
    return <View style={styles.tileWrapper}>
        {props.icon}
        <Text style={styles.text} variant={"labelSmall"}>{props.text}</Text>
    </View>
}

const styles = StyleSheet.create({
    tileWrapper: {
        display: "flex",
        flexDirection: "row",
        paddingVertical: 2,
        paddingHorizontal: 8,
        backgroundColor: "#FCEEC2",
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 100,
        borderColor: "#F7D467",
        color: "#3C2F03",
        marginRight: 8
    },
    text: {
        marginLeft: 6
    }
})

interface TagTileProps {
    icon: any,
    text: string;
}
