import * as React from "react"
import { memo } from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const IconLocation = (props: SvgProps) => (
    <Svg
        width={153}
        height={162}
        fill="none"
        {...props}
    >
        <Path
            d="M74.69 9.721H9.176A8.177 8.177 0 0 0 1 17.898v134.925A8.177 8.177 0 0 0 9.177 161h134.925a8.181 8.181 0 0 0 8.177-8.177V17.898a8.18 8.18 0 0 0-8.177-8.177h-20.231"
            fill="#FBFAF8"
        />
        <Path
            d="M74.69 9.721H9.176A8.177 8.177 0 0 0 1 17.898v134.925A8.177 8.177 0 0 0 9.177 161h134.925a8.181 8.181 0 0 0 8.177-8.177V17.898a8.18 8.18 0 0 0-8.177-8.177h-20.231"
            stroke="#1C1B1F"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M74.69 9.721H9.176A8.177 8.177 0 0 0 1 17.898v134.925A8.177 8.177 0 0 0 9.177 161h134.925a8.181 8.181 0 0 0 8.177-8.177V17.898a8.18 8.18 0 0 0-8.177-8.177h-20.231"
            stroke="#D0BCFF"
            strokeOpacity={0.05}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M99.282 1A39.063 39.063 0 0 0 60.22 40.063c0 30.574 29.9 67.421 37.644 76.457a2.366 2.366 0 0 0 2.797.6c.31-.146.585-.358.805-.621 7.613-9.06 36.879-45.882 36.879-76.432a39.05 39.05 0 0 0-11.44-27.624A39.064 39.064 0 0 0 99.282 1Zm0 53.663a14.596 14.596 0 1 1 0-29.192 14.596 14.596 0 0 1 0 29.192Zm21.474 35.023 31.523-4.857-31.523 4.857ZM1 108.142l81.037-12.49L1 108.142Zm50.732-7.817L36.44 9.721l15.292 90.604Z"
            fill="#F8DD85"
        />
        <Path
            d="m120.756 89.686 31.523-4.857M1 108.142l81.037-12.49m-30.305 4.673L36.44 9.721M99.282 1A39.063 39.063 0 0 0 60.22 40.063c0 30.574 29.9 67.421 37.644 76.457a2.366 2.366 0 0 0 2.797.6c.31-.146.585-.358.805-.621 7.613-9.06 36.879-45.882 36.879-76.432a39.051 39.051 0 0 0-11.44-27.624A39.064 39.064 0 0 0 99.282 1Zm0 53.663a14.596 14.596 0 1 1 0-29.192 14.596 14.596 0 0 1 0 29.192Z"
            stroke="#1C1B1F"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="m120.756 89.686 31.523-4.857M1 108.142l81.037-12.49m-30.305 4.673L36.44 9.721M99.282 1A39.063 39.063 0 0 0 60.22 40.063c0 30.574 29.9 67.421 37.644 76.457a2.366 2.366 0 0 0 2.797.6c.31-.146.585-.358.805-.621 7.613-9.06 36.879-45.882 36.879-76.432a39.051 39.051 0 0 0-11.44-27.624A39.064 39.064 0 0 0 99.282 1Zm0 53.663a14.596 14.596 0 1 1 0-29.192 14.596 14.596 0 0 1 0 29.192Z"
            stroke="#D0BCFF"
            strokeOpacity={0.05}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
)

const Memo = memo(IconLocation)
export default Memo
