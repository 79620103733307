import {Alert, Image, StyleSheet, TouchableOpacity, View} from "react-native";
import {QuizModalProps} from "../../pages/quizModal";
import {Button, Dialog, Portal, RadioButton, Text} from "react-native-paper";
import React, {useEffect, useState} from "react";
import {QuizAnswer, QuizQuestionType} from "../../data/quizQuestions";
import { CombinedTheme } from "../../styles/theme";

export function shuffleArray(array: any) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export default function QuizQuestion(props: QuizQuestionProps) {
    const [checked, setChecked] = useState('');
    const [answers, setAnswers] = useState<QuizAnswer[]>([]);
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);


    useEffect(() => {
        setChecked('');
        setAnswers(() => shuffleArray(props.question.answers))
    }, [props.question]);

    return <View style={styles.modalView}>
        <Text style={styles.title} variant={"titleMedium"}>pytanie nr {props.questionNumber + 1}</Text>
        <Text style={{ textAlign: "center" }} variant={"headlineSmall"}>{props.question.title}</Text>
        {props.question.image && <Image style={styles.imageWrapper} source={props.question.image}/>}
        <View style={styles.answers}>
            {answers.map((ans, idx) => {
                return <TouchableOpacity key={idx} onPress={() => {
                    setChecked(ans.value)
                }} style={styles.answerWrapper}>
                    <RadioButton.Android
                        onPress={() => setChecked(ans.value)}
                        uncheckedColor={"#49454F"}
                        value={ans.value}
                        status={checked === ans.value ? 'checked' : 'unchecked'}
                    />
                    <Text style={styles.answerLabel} variant={"bodyMedium"}>{ans.answer}</Text>
                </TouchableOpacity>
            })}
        </View>
        <View style={styles.buttonsWrapper}>
            <Button mode={"contained"} style={{ marginBottom: 16 }} disabled={!checked} onPress={() => props.onNextQuestionPress(checked)}>Następne pytanie</Button>
            <Button mode={"outlined"} onPress={() => setDialogVisible(true)}>
                <Text variant={"labelLarge"}>Zakończ quiz</Text>
            </Button>
        </View>
        <Dialog visible={dialogVisible} onDismiss={() => setDialogVisible(false)}>
            <Dialog.Title>Uwaga</Dialog.Title>
            <Dialog.Content>
                <Text>Czy na pewno chcesz zakończyć quiz?</Text>
                <View style={styles.dialogButtonsWrapper}>
                    <Button mode={"contained"} style={{ marginBottom: 16 }} onPress={() => setDialogVisible(false)}>Wróć do quizu</Button>
                    <Button mode={"outlined"} textColor={CombinedTheme.colors.onSurface} onPress={() => props.setModalVisible(false)}>Zakończ quiz</Button>
                </View>
            </Dialog.Content>
        </Dialog>
    </View>
}

const styles = StyleSheet.create({
    modalView: {
        zIndex: 100,
        marginHorizontal: 24,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 24,
        alignItems: "stretch",
        maxWidth: 600
    },
    answerLabel: {
        marginLeft: 8,
        flexWrap: "wrap",
        flexShrink: 1
    },
    answerWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        marginBottom: 8
    },
    answers: {
        marginHorizontal: 6,
        marginTop: 40,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 15,
    },
    title: {
        textAlign: "center",
        marginBottom: 16,
        marginTop: 6,
        fontWeight: "500",
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: 0.1,
        textTransform: "uppercase"
    },
    buttonsWrapper: {
        marginTop: 24,
        display: "flex",
        flexDirection: "column"
    },
    imageWrapper: {
        width: 294,
        height: 131,
        marginTop: 24,
        resizeMode: "contain"
    },
    dialogButtonsWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        marginTop: 32
    }
});

interface QuizQuestionProps {
    questionNumber: number,
    question: QuizQuestionType,
    modalVisible: boolean,
    setModalVisible: any,
    setPhase: any,
    onNextQuestionPress: (answer: string) => void;
}
