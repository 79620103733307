import { THREE, TextureLoader, Renderer } from 'expo-three';
import React, { useEffect, useRef, useState } from 'react';
import { ExpoWebGLRenderingContext, GLView } from 'expo-gl';
import { Gesture, GestureDetector, GestureHandlerRootView } from 'react-native-gesture-handler';
import { Platform } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { DeviceMotion } from 'expo-sensors';
import { Asset } from 'expo-asset';
import { resolveExoticAsync } from 'expo-exotic-asset';
import threeFont from 'three/examples/fonts/droid/droid_sans_regular.typeface.json';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { Mesh } from 'three';

const initialFov = 80;
const hotpointDistance = 90;
let defaultHotspotImage = Platform.OS !== "android" ? new TextureLoader().load(require("../../assets/hotspot.png")) : null;

const font = new FontLoader().parse(threeFont);

export type abpCamera = THREE.PerspectiveCamera & { target?: THREE.Vector3 };

export default function WebGLPanorama(props: WebGLPanoramaProps) {
    const raycaster = new THREE.Raycaster();
    const [currentSceneImage, setCurrentSceneImage] = useState<any>(props.image);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSceneInitializing, setIsSceneInitializing] = useState<boolean>(true);
    const camera = useRef<abpCamera>();
    const renderer = useRef<Renderer>();
    const dismounted = useRef<boolean>(false);
    const useSensors = useRef<boolean>(false);
    const cursorType = useRef<string>("default");
    const scene = useRef<THREE.Scene>();
    const mousePosition = useRef<{ x: number, y: number }>();

    const layoutWidth = useRef(1);
    const layoutHeight = useRef(1);

    const isUserInteracting = useRef<boolean>(false);
    const cameraPosition = useRef({
        lon: 0,
        lat: 0,
        phi: 0,
        theta: 0,
        rotation: 0,
        alpha: 0,
        beta: 0,
        gamma: 0
    });

    const updateCameraTarget = (lat: number, lon: number) => {
        let phi = cameraPosition.current.phi;
        let theta = cameraPosition.current.theta;
        lat = cameraPosition.current.lat = Math.max(-75, Math.min(85, lat));
        phi = cameraPosition.current.phi = THREE.MathUtils.degToRad(90 - lat);
        theta = cameraPosition.current.theta = THREE.MathUtils.degToRad(lon);

        camera.current!.target!.x = 500 * Math.sin(phi) * Math.cos(theta);
        camera.current!.target!.y = 500 * Math.cos(phi);
        camera.current!.target!.z = 500 * Math.sin(phi) * Math.sin(theta);
    }

    useEffect(() => {

        useSensors.current = props.useSensors;

        if (!props.useSensors) {
            cameraPosition.current.rotation = 0;

            return;
        }
        
        if (!isLoading) {
            updateCameraTarget(-90, 0);
        }

        DeviceMotion.setUpdateInterval(8);
        const sub = DeviceMotion.addListener(dm => {
            if (!dm.rotation) {
                return;
            }
            const { alpha, beta, gamma } = dm.rotation;

            cameraPosition.current!.alpha = alpha;
            cameraPosition.current!.beta = beta;
            cameraPosition.current!.gamma = gamma;
        });

        isUserInteracting.current = true;

        return () => sub.remove();
    }, [props.useSensors, isLoading]);

    useEffect(() => {
        if (!isSceneInitializing) {

            if (Platform.OS === "android") {
                
                const setHotspotImages = () => {
                    
                    const meshes = ((scene.current!.children.slice(1) as THREE.Mesh[]));
    
                    meshes.forEach(m => {
                        const material = new THREE.MeshBasicMaterial({
                            transparent: true,
                            opacity: .9
                        });
    
                        material.map = defaultHotspotImage;
                        material.map!.minFilter = THREE.LinearFilter;
                        material.needsUpdate = true;
    
                        m.material = material;
                    });
                }
    
                if (defaultHotspotImage) {
                    setHotspotImages();
                } else {
                    const hotspotAsset = resolveExoticAsync(require("../../assets/hotspot.png"));
        
                    hotspotAsset.then(a => {
                        defaultHotspotImage = new TextureLoader().load(a);
        
                        setHotspotImages();
                    });
                }
            }

            const asset = Asset.fromURI(props.image.uri);

            asset.downloadAsync().then(a => {

                const materialMap = new TextureLoader().load(a, () => setIsLoading(false), e => console.log("PROGRESS", e), e => console.log("ERROR", e));

                const mesh = ((scene.current!.children[0] as THREE.Mesh));

                const material = new THREE.MeshBasicMaterial();
                material.map = materialMap;
                material.map!.name = "photo";
                material.map!.minFilter = THREE.LinearFilter;
                material.needsUpdate = true;             
                
                mesh.material = material;
            });
        }
    }, [props.image, isSceneInitializing]);

    function getMeshWithImage() {
        const geometry = new THREE.SphereGeometry(500, 60, 40);
        geometry.scale(-1, 1, 1);

        const material = new THREE.MeshBasicMaterial();

        return new THREE.Mesh(geometry, material);
    }

    function getMeshWithHotspot(definition: Hotspot) {
        const plane = new THREE.PlaneGeometry(
            definition.width || 32,
            definition.height || 24,
            100, 100);

        const material = new THREE.MeshBasicMaterial({
            transparent: true,
            opacity: .9
        });

        if (defaultHotspotImage) {
            material.map = defaultHotspotImage;
            material.map.name = "photo";
            material.map.minFilter = THREE.LinearFilter;
        }

        const hotspot = new THREE.Mesh(plane, material);

        const { degreesHorizontal, degreesVertical } = definition.positionRelativeToCamera;
        const horizontalRadians = THREE.MathUtils.degToRad(degreesHorizontal);
        const verticalRadians = THREE.MathUtils.degToRad(degreesVertical);

        hotspot.position.set(
            hotpointDistance * Math.cos(horizontalRadians),
            hotpointDistance * Math.sin(verticalRadians),
            hotpointDistance * Math.sin(horizontalRadians)
        );

        const { x, y, z } = hotspot.position;

        // make hotpoint face the camera
        hotspot.rotateY(Math.atan2(x, z) + 3.14);
        hotspot.rotateX(Math.atan2(y, Math.sqrt(x*x + z*z)));

        hotspot.userData.isHotspot = true;
        hotspot.userData.onPress = definition.onPress;
        
        if (definition.description) {
            const textGeometry = new TextGeometry(definition.description, {
                font: font,
                size: 1.8,
                height: .2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: .1,
                bevelSize: .1,
                bevelOffset: 0,
                bevelSegments: 5
            });
    
            var textMaterial = new THREE.MeshPhongMaterial({
                color: 0xffffff,
                transparent: true,
                opacity: .8
            });
    
            const textMesh = new Mesh(textGeometry, textMaterial);
    
            textMesh.position.setX(-definition.description.length * 0.6);
            textMesh.position.setY(12);
    
            hotspot.add(textMesh);
        }

        return hotspot;
    }

    function getHotspotMeshes(definitions: Hotspot[]) {
        return definitions.map(getMeshWithHotspot);
    }

    function initAndGetScene() {

        camera.current = new THREE.PerspectiveCamera(initialFov, window.innerWidth / window.innerHeight, 1, 1100);
        camera.current.target = new THREE.Vector3(0, 0, 0);

        const scene = new THREE.Scene();
        scene.add(getMeshWithImage());

        const light = new THREE.PointLight(0xffffc0, 1000, 1000);
        light.position.setY(200);
        scene.add(light);

        if (props.hotspots?.length) {
            scene.add(...getHotspotMeshes(props.hotspots));
        }

        return scene;
    }

    function update(context: ExpoWebGLRenderingContext, renderer: Renderer, scene: THREE.Scene, timeDelta: number) {

        if (!useSensors.current) {
            velocityX.current *= 0.9;
            velocityY.current *= 0.9;
            velocityZ.current *= 0.9;
            cameraPosition.current.lon -= velocityX.current * timeDelta * 0.2;
            cameraPosition.current.lat += velocityY.current * timeDelta * 0.2;

            const lon = cameraPosition.current.lon;
            let lat = cameraPosition.current.lat;
    
            // rotate slowly before user does any action
            if (isUserInteracting.current === false) {
                cameraPosition.current.lon += timeDelta * 2;
            }

            lat = cameraPosition.current.lat = Math.max(-75, Math.min(85, lat));
            updateCameraTarget(lat, lon);

            camera.current!.lookAt(camera.current!.target!);
        } else {
            camera.current!.lookAt(camera.current!.target!);

            camera.current!.rotateZ(cameraPosition.current.alpha);
            camera.current!.rotateX(cameraPosition.current.beta);
            camera.current!.rotateY(cameraPosition.current.gamma);
        }

        renderer.render(scene, camera.current!);

        // required on mobile to actually render something
        context.endFrameEXP();

        if (!mousePosition.current) {
            return;
        }

        const tapX = mousePosition.current.x;
        const tapY = mousePosition.current.y;

        const normalizedX = (tapX / layoutWidth.current) * 2 - 1;
        const normalizedY = -(tapY / layoutHeight.current) * 2 + 1;

        raycaster.setFromCamera(
            { x: normalizedX, y: normalizedY },
            camera.current!);

        const intersects = raycaster.intersectObjects(scene.children.filter(c => c.userData?.isHotspot));

        if (Platform.OS === "web") {
            const canvas = document.getElementById("panorama-canvas");
    
            if (canvas) {
                if (intersects.length) {
                    canvas.style.cursor = 'pointer';
                } else {
                    canvas.style.cursor = cursorType.current;
                }
            }
        }
    }

    const lastTime = useRef(0);

    function animate(context: ExpoWebGLRenderingContext, renderer: Renderer, scene: THREE.Scene, currentTime: number) {
        const timeDelta = currentTime - lastTime.current;
        lastTime.current = currentTime;

        if (!dismounted.current) {
            requestAnimationFrame(t => animate(context, renderer, scene, t));
            update(context, renderer, scene, timeDelta / 1000);
        }
    }

    const onContextCreate = (gl: ExpoWebGLRenderingContext) => {
        renderer.current = new Renderer({ gl });
        renderer.current!.setPixelRatio(window.devicePixelRatio);
        layoutWidth.current = gl.drawingBufferWidth / window.devicePixelRatio;
        layoutHeight.current = gl.drawingBufferHeight / window.devicePixelRatio
        renderer.current.setSize(layoutWidth.current, layoutHeight.current);
        scene.current = initAndGetScene();
        setIsSceneInitializing(false);
        animate(gl, renderer.current, scene.current, 0);
    };

    useEffect(() => {
        if (currentSceneImage !== props.image) {
            setIsLoading(true);
            scene.current!.clear();
            scene.current!.add(getMeshWithImage());

            if (props.hotspots?.length) {
                scene.current!.add(...getHotspotMeshes(props.hotspots));
            }

            setCurrentSceneImage(props.image);
        }
    }, [props.image]);

    useEffect(() => {

        if (scene.current) {
            scene.current!.remove(...scene.current!.children.filter(c => c.userData.isHotspot));
            if (props.hotspots?.length) {
                scene.current!.add(...getHotspotMeshes(props.hotspots));
            }
        }

    }, [props.hotspots]);

    const originalLon = useRef(0);
    const originalLat = useRef(0);
    const velocityX = useRef(0);
    const velocityY = useRef(0);
    const velocityZ = useRef(0);

    useEffect(() => {
        const onwheel = (ev: WheelEvent) => {
            camera.current!.fov += ev.deltaY * 0.25;
            camera.current!.fov = Math.min(100, Math.max(35, camera.current!.fov));
            camera.current!.updateProjectionMatrix();
        };

        window.addEventListener('wheel', onwheel);

        return () => window.removeEventListener('wheel', onwheel);
    }, []);
    
    useEffect(() => {
        if (document) {
            const listener = (ev: MouseEvent) => mousePosition.current = { x: ev.x, y: ev.y };
            document.addEventListener("mousemove", listener);

            return () => document.removeEventListener("mousemove", listener);
        }
    }, []);

    useEffect(() => {
        return () => { dismounted.current = true };
    }, []);

    const panGesture = Gesture.Pan()
        .onBegin(() => {
            isUserInteracting.current = true;
            originalLon.current = cameraPosition.current.lon;
            originalLat.current = cameraPosition.current.lat;
            cursorType.current = "drag";
        }).runOnJS(true)
        .onUpdate(e => {
            cameraPosition.current.lon = (-e.translationX * 0.1) + originalLon.current;
            cameraPosition.current.lat = (e.translationY * 0.1) + originalLat.current;
        }).runOnJS(true)
        .onEnd(e => {
            originalLon.current = cameraPosition.current.lon;
            originalLat.current = cameraPosition.current.lat;

            velocityX.current = e.velocityX;
            velocityY.current = e.velocityY;
            cursorType.current = "default";
        }).runOnJS(true);

    const pinchGesture = Gesture.Pinch()
        .onBegin(() => {
            isUserInteracting.current = true;
        }).runOnJS(true)
        .onUpdate(e => {
            camera.current!.fov = Math.min(100, Math.max(35, (-e.scale + 2) * initialFov));
            camera.current!.updateProjectionMatrix();
        }).runOnJS(true);

    const tapGesture = Gesture.Tap()
        .onEnd(e => {
            if (!scene.current) {
                return;
            }

            const tapX = Platform.OS === "web" ? e.x : e.absoluteX;
            const tapY = Platform.OS === "web" ? e.y : e.absoluteY;

            const normalizedX = (tapX / layoutWidth.current) * 2 - 1;
            const normalizedY = -(tapY / layoutHeight.current) * 2 + 1;

            raycaster.setFromCamera(
                { x: normalizedX, y: normalizedY },
                camera.current!);

            const intersects = raycaster.intersectObjects(scene.current!.children.filter(c => c.userData?.isHotspot));

            intersects.forEach(i => i.object.userData?.onPress?.());
        }).runOnJS(true);

    const gestures = props.useSensors ? Gesture.Race(pinchGesture, tapGesture) : Gesture.Race(pinchGesture, panGesture, tapGesture);

    return <>
        <GestureHandlerRootView style={{ flex: 1, width: '100%', height: '100%'  }}>
            <GestureDetector gesture={gestures}>
                <GLView
                    nativeID='panorama-canvas'
                    style={{ flex: 1, width: '100%', height: '100%' }}
                    onContextCreate={onContextCreate}
                    onLayout={(event) => {
                        var { width, height } = event.nativeEvent.layout;
                        layoutWidth.current = width;
                        layoutHeight.current = height;
                        camera.current?.updateProjectionMatrix();
                        renderer.current?.setSize(width, height);
                    }}
                >
                </GLView>
            </GestureDetector>
        </GestureHandlerRootView>
        {(isLoading || isSceneInitializing) && <ActivityIndicator
            style={{ position: 'absolute', backgroundColor: 'white', width: '100%', height: '100%', zIndex: 100, elevation: 100 }}
            size="large" />}
    </>
}

interface WebGLPanoramaProps {
    image: any,
    hotspots?: Hotspot[],
    useSensors: boolean
}

export interface Hotspot {
    width?: number,
    height?: number,
    image?: any,
    positionRelativeToCamera: HotspotPosition,
    onPress: () => any,
    description?: string
}

export interface HotspotPosition {
    degreesHorizontal: number,
    degreesVertical: number
}