import {Button, Surface, Text} from "react-native-paper";
import {LinearGradient} from "expo-linear-gradient";
import {Image, ImageSourcePropType, StyleSheet, View} from "react-native";
import {useNavigation} from "@react-navigation/native";
import moment from "moment";
import { EventDto } from "../../api";

function MultilineText(props: { text: string }) {
    return  <Text variant={"bodyMedium"}>{props.text.split("\n").map(t => <>{t}{"\n"}</>)}</Text>
}

export default function NewsTile(props: NewsTileProps) {

    const navigation = useNavigation();

    return <Surface style={styles.tileWrapper} elevation={1}>
        <LinearGradient colors={["#f1bc000d", "#f1bc000d"]}>
            <Image style={{height: 169, width: "100%", resizeMode: "cover" }} resizeMode="cover" source={props.image}/>
            <View style={styles.textWrapper}>
                <Text variant={"labelLarge"}>{moment(props.date).format("yyyy-MM-DD HH:mm")}</Text>
                <Text variant={"titleMedium"} style={{marginVertical: 8}}>{props.header}</Text>
                <MultilineText text={props.firstParagraph} />
                <View style={styles.buttonsWrapper}>
                    <Button mode={"contained"} onPress={() => navigation.navigate("NewsTileDetails" as never, { id: props.item.id } as never)}>
                        <Text variant={"labelLarge"}>Czytaj więcej</Text>
                    </Button>
                </View>
            </View>
        </LinearGradient>
    </Surface>
}

export interface NewsTileProps {
    item: EventDto,
    date: string,
    header: string,
    firstParagraph: string,
    image: ImageSourcePropType;
}

const styles = StyleSheet.create({
    tileWrapper: {
        position: "relative",
        borderRadius: 12,
        overflow: "hidden",
    },
    textWrapper: {
        padding: 16
    },
    buttonsWrapper: {
        width: 140,
        marginTop: 24
    },
    buttonText: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 10
    }
})
