import {useNavigation} from "@react-navigation/native"
import React from "react";
import {View, TouchableOpacity, Dimensions, useWindowDimensions} from "react-native";
import Icon from "react-native-paper/src/components/Icon";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {CombinedTheme} from "../../styles/theme";
import Logo from "../icons/logo";
import StyleSheet from "react-native-media-query";
import MenuSider from "../../pages/menuSider";

export default function Header(props: HeaderProps) {

    const navigation = useNavigation();
    const {top} = useSafeAreaInsets();
    const { width, height } = useWindowDimensions();

    const onBack = () => {
        navigation.goBack();
    }

    const onMenu = () => {
        navigation.navigate("MenuModal" as never);
    }

    return <>
        <View style={[styles.mobileHeader, {paddingTop: top + 8}]} dataSet={{media: ids.mobileHeader}}>
            <View style={styles.topBar}>
                {props.hideBackButton
                    ? <View style={{width: 26}}/>
                    : <TouchableOpacity accessibilityLabel="Wstecz" onPress={onBack}>
                        <Icon source="arrow-left" size={26}/>
                    </TouchableOpacity>}
                <Logo width={44} height={44}/>
                <TouchableOpacity accessibilityLabel="Menu" onPress={onMenu}>
                    <Icon source="menu" size={26}/>
                </TouchableOpacity>
            </View>
        </View>
        <View style={styles.webHeader} dataSet={{media: ids.webHeader}}>
            <MenuSider height={height}/>
        </View>
    </>
}

interface HeaderProps {
    hideBackButton?: boolean
}

const {ids, styles} = StyleSheet.create({
    webHeader: {
        display: "flex",
        zIndex: 1,
        '@media (max-width: 1400px)': {
            display: "none"
        }
    },
    mobileHeader: {
        paddingHorizontal: 26,
        zIndex: 1,
        backgroundColor: CombinedTheme.colors.background,
        paddingBottom: 8,
        '@media (min-width: 1401px)': {
            display: "none"
        }
    },
    topBar: {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between"

    }
});
