import * as React from "react"
import Svg, { SvgProps, Rect, Path } from "react-native-svg"
import { memo } from "react"

const PauseButton = (props: SvgProps) => (
  <Svg
    width={48}
    height={48}
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <Rect width={32} height={32} rx={16} fill="#F3C32A" />
    <Path
      d="M10.857 22h3.429V10h-3.429v12Zm6.857-12v12h3.429V10h-3.429Z"
      fill="#1C1B1F"
    />
  </Svg>
)

const Memo = memo(PauseButton)
export default Memo
