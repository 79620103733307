import TrailTile from "./trailTile";
import React, { useEffect, useState } from "react";
import {Platform, View} from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { EducationPointData, educationPointsData } from "../../data/educationPoints";
import StyleSheet from "react-native-media-query";

export default function TrailTileList() {

    if (Platform.OS !== "web") {
        const [isLoading, setIsLoading] = useState<boolean>(true);
    
        useEffect(() => {
            // avoid delaying the navigation when rendering all tiles
            const timeout = setTimeout(() => setIsLoading(false), 1);
    
            return () => clearTimeout(timeout);
        }, []);
    
        if (isLoading) {
            return <ActivityIndicator />;
        }
    }

    return <View style={styles.listWrapper} dataSet={{ media: ids.listWrapper }}>
        {educationPointsData.map((data: EducationPointData, idx: number) => {
            return <View key={idx} style={styles.listItem} dataSet={{ media: ids.listItem }}>
                <TrailTile boardNumber={idx + 1} boardData={data} key={idx}/>
            </View>
        })}
    </View>
}

const { ids, styles } = StyleSheet.create({
    listWrapper: {
        display: "flex",
        '@media (min-width: 800px)': {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "stretch"
        }
    },
    listItem: {
        position: "relative",
        '@media (min-width: 800px)': {
            width: "50%",
            padding: 10,
        }
    }
})
