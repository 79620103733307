import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { View, StyleSheet, AppState, Image } from "react-native";
import { ActivityIndicator, Text, IconButton, Surface } from "react-native-paper";
import Icon from "react-native-paper/src/components/Icon";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import useArModelPath from "../../hooks/useArModelPath";
import ARView from "./arView";

const prodAnchorId = "8f527c00-0c26-4423-9c57-f8728c5ab8b3";
const prodSupportAnchorId = "8b20cd51-382d-4d93-a875-131b29ddb366";

const supportAnchorIds: string[] = [
    prodSupportAnchorId,
];

const anchorX = 2;
const anchorY = -0.5;
const anchorZ = 2.7;
const anchorScale = 1.03;
const anchorScaleX = 1.01;
const anchorRotation = -14.7;

export default function ARViewContainer() { 
    const [isLoading, setIsLoading] = useState(false);
    const [isAnchorCreated, setIsAnchorCreated] = useState<boolean>(false);
    const [shouldDisplayHint, setShouldDisplayHint] = useState<boolean>(false);
    const anchorId = prodAnchorId;//"6ed0baf6-beeb-4d9a-8b3f-888c0b876faa");//"ecce5da2-cc8d-4fca-96de-450fcedd9c56");//"f2de36b5-a266-4340-b19c-63026f8fcb06");//"94340b96-5891-4dbf-9e23-7b7ae18ef072");
    const { modelPath, progress } = useArModelPath();
    const navigation = useNavigation();
    const { top, left, bottom } = useSafeAreaInsets();

    useEffect(() => {
        console.log(isAnchorCreated);
        if (isAnchorCreated) {
            setShouldDisplayHint(true);

            const timeout = setTimeout(() => setShouldDisplayHint(false), 5000);
    
            return () => clearTimeout(timeout);
        }
    }, [isAnchorCreated]);

    useEffect(() => {
        const sub = AppState.addEventListener("change", nextAppState => {
            console.log("app state changed", nextAppState);
            if (nextAppState !== "active") {
                navigation.navigate("EducationBoard4" as never);
            }
        });

        return () => sub.remove();
    }, []);

    if (!modelPath) {
        return <View style={{ flex: 1, width: "100%", justifyContent: "center", alignItems: "center" }}>
            <ActivityIndicator />
            <Text variant="bodyMedium">{Math.round(progress)}%</Text>
            <Text variant="bodyLarge" style={{ marginTop: 10 }}>Proszę czekać, trwa pobieranie modelu 3D</Text>
        </View>
    }

    return <>
        <ARView
            modelAssetPath={modelPath.replace("file://", "")}
            style={styles.arView}
            spatialAnchorId={anchorId}
            anchorX={+anchorX}
            anchorY={+anchorY}
            anchorZ={+anchorZ}
            anchorScaleX={+anchorScaleX}
            anchorScale={+anchorScale}
            anchorRotation={+anchorRotation}
            supportAnchorIds={supportAnchorIds}
            onARError={e => {
                console.log("onError", e.nativeEvent);
                navigation.navigate("EducationBoard4" as never);
                alert(e.nativeEvent.message);
            }}
            onStatusMessage={e => {
                console.log("onStatusMessage", e.nativeEvent.message);
                //setStatusMessage(e.nativeEvent.message);
            }}
            onCloudAnchorUploaded={async (e) => {
                console.log("CLOUD ANCHOR UPLAODED", e.nativeEvent.id);
            }}
            onAnchorCreated={() => setIsAnchorCreated(true)}
            onModelLoading={e => {
                console.log("onModelLoading", e.nativeEvent);
                setIsLoading(e.nativeEvent.loading);
            }}
        />
        {isLoading && <View style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 1, flex: 1, width: "100%" }}>
            <ActivityIndicator />
        </View>}

        <View style={styles.overlay}>
            <IconButton
                mode="contained"
                selected={true}
                accessibilityLabel="Zamknij widok AR"
                onPress={() => {
                    navigation.navigate("EducationBoard4" as never);
                }}
                size={36}
                icon={(props) => <Icon {...props} source="arrow-left" size={24} />}
                style={[{top, left}, styles.backButton]} />

            {(!isAnchorCreated || shouldDisplayHint) && <View style={{ bottom, position: "absolute", alignSelf: "center" }}>
                {!isAnchorCreated && <Image source={require("../../assets/ar-sample.jpg")} style={{ width: "90%", alignSelf: "center", resizeMode: "contain", opacity: .5 }} />}
                <Surface style={[styles.hintContainer]}>
                    {!isAnchorCreated && <Text style={{ textAlign: "center" }} variant='bodyLarge'>Upewnij się, że znajdujesz się w pobliżu fundamentów Pałacu. Skieruj aparat telefonu na tablicę informacyjną "Pałac", aby skalibrować lokalizację.</Text>}
                    {shouldDisplayHint && <Text style={{ textAlign: "center" }} variant='bodyLarge'>Zlokalizowano poprawnie! Skieruj aparat na obrys fundamentów Pałacu aby wyświetlić wizualizację.</Text>}
                </Surface>
            </View>}
        </View>
    </>
}

const styles = StyleSheet.create({
    arView: {
        width: "100%",
        flex: 1
    },
    overlay: {
        flex: 1,
        width: "100%",
        height: "100%",
        position: "absolute",
        padding: 24
    },
    backButton: {
    },
    hintContainer: {
        marginBottom: 24,
        borderRadius: 12,
        padding: 24,
        opacity: 0.9
    },
});